import React from 'react'
import {
  compose,
  withProps,
  withState,
  wrapDisplayName,
  lifecycle,
} from 'recompose'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import { Map, is } from 'immutable'
import _isEqual from 'lodash/isEqual'
import _pick from 'lodash/pick'
import _merge from 'lodash/merge'
import _zipObject from 'lodash/zipObject'
import _fill from 'lodash/fill'

import { formValuesSelector } from 'utils/reduxFormUtils'

const withFormValuesOnChange = (formName, fields, onChange) => WrappedComponent => {
  const WithFormValuesOnChange = props => <WrappedComponent {...props} />

  if (process.env.NODE_ENV !== 'production') {
    WithFormValuesOnChange.displayName = wrapDisplayName(
      WrappedComponent,
      'withFormValuesOnChange',
    )
  }

  return compose(
    withState('currentValues', 'setCurrentValues', {}),
    connect(
      createStructuredSelector({
        formValues: formValuesSelector(formName),
      }),
    ),
    withProps(({ name, formValues, currentValues, setCurrentValues, dispatch, ...props }) => ({
      updateValue: () => {
        const subset = _pick(formValues, fields)
        const defaults = _zipObject(fields, _fill(Array(fields.length), 0))
        const merged = _merge(defaults, subset)
        const subsetMap = Map(merged)
        const current = Map(currentValues) 
        
        const handleChange = (field, value) => dispatch(change(formName, field, value))
        
        if(!_isEqual(subsetMap, current)) {
            setCurrentValues(merged)
            
            if( onChange )
              handleChange(name, onChange(merged, props))
        }
      }
    })),
    lifecycle({
        componentDidUpdate() {
            const { updateValue } = this.props
            updateValue()
        },
        componentDidMount() {
          const { updateValue } = this.props
            updateValue()
        },
        shouldComponentUpdate(nextProps, nextState) {
          const { formValues } = this.props
          const subset = _pick(formValues, fields)
          const newSubset = _pick(nextProps.formValues, fields)
          
          if(!_isEqual(subset, newSubset))
            return true
          return false
        }
    }),
  )(WithFormValuesOnChange)
}

export default withFormValuesOnChange
