import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Link, Switch, Route } from 'react-router-dom'

import ClientActions from 'actions/ClientActions'

import ClientListItem from 'components/ListItems/ClientListItem'
import { SecondaryButton } from 'components/Buttons'
import Spinner from 'components/globals/Spinner'
import { Message } from 'components/Messages'


import ViewClient from '../client/ViewClient'
import ReviewLoan from '../client/ReviewLoan'
import EditClient from '../client/EditClient'

import LoadingPanel from 'components/LoadingPanel'

class Clients extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isFetching: false
    }
  }


  componentDidMount() {
    this.setState({ isFetching: true })
    this.props.LoadAllClients(() => {
      this.setState({ isFetching: false })
    });
  }

  renderClients() {
    const { LoadAllClients } = this.props
    const display = [];

    let key = 0;

    if(this.state.isFetching)
      return null

    if( this.props.clients.length == 0 ) {
      return <Message text="No clients found." />
    }

    this.props.clients.forEach( client => {
      display.push(<ClientListItem key={key++} {...{ ...client, onComplete: () => LoadAllClients() }} />)
    });

    return display;
  }

  render() {
    return(
      <div className='page clients'>
        <Switch>
          <Route exact path='/dashboard/clients'>
            <div>
              <div className='info page-heading'>
                <h2>
                  Clients
                  <SecondaryButton to='/dashboard/new-client' darkGray>CREATE</SecondaryButton>
                </h2>
              </div>
              <div className='page-content listing'>
                {this.state.isFetching && <LoadingPanel text="Loading clients." /> }
                {!this.state.isFetching && this.props.clients.length > 0 && 
                  <div className='thead'>
                    <div className='row'>
                      <div className='col-sm-6'>Client Name</div>
                    </div>
                  </div>
                }
                <div className='items'>
                  {this.renderClients()}
                </div>
              </div>
            </div>  
          </Route>
          <Route path='/dashboard/clients/details/:id' component={ViewClient} />
          <Route path='/dashboard/clients/edit/:id' component={EditClient} />
          <Route path='/dashboard/clients/review-loan/:id' component={ReviewLoan} />

        </Switch>
        
      </div>
    )
  }
}

const mapState = state => {
  return {
    clients: state.global.clients,
  }
}

const mapDispatch = dispatch => {
  return {
    LoadAllClients: (cb) => {
      dispatch(ClientActions.LoadAll(cb));
    },

  }
}

export default connect(mapState, mapDispatch)(Clients);
