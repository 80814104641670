import { createSelector } from 'reselect'

import _get from 'lodash/fp/get'

export const selectTemplate = createSelector(
    state => state.global,
    _get("template")
)

export const selectInitialValues = createSelector(
    selectTemplate,
    template => {
        return {
            ...template
        }
    }
)

export const selectIsFetching = createSelector(
    state => state.global,
    _get("fetching")
)

export const selectTemplateId = createSelector(
    selectTemplate,
    _get("_id")
)