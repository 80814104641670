import { createSelector } from 'reselect'

import _get from 'lodash/fp/get'

export const selectLoan = createSelector(
    state => state.global,
    _get("loanDetails")
)

export const selectLoanId = createSelector(
    selectLoan,
    _get("_id")
)

export const selectInitialValues = createSelector(
    selectLoan,
    loan => {
        return {
            quantitySurveyor: _get("quantitySurveyor", loan),
            solicitor: _get("solicitor", loan),
            valuer: _get("valuer", loan),
        }
    }
)