import styled from 'styled-components'

import Row from 'components/Row'

export default styled.div`
    font-size: 14px;
    border: 1px solid #ccc;
    border-bottom: none;
    padding: 10px;

    &:last-child {
        border-bottom: 1px solid #ddd;
    }
    
    ${Row} {
        margin: 0px;
        > div {
            padding: 0px;
        }
    }
`