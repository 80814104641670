import React from 'react'
import { compose } from 'recompose'

import ListRecentEntriesContainer from 'containers/Wallet/ListRecentEntries'

import RecentEntryListItem from 'components/ListItems/RecentEntryListItem'

const RecentPayments = ({
    isFetching,
    entries,
}) => {
    return(
        <div className='due-payments'>
            <div className='info'>
                <h2>Recent Transactions</h2>
            </div>

            <div className='page-content listing'>
                <div className='thead'>
                    <div className='row'>
                        <div className='col-sm-4'>Authorized By</div>
                        <div className='col-sm-3'>Type</div>
                        <div className='col-sm-3'>Amount</div>
                    </div>
                </div>
                {entries && entries.map(entry => 
                    <RecentEntryListItem {...{ ...entry, key: entry._id }} />   
                )}
            </div>
        </div>
    )
}

export default compose(
    ListRecentEntriesContainer,
)(RecentPayments)