import React from 'react'
import { compose } from 'recompose'
import { Redirect } from 'react-router-dom'

import Row from 'components/Row'
import Page from 'components/Page'
import { Message } from 'components/Messages'
import { Panel } from 'components/Forms'
import { PrimaryButton } from 'components/Buttons' 
import LoadingPanel from 'components/LoadingPanel'

import EditTemplateContainer from 'containers/Templates/EditTemplate'
import * as TemplateFields from 'components/FormFields/Template'

const EditTemplate = ({
    handleSubmit,
    isUpdating, 
    redirectUrl,
    isFetching,
    submitSucceeded,
}) => {
        
    return(
      <Page title='Edit Template'>
        <Panel title="Template Fields">
            {submitSucceeded && !isUpdating && 
                <Message success text="Template updated successfully." />
            } 
            <Row>
                <TemplateFields.TemplateNameField />
            </Row>
            <Row>
                <TemplateFields.TemplateDescriptionField />
            </Row>
            <Row>
                <TemplateFields.TemplateContentField />
            </Row>
            <Row style={{ justifyContent: "flex-end" }}>
                <PrimaryButton onClick={handleSubmit}>UPDATE</PrimaryButton>
            </Row>
        </Panel>
      </Page>
    )
}

export default compose(
    EditTemplateContainer,
)(EditTemplate)