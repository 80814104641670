import React from 'react'

const Popup = ({
  title,
  handleToggle,
  button,
  children,
}) => (
  <div className='popup-outer'>
    <div className='popup-overlay' onClick={() => {handleToggle(false)}}></div>
    <div className='popup-content'>
        <a className='popup-close-btn' onClick={() => handleToggle(false)}></a>
        {title &&
          <div className='popup-title'>
            <h2>{title}</h2>
          </div>
        }
        <div className='popup-content-inner'>
          {children}
          {button && 
            <div className='popup-buttons'>
              {button}
            </div>
          }
        </div>
    </div>
  </div>
)

export default Popup