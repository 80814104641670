import React from 'react'
import { compose } from 'recompose'

import Page from 'components/Page'
import { SecondaryButton } from 'components/Buttons'
import LoadingPanel from 'components/LoadingPanel'

import ViewLoanContainer from 'containers/Loan/ViewLoan'
import ActivateLoanContainer from 'containers/Loan/ActivateLoan'

import LoanTabNavigation from './LoanTabNavigation'
import LoanTabs from './LoanTabs'

import RedeemPopup from './views/RedeemPopup'

const ViewLoan = ({ 
  id, 
  loan, 
  getLoan,
  isFetching,
  activateLoan,
  toggleRedeemPopup,
  redeemPopupVisible,
  ...props 
}) => {

  if( isFetching || !loan )
        return <LoadingPanel text="Loading loan details." />

  return(
    <Page title=''
      buttons={
        <React.Fragment>

        </React.Fragment>

      }>
      {/* <LoanTabNavigation id={id} /> */}
      <LoanTabs id={id} loan={loan} />
    </Page>
  )
}

export default compose(
  ViewLoanContainer,
  ActivateLoanContainer,  
)(ViewLoan)