import React from 'react'

import Page from 'components/Page'

import DirectorsList from './views/DirectorsList'

const Overview = props => {
    return(
        <Page title=''>
            <DirectorsList {...props} />
        </Page>
    )
}
export default Overview