import React, { Component } from 'react';
import {connect} from 'react-redux';
import { NavLink, Link } from 'react-router-dom'
import GlobalActions from '../../actions/GlobalActions';

const qs = require('query-string');

class AllowAccess extends Component {
        
    componentDidMount() {
        
        var search = window.location.search;
        var params = qs.parse(search);

        if( params.code != null ) {
            this.props.Allow(params.code);
        }
    }

    render() {
        return (
        <div id='allow-access-page' className='page full-page'>
            <div className='inner'>
                <div className='container'>
                    <div className='allow-msg'>
                        <h2>Access Granted</h2>
                        <p><Link to="/" className='btn btn-blue'>Login</Link></p>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

const mapState = (state) => {
    return {

    }
}

const mapDispatch = (dispatch) => {
    return {
        Allow: (code) => {
            GlobalActions.AllowAccess(code);
        }
    }
}


export default connect(mapState, mapDispatch)(AllowAccess);
