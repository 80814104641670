import React from 'react'

import LoanTermDataHeadersWrapper from './LoanTermDataHeadersWrapper'

const LoanTermDataHeaders = () => {
    return(
        <LoanTermDataHeadersWrapper>
            <div className='row'>
                <div className='col-sm-3'>P. Start</div>
                <div className='col-sm-3'>P. End</div>
                <div className='col-sm-3'>Amount</div>
                <div className='col-sm-3'>Compounded</div>
            </div>
        </LoanTermDataHeadersWrapper>
    )
}

export default LoanTermDataHeaders