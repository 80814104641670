import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withFormValuesOnChange } from 'components/HOC'
import { PriceField } from 'components/FormFields'
import { AddLumpSumForm } from 'containers/Loan/AddLumpSum/consts'

import { selectCurrentGrossAmount } from 'containers/Loan/Common/selectors'

const mapState = createStructuredSelector({
    currentGrossAmount: selectCurrentGrossAmount,
})

export default compose(
    connect(mapState),
    withProps(() => ({
        name: 'remainingAmount',
        label: "Remaining Amount",
        prependNumber: "£",
        readOnly: true,
    })),
    withFormValuesOnChange(
        AddLumpSumForm, 
        ['amount'],
        ({ amount }, {currentGrossAmount}) => {
            return parseFloat(currentGrossAmount) - parseFloat(amount)
        }
    ),
)(PriceField)