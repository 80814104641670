import { compose, withProps } from 'recompose'

import { withFormValuesOnChange } from 'components/HOC'
import { PercentageField } from 'components/FormFields'
import { AddLoanForm } from 'containers/Loan/AddLoan/consts'

export default compose(
    withProps(() => ({
        name: 'ltv',
        label: "Loan To Value",
        appendNumber: "%",
        readOnly: true,
    })),
    withFormValuesOnChange(
        AddLoanForm, 
        ['grossAmount', 'mpPrice'],
        ({ grossAmount, mpPrice }) => {
            if( mpPrice > 0 )
                return 100 * (parseFloat(grossAmount) /  parseFloat(mpPrice))
            return 0
        }
    ),
)(PercentageField)