import API from 'api'

const getRedemptionStatementSubmit = () => ({ type: "FETCH/REQUEST" })
const getRedemptionStatementSuccess = (key, data) => ({ type: "FETCH/SUCCESS", key, data })
const getRedemptionStatementError = err => ({ type: "FETCH/ERROR", error: err})

export const getRedemptionStatement = (loanId, values, callback) => dispatch => {
    dispatch(getRedemptionStatementSubmit())

    

    API
        .post(`/api/loans/statement?id=${loanId}`, { body: JSON.stringify(values)})
        .then(res => res.json())
        .then(json => { 
            dispatch(getRedemptionStatementSuccess(`${loanId}_statement`, json.statement)) 
            if(callback) callback(json)
        })
        .catch(err => dispatch(getRedemptionStatementError(err)))
}

const redeemLoanSubmit = () => ({ type: "FETCH/REQUEST" })
const redeemLoanSuccess = data => ({ type: "FETCH/SUCCESS", data })
const redeemLoanError = err => ({ type: "FETCH/ERROR", error: err})

export const redeemLoan = (loanId, values, callback) => dispatch => {
    dispatch(redeemLoanSubmit())

    API
        .post(`/api/loans/redeem?id=${loanId}`, { body: JSON.stringify(values)})
        .then(res => res.json())
        .then(json => { 
            dispatch(redeemLoanSuccess(json)) 
            if(callback) callback(json)
        })
        .catch(err => dispatch(redeemLoanError(err)))
}