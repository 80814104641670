import styled from 'styled-components'

import { COLORS } from 'consts'

import { getColor } from 'utils/color'

import ButtonBase from './ButtonBase'

const activeStyles = props => `
  background: ${COLORS.blue};

  ${!props.toggleButton &&
    `
    pointer-events: none;

    :hover {
      box-shadow: none;
      cursor: default;
      background: ${COLORS.blue};
      color: ${getColor(props, COLORS.blue)}
    }
  `};
`

export default styled(ButtonBase)`
  height: 26px;
  font-weight: bold;
  padding: 0 12px;
  font-size: 12px;
  border-radius: 4px;
  text-decoration: none;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  i {
    font-size: 9px;
    margin-right: 6px;
    margin-left: -6px;
  }

  ${props => `
    color: ${COLORS.white};
    background: ${getColor(props, COLORS.blue)};
    border: 1px solid ${getColor(props, COLORS.blue)};
  `};

  ${props => props.active && activeStyles(props)};

  &.active {
    ${props => activeStyles(props)};
  }

  ${props => props.justText && `
    border: none;
    background: none;
    color: ${getColor(props, COLORS.blue)};

    &:hover {
      color: ${COLORS.blue};
      box-shadow: none;
    }
  `}

  ${props => props.disabled && `
    opacity: 0.25;
    cursor: not-allowed;
  `}
`
