import React from 'react'

const Page = ({
    title,
    className,
    children,
    ...props
}) => (
    <div className={`page ${className}`} {...props}>
        <div className='info page-heading'>
            <h2>{title}</h2>
        </div>
        <div className='page-content'>
            {children}
        </div>
    </div>
)

export default Page