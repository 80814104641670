import API from 'api'

const removeClientRequest = () => ({ type: "FETCH/REQUEST" })
const removeClientSuccess = data => ({ type: "FETCH/SUCCESS", data })
const removeClientError = err => ({ type: "FETCH/ERROR", error: err})

export const removeClient = (id, callback) => dispatch => {
    dispatch(removeClientRequest())

    API
        .post(`/api/clients/delete?id=${id}`)
        .then(res => res.json())
        .then(json => { 
            dispatch(removeClientSuccess(json))
            if(callback)
                callback()
        })
        .catch(err => {  dispatch(removeClientError(err)) })
}