import React from 'react'
import moment from 'moment'
import { compose, withHandlers, withState, withProps } from 'recompose'
import _some from 'lodash/fp/some'
import _every from 'lodash/fp/every'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faFolderOpen } from '@fortawesome/free-solid-svg-icons'


import { withRedirect } from 'components/HOC'
import { SecondaryButton } from 'components/Buttons'
import ListLoanChecksContainer from 'containers/Checks/ListLoanChecks'
import ChecksContainer from 'containers/Loan/Checks'

import { priceFormatter } from 'helpers/Helpers'

import { DEFAULT_DATE_FORMAT } from 'consts'

import GenericListItem from '../GenericListItem'
import LoanStatus from './LoanStatus'
import { Link } from 'react-router-dom'

library.add(faCheckCircle, faFolderOpen)

const LoanListItem = ({
    _id,
    grossAmount,
    currentGrossAmount,
    startDate,
    client,
    status,
    checks,
    redirectUrl,
    setRedirecting,
    isRedirecting,
    onClick,
    firstLevelChecked,
    secondLevelChecked,
    thirdLevelChecked,
    ...props
}) => {
    
    return (
        <GenericListItem className='loan' {...props}>
            <div className='col client-name'>{client.entityName}</div>
            <div className='col'>
                <LoanStatus className={status}>{status}</LoanStatus>
            </div>
            <div className='col'>£{priceFormatter(parseFloat(grossAmount).toFixed(2))}</div>
            <div className='col'>{moment(startDate).format(DEFAULT_DATE_FORMAT)}</div>
            {/* {checks && checks.map((item, index) => {
                const isChecked = _every({ isChecked: true }, item.levels)
                const awaitingUserCheck = _some({ isUserLevel: true, isChecked: false }, item.levels)
                
                if(!isChecked && !awaitingUserCheck) {
                    return(
                        <div className='col'>
                            <LoanStatus className='gray'>Awaiting</LoanStatus>
                        </div>
                    )
                } else if(!isChecked && awaitingUserCheck) {
                    return(
                        <div className='col'>
                            <SecondaryButton to={`/dashboard/loans/details/${_id}/checklist`}>Sign</SecondaryButton>
                        </div>
                    )
                }
                return(
                    <div className='col loan-check-icon'>
                        <FontAwesomeIcon icon={['fal', 'check-circle']} />
                    </div>
                )
            })} */}
            <div className='col'>
                {firstLevelChecked ? <FontAwesomeIcon icon={['fal', 'check-circle']} /> : 'Awaiting'}
            </div>
            <div className='col'>
                {secondLevelChecked ? <FontAwesomeIcon icon={['fal', 'check-circle']} /> : 'Awaiting'}
            </div>
            <div className='col'>
                {thirdLevelChecked ? <FontAwesomeIcon icon={['fal', 'check-circle']} /> : 'Awaiting'}
            </div>
            <div className='col'>
                <Link to={`/dashboard/loans/details/${_id}/documents`}>
                    <FontAwesomeIcon icon={['fal', 'folder-open']} />
                </Link>
            </div>
            <div className='col text-right'>
                <SecondaryButton onClick={onClick} style={{ marginRight: 5 }}>VIEW</SecondaryButton>
                {/* <SecondaryButton justText darkGray>REMOVE</SecondaryButton> */}
            </div>
        </GenericListItem>
    )
}

export default compose(
    withProps(({ _id }) => ({
        loanId: _id,
        redirectUrl: `/dashboard/loans/details/${_id}`
    })),
    ListLoanChecksContainer,
    withRedirect,
    withHandlers({
        onClick: ({ setRedirecting, ...props }) => event => {
            setRedirecting(true)
        }
    }),
    withProps(({
        checks
    }) => ({
        firstLevelChecked: _every({ firstLevel: { checked: true }}, checks),
        secondLevelChecked: _every({ secondLevel: { checked: true }}, checks),
        thirdLevelChecked: _every({ thirdLevel: { checked: true }}, checks),
    }))
)(LoanListItem)