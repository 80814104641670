import { createSelector } from 'reselect'

import _get from 'lodash/fp/get'

export const selectLoan = createSelector(
    state => state.global,
    _get("loanDetails")
)

export const selectExtensions = createSelector(
    selectLoan,
    _get("extensions")
)