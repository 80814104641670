import styled from 'styled-components'
import DocumentMediaImage from './DocumentMediaImage';

export default styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .dropzone {
        height: 100%;
    }

    ${props => props.inline && `
        height: auto;
        margin-bottom: 10px;
        padding: 10px 20px;
        background: none;
        border-radius: 15px;
        flex-basis: calc(25% - 10px);
        margin: 5px;
        padding-bottom: 15px;
        border: 1px dashed #c5c5c5;
        height: 153px;

        .dropzone {
            height: auto;
        }

        p {
            display: none;
        }

        ${DocumentMediaImage} {
            display: none;
        }

    `}
`