import React from 'react'
import { PropTypes } from 'prop-types'
import { compose, withStateHandlers, withHandlers } from 'recompose'

import { priceFormatter } from 'helpers/Helpers';

import InputWrapper from '../InputWrapper'
import TextFieldInput from '../TextField/TextFieldInput'
import FormattedNumberDisplay from './FormattedNumberDisplay'

const NumberField = ({
  autoFocus,
  disabled,
  input,
  large,
  label,
  nonFocusable,
  placeholder,
  readOnly,
  type,
  _onChange,
  _onBlur,
  _onFocus,
  focused,
  value,
  numberParser,
  decimalPlaces,
  forceValue,
  ...props
}) => {
  const decimals = typeof decimalPlaces != 'undefined' ? decimalPlaces : 2
  const parser = typeof numberParser != 'undefined' ? numberParser : parseFloat

  let val = typeof value != 'undefined' ? value : (input ? input.value : 0);
  
  if( typeof forceValue != 'undefined') {
    val = forceValue;
  }
  
  return (
    <InputWrapper
      hasValue={!!((input && input.value) || val)}
      wrapperHeight={large ? '80px' : '57px'}
      {...{  ...props, label, large, disabled, placeholder }}
    >
      <TextFieldInput
        {...{
          autoFocus,
          disabled,
          placeholder,
          readOnly: readOnly || nonFocusable,
          type,
          nonFocusable,
          large,
          onChange: _onChange,
          onFocus: _onFocus,
          onBlur: _onBlur,
          withLabel: !!label,
          hideValue: !focused,
          value: val,
        }}
      />  
      <FormattedNumberDisplay
        visible={!!(val && !focused)}
        value={parser((val)).toFixed(decimals)}
        formatter={priceFormatter}
        numberPrepend={props.numberPrepend && props.numberPrepend}
        numberAppend={props.numberAppend && props.numberAppend}
      />
    </InputWrapper>
  )
}

NumberField.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  large: PropTypes.bool,
  meta: PropTypes.object,
  nonFocusable: PropTypes.bool,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
}

export default compose(
  withStateHandlers(
    {
      focused: false,
    },
    {
      onFocus: () => () => ({
        focused: true,
      }),
      onBlur: () => () => ({
        focused: false,
      })
    }
  ),
  withHandlers({
    _onFocus: ({ input, onFocus }) => event => {
      if(onFocus)
        onFocus(event)
      if(input)
        input.onFocus()
    },
    _onBlur: ({ input, onBlur, ...props }) => event => {
      if(onBlur)
        onBlur(event)
      if(input)  
        input.onBlur()
    },
    _onChange: ({ input, onChange }) => event => {
      const keyValue = event.target.value

      if(isNaN(keyValue))
        return event.preventDefault()
  
      const strNumber = `${keyValue}`
      const dotPos = strNumber.indexOf('.')
      let decimalPlaces = 0

      if( dotPos >= 0 )
        decimalPlaces = strNumber.substr(dotPos + 1).length

      if( decimalPlaces > 2 )
        return event.preventDefault()

      if(onChange)
        onChange(event)
      else input.onChange(keyValue)
      
    },
  })
)(NumberField)
