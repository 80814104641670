import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import { SwitchField } from 'components/FormFields'

const validators = [validateRequired]

export default compose(
    withProps(() => ({
        wrapperConfig: { width: '100%' },
        showDetails: (checked) => { return checked ? true : false },
        label: "Has the company ever been a subject of any CCJ, Receivership, Bankrupcy or winding up order?",
        name: 'everBankrupt',
    })),
)(SwitchField)
