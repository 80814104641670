import moment from 'moment'
import { compose, withProps } from 'recompose'

import { PriceField } from 'components/FormFields'
import { withFormValuesOnChange } from 'components/HOC'
import { withFormValues } from 'components/HOC'

import { validateRequired } from 'utils/validators'

import { ExtensionFormName } from 'containers/Extensions/AddExtension/consts'

const validators = [validateRequired]

export default compose(
    withProps(() => ({
        name: 'grossAmount',
        label: "Gross Loan Amount",
        validators,
        readOnly: true,
    })),
)(PriceField)