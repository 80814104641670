import { connect } from "react-redux";
import { reduxForm, submit } from "redux-form";
import { compose, lifecycle, withState } from "recompose";
import { createStructuredSelector } from "reselect";
import { bindActionCreators } from "redux";

import { withFormValues } from "components/HOC";

import * as actions from "./actions";
import { FORM_NAME } from "./consts";
import {
  selectLoanCheckLevelData,
  selectLoanChecks,
  selectLoanId,
  selectLoanCheckRequest,
} from "./selectors";

const mapState = createStructuredSelector({
  levelData: selectLoanCheckLevelData,
  loanChecks: selectLoanChecks,
  loanId: selectLoanId,
  loanCheckRequest: selectLoanCheckRequest,
});

const mapDispatch = (dispatch) => ({
  ...bindActionCreators(actions, dispatch),
  submitForm: () => dispatch(submit(FORM_NAME)),
});

export default compose(
  connect(mapState, mapDispatch),
  withState("isUpdating", "setUpdating", false),
  reduxForm({
    form: FORM_NAME,
    onSubmit: (values, dispatch, { id, sendCheckRequest, level, loanId }) => {
      sendCheckRequest({ ...values, loanId });
    },
    enableReinitialize: true,
  }),
  withFormValues(FORM_NAME)
);
