import styled from "styled-components";

export default styled.div`
  position: fixed;
  left: 175px;
  top: 0px;
  height: 100%;
  width: 200px;
  background: #494949;
  z-index: 10;
`