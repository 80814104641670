import React from 'react'
import { compose, withProps, withHandlers, withState } from 'recompose'
import { Redirect } from 'react-router-dom' 

import Popup from 'components/globals/Popup'
import { withRedirect } from 'components/HOC'
import { SecondaryButton, PrimaryButton } from 'components/Buttons'

import { ClientType } from 'reducers/NewClientState'
import RemoveClientContainer from 'containers/Client/RemoveClient'

import GenericListItem from '../GenericListItem'

const ClientListItem = ({
    _id,
    companyName,
    personalDetails,
    clientType,
    handleView,
    handleEdit,
    handleRemove,
    togglePopup,
    redirectToEdit,
    popupVisible,
    ...props
}) => {

    const { firstName, lastName } = personalDetails ? personalDetails : { firstName: '', lastName: ''}

    if(redirectToEdit)
        return <Redirect to={`/dashboard/clients/edit/${_id}`} />

    return(
      <GenericListItem>
        <div className='col-sm-6' style={{paddingTop: 5}}>
            {clientType === ClientType.Corporate && <strong>{companyName}</strong>}
            {clientType === ClientType.Personal && <strong>{firstName} {lastName}</strong>}
        </div>
        <div className='col-sm-6 text-right'>
            <SecondaryButton onClick={handleView} style={{marginRight: 5}}>VIEW</SecondaryButton>
            <SecondaryButton onClick={handleEdit} style={{marginRight: 5}}>EDIT</SecondaryButton>
            <SecondaryButton justText darkGray onClick={togglePopup}>REMOVE</SecondaryButton>
        </div>
        {popupVisible && 
            <Popup 
                {...{
                    handleToggle: togglePopup,
                    button: 
                        <div>
                            <PrimaryButton onClick={handleRemove} style={{marginRight: 10}}>Confirm</PrimaryButton>
                            <PrimaryButton onClick={togglePopup} darkGray>Cancel</PrimaryButton>
                        </div>
                }}>
                <h3>Confirm Removal</h3>
                <p>Are you sure you want to remove this client?</p>
            </Popup>
        }
      </GenericListItem>
    )
}

export default compose(
    RemoveClientContainer,
    withState('popupVisible', 'setPopupVisible', false),
    withState('redirectToEdit', 'setRedirectToEdit', false),
    withProps(({ _id, popupVisible, setPopupVisible }) => ({
        redirectUrl: `/dashboard/clients/details/${_id}`,
        togglePopup: () => {
            setPopupVisible(!popupVisible)
        }
    })),
    withRedirect,
    withHandlers({
        handleView: ({ setRedirecting }) => () => {
            setRedirecting(true)
        },
        handleEdit: ({ setRedirectToEdit }) => () => {
            setRedirectToEdit(true)
        },
        handleRemove: ({ togglePopup, removeClient, _id, onComplete }) => () => {
            removeClient(_id, () => {
                if(onComplete) onComplete()
            })
        }
    })
)(ClientListItem)