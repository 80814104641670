import moment from 'moment'

import API from 'api'

const generateTemplateSubmit = () => ({ type: "FETCH/REQUEST" })
const generateTemplateSuccess = data => ({ type: "FETCH/SUCCESS", data })
const generateTemplateError = err => ({ type: "FETCH/ERROR", error: err})

export const generateTemplate = (templateId, loanId, callback) => dispatch => {
    dispatch(generateTemplateSubmit())

    API
        .get(`/api/templates/generate?templateId=${templateId}&loanId=${loanId}`)
        .then(res => res.json())
        .then(json => { 
            dispatch(generateTemplateSuccess(null))
            if(callback) 
                callback(json)
            const buffer = json.buffer.data
            const newBlob = new Blob([new Uint8Array(buffer, 0, buffer.length)])

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob)
                return
            } 
            const data = window.URL.createObjectURL(newBlob)
            let link = document.createElement('a')
            link.href = data
            link.download = json.name
            link.click()

            setTimeout(() => { window.URL.revokeObjectURL(data) }, 100)
        })
        .catch(err => dispatch(generateTemplateError(err)))

}