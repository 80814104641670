import React from 'react'
import { wrapDisplayName, withProps } from 'recompose'

import Outside from './Outside'

const withActionOnOutsideClick = (stateName, fnName) => WrappedComponent => {
  const WithActionOnOutsideClick = ({
    onOutsideClick,
    outsideVisible,
    ...props
  }) => (
    <React.Fragment>
      {outsideVisible && <Outside onClick={onOutsideClick} />}
      <WrappedComponent {...props} />
    </React.Fragment>
  )

  if (process.env.NODE_ENV !== 'production') {
    WithActionOnOutsideClick.displayName = wrapDisplayName(
      WrappedComponent,
      'withActionOnOutsideClick',
    )
  }

  return withProps(props => ({
    outsideVisible: props[stateName],
    onOutsideClick: () => props[fnName] && props[fnName](),
  }))(WithActionOnOutsideClick)
}

export default withActionOnOutsideClick
