import  React from 'react'

import Row from 'components/Row'
import Panel from 'components/Forms/Panel'

import { LumpSumSpecificationFields } from 'components/FormFields/LumpSum'

const LumpSumSpecification = ({

}) => {
    return(
        <Panel title='Lump Sum Specification'>
            <Row>
                <LumpSumSpecificationFields.LumpSumAmountField mr={10} />
                <LumpSumSpecificationFields.LumpSumDateField />
            </Row>
            <Row>
                <LumpSumSpecificationFields.LumpSumInterestRateField mr={10} />
                <LumpSumSpecificationFields.LumpSumTotalInterestField />
            </Row>
            <Row>
                <LumpSumSpecificationFields.LumpSumRemainingAmountField mr={10} />
                <LumpSumSpecificationFields.LumpSumRemainingTermsField />
            </Row>
            <Row>
                <LumpSumSpecificationFields.LumpSumMonthlyPaymentField mr={10} />
                <LumpSumSpecificationFields.LumpSumCalculateNewInterestField />
            </Row>
        </Panel>
    )
}
export default LumpSumSpecification