import API from 'api'

const listLoanReviewsSubmit = () => ({ type: "FETCH/REQUEST" })
const listLoanReviewsSuccess = data => ({ type: "FETCH/SUCCESS", data })
const listLoanReviewsError = err => ({ type: "FETCH/ERROR", error: err})

export const listLoanReviews = (callback) => dispatch => {
    dispatch(listLoanReviewsSubmit())

    API
        .get('/api/loans/reviews')
        .then(res => res.json())
        .then(json => { 
            dispatch(listLoanReviewsSuccess(json))
            if(callback) callback()
        })
        .catch(err => dispatch(listLoanReviewsError(err)))

}
