import API from 'api'

const addTagSubmit = () => ({ type: "FETCH/REQUEST" })
const addTagSuccess = data => ({ type: "FETCH/SUCCESS", data })
const addTagError = err => ({ type: "FETCH/ERROR", error: err})

export const addTag = (values, callback) => dispatch => {
    dispatch(addTagSubmit())

    API
        .post('/api/tags/create', { body: JSON.stringify(values)})
        .then(res => res.json())
        .then(json => { 
            dispatch(addTagSuccess(json))
            if(callback) callback()
        })
        .catch(err => dispatch(addTagError(err)))

}