import styled, { css } from 'styled-components'
import { COLORS } from 'consts'

const TextFieldInputStyles = css`
  border: none;
  background: none;
  color: ${COLORS.darkGray};
  font-size: 16px;
  line-height: 19px;
  outline: none;
  width: 100%;
  height: ${props => props.height};
  font-weight: 300;
  resize: none;
  margin-bottom: 15px;
  padding: 0;

  ${props => props.withLabel && `margin-top: 27px`};

  ::placeholder {
    font-size: 16px;
    color: ${COLORS.lightBlack};
  }

  ${props =>
    props.disabled &&
    `
    ::placeholder {
      color: ${COLORS.lightGray};
    }
  `};

  ${props =>
    props.large &&
    `
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;

    ::placeholder {
      font-size: 24px;
    }
  `};

  ${props =>
    props.nonFocusable &&
    `
    pointer-events: none;
  `};

  ${props =>
    props.hidden &&
    `
    visibility: hidden;
  `};
`

export default styled.textarea`
  ${TextFieldInputStyles};
`
