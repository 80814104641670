import React from 'react'

import Row from 'components/Row'
import { Panel } from 'components/Forms'

import * as DeclarationFields from './DeclarationFields'

const RenderDeclaration = ({
    declaration,
    index,
    ...props
}) => {
    return(
        <Panel title={`Declaration #${index + 1}`}>
            <Row>  
                <DeclarationFields.DeclarationDateField {...{ declaration, index }} />
                <DeclarationFields.DeclarationPrintNameField ml={10} {...{ declaration, index }} />
            </Row>
            <Row>
                <DeclarationFields.DeclarationSignatureField {...{ declaration, index }} />
            </Row>
        </Panel>
    )
}

export default RenderDeclaration