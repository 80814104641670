import React from 'react'
import { compose } from 'recompose'

import DocumentListItem from 'components/ListItems/DocumentListItem'
import { SecondaryButton } from 'components/Buttons'
import { Message } from 'components/Messages'
import { Panel } from 'components/Forms'

import DocumentUploader from 'components/DocumentsUploader'
import DocumentsContainer from 'containers/Documents'

import DocumentsList from './views/DocumentsList'

const ClientDocuments = ({
    objectId,
    documents,
    toggleUpload,
    uploadVisible,
    downloadFile,
    handleRemove,
    ...props
}) => {
    let index = 0
    return(
        <Panel 
            button={<SecondaryButton onClick={() => toggleUpload(true)}>UPLOAD</SecondaryButton>}
            title='Documents'>
            {uploadVisible && <DocumentUploader {...{ objectId, toggleUploadOverride: toggleUpload }} />}
            <DocumentsList>
                {documents && documents.map(doc => 
                    <DocumentListItem key={index++} {...{ ...doc, downloadFile, handleRemove }} />    
                )}
            </DocumentsList>
            {documents && documents.length == 0 && 
                <Message text='No documents found' />
            }
        </Panel>
    )
}

export default compose(
    DocumentsContainer,
)(ClientDocuments)