var dotProp = require('dot-prop-immutable');

const ClientSteps = {
  ClientType: 0,
  Borrower: 1,
  ApplicationDetails: 2,
  Confirmation: 3,
}

const ClientType = {
  Personal: "Personal",
  Corporate: "Corporate",
}

let _default_director = {
  full_name: "",
  share_holdings: "",
}

let _default_applicant = {
  title: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  maiden_name: "",
  date_of_birth: "",
  dependants: "",
  martial_status: "",
  nationality: "",
  uk_resident: "",
  lived_in_uk: "",
  permanent_right: "",
};

let _defaultPersonalDetails = {
  title: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  maiden_name: "",
  date_of_birth: "",
  dependants: "",
  martial_status: "",
  nationality: "",
  uk_resident: "",
  lived_in_uk: "",
  permanent_right: "",
};

let _default_corporate_details = {
  company_name: "",
  country: "",
  registration_number: "",
  registration_date: "",
  registered_address: "",
  registered_postcode: "",
  postal_address: "",
  postal_postcode: "",
  directors: [
    _default_director
  ],
  ever_bankrupt: false,
};

let _state = {
  fetching: false,
  step: ClientSteps.ClientType,
  total_steps: 4,
  onNextStep: null,
  personalDetails: _defaultPersonalDetails,
  corporate_details: _default_corporate_details,
  applicants: [
    _default_applicant
  ]
};

const ClientState = (state = _state, action) => {
  switch(action.type) {

    case "REMOVE_APPLICANT":
      var index = action.index;
      var applicants = [].concat(state.applicants);
      applicants.splice(index, 1);
      var mState = Object.assign({}, state, { applicants: applicants });
      mState.applicants = applicants;

      return mState;

    case "UPDATE_NEW_APPLICANT":
      var index = action.index;
      var applicants = [].concat(state.applicants);
      //dotProp.set(state, `applicants.${index}.${}`, action.likes);
      var applicant = Object.assign({}, applicants[index], action.applicant);
      applicants[index] = applicant;
      var mState = Object.assign({}, state);
      mState.applicants = applicants;

      return mState;

    case "ADD_APPLICANT":
      var applicants = [].concat(state.applicants);
      var mState = Object.assign({}, state);

      applicants.push(_default_applicant);
      mState.applicants = applicants;

      return mState;


    case "UPDATE_NEW_CLIENT":
      var details = Object.assign({}, state.corporate_details, action.corporate_details);
      var mState = Object.assign({}, state);
      mState.corporate_details = details;
      return mState;

    case "ADD_DIRECTOR":
      var directors = [];
      var mState = Object.assign({}, state);

      if( state.corporate_details.directors != null )
        directors = [].concat(state.corporate_details.directors);

      directors.push(_default_director);
      mState.corporate_details.directors = directors;

      return mState;

    case "REMOVE_DIRECTOR":
      var index = action.index;
      var directors = [].concat(state.corporate_details.directors);
      directors.splice(index, 1);
      var mState = Object.assign({}, state);
      mState.corporate_details.directors = directors;
      return mState;

    case "UPDATE_DIRECTOR":
      var index = action.index;
      var directors = [].concat(state.corporate_details.directors);
      var director = Object.assign({}, directors[index], action.data);
      directors[index] = director;
      return dotProp.set(state, `corporate_details.directors.${index}`, director);

    case "NEXT_CLIENT_STEP":
      if( state.step < Object.keys(ClientSteps).length ) {
        return Object.assign({}, state, {
          step: state.step + 1,
        });
      }
      // otherwise its finish
      return state;

    case "PREV_CLIENT_STEP":
      if( state.step > 0 ) {
        return Object.assign({}, state, {
          step: state.step - 1,
        })
      }
      return state;

    case "SET_CLIENT_NEXT_LISTENER":
      return Object.assign({}, state, {
        onNextStep: action.callback,
      })

    case "CREATE_CLIENT_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      })

    case "CREATE_CLIENT_END":
      return Object.assign({}, state, {
        fetching: false,
        corporate_details: _default_corporate_details,
        applicants: [
          _default_applicant
        ],
      });

    default:
      return state;
  }
}

export { ClientSteps, ClientState, ClientType };
