import { compose, withProps } from 'recompose'

import { DatepickerField } from 'components/Fields'
import { Field } from 'components/FormFields'

import { validateRequired } from 'utils/validators'

const validators = [validateRequired]

export default compose(
  withProps(({ applicant }) => ({
    wrapperConfig: { minWidth: "200px" },
    component: DatepickerField,
    label: "Date of Birth",
    name: `personalDetails.dateOfBirth`,
    full: true,
    validators,
  })),
)(Field)