import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { compose, withProps } from 'recompose'
import { reduxForm, submit } from 'redux-form'
import { createStructuredSelector } from 'reselect'

import { ExtensionFormName } from './consts'
import { selectInitialExtensionValues, selectLoanId } from './selectors'
import * as AddExtensionActions from './actions'

const mapState = createStructuredSelector({
  initialValues: selectInitialExtensionValues,
  loanId: selectLoanId,
})

const mapDispatch = dispatch => ({
  ...bindActionCreators(AddExtensionActions, dispatch),
  submitForm: form => dispatch(submit(form)),
})

export default compose(
  connect(mapState, mapDispatch),
  reduxForm({
    form: ExtensionFormName,
    onSubmit: (values, dispatch, { loanId, createExtension }) => {
      createExtension(loanId, values, () => {
        window.location.reload()
      })
    }
  }),
  withProps(({ submitForm }) => ({
    handleSubmit: () => {
      submitForm(ExtensionFormName)
    }
  }))
)