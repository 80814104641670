import { compose, withProps, withHandlers } from 'recompose'

import { Field } from 'components/FormFields'
import { NumberField } from 'components/Fields'

export default compose(
  withProps(() => ({
    component: NumberField,
    numberAppend: "%",
  })),
)(Field)
