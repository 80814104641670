import { compose, withProps } from 'recompose'

import Field from 'components/FormFields/Field'

import { validateRequired } from 'utils/validators'

const validators = [validateRequired]

export default compose(
    withProps(({ applicant, ...props }) => ({
        name: `personalDetails.firstName`,
        label: "First Name",
        validators,
    }))
)(Field)