import React from 'react'
import { compose, wrapDisplayName, withState } from 'recompose'

import LoadingPanel from 'components/LoadingPanel'
import AwaitingStatusChange from 'components/Loans/AwaitingStatusChange'

import GetLoanStatusContainer from 'containers/Loan/GetStatus'

// display wrapped component only if loan status is Active
const withLoanStatus = activeStatus => WrappedComponent => {
    const Component = ({ loanStatus, isCheckingStatus, statusText, ...props }) => {
        if(isCheckingStatus)
            return <LoadingPanel text="Checking status." />

        if(typeof activeStatus == 'string') {
            if(loanStatus == activeStatus) {
                return <WrappedComponent {...props} />
            }
        } else if(typeof activeStatus == 'object') {
            if(activeStatus.indexOf(loanStatus) >= 0) {
                return <WrappedComponent {...props} />
            }
        }
        return <AwaitingStatusChange text={statusText} />
    }

    if (process.env.NODE_ENV !== 'production') {
        Component.displayName = wrapDisplayName(WrappedComponent, 'withLoanStatus')
    }

    return compose(
        GetLoanStatusContainer,
     )(Component)
}
export default withLoanStatus