import { compose, withProps } from 'recompose'

import { withFormValuesOnChange } from 'components/HOC'
import { PriceField } from 'components/FormFields'
import { AddLoanForm } from 'containers/Loan/AddLoan/consts'

export default compose(
    withProps(() => ({
        name: 'totalCompoundedInterestAmount',
        label: "Total Compounded Interest",
        prependNumber: "£",
        readOnly: true,
    })),
    withFormValuesOnChange(
        AddLoanForm, 
        ['term', 'monthlyPaymentAmount'],
        ({ term }) => {
            if(!term)
                return 0
            let total = 0
            term.terms.forEach(data => {
                if( data.compounded )
                    total += parseFloat(data.amount)
            })
            return total
        }
    ),
)(PriceField)