import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import { Field } from 'components/FormFields'
import SignatureField from 'components/Fields/SignatureField';

const validators = [validateRequired]

export default compose(
    withProps((props) => ({
        component: SignatureField,
        validators,
        ...props
    })),
)(Field)
