import React from 'react'

import { PrimaryButton } from 'components/Buttons'

import RenderDirector from './RenderDirector'
import DirectorsWrap from './DirectorsWrap';

const RenderDeclarations = ({
    fields,
    pushField,
    ...props
}) => (
    <DirectorsWrap>
        {fields && fields.length > 0 &&
            <div className='directors-list'>
                {fields.map((director, index) => 
                    <RenderDirector {...{ director, index, ...props }} key={index} />    
                )}
            </div>
        }
        <div className='properties-actions'>
            <PrimaryButton onClick={pushField}>
                Add Director
            </PrimaryButton>
        </div>
    </DirectorsWrap>
)

export default RenderDeclarations