import React from 'react'
import { compose, defaultProps } from 'recompose'

import { Panel } from 'components/Forms'
import { PrimaryButton } from 'components/Buttons'

import ListLoanDynamicTagsContainer from 'containers/Tags/ListLoanDynamicTags'
import UpdateInputTagsContainer from 'containers/Loan/UpdateInputTags'
import ListTemplatesContainer from 'containers/Templates/ListTemplates'

import TemplateListItem from 'components/ListItems/TemplateListItem'
import TagInputField from 'components/FormFields/TagInputField'
import { Message } from 'components/Messages'

import TemplatesListWrapper from './views/TemplatesListWrapper'
import TemplatesCommonFieldsWrapper from './views/TemplatesCommonFieldsWrapper'

const Templates = ({
    id,
    templates,
    inputTags,
    submitForm,
    isUpdating,
    ...props
}) => {
    const updateText = isUpdating ? "PLEASE WAIT..." : "UPDATE"
    
    return(
        <React.Fragment>
            <Panel title='Dynamic Input Tags' expandable>
                <TemplatesCommonFieldsWrapper>
                    {inputTags && inputTags.map(tag => {
                            return <TagInputField {...{ type: tag.type, name: tag._id, label: tag.name, key: tag._id, defaultValue: tag.value }} />
                        }        
                    )}
                    {inputTags && inputTags.length == 0 && <Message text="No tags found." />}
                </TemplatesCommonFieldsWrapper>
                {inputTags && inputTags.length > 0 &&
                    <div className='fields-action' style={{ margin: '0 -5px', textAlign: 'right' }}>
                        <PrimaryButton disabled={isUpdating} onClick={submitForm}>{updateText}</PrimaryButton>
                    </div>
                }
            </Panel>
            <Panel title='Loan Templates'>
                <TemplatesListWrapper>
                    {templates.map(template => <TemplateListItem {...{ ...template, templateId: template._id, loanId: id, key: template._id }} />)}
                </TemplatesListWrapper>
            </Panel>
        </React.Fragment>
    )
}

export default compose(
    ListLoanDynamicTagsContainer,
    UpdateInputTagsContainer,
    ListTemplatesContainer,
)(Templates)