import React from 'react';
import { compose } from 'recompose'

import { Page } from 'pages'

import AddLoanContainer from 'containers/Loan/AddLoan'

import NewLoanActionsBar from './NewLoanActionsBar'
import NewLoanProgress from './NewLoanProgress'
import NewLoanSteps from './steps/NewLoanSteps'

const AddLoan = ({
    step,
    hideActions,
    isCreating,
    redirectUrl,
    ...props
}) => (
    <Page title="Create New Loan" className='new-loan'>
        {!isCreating && <NewLoanProgress />}
        <NewLoanSteps step={step} {...{ isCreating, redirectUrl, ...props }} />
        {!isCreating && <NewLoanActionsBar {...props} />}
    </Page>
)

export default compose(
  AddLoanContainer,
)(AddLoan);
