import React from 'react'
import { FieldArray } from 'redux-form'

import { FieldArrayHelper } from 'components/Fields'

import RenderDeclarations from './RenderDeclarations';

const DeclarationsArrayField = ({
    fields,
    ...props
}) => {
    return (
        <FieldArray
            name="applicantsDetails"
            component={FieldArrayHelper}
            renderFields={RenderDeclarations}
            {...props}
        />
    )
}

export default DeclarationsArrayField