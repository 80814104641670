import React from 'react';
import {Link} from 'react-router-dom';

export default class MainMenu extends React.Component {
  render() {

    return(
      <div className='main-menu-toggle'>
        <div className='flex-outer'>
          <Link className='home-link' to='/dashboard' />
          <Link className='current-link' to='/company' />
          <a className='menu-expand-link'></a>
        </div>
      </div>
    )
  }
}
