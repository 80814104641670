import React from 'react'
import { compose } from 'recompose'
import prettyBytes from 'pretty-bytes'

import { COLORS } from 'consts'

import FileItemUploaderWrapper from './FileItemUploaderWrapper'

import FileItemUploaderContainer from './containers'
import ProgressRing from 'components/ProgressRing';

const FileItemUploader = ({
    name,
    file,
    progress,
}) => {

    return(
        <FileItemUploaderWrapper>
            <ProgressRing 
                {...{
                    progress: progress,
                    radius: 40,
                    stroke: 4,
                    fill: COLORS.gray,
                    color: COLORS.blue,
                }}
            />
            <h3>{file.name}</h3>
            <span>{prettyBytes(file.size)}</span>
        </FileItemUploaderWrapper>
    )
}

export default compose(
    FileItemUploaderContainer,
)(FileItemUploader)