import { connect } from 'react-redux'
import { compose, lifecycle, withState } from 'recompose'
import withProps from 'recompose/withProps'
import { bindActionCreators } from 'redux'

import { createStructuredSelector } from 'reselect'

import * as actions from './actions'
import { selectLoanReviews } from './selectors'

const mapState = createStructuredSelector({
  reviews: selectLoanReviews,
})

const mapDispatch = dispatch => ({ 
    ...bindActionCreators(actions, dispatch),
})

export default compose(
    withState('isSendingReminder', 'setSending', false),
    withProps(({
      sendReviewReminder,
      setSending,
      loanId,
    }) => ({
      handleSendReminder: () => {
        setSending(true)
        sendReviewReminder()
      }
    })),
    connect(mapState, mapDispatch),
    lifecycle({
      componentDidMount() {
        const { listLoanReviews } = this.props
        listLoanReviews()
      }
    })
)