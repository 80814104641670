import styled from 'styled-components'

import { COLORS } from 'consts'

export default styled.div`
  font-size: 12px;
  color: ${COLORS.blue};
  min-width: 40px;
  margin-left: 20px;

  span {
    margin-left: 4px;
    font-weight: 300;
  }
`