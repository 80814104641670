import { compose, withProps, withState } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import * as actions from './actions'
import { selectExtensions, selectLoan } from './selectors'

const mapState = createStructuredSelector({
    loan: selectLoan,
    extensions: selectExtensions,
})

const mapDispatch = dispatch => ({
    ...bindActionCreators(actions, dispatch),
})

export default compose(
    connect(mapState, mapDispatch),
    withState('popupVisible', 'setPopupVisible', false),
    withProps(({
        popupVisible,
        setPopupVisible,
    }) => ({
        toggleExtensionPopup: () => {
            setPopupVisible(!popupVisible)
        }
    }))
)