import styled from 'styled-components'

export default styled.div`
    .properties-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .properties-list {
        margin-top: 20px;
    }


`