import API from 'api'

const getTagsSubmit = () => ({ type: "FETCH/REQUEST" })
const getTagsSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getTagsError = err => ({ type: "FETCH/ERROR", error: err})

export const getTags = () => dispatch => {
    dispatch(getTagsSubmit())

    API
        .get('/api/tags')
        .then(res => res.json())
        .then(json => { 
            dispatch(getTagsSuccess(json)) 
        })
        .catch(err => dispatch(getTagsError(err)))
}

const getDynamicTagsWithValuesSubmit = () => ({ type: "FETCH/REQUEST" })
const getDynamicTagsWithValuesSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getDynamicTagsWithValuesError = err => ({ type: "FETCH/ERROR", error: err})

export const getDynamicTagsWithValues = loanId => dispatch => {
    dispatch(getDynamicTagsWithValuesSubmit())

    API
        .get(`/api/tags/loan?id=${loanId}`)
        .then(res => res.json())
        .then(json => {
            dispatch(getDynamicTagsWithValuesSuccess(json)) 
        })
        .catch(err => dispatch(getDynamicTagsWithValuesError(err)))
}