import { compose, withProps, withState, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import * as actions from './actions'
import { selectClient } from './selectors'

const mapState = createStructuredSelector({
  client: selectClient,
})

const mapDispatch = dispatch => ({
  ...bindActionCreators(actions, dispatch),
})

export default compose(
    connect(mapState, mapDispatch),
    withProps(({ match }) => ({
      id: match.params.id,
    })),
    lifecycle({
      componentDidMount() {
        const { getClient, id } = this.props
        getClient(id)
      },
    }),
)