import { compose, withProps, withState } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm, submit } from 'redux-form'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import { ClientSteps } from 'reducers/NewClientState'

import { AddClientForm } from './consts'
import * as NewClientActions from './actions'
import { selectStep, selectFormValues } from './selectors'

const mapState = createStructuredSelector({
  step: selectStep,
  formValues: selectFormValues,
  initialValues: state => ({
    startDate: new Date(),
  })
})

const mapDispatch = dispatch => ({
  ...bindActionCreators(NewClientActions, dispatch),
  submitForm: form => dispatch(submit(form)),
})

export default compose(
    connect(mapState, mapDispatch),
    withState('isCreating', 'setCreating', false),  
    withState('redirectUrl', 'setRedirect', ''),
    withProps(({ nextStep, prevStep, step, ...props }) => ({
      isCreateStep: step == Object.keys(ClientSteps).length - 2,
      hasNext: step < Object.keys(ClientSteps).length - 1,
      hasPrev: step > 0,
      nextButtonText: step < Object.keys(ClientSteps).length - 2 ? "Next" : "Create",
      onNextStep: () => {
        if( step < Object.keys(ClientSteps).length - 1)
          props.submitForm(AddClientForm)
      },
      onPrevStep: () => {
        if( step > 0 )
          prevStep()
      },
    })),
    reduxForm({
      form: AddClientForm,
      onSubmit: (values, dispatch, { setRedirect, nextStep, isCreateStep, isCreating, setCreating, createClient, formValues }) => {
        if(nextStep) 
          nextStep()
        
        if(isCreateStep && !isCreating) {
          setCreating(true)
          createClient(formValues, (client) => {
            setRedirect(`/dashboard/clients/details/${client._id}`)
            setCreating(false)
          })
        }
      },
    }), 
)