import { createSelector } from 'reselect'
import { formValuesSelector } from 'utils/reduxFormUtils'

import _get from 'lodash/fp/get'

import { FORM_NAME } from './consts'

export const selectNewClient = createSelector(
    state => state.global,
    _get("client")
)

export const selectInitialValues = createSelector(
    selectNewClient,
    client => {
        return {
            ...client
        }
    }
)


export const selectStep = createSelector(
    state => state.client,
    _get("step")
)

export const selectFormValues = formValuesSelector(FORM_NAME)
