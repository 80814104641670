import React from 'react'
import PropTypes from 'prop-types'

import ProgressRingWrapper from './ProgressRingWrapper'

const ProgressRing = ({
    radius,
    stroke,
    progress,
    fill,
    color,
    fontSize,
}) => {
    const normalizedRadius = radius - stroke * 2
    const circumference = normalizedRadius * 2 * Math.PI
    const strokeDashoffset = circumference - progress / 100 * circumference
    
    let fSize = typeof fontSize != undefined ? fontSize : 12 
    let circleColor = !color ? '#fff' : color
    let circleFill = !fill ? 'transparent' : fill

    return (
        <ProgressRingWrapper>
            <svg
                height={radius * 2}
                width={radius * 2}>
                <circle
                    stroke={circleFill}
                    fill="transparent"
                    strokeWidth={stroke}
                    style={{ strokeDashoffset: 0 }}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
                <circle
                    stroke={circleColor}
                    fill="transparent"
                    strokeWidth={stroke}
                    strokeDasharray={circumference + ' ' + circumference}
                    style={{strokeDashoffset}}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                    />
            </svg>
            <span style={{fontSize: fSize + 'px'}}>{parseInt(progress)}%</span>
        </ProgressRingWrapper>
    )
}

ProgressRing.propTypes = {
    radius: PropTypes.number.isRequired,
    stroke: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
}

export default ProgressRing