import React from 'react'
import { Flex } from '@rebass/grid'
import prettyBytes from 'pretty-bytes'

import DocumentListItemWrapper from './DocumentListItemWrapper'
import DocumentMimeIcon from './DocumentMimeIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrashAlt, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'


library.add(faTrashAlt, faCloudDownloadAlt)

const DocumentListItem = ({
    _id,
    name,
    size,
    extension,
    handleRemove,
    downloadFile,
    removeDisabled,
    ...props
}) => {
    
    return(
        <DocumentListItemWrapper>
            <Flex justifyContent="space-between" width="100%">
                <Flex alignItems="center">
                    <DocumentMimeIcon style={{width: 20}} {...{extension}} />
                    <div>
                        <h3>{name}</h3>
                        <span>{prettyBytes(size)}</span>
                    </div>
                </Flex>
                <div className='actions'>
                    {!removeDisabled && 
                        <a onClick={() => handleRemove(_id)}>
                            <FontAwesomeIcon icon={['fal', 'trash-alt']} />
                        </a>
                    }
                    <a onClick={() => downloadFile({ _id: _id, name: name })}>
                        <FontAwesomeIcon icon={['fal', 'cloud-download-alt']} />
                    </a>
                </div>
            </Flex>
        </DocumentListItemWrapper>
    )
}

export default DocumentListItem