import React from 'react'

import { Panel } from 'components/Forms'

import CorporateDetails from './CorporateDetails'
import PersonalDetails from './PersonalDetails'

import { ClientType } from 'reducers/NewClientState'

const ClientGeneralInfo = ({
    client,
}) => {
    if(!client)
        return null
        
    const { clientType } = client

    return(
        <Panel title='Client Details' expandable={true} initiallyExpanded>
            {clientType === ClientType.Corporate && <CorporateDetails {...client} />}
            {clientType === ClientType.Personal && <PersonalDetails {...client.personalDetails} />}
        </Panel>
    )
}

export default ClientGeneralInfo