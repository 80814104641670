import React from 'react'

import Row from 'components/Row'
import { Panel } from 'components/Forms'
import LoanField from 'components/Loans/LoanField'

const MainPropertyOverview = ({
    reviewLoan
}) => {

    const {
        mpUse,
        mpCondition,
        mpType,
        mpAddress,
        mpPostcode,
        mpPrice,
        mpValuation,
        mpTernure,
        mpTotalBedrooms,
        mpExAuthority,
        mpOccupancy,
        mpUnexpiredTermLease,
        mpNewBuild,
    } = reviewLoan

    return(
        <Panel title='Main Property Overview' expandable initiallyExpanded>
            <Row>
                <LoanField forceValue={mpUse} label='Property Use' />
                <LoanField forceValue={mpCondition} label='Property Condition' />
                <LoanField forceValue={mpType} label='Property Type' />
            </Row>
            <Row>
                <LoanField forceValue={mpAddress} label='Address' />
                <LoanField forceValue={mpPostcode} label='Postcode' />
            </Row>
            <Row>
                <LoanField type='number' forceValue={mpPrice} prepend="£" label='Price' />
                <LoanField type='boolean' forceValue={mpValuation ? mpValuation.checked : false} label='Valuation' />
            </Row>
            <Row>
                <LoanField forceValue={mpTernure} label='Ternure' />
                <LoanField forceValue={mpTotalBedrooms} label='Total Bedrooms' />
            </Row>
            <Row>
                <LoanField type='boolean' forceValue={mpExAuthority ? mpExAuthority.checked : false} label='Is Ex Authority?' />
                <LoanField forceValue={mpOccupancy} label='Occupancy' />
            </Row>
            <Row>
                <LoanField type='boolean' forceValue={mpUnexpiredTermLease ? mpUnexpiredTermLease.checked : false} label='Unexpired Term Lease?' />
                <LoanField type='boolean' forceValue={mpNewBuild ? mpNewBuild.checked : false} label='Is New Build?' />
            </Row>
        </Panel>
    )
}

export default MainPropertyOverview