import API from 'api'
import _set from 'lodash/fp/set'

const approveSubmit = () => ({ type: "FETCH/REQUEST" })
const approveSuccess = data => ({ 
    type: "FETCH/SUCCESS", 
    data: data.loan,
    key: 'loanDetails'
})
const approveError = err => ({ type: "FETCH/ERROR", error: err})

export const approve = (data, callback) => dispatch => {
    dispatch(approveSubmit())

    API
        .post('/api/loans/checklist/approve', { body: JSON.stringify(data) })
        .then(res => res.json())
        .then(json => { 
            dispatch(approveSuccess(json))
            if(callback) callback()
        })
        .catch(err => dispatch(approveError(err)))

}