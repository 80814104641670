import React from 'react'
import { compose } from 'recompose'

import Page from 'components/Page'
import Row from 'components/Row'
import { PrimaryButton } from 'components/Buttons'
import LoadingPanel from 'components/LoadingPanel'
import { Message } from 'components/Messages'

import EditTagContainer from 'containers/Tags/EditTag'
import ListTagsContainer from 'containers/Tags/ListTags'

import * as TagFields from 'components/FormFields/Tag'

const EditTag = ({
    tags,
    submitForm,
    formValues,
    isFetching,
    isUpdating,
    submitSucceeded,
    ...props
}) => {
    const type = formValues ? formValues.type : null
    const buttonText = isUpdating ? "PLEASE WAIT..." : "UPDATE"
    
    if(isFetching)
        return <LoadingPanel text='Loading tag details.' />

    return(
        <Page title="Edit Tag">
            {submitSucceeded && !isUpdating && 
                <Message success text="Tag updated successfully." />
            } 
            <Row>
                <TagFields.NameField />
            </Row>
            <Row>
                <TagFields.DescriptionField />
            </Row>
            {type && (type == 'input' || type == 'loan' || type == 'html' || type == 'client') &&
                <Row>
                    <TagFields.FormatField />
                </Row>
            }
            <Row>
                <TagFields.TypeField />
            </Row>
            {type && (type == 'input' || type == 'signature') &&
                <Row>
                    <TagFields.GlobalScopeField />
                </Row>
            }
            {type &&
                <Row>
                    {type == 'formula' && <TagFields.FormulaField {...{ tags }} />}
                    {(type == 'text' || type == 'html' || type == 'loan' || type == 'client') && <TagFields.InputField />}
                </Row>
            }
            <Row style={{ justifyContent: "flex-end" }}>
                <PrimaryButton to='/dashboard/tags/' darkGray style={{marginRight: 10}}>CANCEL</PrimaryButton>
                <PrimaryButton onClick={submitForm}>{buttonText}</PrimaryButton>
            </Row>
        </Page>
    )
}

export default compose(
    ListTagsContainer,
    EditTagContainer,
)(EditTag)