import React from 'react'

import Page from 'components/Page'

import ApplicantsList from './views/ApplicantsList'

const Overview = props => {
    return(
        <Page title=''>
            <ApplicantsList {...props} />
        </Page>
    )
}
export default Overview