import { compose, withProps } from 'recompose'

import { DatepickerField } from 'components/Fields'
import { Field } from 'components/FormFields'

import { validateRequired } from 'utils/validators'

const validators = [validateRequired]

export default compose(
  withProps(({}) => ({
    wrapperConfig: { minWidth: "200px" },
    component: DatepickerField,
    label: "Payment Date",
    name: 'date',
    full: true,
    validators,
  })),
)(Field)