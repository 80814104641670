import React from 'react'
import { Redirect } from 'react-router-dom'
import { compose, wrapDisplayName, withState } from 'recompose'

const withRedirect = WrappedComponent => {
    const Component = ({ isRedirecting, redirectUrl, ...props }) => {
        if( isRedirecting && redirectUrl ) {
            return <Redirect to={redirectUrl} />
        }
        return <WrappedComponent {...props} />
    }

    if (process.env.NODE_ENV !== 'production') {
        Component.displayName = wrapDisplayName(WrappedComponent, 'withRedirect')
    }

    return compose(
        withState('isRedirecting', 'setRedirecting', false), 
     )(Component)
}
export default withRedirect