import { connect } from 'react-redux'
import { change } from 'redux-form'
import { compose, withState, withProps } from 'recompose'
import { createStructuredSelector } from 'reselect'

import { AddRepaymentForm } from '../AddRepayment/consts'

import { selectLoanTerms, selectExtensions } from './selectors'

const mapState = createStructuredSelector({
  terms: selectLoanTerms,
  extensions: selectExtensions,
})

const mapDispatch = dispatch => ({
  updateValue: (field, value) => dispatch(change(AddRepaymentForm, field, value))
})

export default compose(
  connect(mapState, mapDispatch),
  withState('termId', 'setTermId', null),
  withState('amount', 'setAmount', 0),
  withState('repaymentPopupVisible', 'setRepaymentPopupVisible', false),
  withState('lumpSumPopupVisible', 'setLumpSumPopupVisible', false),
  withState('netAdvancePopupVisible', 'setNetAdvancePopupVisible', false),
    withProps(({
        repaymentPopupVisible,
        setRepaymentPopupVisible,
        lumpSumPopupVisible,
        setLumpSumPopupVisible,
        netAdvancePopupVisible,
        setNetAdvancePopupVisible,
        setTermId,
        setAmount,
        updateValue,
    }) => ({
      toggleRepaymentPopup: () => {
        setRepaymentPopupVisible(!repaymentPopupVisible)
      },
      toggleLumpSumPopup: () => {
        setLumpSumPopupVisible(!lumpSumPopupVisible)
      },
      toggleNetAdvancePopup: () => {
        setNetAdvancePopupVisible(!netAdvancePopupVisible)
      },
      setRepaymentInitialValues: (id, amount) => {
        setTermId(id)
        setAmount(amount)
        updateValue('amount', amount)
        updateValue('date', new Date())
      }
    }))
)