import styled from 'styled-components'

import { COLORS } from 'consts'

export default styled.div`
  position: relative;
  border-radius: 5px;
  background-color: #282a37;
  border-bottom: 1px solid #515151;
  color: #fff;
  display: flex;
  padding: 45px 15px 15px 15px;
  position: relative;
  width: 100%;
  width: 100%;
  cursor: text;
  min-height: 200px;
  font-size: 12px;
  font-family: 'Roboto Mono', monospace;

  .tag {
    color: ${COLORS.blue};
  }

  .comment {
    color: ${COLORS.editorComment};
  }

  .keyword {
    color: ${COLORS.editorKeyword};
  }

  .string {
    color: ${COLORS.editorString};
  }

  .function {
    color: ${COLORS.editorFunction};
  }
`
