import React from 'react'
import styled from 'styled-components'

import { COLORS } from 'consts'

import SlateButton from './SlateButton'

const SlateToolbar = styled.div`
  position: absolute;
  display: flex;
  top: -22px;
  right: -15px;
  background: ${COLORS.white}dd;
  padding-right: 16px;
  border-radius: 0 4px 0 4px;
  opacity: 0;
  transition: opacity 300ms linear;

  ${props =>
    props.visible &&
    `
      opacity: 1
      transition: opacity 100ms linear;
  `};

  > div:first-child {
    margin-left: 6px;
  }

  > div:last-child {
    margin-left: 12px;
  }
`

export default ({ disallowTitle, editor, value, ...props }) => (
  <SlateToolbar className="SlateToolbar" {...props}>
    <div>
      <SlateButton type="bold" {...{ editor, value }} />
      <SlateButton type="italic" {...{ editor, value }} />
      <SlateButton type="underlined" {...{ editor, value }} />
    </div>
    <div>
      {!disallowTitle && (
        <SlateButton type="title" {...{ editor, value }} block />
      )}
      <SlateButton type="block-quote" {...{ editor, value }} block />
      <SlateButton type="numbered-list" {...{ editor, value }} block />
      <SlateButton type="bulleted-list" {...{ editor, value }} block />
    </div>
  </SlateToolbar>
)
