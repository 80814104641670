import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

const validators = [validateRequired]

const options = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
]

export default compose(
    withProps(({ applicant, ...props }) => ({
        placeholder: 'Select Number of Dependants',
        name: `personalDetails.dependants`,
        wrapperConfig: { width: '100%' },
        component: DropdownField,
        label: "Number of Dependants",
        isClearable: true,
        options: options,
        full: true,
        validators,
    })),
)(Field)
