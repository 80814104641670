import styled from 'styled-components'

export default styled.div`
    margin-bottom: 10px;
    padding: 10px 20px;
    background: #eee;
    border-radius: 15px;
    flex-basis: calc(25% - 10px);
    margin: 5px;
    padding-bottom: 15px;
    min-width: 150px;

    h3 {
        margin-top: 0px;
        font-size: 11px!important;
        text-transform: uppercase;
        font-style: normal;
        font-weight: normal;
        margin-bottom: 0px!important;
        color: #565656!important;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin-top: 10px;
        height: 26px;
        overflow: hidden;
    }

    span {
        font-size: 12px;
        color: #3a75ab;
    }
`