import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, withProps, withState } from 'recompose'

import * as actions from './actions'

const mapDispatch = dispatch => ({
    ...bindActionCreators(actions, dispatch)
})

export default compose(
    connect(null, mapDispatch),
    withState('isGenerating', 'setGenerating', false),
    withProps(({ isGenerating, setGenerating, generateTemplate, templateId, loanId }) => ({
        handleClick: () => {
            setGenerating(true)
            generateTemplate(templateId, loanId, () => {
                setTimeout(() => setGenerating(false), 1000)
            })
        }
    })),
)