import React from 'react'

import Row from 'components/Row'
import { Panel } from 'components/Forms'
import { SecondaryButton } from 'components/Buttons'

import * as PropertyFields from './PropertyFields'

const RenderProperty = ({
    property,
    index,
    removeField,
    ...props
}) => {
    return(
        <Panel 
            button={<SecondaryButton onClick={() => removeField(index)}>Remove</SecondaryButton>}
            title={`Security #${index + 1}`}>
            <Row>
                <PropertyFields.PropertyUseField {...{ property, index }}/>
            </Row>
            <Row>
                <PropertyFields.PropertyAddressField {...{ property, index }} />
            </Row>
            <Row>  
                <PropertyFields.PropertyPostcodeField mr={10} {...{ property, index }} />
                <PropertyFields.PropertyConditionField {...{ property, index }} />
            </Row>
            <Row>
                <PropertyFields.PropertyTypeField {...{ property, index }} mr={10} />
                <PropertyFields.PropertyOutstandingLiabilitiesField {... { property, index }} />
            </Row>
            <Row>
                <PropertyFields.PropertyPriceField mr={10} {...{ property, index }} />
                <PropertyFields.PropertyValuationField {...{ property, index }} />
            </Row>
            <Row>
                <PropertyFields.PropertyTernureField mr={10} {...{ property, index }} />
                <PropertyFields.PropertyTotalBedroomsField {...{ property, index }} />
            </Row>
            <Row>
                <PropertyFields.PropertyExAuthorityField {...{ property, index }} />
                <PropertyFields.PropertyOccupancyField ml={10} {...{ property, index }} />
            </Row>
            <Row>
                <PropertyFields.PropertyUnexpiredTermLeaseField mr={10} {...{ property, index }} />
                <PropertyFields.PropertyIsNewBuildField {...{ property, index }} />
            </Row>
            <Row>
                <PropertyFields.PropertyEquitableCharge {... { property, index }} />
            </Row>
        </Panel>
    )
}

export default RenderProperty