import { compose, withState } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm, submit } from 'redux-form'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import { AddTemplateForm } from './consts'
import * as AddTemplateActions from './actions'

const mapState = createStructuredSelector({

})

const mapDispatch = dispatch => ({
  ...bindActionCreators(AddTemplateActions, dispatch),
  submitForm: form => dispatch(submit(form)),
})

export default compose(
    connect(mapState, mapDispatch),
    withState('isCreating', 'setCreating', false),
    withState('redirectUrl', 'setRedirect', ''),
    reduxForm({
      form: AddTemplateForm,
      onSubmit: (values, dispatch, { setRedirect, isCreating, setCreating, createTemplate }) => {
        
        if(!isCreating) {
          setCreating(true)
          createTemplate(values, (template) => {
            setRedirect(`/dashboard/templates/edit/${template._id}`)
          })
        }
      },
    }), 
)