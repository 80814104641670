import React from 'react'
import { compose } from 'recompose'

import LoanAccessContainer from 'containers/Client/LoanAccess'

const RequestLoanAccess = ({ 
  id, 
  isAuthorized, 
  fetching,
  code,
  verifyAccess,
  setCodePart, 
  ...props 
}) => {
    return(
      <div className='mod-login'>
        <h2><span className='padlock-icon'></span> VERIFY ACCESS</h2>
        <p>Please type in the verification number below, which has been sent to your email.</p>
        <div className='form-group auth-code'>
          <span className='auth-code-input c1'>
            <input id='c1' maxLength='1' type='text' onChange={setCodePart(0)} />
          </span>
          <span className='auth-code-input c2'>
            <input id='c2' maxLength='1' type='text' onChange={setCodePart(1)} />
          </span>
          <span className='auth-code-input c3'>
            <input id='c3' maxLength='1' type='text' onChange={setCodePart(2)} />
          </span>
          <span className='auth-code-input c4'>
            <input id='c4' maxLength='1' type='text' onChange={setCodePart(3)} />
          </span>
          <span className='auth-code-input c5'>
            <input id='c5' maxLength='1' type='text' onChange={setCodePart(4)} />
          </span>
          <span className='auth-code-input c6'>
            <input id='c6' maxLength='1' type='text' onChange={setCodePart(5)} />
          </span>
        </div>
        <div className='form-group login-actions auth-code-actions text-right'>
          <a className='btn btn-grey btn-login' onClick={verifyAccess}>
            {fetching &&
              <span className='spinner'></span>
            }
            Verify
          </a>
        </div>
      </div>
    )
}

export default compose(
  LoanAccessContainer
)(RequestLoanAccess)