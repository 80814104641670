import API from 'api'

const sendToNextSubmit = () => ({ type: "FETCH/REQUEST" })
const sendToNextSuccess = data => ({ type: "FETCH/SUCCESS", data, key: 'loanDetails' })
const sendToNextError = err => ({ type: "FETCH/ERROR", error: err})

export const sendToNext = (data, callback) => dispatch => {
    dispatch(sendToNextSubmit())

    API
        .post('/api/loans/checklist/next', { body: JSON.stringify(data) })
        .then(res => res.json())
        .then(json => { 
            dispatch(sendToNextSuccess(json.data))
            if(callback) callback()
        })
        .catch(err => dispatch(sendToNextError(err)))

}