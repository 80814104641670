import { compose, withProps } from 'recompose'

import { SwitchField } from 'components/FormFields'

export default compose(
    withProps(({ applicant, ...props }) => ({
        name: `${applicant}.arrears`,
        justLabel: true,
        wrapperConfig: { width: '100%' },
        label: "Have you ever had any arrears?",        
    })),
)(SwitchField)
