import React from 'react'
import { compose, withProps, withHandlers, withState } from 'recompose'

import Popup from 'components/globals/Popup'
import { withRedirect } from 'components/HOC'
import { SecondaryButton, PrimaryButton } from 'components/Buttons'

import RemoveCheckContainer from 'containers/Checks/RemoveCheck'

import GenericListItem from '../GenericListItem'

const CheckListItem = ({
  name,
  description,
  levels,
  handleRemove,
  handleEdit,
  togglePopup,
  popupVisible,
}) => {
  return(
    <GenericListItem>
      <div className='col-sm-4'>
        <strong>{name}</strong>
      </div>
      <div className='col-sm-4'>
        {description}
      </div>
      <div className='col-sm-4 text-right'>
        {/* <SecondaryButton style={{marginRight: 5}} onClick={handleEdit}>EDIT</SecondaryButton> */}
        <SecondaryButton justText darkGray onClick={togglePopup}>REMOVE</SecondaryButton>
      </div>
      {popupVisible && 
        <Popup 
            {...{
              handleToggle: togglePopup,
              button: 
                <div>
                    <PrimaryButton onClick={handleRemove} style={{marginRight: 10}}>Confirm</PrimaryButton>
                    <PrimaryButton onClick={togglePopup} darkGray>Cancel</PrimaryButton>
                </div>
            }}>
            <h3>Confirm Removal</h3>
            <p>Are you sure you want to remove this check?</p>
        </Popup>
        }
    </GenericListItem>
  )
}

export default compose(
  RemoveCheckContainer,
  withState('popupVisible', 'setPopupVisible', false),
  withProps(({ _id, popupVisible, setPopupVisible }) => ({
      redirectUrl: `/dashboard/clients/details/${_id}`,
      togglePopup: () => {
          setPopupVisible(!popupVisible)
      }
  })),
  withRedirect,
  withHandlers({
      handleView: ({ setRedirecting }) => () => {
          setRedirecting(true)
      },
      handleRemove: ({ togglePopup, removeCheck, _id, onComplete }) => () => {
          removeCheck(_id, () => {
              if(onComplete) onComplete()
          })
      }
  })

)(CheckListItem)