import React from 'react'
import Row from 'components/Row'
import ClientField from 'components/Clients/ClientField'

const CorporateOverview = ({
  companyName,
  registrationDate,
  registrationNumber,
  registeredPostcode,
  registeredAddress,
  postalAddress,
  postalPostcode,
  country,
}) => {

  return(
    <React.Fragment>
      <Row>
          <ClientField forceValue={companyName} label='Company Name' />
          <ClientField forceValue={registrationNumber} label='Registration Number' />
          <ClientField type='date' forceValue={registrationDate} label='Registration Date' />
      </Row>
      <Row>
          <ClientField forceValue={registeredAddress} label='Registered Address' />
          <ClientField forceValue={registeredPostcode} label='Registered Postcode' />
      </Row>
      <Row>
          <ClientField forceValue={postalAddress} label='Postal Address' />
          <ClientField forceValue={postalPostcode} label='Postcode' />
          <ClientField forceValue={country} label='Country' />
      </Row>
    </React.Fragment>
  )
}

export default CorporateOverview