import { compose, withProps } from 'recompose'

import { Field } from 'components/FormFields'
import { TextAreaField } from 'components/Fields'

import { validateRequired } from 'utils/validators'

const validators = [validateRequired]

export default compose(
    withProps(() => ({
        name: 'description',
        label: "Description",
        component: TextAreaField,
    }))
)(Field)