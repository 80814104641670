import API from 'api'

const getGlobalTagsSubmit = () => ({ type: "FETCH/REQUEST" })
const getGlobalTagsSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getGlobalTagsError = err => ({ type: "FETCH/ERROR", error: err})

export const getGlobalTags = () => dispatch => {
    dispatch(getGlobalTagsSubmit())

    API
        .get('/api/tags/global')
        .then(res => res.json())
        .then(json => { 
            dispatch(getGlobalTagsSuccess(json)) 
        })
        .catch(err => dispatch(getGlobalTagsError(err)))
}


const updateGlobalTagsSubmit = () => ({ type: "FETCH/REQUEST" })
const updateGlobalTagsSuccess = data => ({ type: "FETCH/SUCCESS", data })
const updateGlobalTagsError = err => ({ type: "FETCH/ERROR", error: err})

export const updateGlobalTags = (values, callback) => dispatch => {
    dispatch(updateGlobalTagsSubmit())

    API
        .post('/api/tags/global', { body: JSON.stringify(values)})
        .then(res => res.json())
        .then(json => { 
            dispatch(updateGlobalTagsSuccess(json)) 
            if( callback )
                callback()
        })
        .catch(err => dispatch(updateGlobalTagsError(err)))
}