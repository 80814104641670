import { connect } from "react-redux";
import { reduxForm, submit } from "redux-form";
import { compose, lifecycle, withState } from "recompose";
import { createStructuredSelector } from "reselect";
import { bindActionCreators } from "redux";

import { withFormValues } from "components/HOC";

import * as actions from "./actions";
import { FORM_NAME } from "./consts";

const mapDispatch = (dispatch) => ({
  ...bindActionCreators(actions, dispatch),
  submitForm: () => dispatch(submit(FORM_NAME)),
});

export default compose(
  connect(null, mapDispatch),
  withState("isUpdating", "setUpdating", false),
  reduxForm({
    form: FORM_NAME,
    onSubmit: (values, dispatch, { approve, loanId, handleToggle, setUpdating }) => {
      setUpdating(true)
      approve({ ...values, loanId }, () => {
        setUpdating(false)
        handleToggle()
      });
    },
    enableReinitialize: true,
  }),
  withFormValues(FORM_NAME)
);
