export const DEFAULT_NODE = 'paragraph'

export const hasBlock = (value, type) =>
  value.blocks.some(node => node.type === type)

export const hasMark = (value, type) =>
  value.activeMarks.some(mark => mark.type === type)

export const onClickBlock = (type, editor, value) => {
  const { document, blocks } = value

  if (type !== 'bulleted-list' && type !== 'numbered-list') {
    const isActive = hasBlock(value, type)
    const isList = hasBlock(value, 'list-item')

    if (isList) {
      editor
        .setBlocks(isActive ? DEFAULT_NODE : type)
        .unwrapBlock('bulleted-list')
        .unwrapBlock('numbered-list')
    } else {
      editor.setBlocks(isActive ? DEFAULT_NODE : type)
    }
  } else {
    const isList = hasBlock(value, 'list-item')
    const isType = blocks.some(
      block => !!document.getClosest(block.key, parent => parent.type === type),
    )

    if (isList && isType) {
      editor
        .setBlocks(DEFAULT_NODE)
        .unwrapBlock('bulleted-list')
        .unwrapBlock('numbered-list')
    } else if (isList) {
      editor
        .unwrapBlock(
          type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list',
        )
        .wrapBlock(type)
    } else {
      editor.setBlocks('list-item').wrapBlock(type)
    }
  }
}

export const iconsMap = {
  'block-quote': 'format_quote',
  'bulleted-list': 'format_list_bulleted',
  'numbered-list': 'format_list_numbered',
  bold: 'format_bold',
  italic: 'format_italic',
  title: 'format_size',
  underlined: 'format_underlined',
}
