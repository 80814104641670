import API from 'api';

let UserActions = {
  InitUser: (user) => {
    return (dispatch) => {

      dispatch({
        type: "io/CONNECT",
        io_event_name: "user_join",
        io_event_data: {
          user_id: user._id,
        },
      });
      dispatch({
        type: "INIT_USER",
        user: user,
      });
    }
  },

  FocusPostPanel: (flag) => {
    return {
      type: "FOCUS_POST_PANEL",
      flag: flag,
    }
  },

  TimelinePostCreated: (post) => {
    return {
      type: "TIMELINE_POST_CREATED",
      post: post,
    }
  },

  LoadTimelinePosts: (page = 0) => {
    return (dispatch) => {
      API.get("/api/user/timeline?page=" + page)
        .then(res => { return res.json() })
        .then(json =>{
          if( json.success ) {
            dispatch({
              type: "TIMELINE_POSTS_RECEIVED",
              posts: json.posts,
            })
          }
        })

      dispatch({
        type: "TIMELINE_POSTS_FETCHING",
        status: true,
      })
    }
  },

  ToggleLikeActivity: (post) => {
    return (dispatch) => {
      var formData = {
        post: post,
      };
      API.post("/api/user/timeline/post/like", { body: JSON.stringify(formData) })
        .then( res => { return res.json() })
        .then( json => {

          if( json.success ) {
            dispatch({
              type: "TOGGLE_LIKE_ACTIVITY",
              status: json.status,
              post: post,
              likes: json.likes,
            });
          }
        })
    }
  },

  PostComment: (post, content) => {
    return (dispatch) => {
      var formData = {
        post: post,
        content: content,
      };
      API.post("/api/user/timeline/post/comment", { body: JSON.stringify(formData) })
        .then( res => { return res.json() })
        .then( json => {
          if( json.success ) {
            dispatch({
              type: "COMMENT_POSTED",
              status: json.status,
              post: post,
              comment: json.comment,
            });
          }
        })
    }
  },

  ToggleLikeComment: (comment_id) => {
    return (dispatch) =>  {
      var data = {
        comment_id: comment_id,
      };
      API.post('/api/user/timeline/post/comment/like', { body: JSON.stringify(data)} )
        .then(res => { return res.json() })
        .then(json => {
          if( json.success ) {
            dispatch({
              type: "TOGGLE_LIKE_COMMENT",
              comment_id: comment_id,
              post_id: json.post_id,
              likes: json.likes,
            });
          }
        });
    }
  },

  ToggleUserImageEdit: (flag) => {
    return {
      type: "TOGGLE_USER_IMAGE_EDIT",
      flag: flag,
    }
  },

  UserPictureChanged: (image_url) => {
    return {
      type: "USER_PICTURE_CHANGED",
      image_url: image_url,
    }
  },

}

export default UserActions;
