import styled from 'styled-components'

const Row = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;

    ${props => props.alignTop && `
        align-items: flex-start;
    `}
`

export default Row