import React from 'react'
import PropTypes from 'prop-types'

import Flex from 'components/Flex'
import TextFieldWrapper from './TextFieldWrapper'
import TextFieldLabel from './TextFieldLabel'
import TextFieldError from './TextFieldError'

const TextField = ({
  append,
  children,
  disabled,
  fontSize,
  full,
  hasValue,
  label,
  large,
  list,
  listVisible,
  meta,
  placeholder,
  prepend,
  onClick,
  wrapperHeight,
  ...props
}) => {
  const hasLabel = !!label
  const hasPlaceholder = !!placeholder
  const hasError = meta && meta.submitFailed && meta.invalid
  const focused = (meta && meta.active) || listVisible
  const moveLabel = (hasValue || placeholder || focused) && label
  const hasList = !!list
  const showList = list && listVisible

  return (
    <TextFieldWrapper
      {...{
        disabled,
        focused,
        full,
        hasError,
        hasLabel,
        hasList,
        hasPlaceholder,
        large,
        moveLabel,
        onClick,
        wrapperHeight,
        ...props
      }}
    >
      {prepend && <Flex className="TextField__prepend">{prepend}</Flex>}
      <Flex className="TextField__input">
        {label && (
          <TextFieldLabel {...{ moveLabel, large, fontSize }}>
            {label} 
            {hasError && 
              <TextFieldError>{meta.error}</TextFieldError>
            }
          </TextFieldLabel>
        )}
        {children}
      </Flex>
      {showList && (
        <Flex className="TextField__list" onClick={ev => ev.stopPropagation()}>
          {list}
        </Flex>
      )}
      {append && <Flex className="TextField__append">{append}</Flex>}
    </TextFieldWrapper>
  )
}

TextField.propTypes = {
  append: PropTypes.node,
  disabled: PropTypes.bool,
  full: PropTypes.bool,
  hasError: PropTypes.bool,
  hasValue: PropTypes.bool,
  label: PropTypes.string,
  large: PropTypes.bool,
  list: PropTypes.node,
  listVisible: PropTypes.bool,
  meta: PropTypes.object,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  prepend: PropTypes.node,
  wrapperHeight: PropTypes.string,
}

export default TextField
