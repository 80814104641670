import React from 'react'

import Row from 'components/Row'
import { Panel } from 'components/Forms'
import LoanField from 'components/Loans/LoanField'

const LoanOverview = ({
    reviewLoan
}) => {
    const { 
        loanPurpose,
        loanExitRoute,
        startDate,
        grossAmount,
        netAmount,
        interestRate,
        arrangementFee,
        exitRate,
        solicitorFee,
        terms,
        isMezzanine,
        ltv,
        deposit,
        totalFeeAmount,
        totalExitAmount,
        totalInterestAmount,
        totalCompoundedInterestAmount,
        monthlyPaymentAmount,
        totalLoanCost,
    } = reviewLoan

    return(
        <Panel title='Initial Specification Overview' expandable initiallyExpanded>
            <Row>
                <LoanField forceValue={loanPurpose} label='Purpose of Loan' />
                <LoanField forceValue={loanExitRoute} label='Exit Route' />
                <LoanField type='date' forceValue={startDate} label='Loan Start Date' />
            </Row>
            <Row>
                <LoanField type='number' forceValue={grossAmount} prepend="£" label='Gross Loan Amount' />
                <LoanField type='number' forceValue={netAmount} prepend='£' label='Net Loan Amount' />
            </Row>
            <Row>
                <LoanField type='number' forceValue={interestRate} append='%' label='Interest Rate' />
                <LoanField type='number' forceValue={arrangementFee} append='%' label='Arrangement Fee' />
            </Row>
            <Row>
                <LoanField type='number' forceValue={exitRate} append='%' label='Exit Rate' />
                <LoanField type='number' forceValue={solicitorFee} prepend='£' label='Solicitor Fee' />
            </Row>
            <Row>
                <LoanField forceValue={terms ? terms.length : 0} append='month(s)' label='Term' />
                <LoanField type='boolean' forceValue={isMezzanine ? isMezzanine.checked : false} label='Is Mezzanine?' />
            </Row>
            <Row>
                <LoanField type='number' forceValue={ltv} append='%' label='LTV' />
                <LoanField type='number' forceValue={deposit} prepend='£' label='Deposit' />
            </Row>
            <Row>
                <LoanField type='number' forceValue={totalFeeAmount} prepend='£' label='Total Arrangement Fee' />
                <LoanField type='number' forceValue={totalExitAmount} prepend='£' label='Total Exit Amount' />
            </Row>
            <Row>
                <LoanField type='number' forceValue={totalInterestAmount} prepend='£' label='Total Interest Amount' />
                <LoanField type='number' forceValue={totalCompoundedInterestAmount} prepend='£' label='Total Compounded Interest Amount' />
            </Row>
            <Row>
                <LoanField type='number' forceValue={monthlyPaymentAmount} prepend='£' label='Monthly Payment Amount' />
                <LoanField type='number' forceValue={totalLoanCost} prepend='£' label='Total Loan Cost' />
            </Row>
        </Panel>
    )
}

export default LoanOverview