import { createSelector } from 'reselect'
import _find from 'lodash/fp/find'
import _get from 'lodash/fp/get'

export const selectCheckId = (state, props) => props.checkId

export const selectLoan = (state, props) => state.global.loanDetails

export const selectLoanChecks = createSelector(
    selectLoan,
    _get('checks')
)

export const selectLoanId = createSelector(
    selectLoan,
    _get('id')
)

export const selectLoanCheckRequest = createSelector(
    selectLoan,
    _get('checkRequest')
)

export const selectLoanCheckLevelData = createSelector(
    [selectLoan, selectCheckId],
    (loan, checkId) => {
        return _find({ checkId }, loan.checks)
    }
)