import React from 'react'
import { branch } from 'recompose'
import styled from 'styled-components'

const FormattedNumberWrapper = styled.div`
    position: absolute;
    left: 15px;
    top: 25px;
    pointer-events: none;
    font-weight: 300;
`
const FormattedPrependText = styled.span`
    margin-right: 3px;
`

const FormattedAppendText = styled.span`
    margin-left: 3px;
`

const FormattedNumberDisplay = ({
    visible,
    value,
    numberPrepend,
    numberAppend,
    formatter,
}) => {
    if( !visible )
        return null
    return(
        <FormattedNumberWrapper>
            {!!numberPrepend &&
                <FormattedPrependText>{numberPrepend}</FormattedPrependText>
            }
            {formatter ? formatter(value) : value}
            {!!numberAppend && 
                <FormattedAppendText>{numberAppend}</FormattedAppendText>
            }
        </FormattedNumberWrapper>
    )
}

export default FormattedNumberDisplay