import styled from 'styled-components'

export default styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    color: #666;
    
    h3 {
        margin: 20px 0 5px 0;
        text-transform: uppercase;
        font-style: italic;
    }

    p {
        margin: 0px;
        font-size: 12px;
        text-transform: uppercase;
    }
`