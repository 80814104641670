import styled from 'styled-components'

import TextBase from './TextBase'

const H1 = styled(TextBase)`
  font-weight: 300;
  letter-spacing: auto;
`

H1.defaultProps = {
  fontSize: '27px',
  lineHeight: '33px',
}

export default H1
