import { compose, withProps } from 'recompose'

import { PriceField } from 'components/FormFields'

import { validateRequired } from 'utils/validators'
import { withFormValuesOnChange } from 'components/HOC'
import { AddLoanForm } from 'containers/Loan/AddLoan/consts'

const validators = [validateRequired]

export default compose(
    withProps(() => ({
        name: 'personalGuaranteeLimit',
        label: "Limit Amount",
        validators,
    })),
)(PriceField)