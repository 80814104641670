import API from 'api'

const getClientRequest = () => ({ type: "FETCH/REQUEST" })
const getClientSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getClientError = err => ({ type: "FETCH/ERROR", error: err})

export const getClient = (id) => dispatch => {
    dispatch(getClientRequest())

    API
        .get(`/api/clients/single?id=${id}`)
        .then(res => res.json())
        .then(json => { dispatch(getClientSuccess(json)) })
        .catch(err => dispatch(getClientError(err)))
}