import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm, submit } from 'redux-form'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import { AddNetAdvanceForm } from './consts'
import * as NetAdvanceActions from './actions'
import { selectLoanId } from './selectors'

const mapState = createStructuredSelector({
    initialValues: state => ({
        date: new Date(),
    }),
    loanId: selectLoanId,
})

const mapDispatch = dispatch => ({
    ...bindActionCreators(NetAdvanceActions, dispatch),
    submitForm: form => dispatch(submit(form)),
})

export default compose(
    connect(mapState, mapDispatch),
    reduxForm({
        form: AddNetAdvanceForm, 
        onSubmit: (values, dispatch, props) => {
            const { createNetAdvance, loanId } = props
            createNetAdvance({
                ...values,
                loanId,
            }, () => {
                window.location.reload()
            })
        }
    }),
    withProps(({ submitForm }) => ({
        handleSubmit: () => {
            submitForm(AddNetAdvanceForm)
        }
    }))
)