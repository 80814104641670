import React from 'react'

import { Message } from 'components/Messages'

const AwaitingStatusChange = ({
    text,
    ...props
}) => {
    return( 
        <Message>{text}</Message>
    )
}
export default AwaitingStatusChange