import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom'
import RegisterMod from '../../components/auth/RegisterMod';

import logo from '../../images/logo.svg';

class RegisterPage extends Component {
  render() {
    return (
      <div id='register-page' className='page full-page'>
        <div className='inner'>
          <div className='brand-logo'>
            <Link to="/">
              <img src={logo} />
            </Link>
          </div>
          <RegisterMod />
        </div>
      </div>
    );
  }
}

export default RegisterPage;
