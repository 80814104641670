import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { validateRequired } from 'utils/validators'

import { PercentageField } from 'components/FormFields'
import { withFormValuesOnChange } from 'components/HOC'

import { selectActiveInterestRate } from 'containers/Loan/Common/selectors'
import { AddLumpSumForm } from 'containers/Loan/AddLumpSum/consts'

const validators = [validateRequired]

const mapState = createStructuredSelector({
    interestRate: selectActiveInterestRate,
})

export default compose(
    connect(mapState),
    withProps(({ interestRate }) => ({
        name: 'interestRate',
        label: "Interest Rate",
        validators,
        defaultValue: interestRate,
    })),
    withFormValuesOnChange(
        AddLumpSumForm, 
        [ 'remainingAmount' ],
        ({ remainingAmount }, { interestRate }) => {
            return interestRate
        }
    ),
)(PercentageField)