import React from 'react'
import Row from 'components/Row'
import ClientField from 'components/Clients/ClientField'

const CorporateOverview = ({
  borrowerEmail,
  borrowerTelephone
}) => {

  return(
    <React.Fragment>
      <Row>
          <ClientField path='companyName' label='Company Name' />
          <ClientField path='registrationNumber' label='Registration Number' />
          <ClientField type='date' path='registrationDate' label='Registration Date' />
      </Row>
      <Row>
          <ClientField path='registeredAddress' label='Registered Address' />
          <ClientField path='registeredPostcode' label='Registered Postcode' />
      </Row>
      <Row>
          <ClientField path='postalAddress' label='Postal Address' />
          <ClientField path='postalPostcode' label='Postcode' />
          <ClientField path='country' label='Country' />
      </Row>
      {borrowerTelephone && borrowerEmail && 
        <Row alignTop>
            <ClientField label='Borrower Email Address' forceValue={borrowerEmail} />
            <ClientField label='Borrower Telephone' forceValue={borrowerTelephone} />
        </Row>
      }
    </React.Fragment>
  )
}

export default CorporateOverview