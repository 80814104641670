import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'
import _get from 'lodash/fp/get'

import Spinner from 'components/globals/Spinner'

import LoadingPanelWrapper from './LoadingPanelWrapper'

const selectIsFetching = createSelector(
    state => state.global,
    _get("fetching")
)

const mapState = createStructuredSelector({
    isFetching: selectIsFetching
})

const LoadingPanel = ({
    isFetching,
    text,
}) => {
    if(!isFetching) return null

    return(
        <LoadingPanelWrapper>
            <Spinner />
            <h3>Please Wait...</h3>
            {text && <p>{text}</p>}
        </LoadingPanelWrapper>
    )
}

export default connect(mapState)(LoadingPanel)