import React from 'react'
import { compose } from 'recompose'
import { Redirect } from 'react-router-dom'

import Row from 'components/Row'
import Page from 'components/Page'
import Spinner from 'components/HOC/withSpinner/Spinner'
import { Panel } from 'components/Forms'
import { PrimaryButton } from 'components/Buttons' 

import AddTemplateContainer from 'containers/Templates/AddTemplate'
import * as TemplateFields from 'components/FormFields/Template'

const AddTemplate = ({
    handleSubmit,
    isCreating, 
    redirectUrl,
}) => {
    if(isCreating) {
        if( redirectUrl.length > 0 ) 
            return <Redirect to={redirectUrl} />
        else return <Spinner />
    }
        
    return(
      <Page title='Add Template'>
        <Panel title="Template Fields">
            <Row>
                <TemplateFields.TemplateNameField />
            </Row>
            <Row>
                <TemplateFields.TemplateDescriptionField />
            </Row>
            <Row>
                <TemplateFields.TemplateContentField />
            </Row>
            <Row style={{ justifyContent: "flex-end" }}>
                <PrimaryButton onClick={handleSubmit}>CREATE</PrimaryButton>
            </Row>
        </Panel>
      </Page>
    )
}

export default compose(
    AddTemplateContainer,
)(AddTemplate)