import { compose, withProps } from 'recompose'

import { PercentageField } from 'components/FormFields'

import { validateRequired } from 'utils/validators'
const validators = [validateRequired]

export default compose(
    withProps(({}) => ({
        name: 'arrangementFee',
        label: "Arrangement Fee",
        validators,
        
    }))
)(PercentageField)