import React from 'react'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as actions from './actions'

const mapDispatch = dispatch => bindActionCreators(actions, dispatch)

export default compose(
    connect(null, mapDispatch)
)