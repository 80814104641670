import React from 'react'
import { connect } from 'react-redux';
import { compose } from 'recompose'
import { Switch, Route, Link } from 'react-router-dom';

import LoanActions from 'actions/LoanActions'
import GlobalActions from 'actions/GlobalActions'

import Spinner from 'components/globals/Spinner'
import LoanData from 'components/Loans/LoanData'
import { Message } from 'components/Messages'
import { LoanListItem } from 'components/ListItems'
import LoadingPanel from 'components/LoadingPanel'
import { SecondaryButton } from 'components/Buttons'

import ViewLoan from '../loans/ViewLoan';

class Loans extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isFetching: true,
    }
  }

  componentDidMount() {
    this.props.LoadAllLoans(() => {
      this.setState({
        isFetching: false,
      })
    })
  }

  renderLoans() {
    var key = 0;
    var display = [];

    if(this.state.isFetching)
      return null

    if( this.props.loans.length == 0 ) {
      return <Message text="No loans found." />
    }

    this.props.loans.forEach( loan => {
      display.push(
        <LoanListItem key={key++} {...loan}/>
      )
    });

    return display;
  }

  render() {
    const { checks } = this.props

    return(
      <div className='page loans'>
        <Switch>
          <Route exact path='/dashboard/loans/'>
          <div>
            <div className='info page-heading'>
              <h2>
                Loans
                <SecondaryButton to='/dashboard/new-loan' darkGray>CREATE</SecondaryButton>
              </h2>
            </div>
        
            <div className='page-content listing'>
              {this.state.isFetching && <LoadingPanel text="Loading loans." />}

              {!this.state.isFetching && this.props.loans.length > 0 && 
                <div className='thead'>
                  <div className='row'>
                    <div className='col'>Client</div>
                    <div className='col'>Status</div>
                    <div className='col'>Amount</div>
                    <div className='col'>Start Date</div>
                    {/* {checks && checks.map((item, num) => {
                      return(
                        <div className='col' key={num}>Check {num + 1}</div>
                      )
                    })} */}
                    <div className='col'>Level 1</div>
                    <div className='col'>Level 2</div>
                    <div className='col'>Level 3</div>

                    <div className='col'>Docs</div>
                    <div className='col'></div>
                  </div>
                </div>
              }
              <div className='items'>
                {this.renderLoans()}
              </div>
            </div>
          </div>
          </Route>
          <Route path='/dashboard/loans/details/:id' component={ViewLoan} />
        </Switch>
      </div>
    )
  }
}

const mapState = state => {
  return {
    loans: state.global.loans,
    checks: state.global.loan_checks,
  }
}

const mapDispatch = dispatch => {
  return {
    LoadAllLoans: (callback) => {
      dispatch(LoanActions.LoadAll(callback))
      dispatch(GlobalActions.LoadAllChecks())
    }
  }
}

export default compose(
  connect(mapState, mapDispatch),
)(Loans);
