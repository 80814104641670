import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { reducer as formReducer } from 'redux-form'

import Root from './components/Root';
import { Provider } from 'react-redux';
import registerServiceWorker from './registerServiceWorker';
import {ClientState} from './reducers/NewClientState';
import {LoanState} from './reducers/NewLoanState';
import GlobalState from './reducers/GlobalState';
import defaultState from './reducers/DefaultState';

// import io from 'socket.io-client';
import thunk from 'redux-thunk';

import "./styles/css/main.css";

const reducers = combineReducers({
  user: defaultState,
  client: ClientState,
  loan: LoanState,
  global: GlobalState,
  form: formReducer,
});

// const wildcard = require('socketio-wildcard')();
// const socket = io('http://localhost:3445');
// const socket = io('http://ec2-18-134-205-71.eu-west-2.compute.amazonaws.com:3445');
// const socket = io('http://iosephbackend-env.eba-x23dpdkj.eu-west-2.elasticbeanstalk.com');
// const socket = io('https://api.iosephconnect.com');

// var onevent = socket.onevent;
// socket.onevent = function (packet) {
//   var args = packet.data || [];
//   onevent.call (this, packet);
//   packet.data = ["*"].concat(args);
//   onevent.call(this, packet);
// };

// const responseBuilder = (type, body) => {
//   let merged = { type: type, ...body };
//   return merged;
// }

// const ioMiddleware = (store) => {
//   return next => action => {
//     const result = next(action);

//     if (socket && action.type.startsWith('io/')) {
//       var eventName = action.io_event_name;
//       var eventData = action.io_event_data;
//       if( !eventName ) {
//         throw "No io event name specified...";
//         return;
//       }
//       if( !eventData )
//         eventData = {};

//       socket.emit(eventName, eventData);
//     }

//     return result;
//   };
// }

// const ioReceiver = (store) => {
//   socket.on('*', function(type, data) {
//     var mData = data ? data : {};
//     store.dispatch(responseBuilder(type, mData));
//   });
// }

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk),
  // applyMiddleware(thunk, ioMiddleware),
  // other store enhancers if any
);
const store = createStore(reducers, enhancer);

// ioReceiver(store);

ReactDOM.render(
    <Provider store={store}>
      <Root />
    </Provider>,
    document.getElementById('root'));

registerServiceWorker();
