import API from 'api'

const getTagSubmit = () => ({ type: "FETCH/REQUEST" })
const getTagSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getTagError = err => ({ type: "FETCH/ERROR", error: err})

export const getTag = id => dispatch => {
    dispatch(getTagSubmit())

    API
        .get(`/api/tags/single?id=${id}`)
        .then(res => res.json())
        .then(json => { 
            dispatch(getTagSuccess(json))
        })
        .catch(err => dispatch(getTagError(err)))
}


const updateTagSubmit = () => ({ type: "FETCH/REQUEST" })
const updateTagSuccess = data => ({ type: "FETCH/SUCCESS", data })
const updateTagError = err => ({ type: "FETCH/ERROR", error: err})

export const updateTag = (id, values, callback) => dispatch => {
    dispatch(updateTagSubmit())

    API
        .post(`/api/tags/update?id=${id}`, { body: JSON.stringify(values)})
        .then(res => res.json())
        .then(json => { 
            
            dispatch(updateTagSuccess(json))
            if(callback) callback()
        })
        .catch(err => dispatch(updateTagError(err)))

}