import moment from 'moment'
import _get from 'lodash/fp/get'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import { createSelector, createStructuredSelector } from 'reselect';

import { withFormValuesOnChange } from 'components/HOC'
import { PriceField } from 'components/FormFields'

import { ExtensionFormName } from 'containers/Extensions/AddExtension/consts'
import { selectLoanExtensions, selectLoanTerms } from 'containers/Extensions/Common/selectors'


const mapState = createStructuredSelector({
    extensions: selectLoanExtensions,
    terms: selectLoanTerms,
})

export default compose(
    connect(mapState),
    withProps(() => ({
        name: 'includedRepaymentsAmount',
        label: "Included Repayments Amount",
        prependNumber: "£",
        readOnly: true,
    })),
    withFormValuesOnChange(
        ExtensionFormName, 
        ['startDate'],
        ({ startDate }, { terms, extensions }) => {
            let totalAmount = 0
            if(!extensions || extensions.length == 0) {
                // no extensions check the base terms only
                // only non compounded entries 
                terms.forEach(td => {
                    if( !td.fullyPaid )
                        if( moment(td.periodEnd).isBefore(startDate) ) 
                            totalAmount += td.amount
                })
            } else {
                extensions.forEach(extension => {
                    extension.terms.forEach(td => {
                        if( !td.fullyPaid )
                            if( moment(td.periodEnd).isBefore(startDate) ) 
                                totalAmount += td.amount
                    })
                })
            }
            return totalAmount
        }
    ),
)(PriceField)