import React from 'react'

import { LoanSteps } from 'reducers/NewLoanState';

import NewLoanDetails from './Details';
import NewLoanBorrower from './Borrower';
import NewLoanDeclaration from './Declaration'
import NewLoanConfirmation from './Confirmation'
import NewLoanAdditionalInfo from './AdditionalInfo'
import NewLoanApplicantDetails from './ApplicantDetails'
import NewLoanAdditionalSecurity from './AdditionalSecurity'
import NewLoanAdditionalProperties from './AdditionalProperties'

const NewLoanSteps = ({
    step,
    ...props
}) => (
    <div className='new-loan-steps'>
        {step == LoanSteps.ClientSelect && <NewLoanBorrower {...props} />}
        {step == LoanSteps.LoanDetails && <NewLoanDetails {...props} />}
        {step == LoanSteps.AdditionalProperties && <NewLoanAdditionalProperties {...props} />}
        {step == LoanSteps.AdditionalSecurity && <NewLoanAdditionalSecurity {...props} />}
        {step == LoanSteps.ApplicantsDetails && <NewLoanApplicantDetails {...props} />}
        {step == LoanSteps.AdditionalInfo && <NewLoanAdditionalInfo {...props} />}
        {/* {step == LoanSteps.Declaration && <NewLoanDeclaration {...props} />} */}
        {step == LoanSteps.Confirmation && <NewLoanConfirmation {...props} />}
    </div>
)

export default NewLoanSteps