import React from 'react'
import { compose } from 'recompose'

import ListDuePaymentsContainer from 'containers/Loan/ListDuePayments'

import DueTermListItem from 'components/ListItems/DueTermListItem'

const DuePayments = ({
    isFetching,
    terms,
}) => {
    
    return(
        <div className='due-payments'>
            <div className='info'>
                <h2>Due Payments</h2>
            </div>

            <div className='page-content listing'>
                <div className='thead'>
                    <div className='row'>
                        <div className='col-sm-4'>Client</div>
                        <div className='col-sm-3'>Due Date</div>
                        <div className='col-sm-3'>Amount Due</div>
                    </div>
                </div>
                {terms && terms.map(term => 
                    <DueTermListItem {...{ ...term, key: term._id }} />    
                )}
            </div>
        </div>
    )
}

export default compose(
    ListDuePaymentsContainer,
)(DuePayments)