import React from 'react'
import { compose } from 'recompose'

import Page from 'components/Page'
import Row from 'components/Row'
import { PrimaryButton } from 'components/Buttons' 

import AddProfessionalContainer from 'containers/Professionals/AddProfessional'

import * as ProfessionalFields from 'components/FormFields/Professionals'

const AddProfessional = ({
    submitForm,
    formValues,
    ...props
}) => {
    const type = formValues ? formValues.type : null
    
    return(
        <Page title="Create New Professional">
            <Row>
                <ProfessionalFields.NameField />
            </Row>
            <Row>
                <ProfessionalFields.ContactField />
            </Row>
            <Row>
                <ProfessionalFields.TypeField />
            </Row>
            <Row>
                <ProfessionalFields.EmailField />
            </Row>
            <Row>
                <ProfessionalFields.TelephoneField />
            </Row>
            <Row>
                <ProfessionalFields.AddressField />
            </Row>
            <Row>
                <ProfessionalFields.PostcodeField />
            </Row>
            <Row>
                <ProfessionalFields.CityField />
            </Row>
            <Row style={{ justifyContent: "flex-end" }}>
                <PrimaryButton onClick={submitForm}>CREATE</PrimaryButton>
            </Row>
        </Page>
    )
}

export default compose(
    AddProfessionalContainer,
)(AddProfessional)