import { createSelector } from 'reselect'
import { formValuesSelector } from 'utils/reduxFormUtils'

import _get from 'lodash/fp/get'

import { FormName } from './consts'


export const selectCheckLevels = createSelector(
  state => state.global,
  _get("checkLevels")
)

export const selectInitialValues = createSelector(
  selectCheckLevels,
  checks => {
      if(checks)
        return {
            ...checks
        }
      return null
  }
)

export const selectFormValues = formValuesSelector(FormName)