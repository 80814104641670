// export const API_URL = "http://crm.thinkcreatedigital.com:3445"
// export const API_URL = "http://ec2-18-134-205-71.eu-west-2.compute.amazonaws.com:3445"
// export const API_URL = "http://iosephbackend-env.eba-x23dpdkj.eu-west-2.elasticbeanstalk.com";
//export const API_URL = "http://localhost:3445";
export const API_URL = "https://iosephlimited.com/backend";


const API = {
  get(endpoint, params) {
    var data = Object.assign(
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        credentials: "include",
        withCredentials: true,
      },
      params
    );
    return fetch(API_URL + endpoint, data);
  },
  post(endpoint, params) {
    var data = Object.assign(
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        credentials: "include",
        withCredentials: true,
      },
      params
    );
    return fetch(API_URL + endpoint, data);
  },

  getEndpointURL(endpoint) {
    return API_URL + endpoint;
  },
};

export default API;
