import { compose, withProps } from 'recompose'

import Field from 'components/FormFields/Field'
import { TextField, TextAreaField } from 'components/Fields'

export default compose(
    withProps(({ name, type, label, value, input }) => ({
        name: name,
        label: label,
        defaultValue: value,
    }))
)(Field)