import React from 'react';

class Spinner extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    }
  }

  setVisible(flag) {
    this.setState({
      visible: flag,
    });
  }

  render() {
    if( !this.state.visible )
      return null;

    return(
      <div className='spinner'></div>
    )
  }
}

export default Spinner;
