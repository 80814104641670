import React from 'react'
import moment from 'moment'

import { DEFAULT_DATE_FORMAT } from 'consts'

import LoanExtensionRepaymentTerms from './LoanExtensionRepaymentTerms'

export default ({
    extensions,
    ...props
}) => {
    if(!extensions)
        return null

    return(
        <React.Fragment>
            {extensions.map(extension => (
                <React.Fragment key={extension._id}>
                    <h3 style={{margin: '30px 0'}}>Extension {moment(extension.startDate).format(DEFAULT_DATE_FORMAT)}</h3>
                    <LoanExtensionRepaymentTerms 
                        {...{ key: extension._id, extension, ...props }} />
                </React.Fragment>
            ))}     
        </React.Fragment>
    )
}