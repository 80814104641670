import API from 'api'

const addWalletEntrySubmit = () => ({ type: "FETCH/REQUEST" })
const addWalletEntrySuccess = data => ({ type: "FETCH/SUCCESS", data })
const addWalletEntryError = err => ({ type: "FETCH/ERROR", error: err})

export const addWalletEntry = (values, callback) => dispatch => {
    dispatch(addWalletEntrySubmit())

    API
        .post('/api/wallet/create', { body: JSON.stringify(values)})
        .then(res => res.json())
        .then(json => { 
            dispatch(addWalletEntrySuccess(json))
            if(callback) callback()
        })
        .catch(err => dispatch(addWalletEntryError(err)))

}