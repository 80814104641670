import API from 'api'

const getUsersSubmit = () => ({ type: "FETCH/REQUEST" })
const getUsersSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getUsersError = err => ({ type: "FETCH/ERROR", error: err})

export const getUsers = () => dispatch => {
    dispatch(getUsersSubmit())

    API
        .get('/api/auth/list-users')
        .then(res => res.json())
        .then(json => { 
            dispatch(getUsersSuccess(json)) 
        })
        .catch(err => dispatch(getUsersError(err)))
}

const getUserSubmit = () => ({ type: "FETCH/REQUEST" })
const getUserSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getUserError = err => ({ type: "FETCH/ERROR", error: err})

export const getUser = () => dispatch => {
    dispatch(getUserSubmit())

    API
        .get('/api/auth/me')
        .then(res => res.json())
        .then(json => { 
            dispatch(getUserSuccess(json)) 
        })
        .catch(err => dispatch(getUserError(err)))
}