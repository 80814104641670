import styled from 'styled-components'

import TagInputField from 'components/FormFields/TagInputField'

export default styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 30px -10px 0 -10px;

    .sc-dnqmqq {
        margin: 0 5px 10px 0px;
        min-width: calc(50% - 10px);
        width: auto;
    }
`