import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import {validateEmail} from 'helpers/Helpers';
import API from 'api'

const Steps = {
  Email: 0,
  Confirmation: 1,
}

export default class PasswordRecoveryMod extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: Steps.Email,
      email: "",
      password: "",
      error: "",
      show_error: false,
    };
  }

  componentDidMount() {

  }

  handlePrev() {
    var step = this.state.step - 1;
    this.setState({
      step: step,
      show_error: false,
    })
  }

  handleNext() {
    switch(this.state.step) {
      case Steps.Email:
        // validate email
        if( !validateEmail(this.state.email) )
          this.setState({
            error: "This email address is invalid.",
            show_error: true,
          });
        else {
          // set loading state
          this.setState({
            fetching: true,
          })
          // email valid, call api and
          // send the validation code
          API.post("/api/user/password-reminder", { body: JSON.stringify({ email: this.state.email })})
            .then( res => { return res.json() })
            .then( json => {
              if( json.success ) {
                // proceed to the next step
                this.setState({
                  step: Steps.Confirmation,
                  fetching: false,
                });
              } else {
                this.setState({
                  show_error: true,
                  error: json.error,
                  fetching: false,
                })
              }
            })
        }
        break;
    }
  }

  handleEmailChange(e) {
    var value = e.target.value;
    var show_error = this.state.show_error;
    var email_valid = validateEmail(value);

    // if error showing validate on the fly
    if(show_error) {
      this.setState({
        show_error: !email_valid,
        email: value,
      });
    } else {
      this.setState({
        email: value,
      })
    }
  }

  handleKeyUp(e) {
    e.preventDefault()
    e.stopPropagation()
    
    if( e.keyCode == 13 ) {
      this.handleNext()
    }
  }

  renderStep() {
    switch(this.state.step) {
      case Steps.Email:
        return(
          <div className='form-group'>
            <p>Enter your email below then check your inbox for steps to reset your password.</p>
            <input type='text' autoComplete="nope" onKeyUp={this.handleKeyUp.bind(this)} onChange={this.handleEmailChange.bind(this)} value={this.state.email} placeholder='Email address' />
          </div>
        );
      case Steps.Confirmation:
        return(
          <div className='form-group'>
            <p>Email has been sent. Please check your inbox and follow instructions to reset your password.</p>
          </div>
        );
    }
    return null;
  }

  renderActions() {
    if( this.state.step == Steps.Confirmation ) {
      return(
        <div className='form-group login-actions recovery-actions'>
          <Link className='btn btn-back' to='/'>Back to Login</Link>
        </div>
      )
    }

    return(
      <div className='form-group login-actions recovery-actions'>
        {this.state.step == Steps.Email ?
          <Link className='btn btn-back' to='/'>Back</Link> :
          <a className='btn btn-default' onClick={this.handlePrev.bind(this)}>Back</a>
        }
        <a className={'btn btn-grey' + (this.state.fetching ? ' disabled' : '')} disabled={this.state.fetching} onClick={this.handleNext.bind(this)}>
          {this.state.fetching ? "Please Wait" : "Next"}
        </a>
      </div>
    )
  }

  render() {
    return(
      <div className='mod mod-login mod-password-recovery'>
        <h2>Password Recovery</h2>

        {this.state.show_error &&
          <div className='error'>
            <p>{this.state.error}</p>
          </div>
        }

        {this.renderStep()}
        {this.renderActions()}
      </div>
    )
  }
}
