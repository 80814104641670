import API from 'api'

const getWalletEntriesSubmit = () => ({ type: "FETCH/REQUEST" })
const getWalletEntriesSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getWalletEntriesError = err => ({ type: "FETCH/ERROR", error: err})

export const getWalletEntries = () => dispatch => {
    dispatch(getWalletEntriesSubmit())

    API
        .get('/api/wallet')
        .then(res => res.json())
        .then(json => { 
            dispatch(getWalletEntriesSuccess(json)) 
        })
        .catch(err => dispatch(getWalletEntriesError(err)))
}