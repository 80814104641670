import React from 'react'
import {
  compose,
  withProps,
  withState,
  wrapDisplayName,
  lifecycle,
} from 'recompose'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { formValuesSelector } from 'utils/reduxFormUtils'

const withFormValues = formName => WrappedComponent => {
  const WithFormValues = props => <WrappedComponent {...props} />

  if (process.env.NODE_ENV !== 'production') {
    WithFormValues.displayName = wrapDisplayName(
      WrappedComponent,
      'withFormValues',
    )
  }

  return compose(
    withState('formValuesInitialized', 'setFormValuesInitialized', false),
    connect(
      createStructuredSelector({
        formValues: formValuesSelector(formName),
      }),
    ),
    lifecycle({
      componentDidUpdate() {
        const {
          initialValues,
          formValuesInitialized,
          setFormValuesInitialized,
        } = this.props

        if (
          !initialValues ||
          (!formValuesInitialized &&
            (!Object.keys(initialValues).length ||
              !!Object.keys(initialValues).length))
        ) {
          setFormValuesInitialized(true)
        }
      },
    }),
    withProps(({ formValuesInitialized, initialValues, formValues }) => ({
      formValues: formValuesInitialized ? formValues : initialValues,
    })),
  )(WithFormValues)
}

export default withFormValues
