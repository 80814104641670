import React from 'react'
import styled from 'styled-components'

import { COLORS } from 'consts'

import FormulaButton from './FormulaButton'

const FormulaToolbar = styled.div`
  position: absolute;
  display: flex;
  top: 5px;
  left: 5px;
  background: #323444;
  width: 100%;
  font-size: 12px;
  padding-right: 16px;
  border-radius: 4px;
  max-width: calc(100% - 10px);
  overflow: hidden;
  transition: opacity 300ms linear;
  align-items: center;

  ${props =>
    props.visible &&
    `
      opacity: 1
      transition: opacity 100ms linear;
  `};

  > div:first-child {
    margin-left: 6px;
  }

  > div:last-child {
    margin-left: 12px;
  }
`

export default ({ disallowTitle, editor, value, ...props }) => (
  <FormulaToolbar className="FormulaToolbar" {...props}>
      <FormulaButton type="fx" {...{ editor, value }} />
      <FormulaButton type="tags" {...{ editor, value }} />
  </FormulaToolbar>
)
