import React from 'react'

import SystemOverview from 'components/payments/SystemOverview'


export default () => {
  return(
    <div>
      <SystemOverview />
    </div>
  )
}