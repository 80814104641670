import React from 'react'

import RepaymentListItem from 'components/ListItems/RepaymentListItem'

import RepaymentsListItemsHeaders from './RepaymentsListItemsHeaders'

export default ({
    terms,
    currentGrossAmount,
    toggleRepaymentPopup,
    setRepaymentInitialValues,
}) => {
    if(!terms)
        return null
        
    return(
        <React.Fragment>
          <RepaymentsListItemsHeaders />
          {terms.map(data => (
            <RepaymentListItem 
              {...{
                key: data._id,
                handleClick: (id, amount) => {
                  setRepaymentInitialValues(id, amount)
                  toggleRepaymentPopup()
                },
                currentGrossAmount,
                ...data,
              }}
            />
          ))}
        </React.Fragment>
    )
}