import styled from 'styled-components'

import { COLORS } from 'consts'

import ButtonBase from './ButtonBase'

export default styled(ButtonBase)`
  height: ${props => props.height || '30px'};
  font-size: ${props => props.fontSize || '15px'};
  color: ${props => props.color || COLORS.darkGray};
  text-decoration: none;

  span {
    text-decoration: ${props => (props.underlined ? 'underline' : 'none')};
  }

  i {
    margin-right: ${props => (props.children || props.text ? '5px' : '0')};
  }

  :hover {
    box-shadow: none;
  }

  ${props =>
    props.active &&
    !props.toggleButton &&
    `
    opacity: 0.87;
    pointer-events: none;
  `};

  ${props =>
    !props.toggleButton &&
    `
      &.active {
        opacity: 0.87;
        pointer-events: none;

        ${props.rotateIcon &&
          `
          i {
            transform: rotate(-180deg);
          }
        `};
      }
    `};

  ${props =>
    (!props.active || props.toggleButton) &&
    props.hoverBorder &&
    `
    padding: 4px 6px;
    border-radius: 6px;

    :hover {
      padding: 3px 6px;
      border: 1px solid ${props.hoverBorder};
      border-radius: 3px;
    }
  `};
`
