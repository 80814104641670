import React from 'react'
import { compose } from 'recompose'

import GenerateTemplateContainer from 'containers/Templates/GenerateTemplate'

import { SecondaryButton } from 'components/Buttons'
import Spinner from 'components/globals/Spinner'

import TemplateListItemWrapper from './TemplateListItemWrapper'

const TemplateListItem = ({
    name,
    description,
    handleClick,
    isGenerating,
}) => {
    const buttonText = isGenerating ? "PLEASE WAIT " : "GENERATE PDF"
    return(
        <TemplateListItemWrapper>
            <div className='row'>
                <div className='col'>
                    <h3>{name}</h3>
                    <p>{description}</p>
                </div>
                <div className='col text-right' style={{ justifyContent: 'flex-end'}}>
                    <SecondaryButton disabled={isGenerating} onClick={handleClick}>
                        <span style={{ marginRight: isGenerating ? 5 : 0 }}>{buttonText}</span>
                        {isGenerating && <Spinner />}
                    </SecondaryButton>
                </div>
            </div>
        </TemplateListItemWrapper>
    )
}

export default compose(
    GenerateTemplateContainer,
)(TemplateListItem)