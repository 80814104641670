import { compose, withProps } from 'recompose'

import Field from 'components/FormFields/Field'

import { validateRequired } from 'utils/validators'

const validators = [validateRequired]

export default compose(
    withProps(({ property, ...props }) => ({
        name: `${property}.type`,
        label: "Property Type",
        validators,
    }))
)(Field)