import React from 'react'

import RedemptionInfoPanel from './RedemptionInfoPanel'

export default ({
    companyName,
    statementDateFormatted,
}) => {
    return(
        <RedemptionInfoPanel>
            <h3>Redemption Statement for {companyName}</h3>
            <p>Redemption calculations based on redemption on or after the {statementDateFormatted}</p>
        </RedemptionInfoPanel>
    )
}