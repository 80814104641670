import React, { Component } from 'react'
import {connect} from 'react-redux'
import { BrowserRouter as Router, Route, Switch, NavLink, Link } from 'react-router-dom'
import {TweenMax,} from 'gsap'
import { Flex } from '@rebass/grid'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCreditCard, faUserFriends, faCog, faWallet, faChartBar, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

import API from 'api'
import Panel from '../../components/globals/Panel'
import Logo from '../../components/globals/Logo'

// import main components //
import PaymentsInfo from './PaymentsInfo'
import SystemOverview from '../../components/payments/SystemOverview'
import NewClient from '../client/AddClient'
import AddLoan from '../loans/AddLoan'
import Clients from './Clients'
import Notifications from './Notifications'
import Loans from './Loans'
import Settings from './views/Settings'
import NewUser from '../users/NewUser'

import GlobalActions from '../../actions/GlobalActions'

import ListWalletEntries from '../wallet/ListEntries'
import AddWalletEntry from '../wallet/AddEntry'
import EditWalletEntry from '../wallet/EditEntry'

import DashboardSubmenu from './views/DashboardSubmenu'
import DashboardTopSection from './views/DashboardTopSection'

import Overview from './views/Overview'
import Pipeline from './views/Overview/views/Pipeline'
import RecentPayments from './views/Overview/views/RecentTransactions'
import DuePayments from './views/Overview/views/DuePayments'

import ReviewCalendar from './views/ReviewCalendar'


library.add(faCreditCard, faUserFriends, faCog, faWallet, faChartBar, faCalendarAlt)
class Dashboard extends Component {

  componentDidMount() {

    this.props.Init()
    // window.addEventListener("scroll", this.onScroll.bind(this))
  }

  // onScroll(e) {
  //   let scrollY = window.scrollY
  //   if( scrollY > 35 ) {
  //     TweenMax.set(this.refs.wrap, { position: "fixed", top: 20  })
  //   } else if( scrollY <= 35) {
  //     TweenMax.set(this.refs.wrap, { position: "absolute", top: 55 })
  //   }

  // }

  renderNotifications() {
    var str = "(" + this.props.total_notifications + ")"
  
    return(
      <span>
        <span>{str}</span>
        {this.props.new_notifications > 0 && 
          <span className='new-notifications'>{this.props.new_notifications}</span>
        }
      </span>
    )
  }

  render() {
    return (
      <div id='dashboard' className='page'>
        <div ref='wrap' className='dashboard-fixed-wrap'>
          <div className=''>
            <Flex justifyContent="stretch" alignItems="stretch">
              <div ref='left' className='left-column'>
                <Panel className="toolbar">
                  <div className='logo'>
                    <Logo />
                    <span>MANAGEMENT TOOL</span>
                  </div>
                  <div className='main-menu'>
                    <NavLink to='/dashboard/due-payments'>
                      <FontAwesomeIcon icon={['fal', 'chart-bar']} />
                      Dashboard
                    </NavLink>
                    <NavLink to='/dashboard/loans'>
                      <FontAwesomeIcon icon={['fal', 'credit-card']} />
                      Loans
                    </NavLink>
                    {/* <NavLink to='/dashboard/loan-checks'>Loan Checks</NavLink> */}
                    <NavLink to='/dashboard/clients'>
                      <FontAwesomeIcon icon={['fal', 'user-friends']} />
                      Clients
                    </NavLink>
                    <NavLink to='/dashboard/calendar'>
                      <FontAwesomeIcon icon={['fal', 'calendar-alt']} />
                      Review Calendar
                    </NavLink>
                    {/* <NavLink to='/dashboard/professionals'>Professionals</NavLink> */}
                    {/* <NavLink to='/dashboard/templates'>Templates</NavLink> */}
                    {/* <NavLink to='/dashboard/tags'>Tags</NavLink> */}
                    {/* <NavLink to='/dashboard/users'>Users</NavLink> */}
                    <NavLink to='/dashboard/settings'>
                      <FontAwesomeIcon icon={['fal', 'cog']} />
                      System Admin
                    </NavLink>
                    <NavLink to='/dashboard/wallet'>
                      <FontAwesomeIcon icon={['fal', 'wallet']} />
                      Wallet
                    </NavLink>
                  </div>
                  <div className='quick-access'>
                    <Link to='/dashboard/new-loan/borrower'>
                      <span className='icon-medium icon-plus'></span>
                      NEW <strong>LOAN</strong>
                    </Link>
                    <Link to='/dashboard/new-client/borrower'>
                      <span className='icon-medium icon-plus'></span>
                      NEW <strong>CLIENT</strong>
                    </Link>
                  </div>
                </Panel> 
              </div>
              {/* <div ref='right' className='right-column'>
                <Panel>
                  <SystemOverview />
                </Panel>
              </div> */}
              <div className='main'>
                
                  <DashboardSubmenu />
                  <div className='' style={{ flex: 1, width: '100%' }}>
                    <DashboardTopSection />
                    <Panel>
                      <Switch>
                        <Route path='/dashboard/due-payments' component={DuePayments} />
                        <Route path='/dashboard/recent-transactions' component={RecentPayments} />
                        <Route path='/dashboard/pipeline' component={Pipeline} />

                        <Route path='/dashboard/new-client' component={NewClient} />
                        <Route path='/dashboard/new-loan' component={AddLoan} />
                        <Route path='/dashboard/clients' component={Clients} />
                        <Route path='/dashboard/notifications' component={Notifications} />
                        <Route path='/dashboard/loans' component={Loans} />
                        <Route path='/dashboard/settings' component={Settings} />
                        <Route path='/dashboard/new-user' component={NewUser} />
                        <Route path='/dashboard/calendar' component={ReviewCalendar} />

                        <Route exact path='/dashboard/wallet' component={ListWalletEntries} />
                        <Route exact path='/dashboard/wallet/create' component={AddWalletEntry} />
                        <Route exact path='/dashboard/wallet/edit/:id/' component={EditWalletEntry} />
                      </Switch>
                    </Panel>
                  </div>
                </div>
            </Flex>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state) => {
  return {
    user: state.user,
    total_clients: state.global.total_clients,
    total_loans: state.global.total_loans,
    total_notifications: state.global.notifications.length,
    total_users: state.global.total_users,
    total_templates: state.global.total_templates,
    new_notifications: state.global.new_notifications,
  }
}

const mapDispatch = (dispatch) => {
  return {
    Init: () => {
      // this action initializes all
      dispatch(GlobalActions.Init())
    },
  }
}


export default connect(mapState, mapDispatch)(Dashboard)
