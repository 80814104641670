import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

const validators = [validateRequired]


const options = [
    { value: 'DEPOSIT', label: 'Deposit' },
    { value: 'WITHDRAW', label: 'Withdraw' },
    { value: 'SALARY', label: 'Salary' },
    { value: 'HMRC', label: 'HMRC' },
]

export default compose(
    withProps(() => ({
        name: 'type',
        wrapperConfig: { width: '100%' },
        component: DropdownField,
        placeholder: 'Select Type',
        label: "Transaction Type",
        isClearable: true,
        full: true,
        options,
        validators,
    })),
)(Field)
