import React from 'react'

import Panel from 'components/Forms/Panel'
import Row from 'components/Row'

import * as RedemptionStatementFields from 'components/FormFields/Loan/RedemptionStatement'

const RedemptionStatementForm = () => {
    return(
        <Panel title="Statement Details">
            <Row>
                <RedemptionStatementFields.RedemptionRateField mr={10} />
                <RedemptionStatementFields.RedemptionDateField />
            </Row>
        </Panel>
    )
}

export default RedemptionStatementForm