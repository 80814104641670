import API from 'api'

let ClientActions = {

  Create: (data) => {
    return (dispatch) => {

      dispatch({
        type: "CREATE_CLIENT_BEGIN",
        data: data,
      });

      API.post("/api/clients/create", { body: JSON.stringify(data) })
        .then( res => { return res.json(); })
        .then( json => {
          if( json.success ) {
            dispatch({
              type: "CREATE_CLIENT_END",
              client: json.client,
            });
          }
        })
    }
  },

  LoadAll: (cb) => {
    return (dispatch) => {

      dispatch({
        type: "LOAD_CLIENTS_BEGIN",
      });

      API.get("/api/clients/")
        .then( res => { return res.json(); })
        .then( json => {
          if( json.success ) {
            dispatch({
              type: "LOAD_CLIENTS_END",
              clients: json.clients,
            });
            if(cb)  
              cb()
          }
        })
    }
  },
}

export default ClientActions;
