import { COLORS } from 'consts'

export const getColor = (props, defaultColor = COLORS.blue) =>
  (props.gray && COLORS.gray) ||
  (props.white && COLORS.white) ||
  (props.darkGray && COLORS.darkGray) ||
  (props.blue && COLORS.blue) ||
  (props.darkBlue && COLORS.darkBlue) ||
  (props.yellow && COLORS.yellow) ||
  (props.green && COLORS.green) ||
  (props.red && COLORS.red) ||
  (props.darkRed && COLORS.darkRed) ||
  (props.darkRed2 && COLORS.darkRed2) ||
  (props.lightGray && COLORS.lightGray) ||
  (props.lightGray2 && COLORS.lightGray2) ||
  (props.black && COLORS.black) ||
  (props.lightRed && COLORS.lightRed) ||
  props.color ||
  defaultColor
