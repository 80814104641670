import React from 'react'
import moment from 'moment'
import { compose } from 'recompose'

import { DEFAULT_DATE_FORMAT } from 'consts'

import { priceFormatter } from 'helpers/Helpers'

import { SecondaryButton } from 'components/Buttons'

const RepaymentListItem = ({
    _id,
    periodStart,
    periodEnd,
    amount,
    compounded,
    fullyPaid,
    isDue,
    interin,
    handleClick,
    ...props
}) => {
    
    return(
        <div className={`term-info ${isDue ? 'due' : ''}`}>
            <div className='row'>
                <div className='col-sm-3'>{moment(periodStart).format(DEFAULT_DATE_FORMAT)}</div>
                <div className='col-sm-3'>{moment(periodEnd).format(DEFAULT_DATE_FORMAT)}</div>
                <div className='col-sm-3'>{`£${priceFormatter(parseFloat(amount).toFixed(2))}`}</div>
                <div className='col-sm-3'>
                    {!fullyPaid && !interin && !compounded && <SecondaryButton onClick={() => {handleClick(_id, amount)}}>REPAYMENT</SecondaryButton>}
                    {compounded && !interin && <span className='compounded'>Compounded</span>}
                    {!compounded && fullyPaid && <span className='fully-paid'>Interest serviced</span>}
                    {interin && <span className='interin'>Interest not serviced</span>}
                </div>
            </div>
        </div>
    )
}

export default compose(
)(RepaymentListItem)