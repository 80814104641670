import styled from 'styled-components'

import { COLORS } from 'consts'

export default styled.div`
  border-right: 1px solid ${COLORS.primary};
  min-width: 350px;
  a {
    display: flex;
    align-items: center;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    padding: 5px 10px;
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 300;
    
    svg {
      font-size: 16px;
      margin-right: 5px;
      min-width: 20px;
    }

    &.active {
      background: ${COLORS.primary};
      color: #fff;

      svg {
        fill: #fff;
      }
    }
  }
`