import { connect } from 'react-redux'
import { compose, lifecycle, withProps } from 'recompose'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import * as actions from './actions'
import { selectTags } from './selectors'

const mapState = createStructuredSelector({
    inputTags: selectTags,
})

const mapDispatch = dispatch => bindActionCreators(actions, dispatch)

export default compose(
    connect(mapState, mapDispatch),
    withProps(({ match }) => ({
        id: match.params.id,
    })),
    lifecycle({
        componentDidMount() {
            const { getDynamicTagsWithValues, id } = this.props
            getDynamicTagsWithValues(id)
        }
    })
)