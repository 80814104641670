import React from 'react'

import { PrimaryButton } from 'components/Buttons'
import { Text } from 'components/Texts'

import PropertiesWrap from './PropertiesWrap'
import RenderAdditionalField from './RenderAdditionalField'

const RenderAdditionalFields = ({
    fields,
    pushField,
    ...props
}) => (
    <PropertiesWrap>
        <div className='properties-actions'>
            <Text>Add custom security to the loan.</Text>
            <PrimaryButton onClick={pushField}>
                Add Text
            </PrimaryButton>
        </div>
        {fields && fields.length > 0 &&
            <div className='properties-list'>
                {fields.map((additionalSecurity, index) => 
                    <RenderAdditionalField {...{ additionalSecurity, index, ...props }} key={index} />    
                )}
            </div>
        }
    </PropertiesWrap>
)

export default RenderAdditionalFields