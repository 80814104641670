import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import GlobalActions from 'actions/GlobalActions'
import TemplateActions from 'actions/TemplateActions'

import ListTemplates from 'pages/templates/ListTemplates'
import EditTemplate from 'pages/templates/EditTemplate'
import AddTemplate from 'pages/templates/AddTemplate'
import ListTags from 'pages/tags/ListTags'
import AddTag from 'pages/tags/AddTag'
import EditTag from 'pages/tags/EditTag'
import ListProfessionals from 'pages/professionals/ListProfessionals'
import AddProfessional from 'pages/professionals/AddProfessional'
import EditProfessional from 'pages/professionals/EditProfessional'

import AddCheck from 'pages/checks/AddCheck'
import ListChecks from 'pages/checks/ListChecks'

import Users from 'pages/dashboard/Users'

import GlobalSettings from './views/GlobalSettings'

class Settings extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      first_level_checks: null,
      second_level_checks: null,
      third_level_checks: null,
      redemption_template: null,
    }
  }

  componentDidMount() {
    this.props.LoadUsers();
    this.props.LoadTemplates();
  }

  handleLoanSettingsUpdate() {
    var data = {
      first_level_checks: this.state.first_level_checks,
      second_level_checks: this.state.second_level_checks,
      third_level_checks: this.state.third_level_checks,
      redemption_template: this.state.redemption_template,
    }

    this.props.UpdateLoanSettings(data);
  }

  render() {
    let settings = this.props.settings;

    var options = [];
    var templateOptions = [];
    
    this.props.users.forEach(user => {
      var option = {
        label: user.first_name + " " + user.last_name,
        value: user._id,
      };
      options.push(option);
    })
    this.props.templates.forEach(template => {
      templateOptions.push({
        label: template.name,
        value: template._id
      })
    });

    return(
      <div className='page'>
        <Switch>
          <Route exact path='/dashboard/settings/checklist' component={ListChecks} />
          <Route exact path='/dashboard/settings/checklist/create' component={AddCheck} />
          <Route exact path='/dashboard/settings/checklist' component={ListTags} />
          <Route exact path='/dashboard/settings/tags' component={ListTags} />
          <Route exact path='/dashboard/settings/tags/create' component={AddTag} />
          <Route exact path='/dashboard/settings/tags/edit/:id/' component={EditTag} />
          <Route exact path='/dashboard/settings/templates' component={ListTemplates} />
          <Route exact path='/dashboard/settings/templates/create' component={AddTemplate} />
          <Route exact path='/dashboard/settings/templates/edit/:id' component={EditTemplate} />
          <Route exact path='/dashboard/settings/professionals' component={ListProfessionals} />
          <Route exact path='/dashboard/settings/professionals/create' component={AddProfessional} />
          <Route exact path='/dashboard/settings/professionals/edit/:id' component={EditProfessional} />
          <Route path='/dashboard/settings/globals' component={GlobalSettings} />
          <Route path='/dashboard/settings' component={Users} />
        </Switch>
        {/* <GlobalTagsSettings />*/}
      </div>
    )
  }
}

const mapState = state => {
  return {
    users: state.global.users,
    templates: state.global.templates,
    fetching: state.global.fetching,
    loan_settings_update_success: state.global.loan_settings_update_success,
    settings: state.global.settings,
    fetching_settings: state.global.fetching_settings,
  }
}

const mapDispatch = dispatch => {
  return {
    LoadUsers: () => {
      dispatch(GlobalActions.LoadAllUsers());
    },
    LoadTemplates: () => {
      dispatch(TemplateActions.LoadAll())
    },
    UpdateLoanSettings: (data) => {
      dispatch(GlobalActions.UpdateLoanSettings(data))
    }
  }
}

export default connect(mapState, mapDispatch)(Settings);
