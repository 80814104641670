import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import { SwitchField } from 'components/FormFields'

export default compose(
    withProps(() => ({
        wrapperConfig: { width: '100%' },
        showDetails: (checked) => { return false },
        label: "Global Scope",
        name: 'globalScope',
        justLabel: true,
    })),
)(SwitchField)
