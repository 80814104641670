import React from 'react'
import { compose } from 'recompose'

import Page from 'components/Page'

import ListLoanReviewsContainer from 'containers/Loan/Reviews/ListReviews'
import ReviewLoanListItem from 'components/ListItems/ReviewLoanListItem'

const ReviewCalendar = ({
  reviews
}) => {
  return(
      <Page
        title="Review Calendar">
        <div className='listing'>
          <div className='thead'>
            <div className='row'>
              <div className='col-sm-2'>Review Date</div>
              <div className='col-sm-3'>Client Name</div>
              <div className='col-sm-2'>Gross Amount</div>
              <div className='col-sm-3'>Managed By</div>
            </div>
          </div>
          <div className='items'>
            {reviews && reviews.map((review, index) => 
              <ReviewLoanListItem {...{ ...review }} key={index} /> 
            )}
          </div>
        </div>
      </Page>
  )
}

export default compose(
  ListLoanReviewsContainer,
)(ReviewCalendar)