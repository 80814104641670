import React from 'react';
import { compose } from 'recompose'

import { Page } from 'pages'
import Row from 'components/Row'

import * as CheckFields from 'components/FormFields/LoanCheck'
import { PrimaryButton } from 'components/Buttons'

import AddCheckContainer from 'containers/Checks/AddCheck'


const AddCheck = ({
    handleSubmit,
    ...props
}) => (
    <Page title="New Loan Check">
        <Row>
          <CheckFields.NameField />
        </Row>
        <Row>
          <CheckFields.DescriptionField />
        </Row>
        <Row style={{justifyContent: "flex-end"}}>
          <PrimaryButton darkGray to='/dashboard/settings/checklist' style={{marginRight: 10}}>Back</PrimaryButton>
          <PrimaryButton onClick={handleSubmit}>Create</PrimaryButton>
        </Row>
    </Page>
)

export default compose(
  AddCheckContainer,
)(AddCheck);
