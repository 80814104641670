import styled from 'styled-components'

export default styled.div`
    display: block;
    width: 100%;

    .page-title {
        margin: 0px!important;
        h2 {
            font-size: 21px;
            line-height: 16px;
            color: #5B5B5B;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }
`