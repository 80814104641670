import { compose, withProps } from 'recompose'

import { withFormValuesOnChange } from 'components/HOC'
import { PriceField } from 'components/FormFields'
import { ExtensionFormName } from 'containers/Extensions/AddExtension/consts'

export default compose(
    withProps(() => ({
        name: 'newGrossAmount',
        label: "New Gross Loan Amount",
        prependNumber: "£",
        readOnly: true,
    })),
    withFormValuesOnChange(
        ExtensionFormName, 
        [
            'grossAmount', 
            'totalFeeAmount', 
            'totalCompoundedInterestAmount',
            'includedRepaymentsAmount',
            'solicitorFee',
            'lateInterest',
        ],
        ({ grossAmount, totalFeeAmount, includedRepaymentsAmount, totalCompoundedInterestAmount, solicitorFee, lateInterest }) => {
            return parseFloat(grossAmount) + 
                parseFloat(includedRepaymentsAmount) +
                parseFloat(totalFeeAmount) + 
                parseFloat(solicitorFee) + 
                parseFloat(totalCompoundedInterestAmount) +
                parseFloat(lateInterest)
        }
    ),
)(PriceField)