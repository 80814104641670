import _trim from 'lodash/trim'
import _get from 'lodash/get'

export const isInvalid = (meta = {}) => {
  const showOnActive = _get(meta.error, 'showOnActive', false)
  return (
    !!meta.error &&
    (showOnActive || !meta.active) &&
    (meta.touched || !meta.pristine)
  )
}

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return !email || re.test(String(email).toLowerCase())
    ? undefined
    : 'validateEmail'
}

export const validateRequired = value =>
  value ? undefined : '*'

export const validateLength = (min = 0, max) => value => {
  if (value && (_trim(value).length > max || _trim(value).length < min)) {
    return max
      ? { messageKey: 'validateLength_between', messageValues: { min, max } }
      : { messageKey: 'validateLength', messageValues: { min } }
  }

  return undefined
}

export const validateSame = field => (value, values) =>
  value && value !== values.get(field) ? `validateSame_${field}` : undefined

export const validateUrl = value => {
  if (!value) return undefined
  const re = /^http[s]?:\/\/(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*(),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+$/
  return re.test(String(value).toLowerCase()) ? false : 'validateUrl'
}
