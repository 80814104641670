import { createSelector } from 'reselect'
import { formValuesSelector } from 'utils/reduxFormUtils'

import _get from 'lodash/fp/get'

import { AddLoanForm } from './consts'

export const selectLoan = createSelector(
    state => state,
    _get("loan")
)

export const selectStep = createSelector(
    selectLoan,
    _get("step")
)

export const selectFormValues = formValuesSelector(AddLoanForm)

export const selectTotalFeeAmount = createSelector(
    state => selectFormValues(state, 'arrangementFee', 'grossAmount'),
    vals => {
        const total = parseFloat(vals.arrangementFee) * parseFloat(vals.grossAmount) * 0.01
        return total
    }
)

export const selectTotalInterestAmount = createSelector(
    state => selectFormValues(state, 'arrangementFee', 'grossAmount'),
    vals => {
        const total = parseFloat(vals.arrangementFee) * parseFloat(vals.grossAmount) * 0.01
        return total
    }
)

export const selectInitialValues = createSelector(
    state => selectFormValues(state, [
        'arrangementFee', 'interestRate', 'term', 'grossAmount'
    ]),
    selectTotalFeeAmount,
    (values, totalFee) => {
        return {
            ...values,
            totalFeeAmount: `${totalFee}`,
        }
    }
)

export const selectPersonalGuarantee = createSelector(
    state => selectFormValues(state, 'personalGuarantee'),
    (vals) => {
        return vals.personalGuarantee ? vals.personalGuarantee.checked : false
    }
)