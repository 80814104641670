export const tags = [
    { 
        _id: "asdb1n2m3kas",
        label: "Loan LTV"
    },
    { 
        _id: "asdb1n2m3kasg",
        label: "Loan LTV"
    },
    { 
        _id: "asdb1n2m3kasd",
        label: "Loan LTV"
    }
]