import React from 'react';
import { compose } from 'recompose'

import { Page } from 'pages'

import EditClientContainer from 'containers/Client/EditClient'

import NewClientActionsBar from './NewClientActionsBar'
import NewClientProgress from './NewClientProgress'
import NewClientSteps from './views/NewClientSteps'

const EditClient = ({
    step,
    hideActions,
    isCreating,
    redirectUrl,
    ...props
}) => (
    <Page title="Edit Client" className='new-loan'>
        {!isCreating && <NewClientProgress />}
        <NewClientSteps step={step} {...{ isCreating, redirectUrl, ...props }} />
        {!isCreating && <NewClientActionsBar {...props} />}
    </Page>
)

export default compose(
  EditClientContainer,
)(EditClient);
