import React from 'react'
import { PropTypes } from 'prop-types'

import InputWrapper from '../InputWrapper'
import TextAreaFieldInput from './TextAreaFieldInput'

const TextAreaField = ({
  autoFocus,
  disabled,
  height = '80px',
  input,
  label,
  placeholder,
  readOnly,
  ...props
}) => (
  <InputWrapper
    hasValue={!!(input && input.value)}
    {...{ ...props, label, disabled, placeholder }}
  >
    <TextAreaFieldInput
      {...{
        ...input,
        autoFocus,
        disabled,
        placeholder,
        readOnly,
        height,
        withLabel: !!label,
      }}
    />
  </InputWrapper>
)

TextAreaField.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
}

export default TextAreaField
