import React from 'react'
import { compose } from 'recompose'

import Page from 'components/Page'

import DuePayments from './views/DuePayments'
import RecentTransactions from './views/RecentTransactions'
import Pipeline from './views/Pipeline'

const Overview = ({

}) => {
    return(
        <Page>
            <DuePayments />
            <RecentTransactions />
            <Pipeline />
        </Page>
    )
}

export default Overview