import API from 'api'

const getSignatureRequestsRequest = () => ({ type: "FETCH/REQUEST" })
const getSignatureRequestsSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getSignatureRequestsError = err => ({ type: "FETCH/ERROR", error: err})

export const getSignatureRequests = (loanId) => dispatch => {
    dispatch(getSignatureRequestsRequest())

    API
        .get(`/api/loans/signature/list?id=${loanId}`)
        .then(res => res.json())
        .then(json => { dispatch(getSignatureRequestsSuccess({ signatureRequests: json.data })) })
        .catch(err => dispatch(getSignatureRequestsError(err)))
}