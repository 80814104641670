import styled from 'styled-components'

export default styled.div`
    text-align: center;
    position: relative;

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #666;
        font-size: 13px;
    }
`