import React from 'react'

import Row from 'components/Row'
import Panel from 'components/Forms/Panel'

import { ExtensionCalculatedFields } from 'components/FormFields/Extension'

const ExtensionCalculated = () => (
  <Panel title='Calculated Fields'>
    <Row>
      <ExtensionCalculatedFields.ExtensionTotalFeeField mr={10} />
      <ExtensionCalculatedFields.ExtensionTotalInterestField mr={10} />
      <ExtensionCalculatedFields.ExtensionTotalCompoundedInterestField />
    </Row>
    <Row>
      <ExtensionCalculatedFields.ExtensionTotalCostField mr={10} />
      <ExtensionCalculatedFields.ExtensionMonthlyPaymentField />
    </Row>
    <Row>
      <ExtensionCalculatedFields.ExtensionNewGrossAmountField mr={10} />
      <ExtensionCalculatedFields.ExtensionIncludedRepaymentsAmountField />
    </Row>
  </Panel>
)

export default ExtensionCalculated