import API from 'api'

const getLoanStatusSubmit = () => ({ type: "FETCH/REQUEST" })
const getLoanStatusSuccess = (key, data) => ({ type: "FETCH/SUCCESS", key, data })
const getLoanStatusError = err => ({ type: "FETCH/ERROR", error: err})

export const getLoanStatus = (loanId, callback) => dispatch => {
    dispatch(getLoanStatusSubmit())

    API
        .get(`/api/loans/status?id=${loanId}`)
        .then(res => res.json())
        .then(json => { 
            dispatch(getLoanStatusSuccess(`loan_${loanId}`, json)) 
            if(callback)    
                callback()
        })
        .catch(err => dispatch(getLoanStatusError(err)))

}