import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

const validators = [validateRequired]

const propertyUseOptions = [
    { value: 'Residential', label: 'Residential' },
    { value: 'Commercial', label: 'Commercial' },
    { value: 'Mixed Use', label: 'Mixed Use' },
    { value: 'Land', label: 'Land' },
]

export default compose(
    withProps(() => ({
        name: 'mpUse',
        placeholder: 'Select Property Use',
        wrapperConfig: { width: '100%' },
        options: propertyUseOptions,
        component: DropdownField,
        label: "Property Use",
        isClearable: true,
        full: true,
        validators,
    })),
)(Field)
