import styled from 'styled-components'

export default styled.div`
    .loan-check-list-item {
        .row {
            align-items: flex-start;
        }    
    }

    h4 {
        margin: 0;
        font-size: 14px;
        text-transform: uppercase;
        font-style: italic;
    }

    p {
        font-size: 12px;
        margin: 0px;
        text-transform: uppercase;
    }
    
    .col-sm-4, .col-sm-8 { 
        display: inline-flex;
        align-items: center;
    }
`