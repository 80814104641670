import styled from 'styled-components'
import { COLORS } from 'consts'

import ButtonBase from './ButtonBase'

export default styled(ButtonBase)`
  height: 30px;
  padding: 0 25px;
  font-size: 12px;
  border-radius: 6px;
  color: ${COLORS.white};
  background: ${COLORS.darkGray};
  text-decoration: none;

  ${props =>
    props.reversed
      ? `
    i {
      font-size: 10px;
      margin-left: 8px;
      margin-right: -11px;
    }
  `
      : `
    i {
      font-size: 10px;
      margin-left: -11px;
      margin-right: 8px;
    }
  `};

  ${props =>
    props.active &&
    `
    opacity: 0.87;
    pointer-events: none;
  `};

  &.active {
    opacity: 0.87;
    pointer-events: none;
  }
`
