import React from 'react';
import { compose } from 'recompose'

import Page from 'components/Page'

import ListChecksContainer from 'containers/Checks/ListChecks'

import CheckListItem from 'components/ListItems/CheckListItem'
import { SecondaryButton } from 'components/Buttons'
import { Message } from 'components/Messages'

const ListChecks = ({
    checks,
    getChecks,
}) => {
  return(
    <Page 
      title="Loan Checks"
      buttons={<SecondaryButton darkGray to='/dashboard/settings/checklist/create'>CREATE</SecondaryButton>}>
      {checks && checks.length == 0 && 
        <Message text='No loan checks found.' />
      } 
      {checks && checks.length > 0 &&
        <div className='listing'>
          <div className='thead'>
            <div className='row'>
              <div className='col-sm-4'>Name</div>
              <div className='col-sm-4'>Description</div>
              <div className='col-sm-4'>Actions</div>
            </div>
          </div>
        </div>
      }
      {checks && checks.map(check => <CheckListItem {...{...check, key: check._id, onComplete: () => getChecks()}} />)}
    </Page>
  )
}

export default compose(
  ListChecksContainer,
)(ListChecks);
