import { compose, withProps } from 'recompose'

import { withFormValuesOnChange } from 'components/HOC'
import { PriceField } from 'components/FormFields'
import { AddLumpSumForm } from 'containers/Loan/AddLumpSum/consts'

export default compose(
    withProps(() => ({
        name: 'totalInterestAmount',
        label: "Total Interest Amount",
        prependNumber: "£",
        readOnly: true,
    })),
    withFormValuesOnChange(
        AddLumpSumForm, 
        ['remainingAmount', 'interestRate', 'remainingTerms'],
        ({ remainingAmount, interestRate, remainingTerms }) => {
            
            return parseFloat(remainingAmount) * 0.01 * parseFloat(interestRate) * parseInt(remainingTerms)
        }
    ),
)(PriceField)