import React from 'react'

import { API_URL } from 'api'

import { Panel } from 'components/Forms'
import Row from 'components/Row'
import LoanField from 'components/Loans/LoanField'

const ApplicantDetails = ({
    _id,
    index,
    applicant,
    arrears,
    courtJudgement,
    everBankrupt,
    defaults,
    iva,
    paydayLoans,
    declaration,
    ...props
}) => {

    return(
        <Panel title={`Applicant #${index}`} expandable initiallyExpanded>
            <Row>
                <LoanField label="Applicant Full Name" forceValue={`${applicant.firstName} ${applicant.lastName}`} />
            </Row>
            <Row alignTop>
                <LoanField label="Have you ever had any arrears?" type='boolean' forceValue={arrears.checked} />
                <LoanField label="Do you have any payday loans?" type='boolean' forceValue={paydayLoans.checked} />
            </Row>
            <Row alignTop>
                <LoanField label="Have you ever been bankrupt/repossesed?" type='boolean' forceValue={everBankrupt.checked} />
                <LoanField label="Have you ever had defaults registered against?" type='boolean' forceValue={defaults.checked} />
            </Row>
            <Row alignTop>
                <LoanField label="Have you entered into any IVA or debt management plan?" type='boolean' forceValue={iva.checked} />
                <LoanField label="Have you ever had a County Court judgement (or decrees) registered against you?" type='boolean' forceValue={courtJudgement.checked} />
            </Row>
            <h3>Declaration</h3>
            {declaration ? 
                <Row alignTop>
                    <LoanField label="Declaration Date" type='date' forceValue={declaration.date} />
                    <LoanField label="Applicant Signature" forceValue={
                        <img 
                            className='applicant-signature' 
                            src={`${API_URL}/docs/signatures/${declaration.signature}`} />
                    }/>
                </Row>
                :
                <Row>
                    <h5>Awaiting client signature.</h5>
                </Row>
            }
        </Panel>
    )
}

export default ApplicantDetails