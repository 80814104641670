import { compose, withProps } from 'recompose'


import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

import { validateRequired } from 'utils/validators'
const validators = [validateRequired]

const exitOptions = [
    { value: 'Sale', label: 'Sale' },
    { value: 'Refinance', label: 'Refinance' },
]

export default compose(
    withProps(() => ({
        name: 'loanExitRoute',
        wrapperConfig: { width: '100%' },
        component: DropdownField,
        label: "Exit Route",
        placeholder: 'Select Exit Route',
        isClearable: true,
        full: true,
        options: exitOptions,
        validators,
    })),
)(Field)
