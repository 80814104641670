import { compose, withProps, withState, lifecycle } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";
import { reduxForm, submit } from 'redux-form'

import { RequestSignatureForm } from './consts'
import * as actions from "./actions";
import { selectDocuments } from "./selectors";

const mapState = createStructuredSelector({
  documents: selectDocuments,
});

const mapDispatch = (dispatch) => ({
  ...bindActionCreators(actions, dispatch),
  submitForm: () => dispatch(submit(RequestSignatureForm)),
});

export default compose(
  connect(mapState, mapDispatch),
  withState("queue", "setQueue", []),
  withState("timeout", "setReloadTimeout", null),
  withState("uploadVisible", "setUploadVisible", false),
  withState("signatureVisible", "setSignatureVisible", false),
  withState("isCreating", "setIsCreating", false),
  withProps(
    ({
      queue,
      setQueue,
      timeout,
      setSignatureVisible,
      signatureVisible,
      setReloadTimeout,
      getDocuments,
      objectId,
      removeFile,
      uploadVisible,
      setUploadVisible,
    }) => ({
      handleUpload: (files) => {
        const mQueue = [].concat(queue, files);
        setQueue(mQueue);
      },
      handleComplete: (file) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          getDocuments(objectId);
        }, 300);
        setReloadTimeout(timeout);
        
        // setTimeout(() => {
        //   let index = queue.findIndex((f) => f.name === file.name);
        //   if (index >= 0) {
        //     queue.splice(index, 1);
        //     const files = [].concat([], queue);
        //     setQueue(files);
        //   }
        //   timeout = setTimeout(() => {
        //     getDocuments(objectId);
        //   }, 300);
        //   setReloadTimeout(timeout);
        // }, 2000)
      },
      handleRemove: (id) => {
        removeFile(id, () => {
          getDocuments(objectId);
        });
      },
      toggleUpload: () => {
        setUploadVisible(!uploadVisible);
      },
      toggleSignature: () => {
        setSignatureVisible(!signatureVisible);
      },
    })
  ),
  reduxForm({
    form: RequestSignatureForm,
    onSubmit: (values, dispatch, { createSignatureRequest, getSignatureRequests, setSignatureVisible, setIsCreating, objectId, reset }) => {
      setIsCreating(true)
      createSignatureRequest(objectId, values, () => {
        setIsCreating(false)
        setSignatureVisible(false)
        getSignatureRequests(objectId)
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      const { getDocuments, objectId } = this.props;
      getDocuments(objectId);
    },
    componentDidUpdate(prevProps) {
      const { getDocuments } = this.props
      if(this.props.objectId != prevProps.objectId) {
        getDocuments(this.props.objectId)
      }
    },
  })
);
