import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, submit } from 'redux-form'
import { compose, lifecycle, withState } from 'recompose'
import { createStructuredSelector } from 'reselect'

import { withFormValues } from 'components/HOC'

import * as actions from './actions'
import { FORM_NAME } from './consts'
import { selectTag, selectIsFetching } from './selectors'

const mapState = createStructuredSelector({
    initialValues: selectTag,
    isFetching: selectIsFetching,
})

const mapDispatch = dispatch => ({ 
    ...bindActionCreators(actions, dispatch),
    submitForm: () => dispatch(submit(FORM_NAME)),
})

export default compose(
    connect(mapState, mapDispatch),
    withState('isUpdating', 'setUpdating', false),
    reduxForm({
        form: FORM_NAME,
        onSubmit: (values, dispatch, { setUpdating, updateTag, reset, match: { params: { id }} }) => {
            setUpdating(true)
            updateTag(id, values, () => {
                setUpdating(false)
            })
        },
        enableReinitialize: true,
    }),
    withFormValues(FORM_NAME),  
    lifecycle({
        componentDidMount() {
            const { match: { params: { id } }, getTag } = this.props
            getTag(id)
        },
        componentWillUnmount() {
            const { reset } = this.props
            reset()
        }
    })
)