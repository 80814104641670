import styled from 'styled-components'

import { COLORS } from 'consts'

export default styled.a`
    color: ${COLORS.blue};
    font-weight: bold;
    font-style: italic;
    font-size: 12px;
    text-transform: uppercase;
    margin: 10px 0;
    text-align: left;
    width: 100%;

    transition: all 100ms ease-out;

    &:hover {
        color: ${COLORS.darkGray};
    }
`