import React from 'react'
import Select from 'react-select'
import { PropTypes } from 'prop-types'

import Flex from 'components/Flex'

import { getDefaultStyles } from './config'

const SelectField = ({ input, wrapperConfig, options, ...props }) => (
  <Flex {...wrapperConfig} flexDirection="column">
    <Select
      styles={{
        ...getDefaultStyles(),
      }}
      onFocus={input && input.onFocus}
      onChange={option =>
        input &&
        (!!option && !!option.value
          ? input.onBlur(option.value)
          : input.onBlur(null))
      }
      onBlur={() => input && input.onBlur(input.value)}
      value={
        options ? options.find(option => option.value === input.value) : []
      }
      {...{ options, ...props }}
    />
  </Flex>
)

SelectField.propTypes = {
  wrapperConfig: PropTypes.object,
  input: PropTypes.object,
  placeholder: PropTypes.string,
}

export default SelectField
