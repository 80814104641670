import API from 'api'

const activateLoanSubmit = () => ({ type: "FETCH/REQUEST" })
const activateLoanSuccess = (key, data) => ({ type: "FETCH/SUCCESS", key, data })
const activateLoanError = err => ({ type: "FETCH/ERROR", error: err})

export const activateLoan = (loanId, callback) => dispatch => {
    dispatch(activateLoanSubmit())

    API
        .post(`/api/loans/activate?id=${loanId}`)
        .then(res => res.json())
        .then(json => { 
            dispatch(activateLoanSuccess(json)) 
            if(callback)    
                callback()
        })
        .catch(err => dispatch(activateLoanError(err)))

}