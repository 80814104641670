import React from 'react'
import styled from 'styled-components'

const IconWrapper = styled.span`
    color: #666;
    font-size: 20px!important;
    font-family: 'Material Icons' !important;
`

const Icon = ({
    children
}) => {
    return(
        <IconWrapper className='material-icons'>
            {children}
        </IconWrapper>
    )
}

export default Icon