import { compose, withProps } from 'recompose'

import { PriceField } from 'components/FormFields'

import { validateRequired } from 'utils/validators'
const validators = [validateRequired]

export default compose(
    withProps(({}) => ({
        name: 'mpPrice',
        label: "Price",
        validators,
    }))
)(PriceField)