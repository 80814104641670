import { compose, withProps } from 'recompose'

import { withFormValuesOnChange } from 'components/HOC'
import { PriceField } from 'components/FormFields'
import { AddLoanForm } from 'containers/Loan/AddLoan/consts'

export default compose(
    withProps(() => ({
        name: 'netAmount',
        label: "Net Loan Amount",
        prependNumber: "£",
        readOnly: true,
    })),
    withFormValuesOnChange(
        AddLoanForm, 
        [
            'grossAmount', 
            'totalFeeAmount', 
            'totalInterestAmount',
            'solicitorFee',
        ],
        ({ grossAmount, totalFeeAmount, totalInterestAmount, solicitorFee }) => {
            return grossAmount - totalFeeAmount - solicitorFee - totalInterestAmount
        }
    ),
)(PriceField)