import React from 'react'
import { compose } from 'recompose'

import ListPipelineContainer from 'containers/Loan/ListPipeline'
import ListChecksContainer from 'containers/Checks/ListChecks'


import LoanListItem from 'components/ListItems/LoanListItem'

const Pipeline = ({
    isFetching,
    pipeline,
    checks,
    ...props
}) => {
    return(
        <div className='due-payments'>
            <div className='info'>
                <h2>Pipeline</h2>
            </div>

            <div className='page-content listing'>
                <div className='thead'>
                    <div className='row'>
                        <div className='col'>Client</div>
                        <div className='col'>Status</div>
                        <div className='col'>Amount</div>
                        <div className='col'>Start Date</div>
                        <div className='col'>Level 1</div>
                        <div className='col'>Level 2</div>
                        <div className='col'>Level 3</div>
                        <div className='col'>Docs</div>
                        <div className='col'></div>
                    </div>
                </div>
                {pipeline && pipeline.map(loan =>
                    <LoanListItem {...{
                        key: loan._id,
                        ...loan,
                    }} />
                )}
            </div>
        </div>
    )
}

export default compose(
    ListPipelineContainer,
    ListChecksContainer,
)(Pipeline)