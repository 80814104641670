import { connect } from 'react-redux'
import { bindActionCreators } from 'redux' 
import { createStructuredSelector } from 'reselect'
import { compose, lifecycle, withState } from 'recompose'

import { selectLoanStatus } from './selectors'
import * as actions from './actions'

const mapState = createStructuredSelector({
    loanStatus: selectLoanStatus
})

const mapDispatch = dispatch => ({
    ...bindActionCreators(actions, dispatch),
})

export default compose(
    connect(mapState, mapDispatch),
    withState("isCheckingStatus", 'setCheckingStatus', true),
    lifecycle({
        componentWillMount() {
            const { getLoanStatus, loanId, setCheckingStatus } = this.props
            setCheckingStatus(true)
            getLoanStatus(loanId, () => {
                setCheckingStatus(false)
            })
        }
    })
)