import API from 'api'

const removeTagRequest = () => ({ type: "FETCH/REQUEST" })
const removeTagSuccess = data => ({ type: "FETCH/SUCCESS", data })
const removeTagError = err => ({ type: "FETCH/ERROR", error: err})

export const removeTag = (id, callback) => dispatch => {
    dispatch(removeTagRequest())

    API
        .get(`/api/tags/delete?id=${id}`)
        .then(res => res.json())
        .then(json => { 
            dispatch(removeTagSuccess(json))
            if(callback)
                callback()
        })
        .catch(err => {  dispatch(removeTagError(err)) })
}