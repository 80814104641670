import React from 'react';
import RegisterMod from '../../components/auth/RegisterMod';

class NewUser extends React.Component {
    render() {
        return(
            <div className='new-loan new-user page'>
                <div className='new-loan-heading'>
                    <h2>CREATE NEW USER</h2>
                </div>

                <div className='new-user-form'>
                    <RegisterMod />
                </div>
            </div>
        )
    }
}

export default NewUser;