import styled from 'styled-components'

import ButtonBase from 'components/Buttons/ButtonBase'
import { Content } from 'components/Texts'

import { COLORS, DIMENSIONS } from 'consts'
// import { lighten } from 'polished'

const Tab = styled(ButtonBase)`
  text-decoration: none;
  color: ${COLORS.gray};
  font-size: 14px;
  text-align: center;
  min-width: 100px;
  z-index: 2;
  display: flex;
  align-items: center;
  height: ${DIMENSIONS.moduleMenuHeight}px;
  box-shadow: none!important;


  &.active {
    border-bottom: 1px solid ${COLORS.primary}!important;
    color: ${COLORS.primary};

    svg > * {
       fill ${COLORS.primary}!important;
    }
  }

  * {
    text-align: center;
    display: block;
  }

  &:hover {
    border-bottom: 1px solid ${COLORS.lightGrayWithOpacity};
  }

  ${props =>
    props.vertical &&
    `
      border: none !important;
      padding: 0 !important;
      height: 40px;
      justify-content: flex-start;
  `};
`

Tab.defaultProps = {
  color: COLORS.blue,
}

export default Tab
