import React from 'react'
import { compose } from 'recompose'

import Row from 'components/Row'
import Page from 'components/Page'

import { PrimaryButton } from 'components/Buttons' 
import * as WalletEntryFields from 'components/FormFields/Wallet'
import { Message } from 'components/Messages'

import EditWalletEntryContainer from 'containers/Wallet/EditEntry'

const loanSpecificTypes = [
    "LOAN_REPAYMENT", 
    "LOAN_FUNDS_RELEASE",
    "LOAN_REPAYMENT",
    "LOAN_REDEMPTION",
    "LOAN_NET_ADVANCE",
    "LOAN_LUMP_SUM_PAYMENT",
]

const EditWalletEntry = ({
    submitForm,
    isUpdating,
    formValues,
    submitSucceeded,
    ...props
}) => {
    const buttonText = isUpdating ? "PLEASE WAIT..." : "UPDATE"
    const typeDisabled = formValues ? loanSpecificTypes.indexOf(formValues.type) >= 0 : false

    return(
        <Page title="Edit Wallet Entry">
            {submitSucceeded && !isUpdating && 
                <Message success text="Wallet entry updated successfully." />
            } 
            <Row>
                <WalletEntryFields.WalletTransactionAmountField />
            </Row>
            <Row>
                {typeDisabled ?
                    <Message text="Type field is disabled because it has loan specific value" />
                    :
                    <WalletEntryFields.WalletTransactionTypeField />
                }
            </Row>
            <Row>
                <WalletEntryFields.WalletTransactionNotesField />
            </Row>
            <Row style={{ justifyContent: "flex-end" }}>
                <PrimaryButton onClick={submitForm} disabled={isUpdating}>{buttonText}</PrimaryButton>
            </Row>
        </Page>
    )
}

export default compose(
    EditWalletEntryContainer,
)(EditWalletEntry)