import Plain from 'slate-plain-serializer'
import _trim from 'lodash/trim'
import { Value } from 'slate'
import { fromJS } from 'immutable'

export const getFormValue = value => fromJS(value.toJSON())
export const getSlateValue = value => Value.fromJSON(value)

export const getSlateContentLength = (value, trim = true) =>
  trim
    ? _trim(Plain.serialize(getSlateValue(value))).length
    : Plain.serialize(getSlateValue(value)).length
