import API from 'api'

const createLumpSumSubmit = () => ({ type: "FETCH/REQUEST" })
const createLumpSumSuccess = data => ({ type: "FETCH/SUCCESS", data })
const createLumpSumError = err => ({ type: "FETCH/ERROR", error: err})

export const createLumpSum = (formValues, callback) => dispatch => {
    dispatch(createLumpSumSubmit())

    API
        .post('/api/loans/lumpsum', { body: JSON.stringify(formValues) })
        .then(res => res.json())
        .then(json => { 
            dispatch(createLumpSumSuccess(json.loan)) 
            if(callback) callback(json.loan)
        })
        .catch(err => dispatch(createLumpSumError(err)))

}