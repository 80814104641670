import React from 'react';
import { Redirect } from 'react-router-dom'

import Spinner from 'components/HOC/withSpinner/Spinner'

import { FormStep } from 'components/Forms'

const Confirmation = ({ isCreating, redirectUrl }) => (
    <FormStep title="Creating Loan">
        {isCreating ? 
            <div style={{ textAlign: 'center' }}>
                <h3>Creating loan, please wait...</h3>
                <Spinner />
            </div>
            :
            <Redirect to={redirectUrl} />
        }
    </FormStep>
)

export default Confirmation