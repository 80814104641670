import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect';
import { compose, lifecycle, withProps } from 'recompose'

import * as ClientActions from './actions'
import { selectClients } from './selectors'

const mapState = createStructuredSelector({
    clientsData: selectClients,
})

const mapDispatch = dispatch => ({
    ...bindActionCreators(ClientActions, dispatch)
})

export default compose(
    connect(mapState, mapDispatch),
    lifecycle({
        componentDidMount() {
            const { getClients } = this.props
            getClients()
        }
    }),
)
