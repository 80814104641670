import { compose, withProps } from 'recompose'

import { withFormValues } from 'components/HOC'
import { LoanTermDataField } from 'components/Fields'
import { Field } from 'components/FormFields'

import { AddLoanForm } from 'containers/Loan/AddLoan/consts'

import { validateRequired } from 'utils/validators'

const validators = [validateRequired]

export default compose(
    withFormValues(AddLoanForm),
    withProps(({ formValues }) => ({
        validators,
        name: 'term',
        label: "Term",
        component: LoanTermDataField,
        startDate: formValues && formValues.startDate ? formValues.startDate : null,
        monthlyPaymentAmount: formValues && formValues.monthlyPaymentAmount ?
            formValues.monthlyPaymentAmount : 0,
        numberParser: parseInt,
        numberAppend: "month(s)",
        decimalPlaces: 0,
    }))
)(Field)