import styled from 'styled-components'

import txtIcon from 'images/mime/txt.svg'
import pngIcon from 'images/mime/png.svg'
import jpgIcon from 'images/mime/jpg.svg'
import pdfIcon from 'images/mime/pdf.svg'
import csvIcon from 'images/mime/csv.svg'
import xmlIcon from 'images/mime/xml.svg'
import zipIcon from 'images/mime/zip.svg'
import svgIcon from 'images/mime/svg.svg'

export default styled.div`
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-right: 5px;
    background-size: contain!important;
    background-position: center!important;
    background-repeat: no-repeat!important;

    ${props => ['txt', 'docx', 'doc'].includes(props.extension) && `
        background: url(${txtIcon});
    `}

    ${props => props.extension == 'svg' && `
        background: url(${svgIcon});
    `}

    ${props => props.extension == 'png' && `
        background: url(${pngIcon});
    `}

    ${props => props.extension == 'jpg' && `
        background: url(${jpgIcon});
    `}
    
    ${props => props.extension == 'pdf' && `
        background: url(${pdfIcon});
    `}

    ${props => props.extension == 'csv' && `
        background: url(${csvIcon});
    `}

    ${props => props.extension == 'xml' && `
        background: url(${xmlIcon});
    `}

    ${props => props.extension == 'zip' && `
        background: url(${zipIcon});
    `}
`