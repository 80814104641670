import React from 'react';
import { NavLink, Link, Redirect } from 'react-router-dom';
import Cookies from "js-cookie";

import API from 'api'

export default class LoginMod extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      error: false,
      message: "",
      fetching: false,
      remember_me: typeof Cookies.get("c_email") != 'undefined',
      verify_device: false,
      to_dashboard: false,
    };
  }

  initCookie() {
    var _email = Cookies.get("c_email");
    if( _email ) {
      this.setState({
        email: _email
      })
    }
  }

  componentDidMount() {
    this.initCookie();
  }

  handleSubmit() {
    var data = {
      email: this.state.email,
      password: this.state.password,
      auth_code: null,
    }

    if( this.state.verify_device ) {
      // get the code
      var auth_code = this.refs['c1'].value + 
                      this.refs['c2'].value + 
                      this.refs['c3'].value +
                      this.refs['c4'].value + 
                      this.refs['c5'].value + 
                      this.refs['c6'].value;
      if( auth_code.trim().length != 6 )
        return;
      
      data.auth_code = auth_code;
    }

    this.setState({
      fetching: true,
    });

    API.post("/api/auth/login", { body: JSON.stringify(data) })
      .then(res => { return res.json() })
      .then(json => {
        if( json.error ) {
          this.setState({
            error: true,
            verify_device: json.hasOwnProperty("verify_device") ? json.verify_device : false,
            message: json.message,
          }, () => {
            setTimeout(() => {
              this.setState({
                fetching: false,
              })
            }, 100);
          })
        } else if( json.success ) {
          window.location.href = '/dashboard/due-payments';
        }
      })
      .catch(ex => {
        
      })
  }

  handleRememberMe() {
    var checked = this.refs.remember.checked;
    if( checked ) {
      if( this.state.email.trim().length > 0 ) {
        Cookies.set("c_email", this.state.email);
      }
    } else {
      Cookies.remove("c_email");
    }

    this.setState({
      remember_me: checked,
    })
  }

  handleEnter(e) {
    if( e.keyCode == 13 ) {
      this.handleSubmit();
    }
  }

  onKeyUp(e, currentInput, prevInput, nextInput) {

    if( e.keyCode == 13 ) {
      this.handleSubmit();
    }

    if( e.keyCode == 8 ) {
      // backspace 
      if( prevInput != null ) {
        setTimeout(() => {
          this.refs[prevInput].focus();
        }, 50);
      }  
    } else {
      if( nextInput != null ) {
        setTimeout(() => {
          this.refs[nextInput].focus();
        }, 50);
      }
    }
  }

  renderVerifyDevice() {
    return(
      <div className='mod mod-login'>
        <h2><span className='padlock-icon'></span> VERIFY DEVICE</h2>
        <p>We see that you're logging in from a new browser or a new computer. Or maybe it's just been a while.  Please add the verification number below, which has been sent to your email.  Any problems, please contact the administrator.</p>
        <div className='form-group auth-code'>
          <span className='auth-code-input c1'><input ref='c1' onKeyDown={e => { this.onKeyUp(e, 'c1', null, 'c2')}} maxLength='1' type='text' /></span>
          <span className='auth-code-input c2'><input ref='c2' onKeyDown={e => { this.onKeyUp(e, 'c2', 'c1', 'c3')}} maxLength='1' type='text' /></span>
          <span className='auth-code-input c3'><input ref='c3' onKeyDown={e => { this.onKeyUp(e, 'c3', 'c2', 'c4')}} maxLength='1' type='text' /></span>
          <span className='auth-code-input c4'><input ref='c4' onKeyDown={e => { this.onKeyUp(e, 'c4', 'c3', 'c5')}} maxLength='1' type='text' /></span>
          <span className='auth-code-input c5'><input ref='c5' onKeyDown={e => { this.onKeyUp(e, 'c5', 'c4', 'c6')}} maxLength='1' type='text' /></span>
          <span className='auth-code-input c6'><input ref='c6' onKeyDown={e => { this.onKeyUp(e, 'c6', 'c5', null )}} maxLength='1' type='text' /></span>
        </div>
        <div className='form-group login-actions auth-code-actions'>
          <a className='btn btn-default btn-back' onClick={e => this.setState({ verify_device: false })}>Back</a>
          <a className='btn btn-grey btn-login' onClick={this.handleSubmit.bind(this)}>
            {this.state.fetching &&
              <span className='spinner'></span>
            }
            Sign in
          </a>
        </div>
      </div>
    )
  }

  render() {

    if( this.state.to_dashboard ) {
      return <Redirect to='/dashboard/due-payments' />
    }

    if( this.state.verify_device ) {
      return this.renderVerifyDevice()
    }

    return(
      <div className='mod mod-login'>
        <h2>Sign In</h2>
        {this.state.error &&
          <div className='error'>
            <p>{this.state.message}</p>
          </div>
        }
        <form autoComplete='off'>
          <div className='form-group'>
            <label className='input-label'>Email Address</label>
            <input type='text' onKeyUp={this.handleEnter.bind(this)} autoComplete="nope" onChange={ e => { this.setState({ email: e.target.value })} } value={this.state.email} placeholder='Email address' />
          </div>
          <div className='form-group'>
            <label className='input-label'>Password</label>
            <input type='password' onKeyUp={this.handleEnter.bind(this)} onChange={ e => { this.setState({ password: e.target.value })} } value={this.state.password} placeholder='Password' />
          </div>
          <div className='form-group login-account-actions'>
            <div className='checkbox'>
              <input ref='remember' checked={this.state.remember_me} name='remember_me' onChange={this.handleRememberMe.bind(this)} id='remember_me' type='checkbox' />
              <label htmlFor='remember_me' >Stay signed in</label>
            </div>
            <div className='forgot-password'>
              <Link className='link' to='/password-recovery'>Forgot Password?</Link>
            </div>
          </div>
          <div className='form-group login-actions'>
            <a className='btn btn-grey btn-login' onClick={this.handleSubmit.bind(this)}>
              {this.state.fetching &&
                <span className='spinner'></span>
              }
              Sign in
            </a>
          </div>
        </form>
      </div>
    )
  }
}
