import styled from 'styled-components'

import { COLORS } from 'consts'

export default styled.div`
    font-size: 11px;
    text-transform: uppercase;
    padding: 5px 10px;
    border-radius: 3px;
    box-shadow: 0 1px 2px grey;
    font-weight: 400;
    width: 100%;
    text-align: center;
    color: #fff;
    text-shadow: 0 1px 6px #545454;

    &.Checking {
        background: ${COLORS.orange};
        color: #fff;
    }

    &.Active {
        background: ${COLORS.green};
    }

    &.Closed {
        background: ${COLORS.blue};
        color: #fff;
    }

    &.Ready {
        background: ${COLORS.yellow};
    }

    &.gray {
        background: ${COLORS.gray};
    }
`