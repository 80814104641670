import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Link} from 'react-router-dom';

import CultchaUtilHeader from '../globals/CultchaUtilHeader';
import Logo from '../globals/Logo'
import MainMenu from '../globals/MainMenu';

class DashboardHeader extends React.Component {

  render() {
    var user = this.props.user.user;
    var date = moment().format('Do MMMM YYYY');
    var time = moment().format('HH:mm');

    return null 
    
    return(
      <div className='dashboard-header'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-2'></div>
            <div className='col-md-7'>
              <div className='info-wrap'>
                <div className='welcome-text'>
                  Welcome <strong>{user.first_name}</strong>!
                </div>
                <div className='welcome-date'>
                  <strong>Today</strong> {date} - {time}
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='info-wrap'>
                <div></div>
                <Link to='/' className='logout'>Logout</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = state => {
  return {
    user: state.user
  }
}

export default connect(mapState)(DashboardHeader);
