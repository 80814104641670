import styled from 'styled-components'

export default styled.div`
    display: block;
    border: 1px solid #DDD;
    width: 100%;
    margin: 2px;
    border-radius: 4px;
    
    label {
        font-size: 12px;
        font-weight: 400;
        display: block;   
        padding: 5px 10px;
        background: #eee;
        text-transform: uppercase;
    }

    > div {
        font-size: 15px;
        padding: 10px;
        font-weight: 400;

        .append {
            margin-left: 3px;
        }

        .prepend {

        }
    }

    .applicant-signature {
        max-width: 100%;

    }
`