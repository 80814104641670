import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'

const validators = [validateRequired]

export default compose(
    withProps(({ applicant, ...props }) => ({
        name: `personalDetails.timeAtCurrentAddress`,
        wrapperConfig: { width: '100%' },
        label: "Time at Current Address",
    })),
)(Field)
