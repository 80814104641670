import React from 'react'
import { PropTypes } from 'prop-types'
import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import Flex from 'components/Flex'

import { getPath } from 'utils/router'

import { BOX_SHADOWS, FONTS } from 'consts'

const ButtonBase = ({
  active,
  activeText,
  children,
  className,
  exact,
  href,
  icon,
  isActive,
  keepQuery,
  onClick,
  params,
  query,
  reversed,
  target,
  text,
  to,
  type,
  style,
}) => {
  const additionalProps = {}
  let BaseComponent = to ? NavLink : 'button'
  let onClickNoPropagation

  if (href) {
    BaseComponent = 'a'
  }

  if (to) {
    additionalProps.to = getPath(to, params, query, keepQuery)
    additionalProps.isActive = isActive
  }

  if (onClick) {
    onClickNoPropagation = ev => {
      ev.stopPropagation()
      return onClick()
    }
  }

  return (
    <BaseComponent
      {...{
        className,
        exact,
        href,
        reversed,
        target,
        to,
        type,
        style,
        ...additionalProps,
      }}
      onClick={onClickNoPropagation}
    >
      {icon && <i className={icon} />}
      <Flex flexDirection="column">
        <span>{children || (active && activeText) || text}</span>
        {activeText && (
          <div className="ButtonBase__widthBase">
            {active ? text : activeText}
          </div>
        )}
      </Flex>
    </BaseComponent>
  )
}

ButtonBase.propTypes = {
  active: PropTypes.bool,
  activeText: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
  exact: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.string,
  imageUrl: PropTypes.string,
  imageSize: PropTypes.string,
  isActive: PropTypes.func,
  keepQuery: PropTypes.bool,
  onClick: PropTypes.func,
  query: PropTypes.object,
  params: PropTypes.object,
  reversed: PropTypes.bool,
  target: PropTypes.string,
  text: PropTypes.any,
  to: PropTypes.string,
  type: PropTypes.string,
}

export const ButtonBaseStyle = css`
  font-family: ${FONTS.primary};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;

  ${props =>
    props.full &&
    `
    width: 100%;
  `};

  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    cursor: default !important;
  `};

  :hover {
    box-shadow: ${BOX_SHADOWS.button};
  }

  ${props =>
    props.rotateIcon &&
    `
      &.active {
        i, img {
          transform: rotate(-180deg);
        }     
      }
    `};

  ${props =>
    props.active &&
    props.rotateIcon &&
    `
    i, img {
      transform: rotate(-180deg);
    }
  `};

  i,
  img {
    margin-right: 4px;
    ::before {
      font-size: ${props => props.iconFontSize || '14px'};
    }
  }

  ${props =>
    props.reversed &&
    `
    flex-direction: row-reverse;
    i, img {
      margin-right: 0;
      margin-left: 4px;
      text-align: center;
    }
  `};

  .ButtonBase__widthBase {
    opacity: 0;
    visibility: hidden;
    height: 0;
    font-weight: 500;
  }

  ${props =>
    props.spaceBetween &&
    `
    justify-content: space-between;
    `};

  ${props =>
    props.uppercase &&
    `
      text-transform: uppercase;
    `};
`
export default styled(ButtonBase)`
  ${ButtonBaseStyle};
`
