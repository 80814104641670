import API from 'api'

const getLoanChecksSubmit = () => ({ type: "FETCH/REQUEST" })
const getLoanChecksSuccess = (data, key) => ({ type: "FETCH/SUCCESS", data, key })
const getLoanChecksError = err => ({ type: "FETCH/ERROR", error: err})

export const getLoanChecks = id => dispatch => {
    dispatch(getLoanChecksSubmit())

    API
        .get(`/api/checks/loan?id=${id}`)
        .then(res => res.json())
        .then(json => { 
            dispatch(getLoanChecksSuccess(json.loanChecks, `${id}.checks`)) 
        })
        .catch(err => dispatch(getLoanChecksError(err)))
}