import API from 'api'

const removeTemplateRequest = () => ({ type: "FETCH/REQUEST" })
const removeTemplateSuccess = data => ({ type: "FETCH/SUCCESS", data })
const removeTemplateError = err => ({ type: "FETCH/ERROR", error: err})

export const removeTemplate = (id, callback) => dispatch => {
    dispatch(removeTemplateRequest())

    API
        .post(`/api/templates/delete?id=${id}`)
        .then(res => res.json())
        .then(json => { 
            dispatch(removeTemplateSuccess(json))
            if(callback)
                callback()
        })
        .catch(err => {  dispatch(removeTemplateError(err)) })
}