import { compose, withProps } from 'recompose'

import { withFormValuesOnChange } from 'components/HOC'
import { PriceField } from 'components/FormFields'
import { AddRepaymentForm } from 'containers/Loan/AddRepayment/consts'

export default compose(
    withProps(() => ({
        name: 'totalInterestAmount',
        label: "Total Interest Amount",
        prependNumber: "£",
        readOnly: true,
    })),
    withFormValuesOnChange(
        AddRepaymentForm, 
        ['grossAmount', 'totalFeeAmount', 'interestRate', 'term'],
        ({ grossAmount, totalFeeAmount, interestRate, term }) => {
            if(!term || !grossAmount)
                return 0
            return (parseFloat(grossAmount) + parseFloat(totalFeeAmount)) * 0.01 * parseFloat(interestRate) * parseInt(term.total)
        }
    ),
)(PriceField)