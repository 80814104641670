import React from 'react'
import { compose, withProps, withHandlers, withState } from 'recompose'
import moment from 'moment'

import { DEFAULT_DATE_FORMAT } from 'consts'

import RemoveExtensionContainer from 'containers/Extensions/RemoveExtension'

import Popup from 'components/globals/Popup'
import { withRedirect } from 'components/HOC'
import { SecondaryButton, PrimaryButton } from 'components/Buttons'

import { priceFormatter } from 'helpers/Helpers'

const ExtensionListItem = ({
    _id,
    startDate,
    grossAmount,
    newGrossAmount,
    interestRate,
    arrangementFee,
    handleRemove,
    togglePopup,
    popupVisible,
    terms,
    ...props
}) => {
    return(
        <div className='term-info'>
            <div className='row'> 
                <div className='col-sm-2'>{moment(startDate).format(DEFAULT_DATE_FORMAT)}</div>
                <div className='col-sm-2'>{`£${priceFormatter(grossAmount.toFixed(2))}`}</div>
                <div className='col-sm-2'>{`£${priceFormatter(newGrossAmount.toFixed(2))}`}</div>
                <div className='col-sm-1'>{interestRate}%</div>
                <div className='col-sm-1'>{arrangementFee}%</div>
                <div className='col-sm-2'>{terms.length} months</div>
                <div className='col-sm-1'>
                    <SecondaryButton darkGray onClick={togglePopup}>REMOVE</SecondaryButton>
                </div>
            </div>
            {popupVisible && 
                <Popup 
                    {...{
                        handleToggle: togglePopup,
                        button: 
                            <div>
                                <PrimaryButton onClick={handleRemove} style={{marginRight: 10}}>Confirm</PrimaryButton>
                                <PrimaryButton onClick={togglePopup} darkGray>Cancel</PrimaryButton>
                            </div>
                    }}>
                    <h3>Confirm Removal</h3>
                    <p>Are you sure you want to remove this item?</p>
                </Popup>
            }
        </div>
    )
}

export default compose(
    RemoveExtensionContainer,
    withState('popupVisible', 'setPopupVisible', false),
    withProps(({ _id, popupVisible, setPopupVisible }) => ({
        togglePopup: () => {
            setPopupVisible(!popupVisible)
        }
    })),
    withHandlers({
        handleRemove: ({ togglePopup, removeExtension, _id, loanId, onComplete }) => () => {
            removeExtension(_id, loanId, () => {
                
                window.location.reload()
            })
        }
    })
)(ExtensionListItem)