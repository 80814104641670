import { compose, withProps, lifecycle } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

const validators = [validateRequired]

const options = [
    { value: 'number', label: 'Number' },
    { value: 'text', label: 'Text' },
    { value: 'boolean', label: 'Boolean' },
    { value: 'booleanDetails', label: 'Boolean with details' },
    { value: 'date', label: 'Date' },
]

export default compose(
    withProps(props => ({
        name: `format`,
        placeholder: 'Select Display Format',
        wrapperConfig: { width: '100%' },
        options: options,
        component: DropdownField,
        label: "Display Format",
        isClearable: true,
        full: true,
        validators,
    })),
)(Field)
