import React from 'react'

import Row from 'components/Row'
import { Panel } from 'components/Forms'
import { SecondaryButton } from 'components/Buttons'

import * as AdditionalFields from './AdditionalFields'

const RenderAdditionalField = ({
    additionalSecurity,
    index,
    removeField,
    ...props
}) => {
    return(
        <Panel 
            button={<SecondaryButton onClick={() => removeField(index)}>Remove</SecondaryButton>}
            title={`Additional Security Field #${index + 1}`}>
            <Row>
                <AdditionalFields.SecurityTextField {...{ additionalSecurity, index }}/>
            </Row>
        </Panel>
    )
}

export default RenderAdditionalField