import { compose, lifecycle, withProps, withState } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm, submit } from 'redux-form'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import { withRedirect } from 'components/HOC'

import { FormName } from './consts'
import * as actions from './actions'
import { selectFormValues, selectInitialValues } from './selectors'

const mapState = createStructuredSelector({
  formValues: selectFormValues,
  initialValues: selectInitialValues,
})

const mapDispatch = dispatch => ({
  ...bindActionCreators(actions, dispatch),
})

export default compose(
    connect(mapState, mapDispatch),
    withState('isFetching', 'setFetching', false),
    withState('updated', 'setUpdated', false),
    reduxForm({
      form: FormName,
      enableReinitialize: true,
      onSubmit: (values, dispatch, { updateGlobalLevels, setUpdated, setFetching }) => {
        setFetching(true)
        setUpdated(false)
        updateGlobalLevels(values)
        setTimeout(() => {
          setFetching(false)
          setUpdated(true)

          setTimeout(() => {
            setUpdated(false)
          }, 3000)
        }, 500)
      }
    }),
    lifecycle({
      componentDidMount() {
        const { getGlobalLevels } = this.props
        getGlobalLevels()
      },

      componentDidUpdate() {
        
      }
    })
)