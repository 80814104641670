import React from 'react'
import { compose, withProps, withState, withHandlers } from 'recompose'

import Popup from 'components/globals/Popup'
import { SecondaryButton, PrimaryButton } from 'components/Buttons'

import { priceFormatter } from 'helpers/Helpers'

import RemoveWalletEntryContainer from 'containers/Wallet/RemoveEntry'

import GenericListItem from '../GenericListItem'

const WalletEntryListItem = ({
    _id,
    amount,
    type,
    notes,
    user,
    popupVisible,
    handleRemove,
    togglePopup,

}) => {
    return(
        <GenericListItem>
            <div className='col-sm-4'>
                <div>
                    <strong>{user.first_name} {user.last_name}</strong>
                    <span className='details'>{notes}</span>
                </div>
            </div>
            <div className='col-sm-3' style={{ fontSize: 14, textTransform: 'capitalize'}}>{type.replaceAll('_', ' ').toLowerCase()}</div>
            <div className='col-sm-2'>£{priceFormatter(amount.toFixed(2))}</div>
            <div className='col-sm-3 text-right'>
                <SecondaryButton to={`/dashboard/wallet/edit/${_id}`}>EDIT</SecondaryButton>
                <SecondaryButton onClick={togglePopup} justText darkGray>REMOVE</SecondaryButton>
            </div>
            {popupVisible && 
                <Popup 
                    {...{
                    handleToggle: togglePopup,
                    button: 
                        <div>
                            <PrimaryButton onClick={handleRemove} style={{marginRight: 10}}>Confirm</PrimaryButton>
                            <PrimaryButton onClick={togglePopup} darkGray>Cancel</PrimaryButton>
                        </div>
                    }}>
                    <h3>Confirm Removal</h3>
                    <p>Are you sure you want to remove this wallet entry?</p>
                </Popup>
            }
        </GenericListItem>
    )
}

export default compose(
    RemoveWalletEntryContainer,
    withState('popupVisible', 'setPopupVisible', false),
    withProps(({ _id, popupVisible, setPopupVisible }) => ({
        togglePopup: () => {
            setPopupVisible(!popupVisible)
        }
    })),
    withHandlers({
        handleRemove: ({ togglePopup, removeWalletEntry, _id, onComplete }) => () => {
            removeWalletEntry(_id, () => {
                if(onComplete) onComplete()
            })
        }
    })
)(WalletEntryListItem)