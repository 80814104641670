import React from 'react'

import Row from 'components/Row'
import { Panel } from 'components/Forms'

import { LoanSpecificationFields } from 'components/FormFields/Loan'

const LoanSpecification = ({}) => {

    return(
        <Panel title="Loan Specification">
            <Row>
                <LoanSpecificationFields.LoanPurposeField  mr={10} />
                <LoanSpecificationFields.LoanExitRouteField />
            </Row>
            <Row>
                <LoanSpecificationFields.LoanGrossAmountField mr={10} />
                <LoanSpecificationFields.LoanStartDateField mr={10} />
                <LoanSpecificationFields.LoanManagedByField />
            </Row>
            <Row>
                <LoanSpecificationFields.LoanInterestRateField mr={10} />
                <LoanSpecificationFields.LoanArrangementFeeField />
            </Row>
            <Row>
                <LoanSpecificationFields.LoanExitRateField mr={10} />
                <LoanSpecificationFields.LoanSolicitorFeeField mr={10} />
                <LoanSpecificationFields.LoanBrokerFeeField />
            </Row>
            <Row>
                <LoanSpecificationFields.LoanTermField mr={10} />
                <LoanSpecificationFields.LoanIsMezzanineField />
            </Row>
        </Panel>
    )
}

export default LoanSpecification