import { compose, withProps } from 'recompose'

import Field from 'components/FormFields/Field'

import { validateRequired, validateEmail } from 'utils/validators'

const validators = [validateRequired, validateEmail]

export default compose(
    withProps(() => ({
        name: `email`,
        label: "Email Address",
        validators,
    }))
)(Field)