import React from 'react'

const ExtensionListItemsHeaders = ({}) => (
  <div className='row table-headers'>
    <div className='col-sm-2'>Start Date</div>
    <div className='col-sm-2'>Old Gross</div>
    <div className='col-sm-2'>New Gross</div>
    <div className='col-sm-1'>IR</div>
    <div className='col-sm-1'>AF</div>
    <div className='col-sm-2'>Term</div>
    <div className='col-sm-2'>Actions</div>
  </div>
)

export default ExtensionListItemsHeaders