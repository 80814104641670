import { compose, withProps } from 'recompose'

import Field from 'components/FormFields/Field'
import { TextAreaField } from 'components/Fields'

import { validateRequired } from 'utils/validators'
const validators = [validateRequired]

export default compose(
    withProps(() => ({
        name: 'notes',
        label: "Notes",
        component: TextAreaField
    }))
)(Field)