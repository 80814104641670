import React from 'react'
import {
  compose,
  withStateHandlers,
  withHandlers,
  lifecycle,
  withPropsOnChange,
} from 'recompose'
import _get from 'lodash/get'

import { TextField } from 'components/Fields'
import { TextButton } from 'components/Buttons'
import { withActionOnOutsideClick } from 'components/HOC'

const DropdownField = ({
  other,
  cursor,
  filter,
  options,
  showList,
  setFilter,
  onKeyDown,
  onItemClick,
  onChangeValue,
  ...props
}) => (
  <TextField
    {...props}
    append={<i className="icon-arrowdown" style={{ fontSize: '14px' }} />}
    input={{
      onKeyDown,
      value: filter,
      onChange: ev => setFilter(ev.target.value),
    }}
    list={options.map(option => (
      <TextButton
        key={option.value}
        onClick={() => onItemClick(option)}
        className={option.value === cursor ? 'active' : ''}
        toggleButton>
        {option.label}
      </TextButton>
    ))}
  />
)

export default compose(
  withStateHandlers(
    ({ input, options }) => ({
      cursor: input && input.value ? input.value : '',
      listVisible: false,
      filter: input && input.value ? _get(options.find(option => option.value === input.value), 'label', '') : '',
    }),
    {
      showList: () => () => ({
        filter: '',
        listVisible: true,
      }),
      hideList: (state, { input: { onBlur, value }, options, onChangeValue }) => cursor => {
        let newValue = cursor || value
        onBlur(newValue)
        
        if( newValue !== value && onChangeValue )
          onChangeValue(newValue)

        newValue = options.find(option => option.value === newValue)
        return {
          filter: newValue ? newValue.label : '',
          listVisible: false,
        }
      },
      setFilter: ({ listVisible }) => filter =>
        listVisible && {
          filter,
        },
      setCursor: () => cursor => ({
        cursor,
      }),
    },
  ),
  withPropsOnChange(
    ['filter', 'options'],
    ({ cursor, filter, options, setCursor }) => {
      const nextOptions = options.filter(
        option => option.label.indexOf(filter) !== -1,
      )
      if (
        nextOptions.findIndex(option => option.value === cursor) === -1 &&
        nextOptions.length > 0
      ) {
        //setCursor(nextOptions[0].value)
      }
      return {
        //options: nextOptions,
      }
    },
  ),
  withHandlers({
    onItemClick: ({ hideList, setFilter }) => option => {
      setFilter(option.label)
      hideList(option.value)
    },
    onKeyDown: ({
      cursor,
      hideList,
      input,
      listVisible,
      options,
      setCursor,
    }) => ev => {
      if (
        ev.key === 'ArrowUp' ||
        ev.key === 'ArrowDown' ||
        ev.key === 'Enter'
      ) {
        const currentIndex = options.findIndex(
          option => option.value === cursor,
        )
        ev.preventDefault()

        if (options.length > 0) {
          let nextIndex = 0

          if (ev.key === 'ArrowUp') {
            nextIndex =
              currentIndex - 1 >= 0 ? currentIndex - 1 : options.length - 1
          }
          if (ev.key === 'ArrowDown') {
            nextIndex = currentIndex + 1 < options.length ? currentIndex + 1 : 0
          }
          
          setCursor(options[nextIndex].value)

        } else {
          setCursor(null)
        }

        if (ev.key === 'Enter' && listVisible) {
          input.onBlur()
          ev.target.blur()
          hideList(cursor)
        }
      }
    },
    onClick: ({ showList, filter, other }) => ev => {
      let container = ev.target
      let input = container.querySelector('input')

      while (!input) {
        container = container.parentNode
        input = container.querySelector('input')
      }

      input.focus()
      
      if(!other || filter !== other)
        showList()
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (!prevProps.listVisible && this.props.listVisible) {
        const activeItem = document.querySelector('.TextField__list > .active')
        if (activeItem) {
          //activeItem.scrollIntoView(false)
        }
      }

      const { cursor, input, setCursor, setFilter, options, hideList } = this.props
      if(prevProps.input.value !== input.value) {
        const opt = options.find(option => option.value === input.value)
        if(opt) {
          setCursor(opt.value)
          hideList(opt.value)
        }
      }

    },
  }),
  withActionOnOutsideClick('listVisible', 'hideList'),
)(DropdownField)
