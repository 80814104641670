import API from 'api';

let GlobalActions = {

  Init: () => {
    return (dispatch) => {
      // dispatch({
      //   type: "GLOBAL_INIT_BEGIN",
      //   fetching: true,
      // });

      // API.get('/api/system')
      //   .then(res => { return res.json() })
      //   .then(json => {
      //     dispatch({
      //       type: "GLOBAL_INIT_END",
      //       data: json,
      //       fetching: false,
      //     })
      //   });
    }
  },

  AllowAccess: (code) => {
    return dispatch => {
      var data = {
        code: code,
      }
      API.post("/api/auth/allow", { body: JSON.stringify(data) })
        .then(res => { return res.json() })
        .then(json => {
          if( json.success ) {
            
          }
        })   
    }
  },

  LoadAllUsers: () => {
    return dispatch => {

      dispatch({
        type: "LOAD_USERS_BEGIN",
      });
      
      API.get("/api/auth/list-users")
        .then(res => { return res.json() })
        .then(json => {
          if( json.success ) {
            dispatch({
              type: "LOAD_USERS_END",
              users: json.users,
            });
          }
        })
    }
  },

  LoadUserTypes: () => {
    return dispatch => {
      dispatch({
        type: "LOAD_USER_TYPES_BEGIN",
      })

      API.get("/api/system/user-types")
        .then(res => { return res.json() })
        .then(json => {
          if( json.success ) {
            dispatch({
              type: "LOAD_USER_TYPES_END",
              user_types: json.user_types,
            })
          }
        })
      }
  },

  LoadAllChecks: () => {
    return dispatch => {
      dispatch({
        type: "LOAD_CHECKS_BEGIN",
      })

      API.get("/api/loans/checks")
        .then(res => { return res.json() })
        .then(json => {

          


          if( json.success ) {
            dispatch({
              type: "LOAD_CHECKS_END",
              loan_checks: json.results,
            })
          }
        })
    }
  },

  LoadLoanChecks: (loan_id) => {
    return dispatch => {
      dispatch({
        type: "LOAD_LOAN_CHECKS_BEGIN",
      })

      API.get("/api/loans/checks/data")
        .then(res => { return res.json() })
        .then(json => {

          

          if( json.success ) {
            dispatch({
              type: "LOAD_LOAN_CHECKS_END",
              loan_checks_data: json.results,
            })
          }
        })
    }
  },

  UpdateLoanSettings: (data) => {
    return dispatch => {
      dispatch({
        type: "UPDATE_LOAN_SETTINGS_BEGIN",
      })

      API.post("/api/loans/settings", { body : JSON.stringify(data) })
        .then(res => { return res.json() })
        .then(json => {
          if( json.success ) {
            dispatch({
              type: "UPDATE_LOAN_SETTINGS_END",
              loan_settings_update_success: true,
            })
          }
        })
    }
  },

  LoadSettings: () => {
    return dispatch => {
      dispatch({
        type: "LOAD_SETTINGS_BEGIN",
      })

      API.get("/api/settings")
        .then(res => { return res.json() })
        .then(json => {
          
          if( json.success ) {
            dispatch({
              type: "LOAD_SETTINGS_END",
            })
          }
        })
    }
  },

  LoadFile: (filename) => {
    return dispatch => {
      API.get("/docs?f=" + filename)
         .then( res => { return res.blob() })
         .then( blob => {
         
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([blob], {type: "application/pdf"})
          
            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
              return;
            } 
          
            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download="file.pdf";
            link.click();
            setTimeout(function(){
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
              }, 100); 
        })
    }
  },

  DownloadFile: (doc, callback ) => {
    return dispatch => {
      var data = {
        id: doc._id,
      }

      API.post("/docs/download", { body: JSON.stringify(data) } )
         .then( res => { return res.blob() })
         .then( blob => {         
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([blob])
          
            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
              if( callback ) {
                
                callback();
              }
              return;
            } 
          
            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download = doc.name;
            link.click();
            setTimeout(function(){
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                if( callback ) {
                  callback();
                }
              }, 100); 
        })
    }
  },

  DeleteFile: (id, callback) => {
    return dispatch => {
      var data = {
        id: id,
      }

      dispatch({
        type: "DELETE_FILE_BEGIN",
      })

      API.post("/docs/remove", { body: JSON.stringify(data)})
        .then(res => { return res.json() })
        .then(json => {

          if( json.success ) {
            dispatch({
              type: "DELETE_FILE_END",
            });
            
            if( callback ) {
              callback(json.doc);
            }
          }
        })
    }
  },

  LoadLoanFiles: (object_id, callback) => {
    return dispatch => {
      dispatch({
        type: "LOAD_LOAN_FILES_BEGIN",
      })

      API.get("/docs/loan?id=" + object_id)
        .then(res => { return res.json() })
        .then(json => {

          if( json.success ) {
            dispatch({
              type: "LOAD_LOAN_FILES_END",
              id: object_id,
              results: json.results,
            })
          }
        })
    }
  },

  LoadDuePayments: () => {
    return dispatch => {
      dispatch({
        type: "LOAD_DUE_PAYMENTS_BEGIN",
      })

      API.get("/api/loans/due_payments")
        .then(res => { return res.json() })
        .then(json => {
          

          if( json.success ) {
            dispatch({
              type: "LOAD_DUE_PAYMENTS_END",
              due_payments: json.results,
            })
          }
        })
    }
  },

  LoadRecentPayments: () => {
    return dispatch => {
      dispatch({
        type: "LOAD_RECENT_PAYMENTS_BEGIN",
      })

      API.get("/api/loans/recent_payments")
        .then(res => { return res.json() })
        .then(json => {
          

          if( json.success ) {
            dispatch({
              type: "LOAD_RECENT_PAYMENTS_END",
              recent_payments: json.results,
            })
          }
        })
    }
  },

  LoadClientDetails: (id) => {
    return dispatch => {
      dispatch({
        type: "LOAD_CLIENT_DETAILS_BEGIN",
      })

      API.get("/api/clients/single?id=" + id)
        .then(res => { return res.json() })
        .then(json => {

          if( json.success ) {
            dispatch({
              type: "LOAD_CLIENT_DETAILS_END",
              client_details: json.result,
            })
          }
        })
    }
  },

  CheckLevel: (data) => {
    return dispatch => {

      dispatch({
        type: "CONFIRM_CHECK_BEGIN",
      })

      API.post("/api/loans/check/data/create", { body: JSON.stringify(data)})
        .then(res => { return res.json() })
        .then(json => {

          

          if( json.success ) {
            dispatch({
              type: "CONFIRM_CHECK_END",
              check: json.result,
            });
          }
        })
    }
  },

  LoadNotifications: () => {
    return dispatch => {
      dispatch({
        type: "LOAD_NOTIFICATIONS_BEGIN",
      })

      API.get("/api/system/notifications")
        .then(res => { return res.json() })
        .then(json => {

          if( json.success ) {
            dispatch({
              type: "LOAD_NOTIFICATIONS_END",
              notifications: json.results,
            });
          }
        })
    }
  }
  
}

export default GlobalActions;
