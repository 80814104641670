import React from 'react'

import Row from 'components/Row'

import RedemptionStatementEntryWrapper from './RedemptionStatementEntryWrapper'

const RedemptionStatementEntry = ({
    title,
    amount,
}) => {
    return(
        <RedemptionStatementEntryWrapper>
            <Row>
                <div className='col-sm-8' dangerouslySetInnerHTML={{ __html: title }}></div>
                <div className='col-sm-4 text-right'>{amount}</div>
            </Row>
        </RedemptionStatementEntryWrapper>
    )
}

export default RedemptionStatementEntry