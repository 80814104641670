import _get from 'lodash/get'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'
import { compose } from 'recompose'

const clientSelector = createSelector(
  state => state.global,
  globalState => _get(globalState, 'client')
)

const fieldPathSelector = createSelector(
  (_, props) => props.path,
  path => path,
)

const selectClientFieldValue = createSelector(
    clientSelector,
    fieldPathSelector,
    (client, path) => _get(client, path),
)

export default compose(
  connect(
    createStructuredSelector({
      value: selectClientFieldValue,
    })
  ),
)
