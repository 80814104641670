import { compose, withProps } from 'recompose'

import { withFormValuesOnChange } from 'components/HOC'
import { PriceField } from 'components/FormFields'
import { AddLumpSumForm } from 'containers/Loan/AddLumpSum/consts'

export default compose(
    withProps(() => ({
        name: 'monthlyPaymentAmount',
        label: "Monthly Payment Amount",
        prependNumber: "£",
        readOnly: true,
    })),
    withFormValuesOnChange(
        AddLumpSumForm, 
        ['totalInterestAmount', 'remainingTerms'],
        ({ totalInterestAmount, remainingTerms }, props) => {
            let _term = parseInt(remainingTerms)
            let _totalInterestAmount = parseFloat(totalInterestAmount)
            if( _term > 0 ) 
                return _totalInterestAmount / _term
            return 0
        }
    ),
)(PriceField)