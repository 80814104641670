import API from 'api'

const createCheckSubmit = () => ({ type: "FETCH/REQUEST" })
const createCheckSuccess = data => ({ type: "FETCH/SUCCESS", data })
const createCheckError = err => ({ type: "FETCH/ERROR", error: err})

export const createCheck = (formValues, callback) => dispatch => {
    dispatch(createCheckSubmit())

    API
        .post('/api/checks/create', { body: JSON.stringify(formValues) })
        .then(res => res.json())
        .then(json => { 
            dispatch(createCheckSuccess(json)) 
            if(callback) callback(json)
        })
        .catch(err => dispatch(createCheckError(err)))

}