import React from 'react';
import styled from 'styled-components'
import { Editor } from 'slate-react'
import { Scrollbars } from 'react-custom-scrollbars'
import { Value, Block, Inline } from 'slate'
import Html from 'slate-html-serializer'
import imageExtensions from 'image-extensions'

import SoftBreak from 'slate-soft-break'
import DeepTable from 'slate-deep-table'

import { getFormValue } from 'utils/slate'

import InputWrapper from 'components/Fields/InputWrapper'

import TagsPopup from './TagsPopup'
import Toolbar from './Toolbar'
import TableMenu from './TableMenu'

const plugins = [
  DeepTable({}),
  SoftBreak({ shift: true }),
]

const Image = styled('img')`
  display: block;
  max-width: 100%;
  max-height: 20em;
  box-shadow: ${props => (props.selected ? '0 0 0 2px blue;' : 'none')};
`

const TagItem = styled('span')`
    display: inline-block;
    background: #4692D7;
    color: #fff;
    font-weight: bold;
    border-radius: 4px;
    text-decoration: none;
    padding: 2px 7px;
    vertical-align: middle;
    font-size: 11px;
    line-height: 22px;
    text-transform: uppercase;
`

const TAG_NODE_TYPE = 'tag'
const DEFAULT_NODE = 'paragraph'

const schema = {
    document: {
        last: { type: 'paragraph' },
            normalize: (editor, { code, node, child }) => {
                switch (code) {
                    case 'last_child_type_invalid': {
                    const paragraph = Block.create('paragraph')
                    return editor.insertNodeByKey(node.key, node.nodes.size, paragraph)
                }
            }
        },
    },      
    blocks: {
        image: {
            isVoid: true,
        },
    },
    inlines: {
        [TAG_NODE_TYPE]: {
            // user cant edit the text inside
            isVoid: true,
        },
    },
};

const initialValue = Value.fromJSON({
    document: {
      nodes: [
        {
          object: 'block',
          type: 'paragraph',
          nodes: [],
        },
      ],
    },
  })

  const SPECIAL_TAGS = {
    tag: 'tag'
  }

  const BLOCK_TAGS = {
    blockquote: 'quote',
    p: 'paragraph',
    pre: 'code',
    paragraph: 'paragraph',
    heading1: 'heading1',
    alignLeft: 'alignReft',
    alignRight: 'alignRight',
    alignCenter: 'alignCenter',
  }

  // Add a dictionary of mark tags.
  const MARK_TAGS = {
    em: 'italic',
    strong: 'bold',
    u: 'underline',
    span: 'strikethrough',
  }

  const rules = [
    {
      deserialize(el, next) {
        const type = BLOCK_TAGS[el.tagName.toLowerCase()]
        if (type) {
          return {
            object: 'block',
            type: type,
            data: {
              className: el.getAttribute('class'),
            },
            nodes: next(el.childNodes),
          }
        }
      },
      serialize(obj, children) {
        if (obj.object == 'block') {
            switch (obj.type) {
                case 'code':
                    return (
                        <pre>
                            <code>{children}</code>
                        </pre>
                    )
                case 'paragraph':
                    return <p className={obj.data.get('className')}>{children}</p>
                case 'alignLeft':
                    return <div className={obj.data.get('className')} style={{textAlign: 'left'}}>{children}</div>
                case 'alignCenter':
                    return <div className={obj.data.get('className')} style={{textAlign: 'center'}}>{children}</div>
                case 'alignRight':
                    return <div className={obj.data.get('className')} style={{textAlign: 'right'}}>{children}</div>
                case 'quote':
                    return <blockquote>{children}</blockquote>
                case 'table_cell':
                    return <td>{children}</td>
                case 'heading1':
                    return <h1>{children}</h1>
                case 'heading2':
                    return <h2>{children}</h2>
                case 'heading3':
                    return <h3>{children}</h3>
                case 'table_row':
                    return <tr>{children}</tr>
                case 'table':
                    return <table cellPadding={0} cellSpacing={0}>{children}</table>
                case 'list-item':
                    return <li>{children}</li>
                case 'bulleted-list':
                    return <ul>{children}</ul>
                case "numbered-list":
                    return <ol>{children}</ol>
                case "image":
                    return <img src={obj.data.src} />
            }
        }
      },
    },
    // Add a new rule that handles marks...
    {
        deserialize(el, next) {
            const type = MARK_TAGS[el.tagName.toLowerCase()]
            if (type) {
                return {
                    object: 'mark',
                    type: type,
                    nodes: next(el.childNodes),
                }
            }
        },
        serialize(obj, children) {
            if (obj.object == 'mark') {
                switch (obj.type) {
                    case 'bold':
                        return <strong>{children}</strong>
                    case 'italic':
                        return <em>{children}</em>
                    case 'underline':
                        return <u>{children}</u>
                    case 'strikethrough':
                        return <span style={{fontStyle: 'line-through'}}>{children}</span>
                    case 'pull-right':
                        return <span style={{float: 'right'}}>{children}</span>
                    case 'pull-left':
                        return <span style={{float: 'left'}}>{children}</span>
                }
            }
        },
    },
    // add a new rule 
    {
        deserialize(el, next) {
            
            const type = SPECIAL_TAGS[el.tagName.toLowerCase()];
            if( type ) {
                return {
                    object: 'inline',
                    type: type,
                    nodes: next(el.childNodes),
                }
            }
        },
        serialize(obj, children) {
        
            if( obj.object == 'inline' ) {
                switch(obj.type) {
                    case TAG_NODE_TYPE:
                        return(
                            <em className='tag' 
                                data-id={obj.data.get("id")}
                                data-name={obj.data.get("name")}>
                                {children}
                            </em>
                        );
                }
            }
        }
    }
  ];

const html = new Html({rules})

function isImage(url) {
  return imageExtensions.find(url.endsWith)
}

function insertImage(editor, src, target) {
    if (target) {
        editor.select(target)
    }

    editor.insertBlock({
        type: 'image',
        data: { src },
    })
}

function PullRightBlock(props) {
    return <span className='pull-right' style={{ float: "right" }}>{props.children}</span>
}

function PullLeftBlock(props) {
    return <span className='pull-left' style={{ float: "left" }}>{props.children}</span>
}

function BoldBlock(props) {
    return <strong>{props.children}</strong>
}

function ItalicBlock(props) {
    return <span className='italic' style={{fontStyle: "italic"}}>{props.children}</span>
}

function StrikethroughBlock(props) {
    return <span className='strikethrough' style={{ textDecoration: "line-through"}}>{props.children}</span>
}

function UnderlineBlock(props) {
    return <span className='underline' style={{textDecoration: "underline"}}>{props.children}</span>
}

class TemplateEditor extends React.Component {
    
    constructor(props) {
        super(props);

        let val = initialValue;

        if( props.raw_content && props.raw_content.length > 0 ) {
            val = Value.fromJSON(props.raw_content);
        }

        this.state = {
            value: val,
            showTagsPopup: false,
            italic: false,
            bold: false,
            initialized: false,
            underline: false,
            strikethrough: false,   
            html: html.serialize(val),
        }

        this.onChange = this.onChange.bind(this);
        this.insertTag = this.insertTag.bind(this);
        this.showTagsPopup = this.showTagsPopup.bind(this)

        this.menuRef = React.createRef()
        this.editor = React.createRef()
    }


    updateMenu = () => {
        const menu = this.menuRef.current
        if (!menu) return
    
        const { value } = this.state
        const { fragment, selection } = value
    
        if (!this.editor.current.isSelectionInTable()) {
          menu.removeAttribute('style')
          return
        }
    
        const native = window.getSelection()
        if(native.focusNode == null)
            return
        const range = native.getRangeAt(0)
        const rect = range.getBoundingClientRect()
        menu.style.opacity = 1
        menu.style.top = `${rect.top + window.pageYOffset - menu.offsetHeight - 20}px`
    
        menu.style.left = `${rect.left +
          window.pageXOffset -
          menu.offsetWidth / 2 +
          rect.width / 2}px`
      }


    init(raw_content) {
        if( raw_content && raw_content.length > 0 ) {
            let val = Value.fromJSON(JSON.parse(raw_content));
            this.setState({
                value: val,
                initialized: true,
            })
        }
    }

    componentDidMount = () => {
        this.updateMenu()
    }
    
    componentDidUpdate = () => {
        this.updateMenu()
    }

    componentWillReceiveProps(nextProps, test) {
        if(!this.state.initialized) 
            if(nextProps.input && nextProps.input.value) {
                this.init(nextProps.input.value.rawContent) 
            }
    }

    onBlur = (event, editor, next) => {
        const editorValue = getFormValue(this.state.value)
        const htmlValue = this.getHTML()
        
        setTimeout(() => this.props.input.onBlur(
            {
                rawContent: JSON.stringify(editorValue.toJSON()),
                htmlContent: htmlValue,
            }
        ), 0)
        next()
    }
    
    onFocus = (event, editor, next) => {
        //setTimeout(() => this.props.input.onFocus(), 0)
        next()
    }

    hasBlock(type) {
        const { value } = this.state
        return value.blocks.some(node => node.type == type)
    }

    getHTML() {
        return html.serialize(this.state.value);
    }

    insertTag(data) {
        let editor = this.editor.current;   
        const selectedRange = editor.value.selection

        
        
        editor
            .insertText(' ')
            .insertInlineAtRange(selectedRange, {
                data: {
                    id: data._id,
                    name: data.name,
                },
                nodes: [
                {
                    object: 'text',
                    leaves: [
                        {
                            text: `${data.name}`,
                        },
                    ],
                },
                ],
                type: TAG_NODE_TYPE,
            })
            .focus()
    }

    renderInline(props, editor, next) {
        const { attributes, node } = props
    
        if (node.type == TAG_NODE_TYPE) {
          // This is where you could turn the mention into a link to the user's
          // profile or something.
          return <TagItem {...attributes}>{props.node.text}</TagItem>
        }
    
        return next()
    }

    renderBlock(props, editor, next) {
        const { attributes, children, node, isFocused } = props
        
        switch (node.type) {
            case 'block-quote':
                return <blockquote {...attributes}>{children}</blockquote>
            case 'bulleted-list':
                return <ul {...attributes}>{children}</ul>
            case 'table':
                return <table {...attributes}><tbody>{children}</tbody></table>
            case 'table_row':
                return <tr {...attributes}>{children}</tr>
            case 'table_cell':
                return <td {...attributes}>{children}</td>
            case 'heading1':
                return <h1 {...attributes}>{children}</h1>
            case 'heading2':
                return <h2 {...attributes}>{children}</h2>
            case 'heading3':
                return <h3 {...attributes}>{children}</h3>
            case 'list-item':
                return <li {...attributes}>{children}</li>
            case 'numbered-list':
                return <ol {...attributes}>{children}</ol>
            case 'paragraph':
                return <p {...attributes}>{children}</p>
            case 'alignCenter':
                    return <div {...attributes} style={{textAlign: 'center'}}>{children}</div>
            case 'alignLeft':
                return <div {...attributes} style={{textAlign: 'left'}}>{children}</div>
            case 'alignRight':
                return <div {...attributes} style={{textAlign: 'right'}}>{children}</div>
            case 'image': {
                const src = node.data.get('src')
                return <Image src={src} selected={isFocused} {...attributes} />
            }

            default:
                return next()
        }
    }
    

    renderMark(props, editor, next) {
        switch (props.mark.type) {
            case 'pull-right':
                return <PullRightBlock {...props} />
            case 'pull-left':
                return <PullLeftBlock {...props} />
            case 'bold':
                return <BoldBlock {...props} />
            case 'italic':
                return <ItalicBlock {...props} />
            case 'underline':
                return <UnderlineBlock {...props} />
            case 'strikethrough':
                return <StrikethroughBlock {...props} />
            default:
                return next();
        }
    }

    showTagsPopup() {
        this.setState({
            showTagsPopup: true,
        })
    }

    hideTagsPopup() {
        this.setState({
            showTagsPopup: false,
        })
    }

    renderTagsPopup() {
        if( !this.state.showTagsPopup ) 
            return null;

        return <TagsPopup editor={this} />
    }

    onClickBlock(event, type) {
        if(event)
            event.preventDefault()

        const editor = this.editor.current
        const { value } = editor
        const { document } = value

        // Handle everything but list buttons.
        if (type != 'bulleted-list' && type != 'numbered-list') {
            const isActive = this.hasBlock(type)
            const isList = this.hasBlock('list-item')

            if (isList) {
                editor
                .setBlocks(isActive ? DEFAULT_NODE : type)
                .unwrapBlock('bulleted-list')
                .unwrapBlock('numbered-list')
            } else {
                editor.setBlocks(isActive ? DEFAULT_NODE : type)
            }
        } else {
            // Handle the extra wrapping required for list buttons.
            const isList = this.hasBlock('list-item')
            const isType = value.blocks.some(block => {
                return !!document.getClosest(block.key, parent => parent.type == type)
            })

            if (isList && isType) {
                editor
                .setBlocks(DEFAULT_NODE)
                .unwrapBlock('bulleted-list')
                .unwrapBlock('numbered-list')
            } else if (isList) {
                
                if(!isType) {
                    // add another block to current block instead of unwrapping 
                    // existing one 
                    editor
                        .wrapBlock(type)    
                } 
            } else {
                editor.setBlocks('list-item').wrapBlock(type)
            }
        }
    }

    onChange(value) {
        this.setState({ 
            value: value.value 
        })
    }

    onKeyDown(event, editor, next) {

        if (!event.ctrlKey) 
            return next();

        switch (event.key) {
            case 'b': {
                event.preventDefault()
                this.boldText();
                return;
            }
            case 'i': {
                event.preventDefault();
                this.italicText();
                return;
            }
            case 'u': {
                event.preventDefault();
                this.underlineText();
                return;
            }
            case 's': {
                event.preventDefault();
                this.strikethroughText();
                return;
            }
            case ']': {
                event.preventDefault();
                this.pullBlockRight();
                return;
            }
            case '[': {
                event.preventDefault();
                this.pullBlockLeft();
                return;
            }
            default:
                return next();
        }
    }

    render() {
        const { input, label, large, disabled, placeholder, ...props } = this.props
        const editor = this.editor.current
        const value = this.state.value
        
        return(
            <InputWrapper {...{ ...props, label, large, disabled, placeholder }}>
                <div className='template-editor-wrap'>
                    <Toolbar {...{ editor, value, showTagsPopup: this.showTagsPopup }} />
                    <div className='template-editor'>
                        <Scrollbars
                            autoHeight
                            autoHeightMax={600}>
                            <Editor 
                                ref={this.editor}
                                plugins={plugins}
                                schema={schema}
                                value={this.state.value} 
                                onBlur={this.onBlur.bind(this)}
                                onFocus={this.onFocus.bind(this)}
                                onChange={this.onChange.bind(this)}
                                onKeyDown={this.onKeyDown.bind(this)}
                                renderInline={this.renderInline.bind(this)}
                                renderBlock={this.renderBlock.bind(this)}
                                renderMark={this.renderMark.bind(this)} />

                                <TableMenu editor={editor} ref={this.menuRef} />
                        </Scrollbars>
                    </div>
                    {this.renderTagsPopup()}
                </div>
            </InputWrapper>
        )
    }
}

export default TemplateEditor;