import React from 'react'
import moment from 'moment'
import _pick from 'lodash/pick'
import _merge from 'lodash/merge'
import _isEqual from 'lodash/isEqual'
import { compose, withHandlers, withState, lifecycle, withProps } from 'recompose'

import ToggableTooltip from 'components/ToggableTooltip'
import NumberField from 'components/Fields/NumberField'
import { SecondaryButton } from 'components/Buttons'

import { COLORS } from 'consts'

import LoanTermDataHeaders from './LoanTermDataHeaders'
import LoanTermDataFieldWrapper from './LoanTermDataFieldWrapper'
import LoanTermDataTermListItem from './LoanTermDataTermListItem'

const _default = {
    total: "",
    totalCompounded: "",
    terms: [],
}

const LoanTermDataField = ({
    input,
    startDate,
    monthlyPaymentAmount,
    tooltipVisible,
    toggleTooltip,
    setTooltipVisible,
    handleTermChange,
    ...props
}) => {
    const inputValue = input != null && input.value ? input.value : {}
    const value = _merge({}, _default, inputValue)

    const append = value.total > 0 ? (
        <SecondaryButton 
            color={COLORS.blue}
            toggleButton
            active={tooltipVisible}
            onClick={toggleTooltip}>
            {value.totalCompounded} Compounded
        </SecondaryButton>
    ) : null

    return(
        <LoanTermDataFieldWrapper {...props}>
            <NumberField 
                {...{ 
                    input: {
                        value: value.total,
                        onFocus: input.onFocus,
                        onBlur: input.onBlur,
                    },
                    onChange: handleTermChange, 
                    numberParser: parseInt, 
                    numberAppend: "month(s)", 
                    decimalPlaces: 0, 
                    append,
                    ...props
                }} 
            />
            <ToggableTooltip 
                display="top" 
                title="Term Breakdown"
                visible={tooltipVisible}
                handleClose={() => setTooltipVisible(false)}> 
                <LoanTermDataHeaders />
                {value.terms.map(term => (
                    <LoanTermDataTermListItem {...{ ...term, input }} key={term.periodStart} />
                ))}
            </ToggableTooltip>
        </LoanTermDataFieldWrapper>
    )
}

export default compose(
    withState('savedValues', 'setValues', {}),
    withState('tooltipVisible', 'setTooltipVisible', false),
    withHandlers({
        toggleTooltip: ({ tooltipVisible, setTooltipVisible }) => () => {
            setTooltipVisible(!tooltipVisible)
        },
        handleTermChange: ({input, startDate, monthlyPaymentAmount, formValues}) => e => {
            const { grossAmount, arrangementFee, interestRate } = formValues
            const terms = []
            const term = e.target.value != "" ? parseInt(e.target.value) : ""
            const currentValue = input && input.value ? {...input.value} : {}

            if(typeof term == undefined) return 
            if(!startDate) return

            if(term != "") {
                let _grossAmount = parseFloat(grossAmount)
                let _interestRate = parseFloat(interestRate)

                for( var i= 0; i< term; i++ ) {
                    const termPayment = _grossAmount * (_interestRate * 0.01)
                    _grossAmount += termPayment

                    terms.push({
                        periodStart: moment(startDate).add(i, 'months'),
                        periodEnd: moment(startDate).add(i + 1, 'months').subtract(1, 'day'),
                        amount: termPayment.toFixed(2),
                        compounded: true,
                    })
                }
            }
            currentValue.total = term
            currentValue.terms = terms
            currentValue.totalCompounded = term
            input.onChange(currentValue)
        }
    }),
    lifecycle({
        componentDidMount() {
            const { input, setValues, formValues } = this.props
            setValues({...input.value, ...formValues})
        },
        componentDidUpdate() {
            const { input, setValues, savedValues, startDate, formValues } = this.props
            const newValues = { ...input.value, ...formValues }
            
            if(!formValues)
                return
            
            const { grossAmount, arrangementFee, interestRate } = formValues
            const currentValue = input && input.value ? {...input.value} : false
            
            if( !currentValue ) return

            let _grossAmount = parseFloat(grossAmount)
            let _interestRate = parseFloat(interestRate)

            if(!_isEqual(savedValues, newValues)) {
                for( var i= 0; i< currentValue.terms.length; i++ ) {
                    const termPayment = _grossAmount * (_interestRate * 0.01)
                    _grossAmount += termPayment

                    currentValue.terms[i].periodStart = moment(startDate).add(i, 'months')
                    currentValue.terms[i].periodEnd = moment(startDate).add(i + 1, 'months').subtract(1, 'day')
                    currentValue.terms[i].amount = termPayment.toFixed(2)
                }
                setValues(newValues)
                input.onChange(currentValue)
            }
        },
    }),
)(LoanTermDataField)