import React from 'react'
import { NavLink } from 'react-router-dom'

import { ClientType } from 'reducers/NewClientState'

import ClientTabNavigationWrapper from './ClientTabNavigationWrapper'

const ClientTabNavigation = ({ 
    id, 
    client,
    ...props
}) => {

    const { clientType } = client ? client : { clientType: '' }

    return(
        <ClientTabNavigationWrapper>
            <ul>
                <li><NavLink exact to={'/dashboard/clients/details/' + id + '/'}>Overview</NavLink></li>
                {clientType === ClientType.Corporate &&
                    <React.Fragment>
                        <li><NavLink to={'/dashboard/clients/details/' + id + '/directors'}>Directors</NavLink></li>
                        <li><NavLink to={'/dashboard/clients/details/' + id + '/applicants'}>Applicants</NavLink></li>
                    </React.Fragment>
                }
                <li><NavLink to={'/dashboard/clients/details/' + id + '/documents'}>Documents</NavLink></li>
            </ul>
        </ClientTabNavigationWrapper>
    )
}

export default ClientTabNavigation