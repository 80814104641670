import styled from 'styled-components'

import TextBase from './TextBase'

const H3 = styled(TextBase)`
  letter-spacing: auto;
`

H3.defaultProps = {
  fontSize: '16px',
  lineHeight: '19px',
}

export default H3
