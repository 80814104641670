import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import * as actions from './actions'
import { selectRecentWalletEntries, selectIsFetching } from './selectors'

const mapState = createStructuredSelector({
    entries: selectRecentWalletEntries,
    isFetching: selectIsFetching,
})

const mapDispatch = dispatch => bindActionCreators(actions, dispatch)

export default compose(
    connect(mapState, mapDispatch),
    lifecycle({
        componentDidMount() {
            const { getRecentWalletEntries } = this.props
            getRecentWalletEntries()
        }
    })
)