import API from 'api'

const sendCheckRequestSubmit = () => ({ type: "FETCH/REQUEST" })
const sendCheckRequestSuccess = data => ({ type: "FETCH/SUCCESS", data })
const sendCheckRequestError = err => ({ type: "FETCH/ERROR", error: err})

export const sendCheckRequest = (data, callback) => dispatch => {
    dispatch(sendCheckRequestSubmit())

    API
        .post('/api/loans/send-check-request', { body: JSON.stringify(data) })
        .then(res => res.json())
        .then(json => { 
            dispatch(sendCheckRequestSuccess(json))
            if(callback) callback()
        })
        .catch(err => dispatch(sendCheckRequestError(err)))

}

const getCheckRequestSubmit = () => ({ type: "FETCH/REQUEST" })
const getCheckRequestSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getCheckRequestError = err => ({ type: "FETCH/ERROR", error: err})

export const getCheckRequests = (loanId, callback) => dispatch => {
    dispatch(getCheckRequestSubmit())

    API
        .post('/api/loans/get-check-request', { body: JSON.stringify({ loanId }) })
        .then(res => res.json())
        .then(json => { 
            dispatch(getCheckRequestSuccess(json))
            if(callback) callback()
        })
        .catch(err => dispatch(getCheckRequestError(err)))

}
