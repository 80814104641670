import styled from 'styled-components'

export default styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    left: 0px;
    top: 0px;
    z-index; 0;
`