import styled from 'styled-components'

import { COLORS, BOX_SHADOWS } from 'consts'

export default styled.div`
  ${props => props.full && `width: 100%`};

  .react-datepicker {
    border: 1px solid ${COLORS.lightestGray};
    z-index: 100;
    position: relative;
    
    &-wrapper,
    &__input-container {
      width: 100%;
      min-width: 200px;
      z-index: 2;

      input {
        padding-left: 0px;
      }
    }

    &-popper {
      z-index: 2;
    }

    &__header {
      background: none;
      border: none;
    }

    &__triangle {
      border-bottom-color: ${COLORS.white};
      display: none;
    }

    &__month-container {
      border-radius: 4px;
    }

    &__current-month {
      font-weight: 500;
      color: ${COLORS.darkestGray};
    }

    &__navigation {
      border: solid ${COLORS.lightGray};
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 1px;
      margin: 5px 5px;

      &--next {
        transform: rotate(-45deg);
      }

      &--previous {
        transform: rotate(135deg);
      }
    }

    &__day {
      color: ${COLORS.darkestGray};
      border-radius: 0;
      margin: 1px;
      width: 33px;
      line-height: 33px;

      &-name {
        opacity: 0;
      }

      &--keyboard-selected {
        background: none;
      }

      &--selected,
      &:hover {
        background: ${COLORS.blue};
        color: ${COLORS.white};
        border-radius: 0;
        box-shadow: ${BOX_SHADOWS.box};
      }

      &--outside-month {
        background: ${COLORS.lightestGray2};
        color: ${COLORS.lightGray};
      }
    }
  }
`
