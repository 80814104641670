import React from 'react'

import Row from 'components/Row'
import { Panel } from 'components/Forms'
import { SecondaryButton } from 'components/Buttons'

import * as CheckLevelFields from './CheckLevelFields'

const RenderLevel = ({
    level,
    index,
    removeField,
    ...props
}) => {
    return(
        <Panel 
            button={<SecondaryButton onClick={() => removeField(index)}>Remove</SecondaryButton>}
            title={`Check #${index + 1}`}>
            <Row>
                <CheckLevelFields.UserField {...{ level, index }} mr={10}/>
                <CheckLevelFields.FileUploadField {...{ level, index }} />
            </Row>
        </Panel>
    )
}

export default RenderLevel