import { compose, lifecycle, withProps, withState } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import * as actions from './actions'
import { selectInitialValues } from './selectors'

const mapState = createStructuredSelector({
  globalLevels: selectInitialValues,
})

const mapDispatch = dispatch => ({
  ...bindActionCreators(actions, dispatch),
})

export default compose(
    connect(mapState, mapDispatch),
    lifecycle({
      componentDidMount() {
        const { getGlobalLevels } = this.props
        getGlobalLevels()
      }
    })
)