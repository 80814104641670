import React from 'react'
import { compose } from 'recompose'

import Page from 'components/Page'
import { Message } from 'components/Messages'
import ProfessionalListItem from 'components/ListItems/ProfessionalListItem'
import LoadingPanel from 'components/LoadingPanel'
import { SecondaryButton } from 'components/Buttons'

import ListProfessionalsContainer from 'containers/Professionals/ListProfessionals'

const ListProfessionals = ({
    professionals,
    getProfessionals,
    isFetching,
    ...props
}) => {

    if( isFetching )
        return <LoadingPanel text="Loading professionals." />
    return(
        <Page 
            title="Approved Professionals"
            buttons={<SecondaryButton to='/dashboard/settings/professionals/create' darkGray>CREATE</SecondaryButton>}>
            {professionals && professionals.length > 0 &&
                <div className='listing'>
                    <div className='thead'>
                        <div className='row'>
                            <div className='col-sm-4'>Name</div>
                            <div className='col-sm-4'>Type</div>
                            <div className='col-sm-2'></div>
                        </div>
                    </div>
                </div>
            }
            {professionals.map(professional => 
                <ProfessionalListItem 
                  {...{
                    ...professional,
                    key: professional.id,
                    onComplete: () => {
                        getProfessionals()
                    }
                  }}
                />    
            )}
            {professionals && professionals.length == 0 && 
                <Message text="No professionals found." />
            }
        </Page>
    )
}

export default compose(
  ListProfessionalsContainer,
)(ListProfessionals)