import React from 'react'

import RedemptionInfoPanel from './RedemptionInfoPanel'

export default ({
    title,
    amount,
}) => {
    return(
        <RedemptionInfoPanel>
            <h3 style={{marginBottom: 0}}>
                {title}
                <strong>{amount}</strong>
            </h3>
        </RedemptionInfoPanel>
    )
}