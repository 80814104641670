import React from "react";
import { FieldArray } from "redux-form";

import { FieldArrayHelper } from "components/Fields";

import RenderAdditionalFields from "./RenderAdditionalFields";

const AdditionalSecurityTextArrayField = ({ fields, ...props }) => {
  return (
    <FieldArray
      name="additionalSecurityText"
      component={FieldArrayHelper}
      renderFields={RenderAdditionalFields}
      {...props}
    />
  );
};

export default AdditionalSecurityTextArrayField;
