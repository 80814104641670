import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import moment from 'moment';

import { Message } from 'components/Messages'

import GlobalActions from 'actions/GlobalActions'

class Notifications extends React.Component {
  
  componentDidMount() {
    this.props.LoadNotifications();
  }

  renderNotifications() {

    var display = [];
    var key = 0;

    if( this.props.notifications.length == 0 ) 
      return <Message text='No notifications found.' />

    this.props.notifications.forEach( notification => {
      display.push(
        <NotificationItem notification={notification} key={key++} />
      )
    });

    return display;
  }

  render() {
    return(
      <div className='page'>
        <div className='page-heading'>
          <h2>NOTIFICATIONS</h2>
        </div>
        {this.renderNotifications()}
      </div>
    )
  }
}

const mapState = state => {
  return {
    notifications: state.global.notifications,
    fetching: false,
  }
}

const mapDispatch = dispatch => {
  return {
    LoadNotifications: () => {
      dispatch(GlobalActions.LoadNotifications());
    }
  }
}

class NotificationItem extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      redirect: false,
      redirect_addr: "",
    }
  }

  handleClick(e) {
    e.preventDefault();

    var notification = this.props.notification;
    var redirect = false;
    var redirect_addr = "";

    

    switch( notification.object_type ) {
      case "LOAN_REPAYMENT":
        redirect = true;
        redirect_addr = "/dashboard/loans/details/" + notification.object_id + "/repayments";    
        break;
    }

    this.setState({
      redirect: redirect,
      redirect_addr: redirect_addr,
    })
  }
  
  render() {

    var notification = this.props.notification;
    var created_at = moment(notification.created_at).fromNow();

    if( this.state.redirect ) {
      return <Redirect to={this.state.redirect_addr} />
    }

    return(
      <div className='obj-list-item notification-item' onClick={this.handleClick.bind(this)}>
        <div className='row'>
          <div className='col-sm-7'>{notification.description}</div>
          <div className='col-sm-3'>{created_at}</div>
          <div className='col-sm-2'>
            <span className='flag-icon'></span>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapState, mapDispatch)(Notifications);
