import React from 'react';

import Row from 'components/Row'
import { FormStep } from 'components/Forms'

import {
    CorporateBorrowerFields,
    ClientEmailField,
    ClientBorrowerEmailField,
    ClientBorrowerTelephoneField,
  } from "components/FormFields/Client"

const Borrower = () => (
    <FormStep subtitle="Corporate Details">
        <Row>
            <CorporateBorrowerFields.ClientCompanyNameField mr={10} />
            <ClientEmailField mr={10} />
            <CorporateBorrowerFields.ClientCountryField />
        </Row>
        <Row>
            <CorporateBorrowerFields.ClientCompanyRegistrationNumberField mr={10} />
            <CorporateBorrowerFields.ClientDateOfIncorporationField />
        </Row>
        <Row>
            <CorporateBorrowerFields.ClientRegisteredAddressField mr={10} />
            <CorporateBorrowerFields.ClientRegisteredPostcodeField />
        </Row>
        <Row>
            <CorporateBorrowerFields.ClientPostalAddressField mr={10} />
            <CorporateBorrowerFields.ClientPostalPostcodeField />
        </Row>
        <Row>
            <ClientBorrowerEmailField />
            <ClientBorrowerTelephoneField ml={10} />
        </Row>
        <Row>
            <CorporateBorrowerFields.DirectorsArrayField />
        </Row>
        <Row>
            <CorporateBorrowerFields.ClientBankrupcyField />
        </Row>
    </FormStep>
)

export default Borrower