import styled from 'styled-components'

import { SecondaryButton } from 'components/Buttons'

export default styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-right: 10px;
    flex-direction: column;

    &:last-child {
        margin: 0px;
    }
    

    ${SecondaryButton} {
        width: 100%;
    }
`