import API from 'api'

const updateTagsSubmit = () => ({ type: "FETCH/REQUEST" })
const updateTagsSuccess = data => ({ type: "FETCH/SUCCESS", data })
const updateTagsError = err => ({ type: "FETCH/ERROR", error: err})

export const updateTags = (loanId, values, callback) => dispatch => {
    dispatch(updateTagsSubmit())

    API
        .post('/api/loans/update-tags', { body: JSON.stringify({...values, loanId}) })
        .then(res => res.json())
        .then(json => { 
            dispatch(updateTagsSuccess(json))
            if(callback) callback()
        })
        .catch(err => dispatch(updateTagsError(err)))

}