import styled from 'styled-components'

import TextBase from './TextBase'

const Content = styled(TextBase)``

Content.defaultProps = {
  my: '5px',
  fontSize: '14px',
}

export default Content
