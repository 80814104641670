import React from 'react'
import { FieldArray } from 'redux-form'

import { FieldArrayHelper } from 'components/Fields'

import RenderDirectors from './RenderDirectors'

const DirectorsArrayField = ({
    fields,
    ...props
}) => {
    return(
        <FieldArray
            name="directors"
            component={FieldArrayHelper}
            renderFields={RenderDirectors}
            minFields={1}
            {...props}
        />
    )
}

export default DirectorsArrayField