import React from 'react'

import Row from 'components/Row'
import Panel from 'components/Forms/Panel'

import * as RepaymentFields from 'components/FormFields/Repayments'

const ExtensionSpecification = () => (
  <Panel title='Repayment Fields'>
    <Row>
      <RepaymentFields.RepaymentAmountField mr={10} />
      <RepaymentFields.RepaymentDateField />
    </Row>
    <Row mt={10}>
      <RepaymentFields.InterinInterestField />
    </Row>
  </Panel>
)

export default ExtensionSpecification