import React from 'react'

import { SecondaryButton } from 'components/Buttons'

import { priceFormatter } from 'helpers/Helpers'

import GenericListItem from '../GenericListItem'

const RecentEntryListItem = ({
    _id,
    amount,
    type,
    notes,
    user,
}) => {
    return(
        <GenericListItem>
            <div className='col-sm-4'>
                <div>
                    <strong>{user.first_name} {user.last_name}</strong>
                    <span className='details'>{notes}</span>
                </div>
            </div>
            <div className='col-sm-3'>{type.replaceAll("_", " ").toLowerCase()}</div>
            <div className='col-sm-2'>£{priceFormatter(amount.toFixed(2))}</div>
            <div className='col-sm-3 text-right'>
                <SecondaryButton to={`/dashboard/wallet/edit/${_id}`}>EDIT</SecondaryButton>
            </div>
        </GenericListItem>
    )
}

export default RecentEntryListItem