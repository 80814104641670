import { Field as ReduxField } from 'redux-form/immutable'
import { compose, withPropsOnChange, defaultProps } from 'recompose'
import _noop from 'lodash/noop'

import { validateRequired } from 'utils/validators'

import { TextField } from 'components/Fields'

export default compose(
  defaultProps({
    component: TextField,
  }),
  withPropsOnChange(
    ['required', 'validators', 'component'],
    ({ required, validators = [] }) => ({
      validate: [required ? validateRequired : _noop, ...validators],
    }),
  ),
)(ReduxField)
