import React from 'react';
import {Link} from 'react-router-dom';

import UserPicture from './UserPicture';

class UserAccountActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    }
  }

  toggleMenu() {
    this.setState({
      showMenu: !this.state.showMenu,
    })
  }

  renderOptions() {
    if( !this.state.showMenu )
      return null;

    return(
      <div className='user-account-actions-menu'>
        <ul>
          <li><Link to='/dashboard'>My Profile</Link></li>
          <li><a href='/'>Logout</a></li>
        </ul>
      </div>
    )
  }

  render() {
    return(
      <div className='user-account-actions'>
        <div className='toggle'>
          <div className='user-actions'>
            <Link className='action-icon messages-toggle' to='/dashboard/messages' />
            <Link className='action-icon notifications-toggle' to='/dashboard/notifications' />
          </div>
          <div onClick={this.toggleMenu.bind(this)}>
            <UserPicture />
          </div>
        </div>
        {this.renderOptions()}
      </div>
    )
  }
}

export default UserAccountActions;
