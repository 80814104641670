import React from 'react'
import Switch from "react-switch";
import { compose, withProps, defaultProps, withHandlers } from 'recompose'

import { TextField, NumberField } from 'components/Fields'

const SwitchField = ({
    label,
    numericInput,
    showDetails,
    currentValue,
    input,
    name,
    justLabel,
    onChange,
    onChangeDetails,
    onDetailsBlur,
    onDetailsFocus,
    disabled,
    testFocus,
    ...props
}) => {
    const hasValue = input && input.value || currentValue
    const value = input && input.value ? 
            input.value: currentValue

    const detailsName = `${name}Details`;
    const checked = hasValue ? value.checked : false
    const detailsDisabled = typeof showDetails == 'undefined' ? true : !showDetails(checked)

    let details = value.details
    details = !!details ? details : ""

    return(
        <div {...props} className={`boolean-switch ${justLabel ? 'just-label' : ''} ${disabled ? 'disabled' : ''}`} style={{ width: '100%', marginRight: props.mr + 'px', marginLeft: props.ml + 'px' }}>
            {!justLabel &&
                (numericInput ?
                    <NumberField 
                        full 
                        label={label}
                        input={input}
                        name={detailsName}
                        disabled={detailsDisabled} 
                        onChange={onChangeDetails}
                        onBlur={onDetailsBlur}
                        onFocus={onDetailsFocus}
                        forceValue={details}
                        {...props}
                    />
                    :
                    <TextField
                        full 
                        label={label} 
                        name={detailsName}
                        disabled={detailsDisabled} 
                        onChange={onChangeDetails}
                        onBlur={onDetailsBlur}
                        onFocus={onDetailsFocus}
                        value={details}
                        {...props}
                    />
                )
            }
            {justLabel && 
                <label>{label}</label>
            } 
            <Switch
                className='switch'
                onColor="#5fcc5f"
                onChange={disabled ? () => {} : onChange}
                checked={checked}
            />
        </div>
    )
}

export default compose(
    defaultProps({
        numericDetails: false,
        justLabel: false,
        currentValue: {
            checked: false,
            details: "",
        }
    }),
    withProps(({ checked, input, details, onChange }) => ({
        onChange: input ? input.onChange : onChange,
    })),
    withHandlers({
        onDetailsBlur: props => event => {
            if( props.input ) {
                let value = props.input.value
                props.input.onBlur({
                    ...value,
                    details: event.target.value
                })
            }
        },
        onDetailsFocus: props => event => {
            if( props.input ) {
                let value = props.input.value
                props.input.onFocus({   
                    ...value,
                    details: event.target.value 
                })
            }
        },
        onChangeDetails: props => event => {
            let value = props.input.value
            if( props.input ) {
                props.input.onChange({
                    ...value,
                    details: event.target.value 
                })
            }
        },
        onChange: props => event => {
            let value = props.input.value
            if( props.input ) {
                props.input.onChange({
                    details: value ? value.details : "",
                    checked: event,
                })
            }
        }
    })
)(SwitchField)