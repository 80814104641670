import React from 'react'
import moment from 'moment'
import { compose } from 'recompose'

import { priceFormatter } from 'helpers/Helpers'

import { SecondaryButton } from 'components/Buttons'
import Spinner from 'components/globals/Spinner'

import SendReviewReminderContainer from 'containers/Loan/Reviews/SendReminder'

import GenericListItem from '../GenericListItem'

const ReviewLoanListItem = ({
  id,
  client,
  reviewDate,
  grossAmount,
  netAmount,
  managedBy,
  isSendingReminder,
  handleSendReminder,
}) => {
  return(
    <GenericListItem>
      <div className='col-sm-2'>
        {moment(reviewDate).format("DD/MM/YYYY")}
      </div>
      <div className='col-sm-3'>
        <strong>{client.entityName}</strong>
      </div>
      <div className='col-sm-2'>
        &pound;{priceFormatter(grossAmount.toFixed(2))}
      </div>
      <div className='col-sm-3'>
        {`${managedBy.first_name} ${managedBy.last_name}`}
      </div>
      <div className='col-sm-2'>
        <SecondaryButton disabled={isSendingReminder} style={{ marginRight: 5 }} onClick={handleSendReminder}>
          TEST
          {isSendingReminder && <Spinner />}
        </SecondaryButton>
        <SecondaryButton to={`/dashboard/loans/details/${id}`}>VIEW</SecondaryButton>
      </div>
    </GenericListItem>
  )
}

export default compose(
  SendReviewReminderContainer,
)(ReviewLoanListItem)