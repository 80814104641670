import { compose, withProps, withHandlers } from 'recompose'

import { Field } from 'components/FormFields'
import { TextAreaField } from 'components/Fields'

export default compose(
  withProps(() => ({
    component: TextAreaField,
  })),
)(Field)
