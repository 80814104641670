import React from 'react'
import styled from 'styled-components'

const Subtitle = styled.h3`
    font-size: 18px;
    color: #666;
    text-align: center;
    margin: 20px 0;
`


const FormStep = ({
    index,
    title,
    subtitle,
    children,
}) => (
    <div className='new-client-step-outer step-1 new-loan-step'>
        {title && 
            <h2 className='new-client-step-title'><span>{title}</span></h2>
        }
        {subtitle &&
            <Subtitle>{subtitle}</Subtitle>
        }
        {children}
    </div>
)

export default FormStep