import React from 'react'
import { compose, withProps, withState, withHandlers } from 'recompose'

import Popup from 'components/globals/Popup'
import { SecondaryButton, PrimaryButton } from 'components/Buttons'

import RemoveTagContainer from 'containers/Tags/RemoveTag'

import GenericListItem from '../GenericListItem'

const TagListItem = ({
    _id,
    name,
    type,
    description,
    togglePopup,
    popupVisible,
    handleRemove,
}) => {
    return(
        <GenericListItem>
            <div className='col-sm-4'>
                <strong>{name}</strong>
            </div>
            <div className='col-sm-4'>{description}</div>
            <div className='col-sm-2'>{type}</div>
            <div className='col-sm-2 text-right'>
                <SecondaryButton to={`/dashboard/settings/tags/edit/${_id}`}>EDIT</SecondaryButton>
                <SecondaryButton onClick={togglePopup} justText darkGray>REMOVE</SecondaryButton>
            </div>
            {popupVisible && 
                <Popup 
                    {...{
                    handleToggle: togglePopup,
                    button: 
                        <div>
                            <PrimaryButton onClick={handleRemove} style={{marginRight: 10}}>Confirm</PrimaryButton>
                            <PrimaryButton onClick={togglePopup} darkGray>Cancel</PrimaryButton>
                        </div>
                    }}>
                    <h3>Confirm Removal</h3>
                    <p>Are you sure you want to remove this tag?</p>
                </Popup>
            }
        </GenericListItem>
    )
}

export default compose(
    RemoveTagContainer,
    withState('popupVisible', 'setPopupVisible', false),
    withProps(({ _id, popupVisible, setPopupVisible }) => ({
        togglePopup: () => {
            setPopupVisible(!popupVisible)
        }
    })),
    withHandlers({
        handleRemove: ({ togglePopup, removeTag, _id, onComplete }) => () => {
            removeTag(_id, () => {
                if(onComplete) onComplete()
            })
        }
    })
)(TagListItem)