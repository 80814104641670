import React from 'react';
import { compose } from 'recompose'

import { Page } from 'pages'

import AddClientContainer from 'containers/Client/AddClient'

import NewClientActionsBar from './NewClientActionsBar'
import NewClientProgress from './NewClientProgress'
import NewClientSteps from './views/NewClientSteps'

const AddClient = ({
    step,
    hideActions,
    isCreating,
    redirectUrl,
    ...props
}) => (
    <Page title="Create New Client" className='new-loan'>
        {!isCreating && <NewClientProgress />}
        <NewClientSteps step={step} {...{ isCreating, redirectUrl, ...props }} />
        {!isCreating && <NewClientActionsBar {...props} />}
    </Page>
)

export default compose(
  AddClientContainer,
)(AddClient);
