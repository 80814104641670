import React from 'react'
import { compose } from 'recompose'

import Page from 'components/Page'

import ViewClientContainer from 'containers/Client/ViewClient'

import ClientTabNavigation from './views/ClientTabNavigation'
import ClientTabs from './views/ClientTabs'

const ViewClient = ({ id, ...props }) => {
    return(
      <Page title='Client Overview'>
        {/* <ClientTabNavigation  {...{ id, ...props}} /> */}
        <ClientTabs {...{ id, ...props }} />
      </Page>
    )
}

export default compose(
  ViewClientContainer,
)(ViewClient)