import React from 'react'

import { COLORS } from 'consts'

import { Content, H2 } from 'components/Texts'

const quoteStyles = { borderLeft: `2px solid ${COLORS.lightGray2}` }

export default fontSize => ({ attributes, children, node }, editor, next) => {
  switch (node.type) {
    case 'paragraph':
      return (
        <Content {...{ ...attributes, fontSize }} block light>
          {children}
        </Content>
      )
    case 'title':
      return (
        <H2 {...{ ...attributes, fontSize }} m="5px 0 12px" block>
          {children}
        </H2>
      )
    case 'block-quote':
      return (
        <Content
          {...{ ...attributes, fontSize }}
          style={quoteStyles}
          pl="12px"
          my="12px"
          color={COLORS.lightGray2}
          block
          light
        >
          {children}
        </Content>
      )
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'numbered-list':
      
      return <ol {...attributes}>{children}</ol>
    case 'list-item':
      return (
        <Content {...{ fontSize }} block light>
          <li {...attributes}>{children}</li>
        </Content>
      )
    default:
      return next()
  }
}
