import _set from 'lodash/set'

let _state = {
  clients: [],
  loans: [],
  users: [],
  professionals: [],
  professional: null,
  templates: [],
  tags: [],
  user_types: [],
  settings: {},
  notifications: [],
  tag_created_callback: null,
  fetching: false,
  total_clients: 0,
  total_loans: 0,
  total_notifications: 0,
  total_users: 0,
  total_templates: 0,
  edit_template: null,
  wallet_entries: [],
  total_funds_available: 0,
  total_deposits: 0,
  total_withdraws: 0,
  new_notifications: 0,
  
  loanDetails: null,
  client_details: null,

  loan_checks: [],

  due_payments: [],
  recent_payments: [],

  fetching_settings: false,
  loan_settings_update_success: false,
  generating_templates: false,
  generation_progress: 0,
  
  pipeline: [],
  show_redemption_popup: false,
};

var dotProp = require('dot-prop-immutable');

const mapClients = (clients = []) => {
  return clients.map(client => {
    client.getName = () => {
      if( client.corporate_details.company_name.trim().length > 0 )
        return client.corporate_details.company_name;
      else 
        return client.applicants[0].first_name + " " + client.applicants[0].last_name;
    };

    return client;
  });
}

const GlobalState = (state = _state, action) => {
  switch(action.type) {

    case "LOAD_CLIENTS_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      });

    case "LOAD_CLIENTS_END":
      return Object.assign({}, state, {
        fetching: false,
        clients: [...mapClients(action.clients)],
      });

    case "GLOBAL_INIT_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
        fetching_settings: true,
      });

    case "GLOBAL_INIT_END":
      var stats = action.data;

      return Object.assign({}, state, {
        fetching: false,
        fetching_settings: false,
        settings: stats.settings,
        total_clients: stats.clients,
        total_loans: stats.loans,
        total_notifications: stats.notifications,
        total_users: stats.users,
        total_templates: stats.templates,
      });

    case "CREATE_LOAN_END":
      return Object.assign({}, state, {
        total_loans: state.total_loans + 1,
      });

    case "LOAD_LOANS_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      })

    case "LOAD_LOANS_END":
      return Object.assign({}, state, {
        fetching: false,
        loans: action.loans,
      });
    
    case "LOAD_TEMPLATES_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      })

    case "LOAD_TEMPLATES_END":
      return Object.assign({}, state, {
        fetching: false,
        templates: action.templates,
      });

    case "CREATE_TAG_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
        tag_created_callback: action.callback,
      })  
    
    case "CREATE_TAG_END":
      if( state.tag_created_callback != null )
        state.tag_created_callback(action.tag);

      return Object.assign({}, state, {
        fetching: false,
      })

    case "SEARCH_TAGS_BEGIN":
      return Object.assign({}, state, {
        
      })

    case "SEARCH_TAGS_END":
      return Object.assign({}, state, {
        tags: action.tags,
      })

    case "CREATE_TEMPLATE_BEGIN": 
      return Object.assign({}, state, {
        fetching: true,
      })

    case "FETCH/REQUEST": 
      return Object.assign({}, state, {
        fetching: true,
      })

    case "FETCH/SUCCESS":
      let _state = Object.assign({}, state, {
        fetching: false,
      })
      if(action.customReducer) {
       _state = action.customReducer(_state)
      } else if( action.key ) {
        _state = _set(_state, action.key, action.data)
      } else {
        _state = Object.assign({}, _state, {
          ...action.data
        })
      }
      return _state

    case "FETCH/ERROR":
        return Object.assign({}, state, {
          ...action.error,
          fetching: false,
        })
  
    case "CREATE_TEMPLATE_END": 
      return Object.assign({}, state, {
        fetching: false,
      })

    case "EDIT_TEMPLATE_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      })

    case "EDIT_TEMPLATE_END":
      return Object.assign({}, state, {
        fetching: false,
        edit_template: action.template,
      })

    case "UPDATE_TEMPLATE_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      })

    case "UPDATE_TEMPLATE_END":
      return Object.assign({}, state, {
        fetching: false,
        edit_template: action.template,
      })

    case "LOAD_WALLET_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      })

    case "LOAD_WALLET_END":
      return Object.assign({}, state, {
        wallet_entries: action.entries,
        fetching: false,
      });
    
    case "CREATE_WALLET_ENTRY_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      })

    case "CREATE_WALLET_ENTRY_END":
      var entries = [].concat(state.wallet_entries);
      entries.unshift(action.entry);

      return Object.assign({}, state, {
        fetching: false,
        wallet_entries: entries,
      })
  
    case "LOAD_WALLET_BALANCE_START":
      return Object.assign({}, state, {
        fetching: true,
      })

    case "LOAD_WALLET_BALANCE_END":
      return Object.assign({}, state, {
        fetching: false,
        total_funds_available: action.result.total_funds_available,
        total_withdraws: action.result.total_withdraws,
        total_deposits: action.result.total_deposits,
      })
    
    case "LOAD_LOAN_DETAILS_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      })

    case "LOAD_LOAN_DETAILS_END":
      return Object.assign({}, state, {
        fetching: false,
        loan_details: action.loan,
      })

    case "LOAD_USERS_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      })

    case "LOAD_USERS_END":
      return Object.assign({}, state, {
        users: action.users,
        fetching: false,
      })

    case "LOAD_USER_TYPES_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      })
    
    case "LOAD_USER_TYPES_END":
      return Object.assign({}, state, {
        user_types: action.user_types,
        fetching: false,
      })

    case "UPDATE_LOAN_TEMPLATES_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
        generating_templates: true,
      })

    case "UPDATE_LOAN_TEMPLATES_END":
      return Object.assign({}, state, {
        fetching: false,
      })

    case "ACTIVATE_LOAN_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      })

    case "ACTIVATE_LOAN_END":
      return Object.assign({}, state, {
        fetching: false,
        loan_details: action.loan,
      })

    case "MAKE_REPAYMENT_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      })

    case "MAKE_REPAYMENT_END":
      
      var loan = Object.assign({}, state.loan_details);
      var installments = [].concat(loan.installments);
      var installment = action.installment;

      if( installment ) {
        var index = installments.findIndex(el => {
          return el._id == installment._id;
        });
        if( index >= 0 ) {
          installments[index] = installment;
          var m_state = dotProp.set(state, `loan_details.installments`, installments);
          return Object.assign({}, m_state, {
            fetching: false,
          })
        }
        // check all extensions now 
        let extIndex = 0
        let instIndex = -1
        loan.extensions.forEach( extension => {
          instIndex = extension.installments.findIndex(el => { return el._id === installment._id })
          if( instIndex >= 0 )
            return
          extIndex++
        })
        if( instIndex >= 0 ) {
          var extensions = [].concat(loan.extensions)
          extensions[extIndex].installments[instIndex] = installment;
          var m_state = dotProp.set(state, `loan_details.extensions`, extensions);
          return Object.assign({}, m_state, {
            fetching: false,
          })          
        }
      }
      return state;

    case "CONFIRM_EXTENSION_BEGIN":
      return Object.assign({}, state, {
        fetching: false,
      })

    case "CONFIRM_EXTENSION_END":
      var loan = Object.assign({}, state.loan_details);
      var extensions = [].concat(loan.extensions);
      extensions.push(action.extension);
      loan.extensions = extensions;

      return Object.assign({}, state, {
        loan_details: loan,
      })

    case "LOAD_LOAN_FILES_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      })

    case "LOAD_LOAN_FILES_END":
      var loan_details = Object.assign({}, state.loan_details);
      var loan_files = [].concat(loan_details.files);
      
      if( loan_details._id == action.id ) {
        loan_files.concat(action.results);
        loan_details.files = loan_files;

        var m_state = dotProp.set(state, `loan_details.files`, action.results);
        m_state.fetching = false;
        return m_state;
      }
      return state;
    
    case "LOAD_DUE_PAYMENTS_START":
      return Object.assign({}, state, {
        fetching: true,
      })

    case "LOAD_DUE_PAYMENTS_END":
      return Object.assign({}, state, {
        fetching: false,
        due_payments: action.due_payments,
      })

    case "LOAD_RECENT_PAYMENTS_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      })

    case "LOAD_RECENT_PAYMENTS_END":
      return Object.assign({}, state, {
        fetching: false,
        recent_payments: action.recent_payments,
      })

    case "LOAD_CLIENT_DETAILS_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      });

    case "LOAD_CLIENT_DETAILS_END":
      return Object.assign({}, state, {
        fetching: false,
        client_details: action.client_details,
      });

    case "LOAD_CHECKS_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      });

    case "LOAD_CHECKS_END":
      return Object.assign({}, state, {
        fetching: false,
        loan_checks: action.loan_checks,
      });
      
    case "CONFIRM_CHECK_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      })

    case "CONFIRM_CHECK_END":
      var checks = [].concat(state.loan_details.checks);
      checks.push(action.check);
      var m_state = dotProp.set(state, `loan_details.checks`, checks);
      return m_state;

    case "LOAD_NOTIFICATIONS_BEGIN":
      return Object.assign({}, state, {
        fetching: true,
      });
    
    case "LOAD_NOTIFICATIONS_END":
      return Object.assign({}, state, {
        notifications: action.notifications,
        fetching: false,
      });

    case "NEW_NOTIFICATION":
      var notifications = [].concat(state.notifications);
      notifications.unshift(action.notification);

      var new_notifications = state.new_notifications + 1;
      return Object.assign({}, state, {
        notifications: notifications,
        new_notifications: new_notifications,
      });

    case "TEMPLATE_GENERATION_END":
      return Object.assign({}, state, {
        generating_templates: false,
        generation_progress: 0,
      })

    case "TEMPLATE_GENERATION_PROGRESS":
      return Object.assign({}, state, {
        generation_progress: action.progress,
      })
    
    case "SHOW_REDEMPTION_POPUP":
      return Object.assign({}, state, {
        show_redemption_popup: action.flag,
      })

    case "NET_ADVANCE_END":
        var loan = Object.assign({}, state.loan_details);
        var netAdvance = [].concat(loan.netAdvance);
        netAdvance.push(action.result);
        loan.netAdvance = netAdvance;

        return Object.assign({}, state, {
          loan_details: loan,
          fetching: false,
        })

    default:
      return state;
  }
}

export default GlobalState;
