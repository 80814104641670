import React from 'react'

import Row from 'components/Row'
import { Panel } from 'components/Forms'

import { LoanCalculatedFields } from 'components/FormFields/Loan'

const LoanCalculations = () => {
    return(
        <Panel title="Loan Calculations">
            <Row>
                <LoanCalculatedFields.LoanLTVField mr={10} />
                <LoanCalculatedFields.LoanDepositField />
            </Row>
            <Row>
                <LoanCalculatedFields.LoanTotalFeeField mr={10} />
                <LoanCalculatedFields.LoanTotalExitField />
            </Row>
            <Row>
                <LoanCalculatedFields.LoanTotalInterestField mr={10} />
                <LoanCalculatedFields.LoanTotalCompoundedInterestField />
            </Row>
            <Row>
                <LoanCalculatedFields.LoanMonthlyPaymentField mr={10}/>
                <LoanCalculatedFields.LoanNetAmountField mr={10} />
                <LoanCalculatedFields.LoanTotalCostField />
            </Row>
        </Panel>
    )
}

export default LoanCalculations