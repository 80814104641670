import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import * as qs from 'query-string';
import taiPasswordStrength from 'tai-password-strength';

import {validateEmail} from '../../helpers/Helpers';
import API from 'api';

const strengthTester = new taiPasswordStrength.PasswordStrength();
strengthTester.addCommonPasswords(taiPasswordStrength.commonPasswords);
strengthTester.addTrigraphMap(taiPasswordStrength.trigraphs);

const Steps = {
  Checking: 0,
  NewPassword: 1,
  Confirmation: 2,
  Success: 3,
}

const PasswordStrengths = {
  "VERY_WEAK" : 1,
  "WEAK" : 2,
  "REASONABLE" : 3,
  "STRONG" : 4,
  "VERY_STRONG" : 5,
};


export default class PasswordResetMod extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: Steps.Checking,
      password: "",
      passwordStrengthLabel: null,
      passwordStrength: 0,
      password_confirm: "",
      show_error: false,
      show_password_strength: false,
      submitted: false,
      fetching: false,
    };
  }

  componentDidMount() {

    var params = qs.parse(window.location.search);

    API.post('/api/user/password-reset-check', { body: JSON.stringify({ token: params.token })})
      .then( res => { return res.json() })
      .then( json => {

        if( json.success ) {
          this.setState({
            step: Steps.NewPassword,
          });
        } else {
          this.setState({
            step: Steps.Error,
            show_error: true,
            error: json.error,
          })
        }
      })
  }

  handlePrev() {
    var step = this.state.step - 1;
    this.setState({
      step: step,
      show_error: false,
    })
  }

  handleNext() {

    this.setState({
      submitted: true,
    });

    var validPassword = this.state.password.trim().length >= 8;
    var validPasswordConfirm = this.state.password_confirm.trim().length >= 8;
    var passwordsMatch = this.state.password == this.state.password_confirm;

    if( validPassword && validPasswordConfirm && passwordsMatch ) {
      // call api to change this password
      var params = qs.parse(window.location.search);

      this.setState({
        fetching: true,
      });

      API.post('/api/user/password-reset', { body: JSON.stringify({ token: params.token, password: this.state.password })})
        .then( res => { return res.json() })
        .then( json => {

          if( json.success ) {
            this.setState({
              step: Steps.Success,
              fetching: false,
            });
          } else {
            this.setState({
              step: Steps.NewPassword,
              show_error: true,
              error: json.error,
              fetching: false,
            })
          }
        })
    }
  }

  handleChangePasswordConfirm(e) {
    var value = e.target.value;
    this.setState({
      password_confirm: value,
    });
  }

  handleChangePassword(e) {
    var value = e.target.value;
    // strength test password
    var results = strengthTester.check(value);
    var passwordStrengthLabel = results.strengthCode;
    var passwordStrength = PasswordStrengths[passwordStrengthLabel];

    this.setState({
      password: value,
      passwordStrengthLabel: passwordStrengthLabel,
      passwordStrength: passwordStrength,
    });
  }

  renderPasswordStrength() {
    if( this.state.password.trim().length > 0 && this.state.show_password_strength) {

        var progress = this.state.passwordStrength * 20;
        var label = this.state.passwordStrengthLabel.replace("_", " ");

        return(
          <div className='password-strength'>
            <div className='password-strength-label'>
              <p><strong>Password strength: </strong> <span>{label}</span></p>
            </div>
            <div className='password-strength-progress'>
              <div className='password-strength-progress-bar' style={{ width: progress + "%" }}></div>
            </div>
            <div className='password-strength-tips'>
              <p>Use at least 8 characters. Don{"'"}t use a password from another site, or something too obvious like your pet{"'"}s name.</p>
            </div>
          </div>
        )
    }
    return null;
  }

  renderError(fieldName) {

    if( !this.state.submitted )
      return;

    if( fieldName == "password" ) {
      if( this.state.password.trim().length == 0 ) {
        return(
          <div className='error'>
           <p>This field cannot be empty.</p>
          </div>
        )
      }

      if( this.state.password.trim().length < 8 ) {
        return(
          <div className='error'>
           <p>Password must be minimum 8 characters long.</p>
          </div>
        )
      }
    }

    if( fieldName == "password_confirm" ) {
      if( this.state.password_confirm.trim().length == 0) {
        return(
          <div className='error'>
           <p>This field cannot be empty.</p>
          </div>
        )
      }

      if( this.state.password_confirm != this.state.password )
       return(
         <div className='error'>
          <p>Password not matching the field above</p>
         </div>
       )
    }
  }

  renderStep() {
    switch(this.state.step) {

      case Steps.Checking:
        return(
          <div className='loading-panel'>
            <div className='spinner'></div>
          </div>
        );
        break;

      case Steps.NewPassword:
        return(
          <div className='form-group password-reset-fields'>
            <h2>Password Reset</h2>
            <p>Enter your email below then check your inbox for steps to reset your password.</p>
            {this.renderPasswordStrength()}
            {this.renderError("password")}
            <input type='password' onBlur={e => { this.setState({ show_password_strength: false })}} onFocus={e => {this.setState({show_password_strength: true}) }} autoComplete="nope" onChange={this.handleChangePassword.bind(this)} value={this.state.password} placeholder='New Password' />
            {this.renderError("password_confirm")}
            <input type='password' autoComplete="nope" onChange={this.handleChangePasswordConfirm.bind(this)} value={this.state.password_confirm} placeholder='Confirm New Password' />
          </div>
        );
        break;

      case Steps.Error:
        return(
          <div className='form-group'>
            <p>Please make sure the link matches exactly the link in email we sent you. You can also try resetting your password again to get new email.</p>
            <div className='form-group login-actions recovery-actions'>
              <Link className='btn btn-back' to='/'>Cancel</Link>
            </div>
          </div>
        );
        break;

      case Steps.Success:
        return(
          <div className='form-group'>
            <p>Your password has been changed sucessfully, you may now login.</p>
          </div>
        );
        break;
    }
    return null;
  }

  renderActions() {

    if( this.state.step == Steps.Success ) {
      return(
        <div className='form-group login-actions recovery-actions'>
          <Link className='btn btn-blue' to='/'>Sign In</Link>
        </div>
      )
    }

    if( this.state.step != Steps.NewPassword )
      return null;

    return(
      <div className='form-group login-actions recovery-actions'>
        <Link className='btn btn-back' to='/'>Cancel</Link>
        <a className={'btn btn-blue' + (this.state.fetching ? ' disabled' : '')} disabled={this.state.fetching} onClick={this.handleNext.bind(this)}>
          {this.state.fetching ? "Please Wait" : "Next"}
        </a>
      </div>
    )
  }

  render() {
    return(
      <div className='mod mod-login mod-password-reset'>

        {this.state.show_error &&
          <div className='error'>
            <p>{this.state.error}</p>
          </div>
        }

        <form autoComplete='off'>
          {this.renderStep()}
          {this.renderActions()}
        </form>

      </div>
    )
  }
}
