import { compose, withProps } from 'recompose'

import { SwitchField } from 'components/FormFields'

export default compose(
    withProps(({ applicant, ...props }) => ({
        name: `${applicant}.paydayLoans`,
        justLabel: true,
        wrapperConfig: { width: '100%' },
        label: "Do you have any payday loans?",
    })),
)(SwitchField)
