import React from 'react'
import moment from 'moment'

import NoteListItemWrapper from './NoteListItemWrapper'

export default ({
  message,
  status,
  createdBy,
  createdAt,
}) => {
  return(
    <NoteListItemWrapper>
      <div className='author'>
        <div>
          {createdBy.first_name} {createdBy.last_name}
          <span className='date'>{moment(createdAt).format('DD/MM/YYYY, HH:mm')}</span>
        </div>
        <span className={`status ${status}`}>{status}</span>
      </div>
      <div className='message'>{message}</div>
    </NoteListItemWrapper>
  )
}