import { compose, withProps } from 'recompose'

import { withFormValuesOnChange } from 'components/HOC'
import { PriceField } from 'components/FormFields'
import { AddRepaymentForm } from 'containers/Loan/AddRepayment/consts'

export default compose(
    withProps(() => ({
        name: 'newGrossAmount',
        label: "New Gross Loan Amount",
        prependNumber: "£",
        readOnly: true,
    })),
    withFormValuesOnChange(
        AddRepaymentForm, 
        [
            'grossAmount', 
            'amount',
        ],
        ({ grossAmount, amount, ...rest }) => {
            
            return parseFloat(grossAmount) + parseFloat(amount)
        }
    ),
)(PriceField)