import moment from 'moment'
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withFormValuesOnChange } from 'components/HOC'
import { NumericField } from 'components/FormFields'
import { AddLumpSumForm } from 'containers/Loan/AddLumpSum/consts'

import { selectTerms, selectExtensions } from 'containers/Loan/Common/selectors'

const mapState = createStructuredSelector({
    extensions: selectExtensions,
    terms: selectTerms,
})

export default compose(
    connect(mapState),
    withProps(() => ({
        name: 'remainingTerms',
        label: "Remaining Terms",
        prependNumber: "£",
        readOnly: true,
    })),
    withFormValuesOnChange(
        AddLumpSumForm, 
        ['date'],
        ({ date }, { terms, extensions }) => {
            let remainingTerms = []
            if( extensions && extensions.length > 0 )
                remainingTerms = [...extensions[extensions.length - 1].terms]
            else remainingTerms = [...terms]
            // filter the list so only not fully paid are left and start after the date
            remainingTerms = remainingTerms.filter(term => {
                return !term.fullyPaid && moment(term.periodStart).isAfter(moment(date))
            })
            return remainingTerms.length
        }
    ),
)(NumericField)