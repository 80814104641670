import React from 'react'
import { compose } from 'recompose'

import Popup from 'components/globals/Popup'
import { PrimaryButton } from 'components/Buttons'
import AddNetAdvanceContainer from 'containers/Loan/AddNetAdvance'

import NetAdvanceSpecification from './views/NetAdvanceSpecification';

const NetAdvancePopup = ({
  handleSubmit,
  ...props
}) => {
  return(
    <Popup button={<PrimaryButton onClick={handleSubmit}>SAVE</PrimaryButton>} {...props}>
      <NetAdvanceSpecification />
    </Popup>
  )
}

export default compose(
    AddNetAdvanceContainer,  
)(NetAdvancePopup)