import { connect } from 'react-redux'
import { reduxForm, submit } from 'redux-form'
import { compose, withProps } from 'recompose'
import { bindActionCreators } from 'redux'

import { withFormValues, withRedirect } from 'components/HOC'

import * as actions from './actions'
import { FORM_NAME } from './consts'

const mapDispatch = dispatch => ({ 
    ...bindActionCreators(actions, dispatch),
    submitForm: () => dispatch(submit(FORM_NAME)),
})

export default compose(
    connect(null, mapDispatch),
    withProps({
        redirectUrl: '/dashboard/wallet/'
    }),
    withRedirect,
    reduxForm({
        form: FORM_NAME,
        onSubmit: (values, dispatch, { addWalletEntry, setRedirecting }) => {
            addWalletEntry(values, () => {
                setRedirecting(true)
            })
        }
    }),
    withFormValues(FORM_NAME),
)