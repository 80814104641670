import React from 'react'

import { ClientSteps } from 'reducers/NewClientState'

import NewClientBorrower from './NewClientBorrower'
import NewClientApplicationDetails from './NewClientApplicationDetails'
import NewClientConfirmation from './NewClientConfirmation'
import NewClientType from './NewClientType'

const NewClientSteps = ({
    step,
    ...props
}) => (
    <div className='new-loan-steps'>
        {step == ClientSteps.ClientType && <NewClientType {...props} />}
        {step == ClientSteps.Borrower && <NewClientBorrower {...props} />}
        {step == ClientSteps.ApplicationDetails && <NewClientApplicationDetails {...props} />}
        {step == ClientSteps.Confirmation && <NewClientConfirmation {...props} />}
    </div>
)

export default NewClientSteps