import React from 'react'

import RepaymentListItem from 'components/ListItems/RepaymentListItem'

import RepaymentsListItemsHeaders from './RepaymentsListItemsHeaders'

export default ({
    extension,
    toggleRepaymentPopup,
    setRepaymentInitialValues,
}) => {
    return(
        <React.Fragment>
          <RepaymentsListItemsHeaders />
          {extension.terms.map(data => (
            <RepaymentListItem 
              {...{
                key: data._id,
                handleClick: (id, amount) => {
                  setRepaymentInitialValues(id, amount)
                  toggleRepaymentPopup()
                },
                ...data,
              }}
            />
          ))}
        </React.Fragment>
    )
}