import React, { Component } from 'react';

import LoginMod from '../../components/auth/LoginMod';

class LandingPage extends Component {
  render() {
    return (
      <div id='landing' className='page'>
        <div className='container'>
          <LoginMod />
        </div>
      </div>
    );
  }
}

class LandingProducts extends React.Component {
  constructor(props) {
    super(props);
    
  }

  render() {
    return(
      <div className='landing-products'>
        <div className='row'>
          <div className='lp-prod-item col-md-4'>
            <span className='lp-prod-icon'></span>
            <h4><strong>Reward</strong> Scheme</h4>
            <a>+ more</a>
          </div>

          <div className='lp-prod-item col-md-4'>
            <span className='lp-prod-icon'></span>
            <h4><strong>Holiday</strong> Planner</h4>
            <a>+ more</a>
          </div>

          <div className='lp-prod-item col-md-4'>
            <span className='lp-prod-icon'></span>
            <h4><strong>KPI</strong> Log</h4>
            <a>+ more</a>
          </div>
        </div>
      </div>
    )
  }
}

export default LandingPage;
