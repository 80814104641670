import styled from 'styled-components'

import TextBase from './TextBase'

const H2 = styled(TextBase)`
  letter-spacing: auto;
`

H2.defaultProps = {
  fontSize: '20px',
  lineHeight: '24px',
}

export default H2
