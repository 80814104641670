import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import { SwitchField } from 'components/FormFields'

export default compose(
    withProps(({ applicant, ...props }) => ({
        name: `${applicant}.courtJudgement`,
        justLabel: true,
        wrapperConfig: { width: '100%' },
        label: "Have you ever had a County Court judgement (or decrees) registered against you?",
    })),
)(SwitchField)
