import API from 'api'

const createExtensionSubmit = () => ({ type: "FETCH/REQUEST" })
const createExtensionSuccess = data => ({ type: "FETCH/SUCCESS", data })
const createExtensionError = err => ({ type: "FETCH/ERROR", error: err})

export const createExtension = (loanId, formValues, callback) => dispatch => {
    dispatch(createExtensionSubmit())

    API
        .post('/api/loans/extend', { body: JSON.stringify({...formValues, loanId}) })
        .then(res => res.json())
        .then(json => { 
            dispatch(createExtensionSuccess(json.extension)) 
            if(callback) callback(json.extension)
        })
        .catch(err => dispatch(createExtensionError(err)))

}