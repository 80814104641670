import React from 'react'
import { PropTypes } from 'prop-types'

import InputWrapper from '../InputWrapper'
import TextFieldInput from './TextFieldInput'

const TextField = ({
  autoFocus,
  disabled,
  input,
  large,
  label,
  nonFocusable,
  placeholder,
  readOnly,
  type,
  value,
  ...props
}) => {
  return(
    <InputWrapper
      hasValue={!!((input && input.value) || value)}
      wrapperHeight={large ? '80px' : '57px'}
      {...{ ...props, label, large, disabled, placeholder }}>
      <TextFieldInput
        {...{
          ...input,
          autoFocus,
          disabled,
          placeholder,
          readOnly: readOnly || nonFocusable,
          type,
          nonFocusable,
          large,
          withLabel: !!label,
          value: value ? value : (input ? input.value : ""),
        }}
        {...props}
      />
    </InputWrapper>
  )
}

TextField.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  large: PropTypes.bool,
  meta: PropTypes.object,
  nonFocusable: PropTypes.bool,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
}

export default TextField
