import styled from 'styled-components'

export default styled.div`
  font-weight: 300;
  color: #FFFFFF;

  h4 {
    margin: 0;
    padding: 5px 15px;
    font-weight: 300;
    margin-top: 20px;
  }

  h2 {
    margin: 0;
    padding: 5px 15px;
    color: #E8FF61;
    font-weight: 400;
    font-size: 16px;
  }

  ul {
    padding: 0;
    list-style: none;
    margin: 0;
    top: 97px;
    position: absolute;
    left: 0px;
    width: 100%;

    li {
      a {
        display: block;
        padding: 10px 15px;
        color: #eee;
        font-weight: 200;
        border-bottom: 1px solid #9797974f;
        font-size: 14px;

        &.active {
          background: #9797974f;
          font-weight: 300;
        }

        &:hover {
          background: #97979724;
        }
      }

      &:last-child {
        a {
          border: none;
        }
      }
    }
  }

`