import React from 'react'
import { connect } from 'react-redux'
import { Portal } from 'react-portal'
import { branch, compose, wrapDisplayName } from 'recompose'

import Spinner from './Spinner'
import { isFetchingSelector } from './selectors'

const withSpinner = WrappedComponent => {
    const Wrapper = ({ isFetching, ...props }) => [
        <WrappedComponent key='component' {...props} />,
        isFetching ? <Spinner key='spinner' /> : null,
    ]

    if (process.env.NODE_ENV !== 'production') {
        Wrapper.displayName = wrapDisplayName(WrappedComponent, 'withSpinner')
    }

    return compose(
        connect(isFetchingSelector)
    )(Wrapper)
}

export default withSpinner
