import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom'

import logo from '../../images/logo.svg';

import PasswordResetMod from '../../components/auth/PasswordResetMod';

class PasswordResetPage extends Component {
  render() {
    return (
      <div id='login-page' className='page full-page'>
        <div className='inner'>
          <PasswordResetMod />
        </div>
      </div>
    );
  }
}

export default PasswordResetPage;
