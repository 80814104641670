import styled from 'styled-components'
import close from 'images/close.svg'

export default styled.div`
    position: absolute;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    width: 100%;
    min-width: 500px;
    min-height: 100px;
    transition: transform 200ms ease-out, opacity 100ms ease-in;

    .tooltip-content {
        border-radius: 10px;
        background: #fff;
        position: relative;
        padding: 15px;
        z-index: 2;
        overflow: hidden;
    }

    &:after {
        content: "";
        position: absolute;
        bottom: -10px;
        z-index: 2;
        right: 175px;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        border-top: 10px solid #fff;
    }

    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        top: 10px;
        left: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        box-shadow: 0 0 50px 0px rgba(0, 0, 0, 0.35);

    }


    .tooltip-close {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 15px;
        top: 15px;
        background: url(${close});
        background-size: contain;
        cursor: pointer;
    }

    ${props => props.visible && `
        visibility: visible;
        opacity: 1;
        z-index: 999;
        pointer-events: all;

        ${props => props.display && props.display == 'right' && `
            right: 0px;
            top: 50%;
            transform: translate(0%, -50%);
        `}

        ${props => props.display && props.display == 'top' && `
            left: 50%;
            top: 0px;
            transform: translate(-50%, 0%);
        `}
    `}

    ${props => props.display == 'right' && `
        right: 0px;
        top: 50%;
        transform: translate(100%, -50%);
    `}

    ${props => props.display && props.display == 'top' && `
        left: 50%;
        top: 0px;
        transform: translate(-50%, -100%);
    `}
`
