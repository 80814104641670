import API from 'api'

const getChecksSubmit = () => ({ type: "FETCH/REQUEST" })
const getChecksSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getChecksError = err => ({ type: "FETCH/ERROR", error: err})

export const getChecks = () => dispatch => {
    dispatch(getChecksSubmit())

    API
        .get('/api/checks')
        .then(res => res.json())
        .then(json => { 
            dispatch(getChecksSuccess(json)) 
        })
        .catch(err => dispatch(getChecksError(err)))
}