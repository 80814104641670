import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import { SwitchField } from 'components/FormFields'

const validators = [validateRequired]

export default compose(
    withProps(() => ({
        name: 'isMezzanine',
        wrapperConfig: { width: '100%' },
        label: "Is Mezzanine?",
        justLabel: true,
    })),
)(SwitchField)
