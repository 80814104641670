import React from 'react'

import { COLORS } from 'consts'

export function CodeBlock(props) {
  const { editor, node } = props
  const language = node.data.get('language')

  function onChange(event) {
    editor.setNodeByKey(node.key, { data: { language: event.target.value } })
  }

  return (
    <div style={{ position: 'relative' }}>
      <pre>
        <code {...props.attributes}>{props.children}</code>
      </pre>
    </div>
  )
}

export function CodeBlockLine(props) {
  return <div {...props.attributes}>{props.children}</div>
}

export default fontSize => (props, editor, next) => {
  switch (props.node.type) {
    case 'code':
      return <CodeBlock {...props} />
    case 'code_line':
      return <CodeBlockLine {...props} />
    default:
      return next()
  }
}
