import { compose, withProps } from 'recompose'

import { withFormValuesOnChange } from 'components/HOC'
import { PriceField } from 'components/FormFields'
import { AddLoanForm } from 'containers/Loan/AddLoan/consts'

export default compose(
    withProps(() => ({
        name: 'deposit',
        label: "Deposit",
        prependNumber: "£",
        readOnly: true,
    })),
    withFormValuesOnChange(
        AddLoanForm, 
        ['grossAmount', 'mpPrice'],
        ({ grossAmount, mpPrice }) => {
            let total = parseFloat(mpPrice)
            total -= parseFloat(grossAmount)
            
            if(total < 0)
                total = 0
            return total
        }
    ),
)(PriceField)