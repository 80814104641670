import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png';

export default class Logo extends React.Component {

  render() {
    return(
      <div className='cultcha-logo'>
        <Link to='/dashboard'>
          <img src={logo} />
        </Link>
      </div>
    );
  }
}
