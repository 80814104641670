import React from 'react';

import { FormStep } from 'components/Forms'

import { AdditionalSecurityPropertiesArrayField, AdditionalSecurityTextArrayField } from 'components/FormFields/Loan'

const AdditionalSecurity = () => (
    <FormStep title="Additional Security">
        <AdditionalSecurityPropertiesArrayField />
        <AdditionalSecurityTextArrayField />
    </FormStep>
)

export default AdditionalSecurity