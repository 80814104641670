import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose'
import { Link, NavLink } from 'react-router-dom';
import { createStructuredSelector } from 'reselect'

import {Spacer} from '../globals/UI';
import WalletActions from '../../actions/WalletActions';
import UserContainer from 'containers/User/ListUsers'

import ListPipelineContainer from 'containers/Loan/ListPipeline'
import ListRecentEntriesContainer from 'containers/Wallet/ListRecentEntries'
import ListDuePaymentsContainer from 'containers/Loan/ListDuePayments'

const numberWithCommas = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

class SystemOverview extends React.Component {

  componentDidMount() {
    this.props.LoadBalance();
  }

  render() {

    const { 
      awaitingChecks,
      closedLoans,
      activeLoans,
      user,
      pipeline,
      entries,
      due_payments,
    } = this.props


    let pipelineAmount = 0
    if(pipeline)
      pipeline.forEach(p => { pipelineAmount += parseFloat(p.currentGrossAmount)})
    let recentAmount = 0
    if(entries)
      entries.forEach(p => { recentAmount += parseFloat(p.amount)})
    let dueAmount = 0
    if(due_payments)
      due_payments.forEach(p => { dueAmount += parseFloat(p.currentGrossAmount)})

    return(
      <div className='system-overview'>
        <div className='welcome-user'>
          <h2>{user && user.first_name && `Welcome, ${user.first_name}`}.</h2>
        </div>
        <div className='available-funds'>
          <div className='available-funds-amount'>
            <NavLink  to='/dashboard/due-payments'>
              {/* £{numberWithCommas(this.props.total_funds_available.toFixed(2))} */}
              <h3>Due Payments</h3>
              <h4>&pound;{numberWithCommas(dueAmount.toFixed(2))}</h4>
              {/* &pound;{numberWithCommas(this.props.)} */}
            </NavLink>
          </div>
        </div>
        <Spacer />
        <div className='money-out'>
          <div className='money-out-amount'>
            <NavLink  to='/dashboard/recent-transactions'>
              {/* £{numberWithCommas(this.props.total_withdraws.toFixed(2))} */}
              <h3>Recent Transactions</h3>
              <h4>&pound;{numberWithCommas(recentAmount.toFixed(2))}</h4>
            </NavLink>
          </div>
        </div>
        <Spacer />
        <div className='money-out'>
          <div className='money-out-amount'>
            <NavLink  to='/dashboard/pipeline'>
              {/* £{numberWithCommas(this.props.total_withdraws.toFixed(2))} */}
              <h3>Pipeline</h3>
              <h4>&pound;{numberWithCommas(pipelineAmount.toFixed(2))}</h4>
            </NavLink>
          </div>
        </div>
        <Spacer />
        <div className='general-info'>
          <div className='general-info-item'>
            <div>
              <span className='ri active-loans-quantity'>{activeLoans}</span>
              <span>Active Loans</span>
            </div>
            {/* <Link to='/dashboard/loans'>Manage</Link> */}
          </div>
          <Spacer />
          <div className='general-info-item'>
            <div>
              <span className='ri active-loans-tba'>{awaitingChecks}</span>
              <span>Awaits Action</span>
            </div>
            {/* <Link to='/dashboard/loans'>Manage</Link> */}
          </div>
          <Spacer />
          <div className='general-info-item'>
            <div>
              <span className='ri redeemed-loans'>{closedLoans}</span>
              <span>Redeemed Loans</span>
            </div>
            {/* <Link to='/dashboard/loans'>Manage</Link> */}
          </div>
        </div>
      </div>
    )
  }
}

const mapState = createStructuredSelector({
  loans: state => state.global.loans,
  due_payments: state => state.global.due_payments,
  recent_transactions: state => state.global.recent_transactions,
  pipeline: state => state.global.pipeline,
  total_funds_available: state => state.global.total_funds_available,
  total_withdraws: state => state.global.total_withdraws,
  activeLoans: state => state.global.pipeline.filter(l => l.isActive == true).length,
  awaitingChecks: state => state.global.pipeline.filter(l => l.isChecked == false).length,
  closedLoans: state => state.global.pipeline.filter(l => l.isClosed).length,
  user: state=> state.global.user,
})

const mapDispatch = dispatch => {
  return {
    LoadBalance: () => {
      dispatch(WalletActions.LoadBalance());
    } 
  }
}

export default compose(
  UserContainer,
  ListPipelineContainer,
  ListRecentEntriesContainer,
  ListDuePaymentsContainer,
  connect(mapState, mapDispatch)
)(SystemOverview);
