import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import LandingPage from '../pages/landing/LandingPage'
import LandingHeader from '../pages/landing/LandingHeader'
import AllowAccess from '../pages/auth/AllowAccess'
import RegisterPage from '../pages/auth/RegisterPage'
import PasswordRecoveryPage from '../pages/auth/PasswordRecoveryPage'
import PasswordResetPage from '../pages/auth/PasswordResetPage'
import Dashboard from '../pages/dashboard'
import ReviewLoan from '../pages/client/ReviewLoan'
import DashboardHeader from '../components/dashboard/DashboardHeader'

import API from 'api'
import UserActions from '../actions/UserActions'

class Root extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    // check for logged in User
    API.get('/api/auth/me')
      .then(res => { return res.json() })
      .then(json => {
        const { user } = json
        if(user) { 
          this.props.InitUser(user)
        }
      })
      .catch(ex => {
        
      })
  }

  render() {
    return(
      <Router>
        <div>
          <Switch>
            <Route path="/(login|sign-up)" component={null} />
            {this.props.user != null && <Route path="/dashboard" component={DashboardHeader} />}
            <Route path="/" component={LandingHeader} />
          </Switch>
          <Route exact path="/" component={LandingPage} />
          <Route withRouter exact path="/allow-access" component={AllowAccess} />
          <Route exact path="/sign-up" component={RegisterPage} />

          {this.props.user != null && <Route path="/dashboard" component={Dashboard} />}

          <Route path="/review-loan/:id" component={ReviewLoan} />

          <Route exact path="/password-recovery" component={PasswordRecoveryPage} />
          <Route withRouter exact path="/reset-password" component={PasswordResetPage} />
        </div>
      </Router>
    )
  }
}

const mapState = (state) => {
  return {
    user: state.user.user,
  }
}

const mapDispatch = (dispatch) => {
  return {
    InitUser: (user) => {
      dispatch(UserActions.InitUser(user))
    }
  }
}

export default connect(mapState, mapDispatch)(Root)
