import { compose, withProps } from 'recompose'

import { withFormValuesOnChange } from 'components/HOC'
import { PriceField } from 'components/FormFields'
import { AddLoanForm } from 'containers/Loan/AddLoan/consts'

export default compose(
    withProps(() => ({
        name: 'totalExitAmount',
        label: "Total Exit Amount",
        prependNumber: "£",
        readOnly: true,
    })),
    withFormValuesOnChange(
        AddLoanForm, 
        ['grossAmount', 'exitRate'],
        ({ grossAmount, exitRate }) => {
            return parseFloat(grossAmount) * 0.01 * parseFloat(exitRate)
        }
    ),
)(PriceField)