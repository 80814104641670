import React from 'react'

import RedemptionInfoPanel from '../RedemptionInfoPanel'
import RedemptionStatementEntry from './RedemptionStatementEntry'
import RedemptionAmountEntry from './RedemptionAmountEntry'
import RedemptionBalance from './RedemptionBalance'

const RedemptionStatementAccountBalance = ({
    statement,
}) => {
    if(!statement) return null

    const { history, balance, redemption } = statement

    let key = 0

    return(
        <React.Fragment>
            <RedemptionInfoPanel>
                <h3>Statement Of Account</h3>
                {history.map(({ timestamp, entries }) => 
                    entries.map(entry => <RedemptionStatementEntry key={key++} {...entry}/>)
                )}
            </RedemptionInfoPanel>
            <RedemptionBalance {...balance} />
            <RedemptionInfoPanel>
                <h3 style={{ marginBottom: 15 }}>Redemption Amounts in the period {redemption.periodStart} to {redemption.periodEnd}</h3>
                {redemption.entries.map(entry => 
                    <RedemptionAmountEntry key={key++} {...entry} />
                )}
            </RedemptionInfoPanel>
            
       </React.Fragment>
    )  
}

export default RedemptionStatementAccountBalance