import React from 'react'
import { compose } from 'recompose'

import Page from 'components/Page'

import ReviewLoanContainer from 'containers/Client/ReviewLoan'
import Panel from 'components/globals/Panel'

import RequestLoanAccess from './views/RequestLoanAccess'
import ReviewDetails from './views/ReviewDetails'

const ReviewLoan = ({ 
  id, 
  isAuthorized, 
  ...props 
}) => {
    return(
      <div className='container'>
        <Panel>
          <Page title='Review Loan'>
            {!isAuthorized && <RequestLoanAccess {...{ id }} />}
            {isAuthorized && <ReviewDetails {...props } />} 
          </Page>
        </Panel>
      </div>
    )
}

export default compose(
  ReviewLoanContainer,
)(ReviewLoan)