import { compose, lifecycle, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

import ListTemplatesContainer from 'containers/Templates/ListTemplates'

const validators = [validateRequired]

export default compose(
  ListTemplatesContainer,
  withProps(({ templates, ...props }) => ({
      placeholder: 'Select Template',
      name: 'templateId',
      wrapperConfig: { width: '100%' },
      component: DropdownField,
      label: "Template",
      isClearable: true,
      options: templates && templates.map(p => {
        return {
          value: p._id,
          label: p.name,
        }
      }),
      full: true,
      validators,
  })),
)(Field)
