import React from 'react';
import {connect} from 'react-redux';

class UserPicture extends React.Component {
  render() {
    var user = this.props.user;
    var image = user.profile_image_url != null &&
                   user.profile_image_url.length > 0 ?
                      user.profile_image_url : false;
    return(
      <div className='user-picture'>
        {image !== false &&
          <img src={image} />
        }
      </div>
    );
  }
}

const mapState = (state) => {
  return {
    user: state.user.user,
  }
}

export default connect(mapState)(UserPicture)
