import React from 'react'

import { PrimaryButton } from 'components/Buttons'

import DocumentMediaImage from './DocumentMediaImage'
import DocumentUploadMessageWrapper from './DocumentUploadMessageWrapper'

const DocumentUploadMessage = () => (
    <DocumentUploadMessageWrapper>
        <DocumentMediaImage />
        <p>Upload files by dragging them into this area or by clicking the button below.</p>
        <PrimaryButton>UPLOAD</PrimaryButton>
    </DocumentUploadMessageWrapper>
)

export default DocumentUploadMessage