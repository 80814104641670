import React from 'react'
import { Switch, Route } from 'react-router-dom'

import LoanDetails from './views/Details'
import LoanProfessionals from './views/Professionals'
import ClientInfo from './views/ClientInfo'
import Extensions from './views/Extensions'
import Repayments from './views/Repayments'
import Documents from './views/Documents'
import Templates from './views/Templates'
import Redemption from './views/Redemption'
import Checklist from './views/Checklist'

const LoanTabs = ({
    loan,
}) => (
    <div className='loan-sub-page'>
        <Switch>
            <Route exact path='/dashboard/loans/details/:id/' component={LoanDetails} />
            <Route exact path='/dashboard/loans/details/:id/professionals' component={LoanProfessionals} />
            <Route exact path='/dashboard/loans/details/:id/client' render={ routeProps => {
                return <ClientInfo {...{ ...routeProps, client: loan.client }} />
            }} />
            <Route exact path='/dashboard/loans/details/:id/extensions' component={Extensions} />
            <Route exact path='/dashboard/loans/details/:id/repayments' component={Repayments} />
            <Route exact path='/dashboard/loans/details/:id/redemption' component={Redemption} />
            <Route exact path='/dashboard/loans/details/:id/templates' component={Templates} />
            <Route exact path='/dashboard/loans/details/:id/checklist' component={Checklist} />
            <Route path='/dashboard/loans/details/:id/documents' render={ routeProps => {
                return <Documents {...{ routeProps, objectId: routeProps.match.params.id }} />
            }}/>
            
        </Switch>
    </div>
)

export default LoanTabs