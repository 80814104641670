import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

const validators = [validateRequired]

const purposeOptions = [
    { value: 'Purchase', label: 'Purchase' },
    { value: 'Refinance', label: 'Refinance' },
    { value: 'Equity Release', label: 'Equity Release' },
]

export default compose(
    withProps(() => ({
        name: 'loanPurpose',
        wrapperConfig: { width: '100%' },
        component: DropdownField,
        placeholder: 'Select Loan Purpose',
        label: "Purpose of Loan",
        isClearable: true,
        full: true,
        options: purposeOptions,
        validators,
    })),
)(Field)
