import { compose, withProps, withState } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm, submit } from 'redux-form'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import { withRedirect } from 'components/HOC'

import { FormName } from './consts'
import * as actions from './actions'
import { selectFormValues } from './selectors'

const mapState = createStructuredSelector({
  formValues: selectFormValues,
})

const mapDispatch = dispatch => ({
  ...bindActionCreators(actions, dispatch),
})

export default compose(
    connect(mapState, mapDispatch),
    withState('redirectUrl', 'setRedirectUrl', ''),
    withRedirect,
    reduxForm({
      form: FormName,
      onSubmit: (values, dispatch, { createCheck, setRedirectUrl, setRedirecting }) => {
        createCheck(values, (check) => {
          setRedirectUrl(`/dashboard/settings/checklist`)
          setRedirecting(true)
        })
      }
    }), 
)