import API from 'api'

const getPipelineRequest = () => ({ type: "FETCH/REQUEST" })
const getPipelineSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getPipelineError = err => ({ type: "FETCH/ERROR", error: err})

export const getPipeline = () => dispatch => {
    dispatch(getPipelineRequest())

    API
        .get(`/api/loans/pipeline`)
        .then(res => res.json())
        .then(json => { dispatch(getPipelineSuccess(json)) })
        .catch(err => dispatch(getPipelineError(err)))
}