import _get from 'lodash/fp/get'
import { createSelector } from 'reselect'

export const selectLoanStatus = createSelector(
    state => state.global,
    (state, props) => props.loanId,
    (global, loanId) => { 
        return _get(`loan_${loanId}.status`, global) 
    }
)
