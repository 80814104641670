import API from 'api'


const removeFileSubmit = () => ({ type: "FETCH/REQUEST" })
const removeFileSuccess = data => ({ type: "FETCH/SUCCESS", data })
const removeFileError = err => ({ type: "FETCH/ERROR", error: err})

export const removeFile = (id, callback) => dispatch => {
    dispatch(removeFileSubmit())

    API
        .get(`/api/docs/delete?id=${id}`)
        .then(res => res.json())
        .then(json => { 
            dispatch(removeFileSuccess()) 
            if(callback) callback()
        })
        .catch(err => dispatch(removeFileError(err)))

}

const uploadDocumentSubmit = () => ({ type: "FETCH/REQUEST" })
const uploadDocumentSuccess = data => ({ type: "FETCH/SUCCESS", data })
const uploadDocumentError = err => ({ type: "FETCH/ERROR", error: err})

export const uploadDocument = (objectId, files, callback) => dispatch => {
    dispatch(uploadDocumentSubmit())

    API
        .post('/api/docs/upload', { body: JSON.stringify({...files, objectId}) })
        .then(res => res.json())
        .then(json => { 
            dispatch(uploadDocumentSuccess(json.extension)) 
            if(callback) callback(json.extension)
        })
        .catch(err => dispatch(uploadDocumentError(err)))

}

const getDocumentsSubmit = () => ({ type: "FETCH/REQUEST" })
const getDocumentsSuccess = (data, key) => ({ type: "FETCH/SUCCESS", key, data })
const getDocumentsError = err => ({ type: "FETCH/ERROR", error: err})

export const getDocuments = (objectId, callback) => dispatch => {
    dispatch(getDocumentsSubmit())

    API
        .get(`/api/docs/find?id=${objectId}`)
        .then(res => res.json())
        .then(json => { 
            dispatch(getDocumentsSuccess(json.documents, `documents_${objectId}`)) 
            if(callback) callback(json)
        })
        .catch(err => dispatch(getDocumentsError(err)))
}

export const downloadFile = (file, callback) => dispatch => {

    API
        .post("/api/docs/download", { body: JSON.stringify({ id: file._id }) } )
        .then( res => { return res.blob() })
        .then( blob => {         
        var newBlob = new Blob([blob])
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob)
            if( callback ) callback()
            return
        } 
        const data = window.URL.createObjectURL(newBlob);
        let link = document.createElement('a');
        link.href = data
        link.download = file.name
        link.click()

        setTimeout(function(){
            window.URL.revokeObjectURL(data);
            if( callback ) callback()
        }, 100)
    })
}


const createSignatureRequestSubmit = () => ({ type: "FETCH/REQUEST" })
const createSignatureRequestSuccess = data => ({ type: "FETCH/SUCCESS", data })
const createSignatureRequestError = err => ({ type: "FETCH/ERROR", error: err})

export const createSignatureRequest = (loanId, values, callback) => dispatch => {
    dispatch(createSignatureRequestSubmit())

    API
        .post(`/api/loans/signature/create?id=${loanId}`, { body: JSON.stringify(values)})
        .then(res => res.json())
        .then(json => { 
            dispatch(createSignatureRequestSuccess(json))
            if(callback) callback()
        })
        .catch(err => dispatch(createSignatureRequestError(err)))

}