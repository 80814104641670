import { connect } from 'react-redux'
import { reduxForm, submit } from 'redux-form'
import { bindActionCreators } from 'redux'
import { compose, lifecycle, withState } from 'recompose'
import { createStructuredSelector } from 'reselect'

import { FORM_NAME } from './consts'
import * as actions from './actions'
import { selectGlobalTags, selectInitialValues } from './selectors'  

const mapState = createStructuredSelector({
    globalTags: selectGlobalTags,
    initialValues: selectInitialValues,
})

const mapDispatch = dispatch => ({
    ...bindActionCreators(actions, dispatch),
    submitForm: () => submit(FORM_NAME),
})

export default compose(
    withState('isUpdating', 'setUpdating', false),
    connect(mapState, mapDispatch),
    reduxForm({
        form: FORM_NAME,
        onSubmit: (values, dispatch, { setUpdating, updateGlobalTags }) => {
            setUpdating(true)
            updateGlobalTags(values, () => {
                setTimeout(() => setUpdating(false), 1000)
            })
        },
        enableReinitialize: true,
    }),
    lifecycle({
        componentDidMount() {
            const { getGlobalTags } = this.props
            getGlobalTags()
        }
    }),
)