import { compose, withProps, withState, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm, submit, registerField, change } from 'redux-form'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import { ClientType } from 'reducers/NewClientState'

import { withFormValues } from 'components/HOC'

import * as actions from './actions'
import { ReviewLoanForm } from './consts'
import { selectLoanForReview } from './selectors'

const mapState = createStructuredSelector({
  reviewLoan: selectLoanForReview,
})

const mapDispatch = dispatch => ({
  registerApplicantsDetails: () => dispatch(registerField(ReviewLoanForm, 'applicantsDetails', "FieldArray")),
  updateApplicantsDetails: value => dispatch(change(ReviewLoanForm, 'applicantsDetails', value)),
  submitForm: form => dispatch(submit(form)),
  ...bindActionCreators(actions, dispatch),
})

export default compose(
    connect(mapState, mapDispatch),
    withProps(({ match, reviewLoan, submitForm }) => ({
      id: match.params.id,
      isAuthorized: reviewLoan != null,
      handleSubmit: () => {
        submitForm(ReviewLoanForm)
      }
    })),
    withFormValues(ReviewLoanForm),
    lifecycle({
      componentDidMount() {
        const { getLoanAccess, id } = this.props

        getLoanAccess(id)
      },
      componentDidUpdate(prevProps, prevState) {
        const { 
          updateApplicantsDetails, 
          registerApplicantsDetails,
        } = this.props

        if(prevProps.reviewLoan != this.props.reviewLoan) {
          const loan = this.props.reviewLoan
          
          if(loan) {
            const client = loan.client

            if(client && !loan.signed) {
              const type = client.clientType
              const applicants = client.applicants
              const applicantsDetails = []

              applicants.forEach( applicant => {
                  applicantsDetails.push({
                      applicant: applicant,
                  })
              })

              if(type === ClientType.Personal) {
                  applicantsDetails.push({
                      applicant: client.personalDetails
                  })
              }
              registerApplicantsDetails()
              updateApplicantsDetails(applicantsDetails)
            }
          }
        }
      }
    }),
    reduxForm({
      form: ReviewLoanForm,
      onSubmit: (values, dispatch, { id, confirmLoanReview, formValues }) => {
        confirmLoanReview(id, formValues)
      },
      
      enableReinitialize: true,
    }), 
)