import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, lifecycle, withProps, withState } from 'recompose'

import * as actions from './actions'

const mapDispatch = dispatch => ({
    ...bindActionCreators(actions, dispatch)
})

export default compose(
    connect(null, mapDispatch),
    withProps(({ confirmCheckLevel, toggleConfirmPopup, id, checkId, loanId, onCheck, ...props }) => ({
        toggleCheck: (level, checked) => {
            const data = { level, loanId, checkId, checked }
            confirmCheckLevel(data)
        }
    })),
)
