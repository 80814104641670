import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { createSelector, createStructuredSelector } from 'reselect'

import _get from 'lodash/fp/get'

import { Panel } from 'components/Forms'
import { ClientType } from 'reducers/NewClientState'

import CorporateOverview from './CorporateOverview'
import PersonalOverview from './PersonalOverview'

const selectClient = createSelector(
    state => state.global,
    _get('client')
)

const mapState = createStructuredSelector({
    client: selectClient,
})

const ClientOverview = ({
    client,
    ...props
}) => {
    const { clientType, personalDetails, ...rest } = client ? client : { clientType : '' }
    
    return(
        <Panel title='Overview' expandable initiallyExpanded>
            {clientType === ClientType.Corporate && <CorporateOverview {...rest} />}
            {clientType === ClientType.Personal && <PersonalOverview {...personalDetails} {...rest} />}
        </Panel>
    )
}

export default compose(
    connect(mapState),
)(ClientOverview)