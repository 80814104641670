import styled from 'styled-components'

import binImage from 'images/close.svg'
import downloadImage from 'images/icons/download.svg'

export default styled.div`
    margin-bottom: 5px;

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    padding: 7px;

    h3 {
        margin-top: 0px;
        font-size: 12px!important;
        text-transform: none;
        font-style: normal;
        font-weight: normal;
        margin-bottom: 0px!important;
        color: #565656!important;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin-top: 10px;
        word-break: break-word;
        overflow: hidden;
    }

    span {
        font-size: 12px;
        color: #3a75ab;
    }

    .actions {
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 200ms ease-out;
        font-size: 16px;

        a { 
            opacity: 0.5;
            transition: all 100ms ease-out;
            margin-left: 5px;
            &:hover {
                opacity: 1;
            }
        }
    }

    &:hover {
        .actions {
            visibility: visible;
            opacity: 1;

            a {
                &:hover {
                    
                }
            }
        }
    }
`