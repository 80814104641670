import { compose, withProps } from 'recompose'

import { SwitchField } from 'components/FormFields'

export default compose(
    withProps(() => ({
        name: 'interinInterest',
        wrapperConfig: { width: '100%' },
        label: "Interest not serviced?",
        justLabel: true,
    })),
)(SwitchField)
