import React from 'react'

import ClientOverview from './views/ClientOverview'
import LoanOverview from './views/LoanOverview'
import MainPropertyOverview from './views/MainPropertyOverview'

import ApplicantDetails from './views/ApplicantDetails'

import * as DeclarationFields from 'components/FormFields/Loan/Declaration'

const ReviewDetails = ({
  reviewLoan,
  fetching,
  handleSubmit,
  ...props
}) => {

  
  const { client, signed, applicantsDetails } = reviewLoan

  return (
    <div>
      {signed && <h3 style={{ color: "#38b338", fontWeight: "normal" }}>Application Signed</h3>}
      {reviewLoan && <LoanOverview {... {...props.reviewLoan }} />}
      {reviewLoan && <MainPropertyOverview {... {...props.reviewLoan }} />}
      {client && <ClientOverview {...{ client }} />}
      {signed && 
        applicantsDetails.map((applicant, index) => 
          <ApplicantDetails {...{
            key: index,
            ...applicant
          }}/>
        )
      }
      {!signed && <DeclarationFields.DeclarationsArrayField />}
      {!signed && 
        <div className='form-group login-actions auth-code-actions text-right'>
            <a className='btn btn-grey btn-login' onClick={handleSubmit}>
              {fetching &&
                <span className='spinner'></span>
              }
              CONFIRM
            </a>
        </div>
      }
    </div>
  )
}

export default ReviewDetails