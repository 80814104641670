import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Scrollbars } from 'react-custom-scrollbars'

import { CloseButton } from 'components/Buttons'
import { H3 } from 'components/Texts'

import FileItemUploader from 'components/ListItems/FileItemUploader'

import DocumentsContainer from 'containers/Documents'

import DocumentUploaderWrapper from './DocumentUploaderWrapper'
import DocumentUploaderOverlay from './DocumentUploaderOverlay'
import DocumentUploaderContent from './DocumentUploaderContent'
import DocumentUploaderList from './DocumentUploaderList'
import DocumentUploadZone from './DocumentUploadZone'

const DocumentUploader = ({
    queue,
    objectId,
    documents,
    toggleUpload,
    toggleUploadOverride,
    handleUpload,
    handleComplete,
    ...props
}) => {
    const hasFiles = (documents && documents.length > 0) || queue.length > 0
    const _toggleUpload = toggleUploadOverride ? toggleUploadOverride : toggleUpload
    let index = 0

    return(
        <DocumentUploaderWrapper>
            <DocumentUploaderOverlay onClick={_toggleUpload} />
            <DocumentUploaderContent>
                <H3>
                    Media Library
                </H3>
                <CloseButton onClick={_toggleUpload}></CloseButton>
                <DocumentUploadZone {...{ handleUpload }} />
                {queue.length > 0 &&
                    <Scrollbars autoHeight autoHeightMin="165px" autoHeightMax="100%">
                        <DocumentUploaderList>
                            {queue.map(file => 
                                <FileItemUploader {...{ key: index++, file, objectId, handleComplete }} />
                            )}
                        </DocumentUploaderList>
                    </Scrollbars>
                }
            </DocumentUploaderContent>
        </DocumentUploaderWrapper>
    )
}

DocumentUploader.propTypes = {
    objectId: PropTypes.string.isRequired,
}

export default compose(
    DocumentsContainer,
)(DocumentUploader)