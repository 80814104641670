import styled from 'styled-components'
import closeBtnImage from 'images/close.svg'

export default styled.a`
    width: 20px;
    height: 20px;
    display: inline-block;
    position: absolute;
    top: 15px;
    right: 15px;
    background: url(${closeBtnImage}) no-repeat;
    background-size: contain;
    background-position: center;

`