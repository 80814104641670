import { compose, withProps, withState, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm, submit } from 'redux-form'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import { ClientSteps } from 'reducers/NewClientState'

import { FORM_NAME } from './consts'
import * as UpdateClientActions from './actions'
import { selectStep, selectFormValues, selectInitialValues } from './selectors'

const mapState = createStructuredSelector({
  step: selectStep,
  formValues: selectFormValues,
  initialValues: selectInitialValues
})

const mapDispatch = dispatch => ({
  ...bindActionCreators(UpdateClientActions, dispatch),
  submitForm: form => dispatch(submit(form)),
})

export default compose(
    connect(mapState, mapDispatch),
    withState('isUpdating', 'setUpdating', false),  
    withState('redirectUrl', 'setRedirect', ''),
    withProps(({ nextStep, prevStep, step, match, ...props }) => ({
      id: match.params.id,
      isUpdateStep: step == Object.keys(ClientSteps).length - 2,
      hasNext: step < Object.keys(ClientSteps).length - 1,
      hasPrev: step > 0,
      nextButtonText: step < Object.keys(ClientSteps).length - 2 ? "Next" : "Update",
      onNextStep: () => {
        if( step < Object.keys(ClientSteps).length - 1)
          props.submitForm(FORM_NAME)
      },
      onPrevStep: () => {
        if( step > 0 )
          prevStep()
      },
    })),
    lifecycle({
      componentDidMount() {
        const { getClient, id } = this.props
        getClient(id)
      }
    }),
    reduxForm({
      form: FORM_NAME,
      enableReinitialize: true,
      onSubmit: (values, dispatch, { id, setRedirect, nextStep, isUpdateStep, isUpdating, setUpdating, resetForm, updateClient, formValues }) => {
        if(nextStep) 
          nextStep()
        
        if(isUpdateStep && !isUpdating) {
          setUpdating(true)
          updateClient(id, formValues, (client) => {
            setRedirect(`/dashboard/clients/details/${id}`)
            setUpdating(false)
            resetForm()
          })
        }
      },
    }), 
)