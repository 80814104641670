import React from 'react'
import { compose } from 'recompose'

import Page from 'components/Page'
import Row from 'components/Row'
import { PrimaryButton } from 'components/Buttons' 

import AddTagContainer from 'containers/Tags/AddTag'
import ListTagsContainer from 'containers/Tags/ListTags'

import * as TagFields from 'components/FormFields/Tag'

const AddTag = ({
    tags,
    submitForm,
    formValues,
    ...props
}) => {
    const type = formValues ? formValues.type : null
    
    return(
        <Page title="Create New Tag">
            <Row>
                <TagFields.NameField />
            </Row>
            <Row>
                <TagFields.DescriptionField />
            </Row>
            <Row>
                <TagFields.TypeField />
            </Row>
            {type && (type == 'input' || type == 'loan' || type == 'html' || type == 'client') &&
                <Row>
                    <TagFields.FormatField />
                </Row>
            }
            {type && (type == 'input' || type == 'signature') &&
                <Row>
                    <TagFields.GlobalScopeField />
                </Row>
            }
            {type &&
                <Row>
                    {type == 'formula' && <TagFields.FormulaField {...{ tags }} />}
                    {(type == 'text' || type == 'html' || type == 'loan' || type == 'client') && <TagFields.InputField />}
                </Row>
            }
            <Row style={{ justifyContent: "flex-end" }}>
                <PrimaryButton onClick={submitForm}>CREATE</PrimaryButton>
            </Row>
        </Page>
    )
}

export default compose(
    ListTagsContainer,
    AddTagContainer,
)(AddTag)