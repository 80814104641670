import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

const validators = [validateRequired]

const occupancyOptions = [
    { value: 'Tenanted', label: 'Tenanted' },
    { value: 'Vacant', label: 'Vacant' },
    { value: 'Commercial Lease', label: 'Commercial Lease' },
]

export default compose(
    withProps(() => ({
        name: 'mpOccupancy',
        wrapperConfig: { width: '100%' },
        component: DropdownField,
        placeholder: 'Select Occupancy Type',
        label: "Occupancy Type",
        isClearable: true,
        full: true,
        options: occupancyOptions,
        validators,
    })),
)(Field)
