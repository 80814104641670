import React from 'react'

import Panel from 'components/Forms/Panel'
import Row from 'components/Row'

import * as RedemptionFields from 'components/FormFields/Loan/Redemption'

const RedemptionForm = () => {
    return(
        <Panel title="Redemption Details">
            <Row>
                <RedemptionFields.RedemptionRateField mr={10} />
                <RedemptionFields.RedemptionDateField />
            </Row>
        </Panel>
    )
}

export default RedemptionForm