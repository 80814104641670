import React from 'react';
import {connect} from 'react-redux';
import { NavLink, Link } from 'react-router-dom'
import taiPasswordStrength from 'tai-password-strength';

import GlobalActions from '../../actions/GlobalActions';
import { range } from '../../helpers/Helpers';
import API from 'api';
import { Dropdown } from '../globals/UI';
import Spinner from '../globals/Spinner';


const strengthTester = new taiPasswordStrength.PasswordStrength();
strengthTester.addCommonPasswords(taiPasswordStrength.commonPasswords);
strengthTester.addTrigraphMap(taiPasswordStrength.trigraphs);

const PasswordStrengths = {
  "VERY_WEAK" : 1,
  "WEAK" : 2,
  "REASONABLE" : 3,
  "STRONG" : 4,
  "VERY_STRONG" : 5,
};

class RegisterMod extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      email_confirm: "",
      password: "",
      password_confirm: "",
      date_of_birth: "",
      passwordStrengthLabel: null,
      passwordStrength: 0,
      show_password_strength: false,
      user_type: "",

      error: false,
      message: "",
      validating: false,
      success: false,
    };
  }

  componentDidMount() {
    this.props.LoadUserTypes();
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleChangePassword(e) {
    var value = e.target.value;
    // strength test password
    var results = strengthTester.check(value);
    var passwordStrengthLabel = results.strengthCode;
    var passwordStrength = PasswordStrengths[passwordStrengthLabel];

    this.setState({
      password: value,
      passwordStrengthLabel: passwordStrengthLabel,
      passwordStrength: passwordStrength,
    });
  }

  handleSubmit() {

    this.setState({
      validating: true,
    });

    if( !this.isValid() )
      return;

    var action = '/api/auth/register';
    var data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      password: this.state.password,
      password_confirm: this.state.password,
      type_id: this.state.user_type,
    }

    API.post( action, { method: "POST", body: JSON.stringify(data) })
      .then( res => { return res.json() })
      .then( json => {
        

        if( json.error == true ) {
          this.setState({
            error: true,
            message: json.message,
          })
        }

        if( json.registered == true ) {
          this.setState({
            validating: false,
            success: true,
            message: json.message,
          })
        }
      })
      .catch( ex => {
        
      })
  }

  isValid() {
    var requiredFields = [
      "first_name", "last_name", "email", "email_confirm", "password", "user_type",
    ];
    var valid = true;

    requiredFields.forEach(fieldName => {
      if( this.state[fieldName].trim().length == 0 )
        valid = false;

      if( fieldName == "email" || fieldName == "email_confirm" ) {
        if( !this.validateEmail(this.state[fieldName]))
          valid = false;
      }

      if( fieldName == 'email_confirm' ) {
        if( this.state[fieldName] != this.state.email )
          valid = false;
      }

      if( fieldName == "password" ) {
        if( this.state.password.trim().length < 8 ) {
          valid = false;
        }
      }
    });

    return valid;
  }

  renderPasswordStrength() {
    if( this.state.password.trim().length > 0 ) {

        var progress = this.state.passwordStrength * 20;
        var label = this.state.passwordStrengthLabel.replace("_", " ");

        return(
          <div className='password-strength'>
            <div className='password-strength-label'>
              <p><strong>Password strength: </strong> <span>{label}</span></p>
            </div>
            <div className='password-strength-progress'>
              <div className='password-strength-progress-bar' style={{ width: progress + "%" }}></div>
            </div>
            <div className='password-strength-tips'>
              <p>Use at least 8 characters. Don{"'"}t use a password from another site, or something too obvious.</p>
            </div>
          </div>
        )
    }
    return null;
  }

  renderError() {
    if( this.state.error ) {
      return(
        <div className='error'>
          <p>{this.state.message}</p>
        </div>
      );
    }
  }


  renderFieldError(fieldName) {
    if( !this.state.validating )
      return;

    if( this.state[fieldName].trim().length == 0 )
      return(
        <div className='field-error'>
          This field cannot be empty.
        </div>
      )

    if( fieldName == 'email' || fieldName == 'email_confirm' ) {
      if( !this.validateEmail(this.state[fieldName])) {
        return(
          <div className='field-error'>
            Please provide a valid email address.
          </div>
        )
      }
    }

    if( fieldName == 'email_confirm' ) {
      if( this.state[fieldName] != this.state.email ) {
        return(
          <div className='field-error'>
            Email address is not matching with previous field.
          </div>
        )
      }
    }

    if( fieldName == "password" ) {
      if( this.state.password.trim().length < 8 ) {
        return(
          <div className='field-error'>
            Password must be minimum 8 characters long.
          </div>
        )
      }
    }
  }

  renderSuccess() {
    return(
      <div className='mod mod-register mod-register-success'>
        <p>User created, automatic email will be sent to given email address with login instructions.</p>
        <div className='mod-register-actions'>
          <Link className='btn btn-blue' to='/dashboard/users/'>Back</Link>
        </div>
      </div>
    )
  }

  render() {

    var userTypesOptions = [];
    var key = 0;

    this.props.user_types.forEach(type => {
      userTypesOptions.push({
        label: type.name,
        value: type._id,
      })
    })

    if( this.props.fetching ) {
      return(
        <div className='fetching-results text-center'>
          <Spinner />
        </div>
      )
    }

    if( this.state.success ) {
      return this.renderSuccess();
    }

    return(
      <div className='mod mod-register'>

        <div className='form-group'>
          <label>First Name</label>
          {this.renderFieldError("first_name")}
          <input type='text' name='first_name' onChange={ e => { this.setState({ first_name: e.target.value })}} placeholder='' />
        </div>
        <div className='form-group'> 
          <label>Last Name</label>
          {this.renderFieldError("last_name")}
          <input type='text' name='last_name' onChange={ e => { this.setState({ last_name: e.target.value })}} placeholder='' />
        </div>
        <div className='form-group'>
          <label>Email address</label>
          {this.renderFieldError("email")}
          <input type='email' name='email' onChange={ e => { this.setState({ email: e.target.value })}} placeholder='' />
        </div>
        <div className='form-group'>
          <label>Confirm email address</label>
          {this.renderFieldError("email_confirm")}
          <input type='email' name='email' onChange={ e => { this.setState({ email_confirm: e.target.value })}} placeholder='' />
        </div>
        <div className='form-group'>
          <label>Password</label>
          {this.renderPasswordStrength()}
          {this.renderFieldError("password")}
          <input type='password'  onBlur={e => { this.setState({ show_password_strength: false })}} onFocus={e => {this.setState({show_password_strength: true}) }} autoComplete="nope" onChange={this.handleChangePassword.bind(this)} onChange={this.handleChangePassword.bind(this)} name='password' />
        </div>
        <div className='form-group'>
          <label>User Type</label>
          {this.renderFieldError("user_type")}
          <Dropdown 
            options={userTypesOptions}
            value={this.state.user_type}
            onChange={ e => {  this.setState({ user_type: e.target.value })}}
          />
        </div>

        {this.renderError()}

        <div className='form-group register-actions text-right'>
          <a className='btn btn-blue btn-login' onClick={this.handleSubmit.bind(this)}>Create</a>
        </div>

      </div>
    )
  }
}

const mapState = state => {
  return {
    fetching: state.global.fetching,
    user_types: state.global.user_types,
  }
}

const mapDispatch = dispatch => {
  return {
    LoadUserTypes: () => {
      dispatch(GlobalActions.LoadUserTypes())
    }
  }
}

export default connect(mapState, mapDispatch)(RegisterMod);
