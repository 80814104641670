import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import LoanActions from '../../actions/LoanActions';
import Loan from '../../objects/Loan';

const numberWithCommas = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}


class Pipeline extends React.Component {
  componentDidMount() {
    this.props.LoadAllLoans();
  }

  render() {
    var totalPipeline = 0;
    var display = [];
    var key = 0;

    this.props.loans.forEach(data => {
      totalPipeline += parseFloat(data.currentGrossAmount)
      
      display.push(
        <PaymentListItem loan_data={data} loan={data} />
      )
    })

    return(
      <div className='due-payments pipeline'>
        <div className='info'>
          <h2>
            <span className='flag-icon'></span>
            PIPELINE
          </h2>
          <h2 className='total-sum'>£{numberWithCommas(totalPipeline.toFixed(2))}</h2>
        </div>

        <div className='listing'>
          <div className='thead'>
            <div className='row'>
              <div className='col-sm-4'>Client Name</div>
              <div className='col-sm-2'>Loan Term</div>
              <div className='col-sm-2'>Start Date</div>
              <div className='col-sm-3'>Loan Amount</div>
            </div>
          </div>
          <div className='items'>
            {display}
          </div>
        </div>
      </div>
    )
  }
}

class PaymentListItem extends React.Component {
  render() {
    var loan = this.props.loan;
    var loan_data = this.props.loan_data;
    var client = loan_data.client; 
    var amount = loan.currentGrossAmount;
    
    var applicant = client.applicants[0]
    var client_name = client.companyName
    var loan_status = loan_data.active ? "Active" : "Awaiting";
    let loanLink = "/dashboard/loans/details/" + loan_data._id;

    return(
      <div className='payment-list-item'>
        <div className='row'>
          <div className='col-sm-4'>{client_name}</div>
          <div className='col-sm-2'>{loan_data.terms.length} months</div>
          <div className='col-sm-2'>{loan_status}</div>
          <div className='col-sm-2'>£{numberWithCommas(amount.toFixed(2))}</div>
          <div className='col-sm-2 text-right'>
            <Link to={loanLink}>
              <span className='flag-icon'></span>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}


const mapState = state => {
  return {
    loans: state.global.loans,
    fetching: state.global.fetching,
  }
}

const mapDispatch = dispatch => {
  return {
    LoadAllLoans: () => {
      dispatch(LoanActions.LoadAll())
    }
  }
}

export default connect(mapState, mapDispatch)(Pipeline);
