import styled from 'styled-components'

import { H3 } from 'components/Texts'

export default styled.div`
    position: relative;
    z-index: 1;
    width: 800px;
    height: 600px;
    background: #fff;
    box-shadow: 0 2px 5px #666;
    border-radius: 5px;
    max-width: 100%;
    padding: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    ${H3} {
        padding-top: 15px;
        font-size: 14px;
        text-transform: uppercase;
        font-style: italic;
        margin-bottom: 20px;
        color: #000;
        display: flex;
        width: auto;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;    
        margin-top: -15px;
        margin-left: -15px;
        margin-right: -15px;
        background: #eee;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
    }

`