import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux' 
import { createStructuredSelector } from 'reselect'
import { compose, withProps, lifecycle } from 'recompose'

import { DEFAULT_DATE_FORMAT } from 'consts'

import { withFormValues, withFormValuesOnChange } from 'components/HOC'

import { selectClient, selectLoan, selectCompanyName, selectStatement } from './selectors'
import * as actions from './actions'
import { FORM_NAME } from './consts'
import moment from 'moment'

const mapState = createStructuredSelector({
    loan: selectLoan,
    client: selectClient,
    companyName: selectCompanyName,
    statement: selectStatement,
    initialValues: state => ({
        interestRate: 3.0,
        statementDate: new Date(),
    }),
})

const mapDispatch = dispatch => ({
    ...bindActionCreators(actions, dispatch),
})

export default compose(
    connect(mapState, mapDispatch),
    reduxForm({
        form: FORM_NAME,
        onChange: (values, dispatch, { match: { params: { id } }, getRedemptionStatement }) => {
            getRedemptionStatement(id, values)
        }
    }),
    withFormValues(FORM_NAME),
    withProps(({ formValues: { interestRate, statementDate }, match: { params: { id } }}) => ({
        statementDateFormatted: moment(statementDate).format(DEFAULT_DATE_FORMAT),
        id,
    })),
    lifecycle({
        componentDidUpdate() {
            const { getRedemptionStatement, id, formValues } = this.props
            getRedemptionStatement(id, formValues)
        }
    })
)