import API from 'api'

const createRepaymentSubmit = () => ({ type: "FETCH/REQUEST" })
const createRepaymentSuccess = data => ({ type: "FETCH/SUCCESS", data })
const createRepaymentError = err => ({ type: "FETCH/ERROR", error: err})

export const createRepayment = (loanId, termId, formValues, callback) => dispatch => {
    dispatch(createRepaymentSubmit())

    API
        .post('/api/loans/repayment', { body: JSON.stringify({...formValues, loanId, termId}) })
        .then(res => res.json())
        .then(json => { 
            dispatch(createRepaymentSuccess(json.loan)) 
            if(callback) callback(json.loan)
        })
        .catch(err => dispatch(createRepaymentError(err)))

}