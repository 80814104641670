import React from 'react'
import { compose } from 'recompose'

import Popup from 'components/globals/Popup'
import { PrimaryButton } from 'components/Buttons'

import AddRepaymentContainer from 'containers/Loan/AddRepayment'

import RepaymentSpecification from './RepaymentSpecification'
import RepaymentCalculated from './RepaymentCalculated'

const RepaymentPopup = ({
  handleSubmit,
  ...props
}) => {
  return(
    <Popup 
      button={<PrimaryButton onClick={handleSubmit}>SAVE</PrimaryButton>}
      {...props}>
      <RepaymentSpecification />
      {/* <RepaymentCalculated /> */}
    </Popup>
  )
}

export default compose(
  AddRepaymentContainer,  
)(RepaymentPopup)