import { change, registerField } from 'redux-form'
import { connect } from 'react-redux'
import { compose, lifecycle, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

import ListUsersContainer from 'containers/User/ListUsers'

const validators = [validateRequired]

export default compose(
    ListUsersContainer,
    withProps(({ users, input, ...props  }) => ({
        wrapperConfig: { width: '100%' },
        placeholder: 'Select User',
        component: DropdownField,
        isClearable: true,
        value: input && input.value._id,
        options: users.map(user => ({
            value: user._id,
            label: `${user.first_name} ${user.last_name}`,
        })),
        full: true,
        validators,
    })),
)(Field)
