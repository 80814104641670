import styled, { css } from 'styled-components'

import { COLORS, FONTS, TRANSITIONS } from 'consts'

import TextFieldLabel from './TextFieldLabel'

export default styled.div`
  border-radius: 5px;
  background-color: ${COLORS.inputBg};
  border: 1px solid ${COLORS.lightGrayWithOpacity};
  color: ${COLORS.darkGray};
  display: flex;
  padding: 0 15px;
  height: ${props => props.wrapperHeight};
  position: relative;
  width: 100%;
  margin-bottom: 5px;

  ${props =>
    props.mr && css`
      margin-right: ${props.mr}px!important;  
  `};

  ${props =>
    props.ml && css`
      margin-left: ${props.ml}px!important;  
  `};

  &, * {
    transition: ${TRANSITIONS.input};
    font-family: ${FONTS.primary};
  }

  ${props =>
    props.hasError &&
    `
      color: ${COLORS.red};
      border-color: ${COLORS.red};

      ${TextFieldLabel} {
      color: ${COLORS.red};
      }
    `}

  ${props =>
    props.focused &&
    `
      color: ${COLORS.blue};
      border-color: ${COLORS.blue};

      ${TextFieldLabel} {
      color: ${COLORS.blue};
      }
  `}

  ${props =>
    props.disabled &&
    `
      color: ${COLORS.lightGray};
      opacity: 0.5;
      cursor: not-allowed;

      ${TextFieldLabel} {
      color: ${COLORS.lightGray};
      }
  `}

  ${props =>
    props.generated &&
    `
      background: #d8dadc;
      opacity: 0.9;
      pointer-events: none;
      border-color: #666;
  `}

  ${props => props.full && 'width: 100%'};
  ${props => props.hasList && 'cursor: pointer'};

  .TextField {
    &__input {
      justify-content: center;
      flex-direction: column;
      flex: none;
    }

    &__prepend, &__append {
      i {
        font-size: 19px;
        line-height: 21px;
      }
    }

    &__append {
      margin-left: 0px;
      position: absolute;
      right: 10px;
      width: auto;
      top: 0px;
      height: 100%;
    }

    &__prepend {
      margin-right: 8px;
    }
    
    &__list {
      display: flex;
      flex-direction: column;  
      position: absolute;
      bottom: 3px;
      left: 0;
      transform: translateY(100%);
      background: ${COLORS.inputBg};
      max-height: 140px;
      overflow-y: auto;
      width: 100%;
      z-index: 3;
      border-radius: 0 0 4px 4px;
      box-shadow: 0 0 5px #ccc;

      > button {
        justify-content: flex-start;
        padding: 0 16px;
        border: none;
        width: 100%;
        height: 28px;
        flex-shrink: 0;
        font-weight: 300;
        font-size: 16px;

        :hover {
          background: #eee;
        }
        
        &.active {
          background: ${COLORS.blue};
          color: #fff;
        }
      }
    }
  }

  ${props =>
    props.moveLabel &&
    `
    .TextField__append {
      transform: translateY(3px);
    }
  `};

  ${props =>
    props.large &&
    `
    .TextField__prepend, .TextField__append {
      i {
        font-size: 24px;
        line-height: 28px;
      }
    }
  `};

  ${props =>
    props.moveLabel &&
    props.large &&
    `
    .TextField__append {
      
    }
  `};
`
