import React from 'react'

import Row from 'components/Row'
import { Panel } from 'components/Forms'
import LoanField from 'components/Loans/LoanField'

const LoanOverview = props => {
    return(
        <Panel title='Initial Specification Overview' expandable initiallyExpanded>
            <Row>
                <LoanField path='loanPurpose' label='Purpose of Loan' />
                <LoanField path='loanExitRoute' label='Exit Route' />
                <LoanField type='date' path='startDate' label='Loan Start Date' />
            </Row>
            <Row>
                <LoanField type='number' path='grossAmount' prepend="£" label='Gross Loan Amount' />
                <LoanField type='number' path='netAmount' prepend='£' label='Net Loan Amount' />
            </Row>
            <Row>
                <LoanField type='number' path='interestRate' append='%' label='Interest Rate' />
                <LoanField type='number' path='arrangementFee' append='%' label='Arrangement Fee' />
            </Row>
            <Row>
                <LoanField type='number' path='exitRate' append='%' label='Exit Rate' />
                <LoanField type='number' path='solicitorFee' prepend='£' label='Solicitor Fee' />
            </Row>
            <Row>
                <LoanField path='terms.length' append='month(s)' label='Term' />
                <LoanField type='boolean' path='isMezzanine.checked' label='Is Mezzanine?' />
            </Row>
            <Row>
                <LoanField type='number' path='ltv' append='%' label='LTV' />
                <LoanField type='number' path='deposit' prepend='£' label='Deposit' />
            </Row>
            <Row>
                <LoanField type='number' path='totalFeeAmount' prepend='£' label='Total Arrangement Fee' />
                <LoanField type='number' path='totalExitAmount' prepend='£' label='Total Exit Amount' />
            </Row>
            <Row>
                <LoanField type='number' path='totalInterestAmount' prepend='£' label='Total Interest Amount' />
                <LoanField type='number' path='totalCompoundedInterestAmount' prepend='£' label='Total Compounded Interest Amount' />
            </Row>
            <Row>
                <LoanField type='number' path='monthlyPaymentAmount' prepend='£' label='Monthly Payment Amount' />
                <LoanField type='number' path='totalLoanCost' prepend='£' label='Total Loan Cost' />
            </Row>
        </Panel>
    )
}

export default LoanOverview