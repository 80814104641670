import styled from 'styled-components'

export default styled.a`
    padding: 5px;
    display: inline-flex;
    align-items: center;
    opacity: 0.2;

    transition: all 100ms ease-out;

    &:hover {
        opacity: 0.5;
    }

    ${props => props.active && `
        opacity: 1.0;

        &:hover {
            opacity: 1.0;
        }
    `}
`