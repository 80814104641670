import React from "react";
import { compose, withProps } from "recompose";
import { Link, useLocation, useParams } from 'react-router-dom'
import { Flex } from '@rebass/grid'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFolderOpen, faFolder } from '@fortawesome/free-solid-svg-icons'


import DocumentsContainer from "containers/Documents";
import ListChecksContainer from "containers/Checks/ListChecks";

import DocumentListItem from "components/ListItems/DocumentListItem";
import DocumentUploader from "components/DocumentsUploader";
import { SecondaryButton } from "components/Buttons";
import { Message } from "components/Messages";
import { Panel } from "components/Forms";

import DocumentsFolders from './DocumentsFolders'
import DocumentsFiles from './DocumentsFiles'
import LoanDocumentsWrapper from './LoanDocumentsWrapper'

library.add(faFolder, faFolderOpen)

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const DocumentsListing = ({
    loanId,
    documents,
    toggleUpload,
    uploadVisible,
    downloadFile,
    handleRemove,
    checks,
    ...props
}) => {
    const location = useLocation()
    const params = useParams()
    const query = useQuery()
    const folder = query.get("folder")
    
    const fullUrl = location.pathname + location.search
    const isRoot = location.search == ""
    const objectId = folder != null ? `${loanId}_${folder}` : loanId

    return (
        // <Panel
        //   button={
        //     <div>
        //       <SecondaryButton onClick={() => toggleUpload(true)}>
        //         UPLOAD
        //       </SecondaryButton>
        //     </div>
        //   }
        //   title="Uploaded Documents"
        // >
        //   {uploadVisible && (
        //     <DocumentUploader
        //       {...{ objectId, toggleUploadOverride: toggleUpload }}
        //     />
        //   )}
        //   <DocumentListingWrapper>
        //     {documents &&
        //       documents.map((doc) => (
        //         <DocumentListItem
        //           key={doc.id}
        //           {...{ ...doc, downloadFile, handleRemove }}
        //         />
        //       ))}
        //   </DocumentListingWrapper>
        //   {documents && documents.length == 0 && (
        //     <Message text="No documents found" />
        //   )}
        // </Panel>
        <LoanDocumentsWrapper>
            <Flex alignItems='flex-start'>  
                <DocumentsFolders>
                    <Link className={isRoot ? 'active' : ''} to={`/dashboard/loans/details/${loanId}/documents`}>
                        <FontAwesomeIcon icon={['fal', isRoot ? 'folder-open' : 'folder']} />
                        <span>General</span>
                    </Link>
                    {checks && checks.map((check) => {
                        const url = `/dashboard/loans/details/${loanId}/documents?folder=${check.id}`
                        const isActive = fullUrl == url
                        return(
                            <Link className={isActive ? 'active' : ''} to={url}>
                                <FontAwesomeIcon icon={['fal', isActive ? 'folder-open' : 'folder']} />
                                <span>{check.name}</span>
                            </Link>
                        )
                    })}
                </DocumentsFolders>
                <DocumentsFiles objectId={objectId} />
            </Flex>
        </LoanDocumentsWrapper>
    );    
};

export default compose(
  withProps(({ match: { params: { loanId }}}) => ({
      objectId: loanId,
      loanId,
  })),
  ListChecksContainer,
)(DocumentsListing);
