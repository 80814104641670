import React from 'react'

import PageWrapper from './PageWrapper'

const Page = ({
    title,
    buttons,
    children,
    ...props
}) => (
    <PageWrapper className='page' {...props}> 
        {(title || buttons) && 
            <div className='info page-heading'>
                <h2>
                    <span>{title}</span>
                    {buttons &&
                        <div className='page-actions'>
                            {buttons}
                        </div>
                    }
                </h2>
            </div>
        }
        <div className='page-content'>
            {children}
        </div>
    </PageWrapper>
)

export default Page