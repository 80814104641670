import React from 'react';

export default class Panel extends React.Component {
  render() {
    return(
      <div className={'panel' + (this.props.className ? ' ' + this.props.className : '')}>

        <div className='panel-inner'>
          {this.props.children}
        </div>
      </div>
    )
  }
}
