import styled from 'styled-components'

export default styled.div`
    position: relative;
    width: 100%;

    ${props => props.mr && 
    `
        margin-right: ${props.mr}px;
    `};

    ${props => props.ml && 
    `
        margin-left: ${props.ml}px;
    `};
`