import { compose, withProps, lifecycle } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

const validators = [validateRequired]

const options = [
    { value: 'Solicitor', label: 'Solicitor' },
    { value: 'Valuer', label: 'Valuer' },
    { value: 'Quantity Surveyor', label: 'Quantity Surveyor' },
]

export default compose(
    withProps(props => ({
        name: `profType`,
        placeholder: 'Select Professional Type',
        wrapperConfig: { width: '100%' },
        options: options,
        component: DropdownField,
        label: "Professional Type",
        isClearable: true,
        full: true,
        validators,
    })),
)(Field)
