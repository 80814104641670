import  React from 'react'

import Row from 'components/Row'
import Panel from 'components/Forms/Panel'

import { NetAdvanceSpecificationFields } from 'components/FormFields/NetAdvance'

const NetAdvanceSpecification = ({

}) => {
    return(
        <Panel title='Net Advance Specification'>
            <Row>
                <NetAdvanceSpecificationFields.NetAdvanceAmountField mr={10} />
                <NetAdvanceSpecificationFields.NetAdvanceDateField />
            </Row>
            <Row>
                <NetAdvanceSpecificationFields.NetAdvanceInterestRateField mr={10} />
                <NetAdvanceSpecificationFields.NetAdvanceTotalInterestField />
            </Row>
            <Row>
                <NetAdvanceSpecificationFields.NetAdvanceRemainingAmountField mr={10} />
                <NetAdvanceSpecificationFields.NetAdvanceRemainingTermsField />
            </Row>
            <Row>
                <NetAdvanceSpecificationFields.NetAdvanceMonthlyPaymentField mr={10} />
                <NetAdvanceSpecificationFields.NetAdvanceCalculateNewInterestField />
            </Row>
        </Panel>
    )
}
export default NetAdvanceSpecification