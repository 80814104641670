import React from 'react'
import { Value } from 'slate'
import { Editor } from 'slate-react'
import Html from 'slate-html-serializer'
import SoftBreak from 'slate-soft-break'
import Plain from 'slate-plain-serializer'
import CollapseOnEscape from 'slate-collapse-on-escape'
import Prism from 'prismjs'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import _filter from 'lodash/filter'
import stringSimilarity from 'string-similarity'
 
import { getFormValue, getSlateValue, getSlateContentLength } from 'utils/slate'

import initialValue from './value.json'

import { CodeBlock, CodeBlockLine } from './FormulaBlock'
import FormulaMark from './FormulaMark'
import FormulaToolbar from './FormulaToolbar'
import FormulaWrapper from './FormulaWrapper'
import TagSuggestions from './TagSuggestions'
import { tags } from './tags.mock.js'

const plugins = [SoftBreak({ shift: true }), CollapseOnEscape()]

const TAG_MENTION_NODE_TYPE = 'tagMention'
const CONTEXT_ANNOTATION_TYPE = 'mentionContext'
const SPECIAL_TAGS = {
  tag: TAG_MENTION_NODE_TYPE,
}

const getContent = (token) => {
  if (typeof token === 'string') {
    return token
  } else if (typeof token.content === 'string') {
    return token.content
  } else {
    return token.content.map(getContent).join('')
  }
}

let n = 0
function getMentionKey() {
  return `highlight_${n++}`
}

const schema = {
  inlines: {
    [TAG_MENTION_NODE_TYPE]: {
      // It's important that we mark the mentions as void nodes so that users
      // can't edit the text of the mention.
      isVoid: true,
    },
  },
}

const rules = [
  {
    serialize(obj, children) {
        if (obj.object == 'block') {
            switch (obj.type) {
                case 'code_line':
                    return <code>{children}</code>
                case 'code':
                    return <div>{children}</div>
            }
        }
    },
  },
  {
    serialize(obj, children) {
        if( obj.object == 'inline' ) {
            switch(obj.type) {
                case TAG_MENTION_NODE_TYPE:
                    return(
                        <em className='tag' data-id={obj.data.get("id")}>{obj.data.get("name")}</em>
                    );
            }
        }
    }
  },
]
const htmlSerializer = new Html({rules})
const CAPTURE_REGEX = /@(\S*)$/

function getInput(value) {
  // In some cases, like if the node that was selected gets deleted,
  // `startText` can be null.
  if (!value.startText) {
    return null
  }

  const startOffset = value.selection.start.offset
  const textBefore = value.startText.text.slice(0, startOffset)
  const result = CAPTURE_REGEX.exec(textBefore)

  return result == null ? null : result[1]
}


class Formula extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: getSlateValue(props.value || initialValue),
      tags: [],
    }

    this.editor = React.createRef()
  }

  componentDidMount() {
    const rawContent = this.props.input && this.props.input.value ? this.props.input.value.raw : null
    if(rawContent) {
      const val = Value.fromJSON(JSON.parse(rawContent));
      this.setState({
          value: val,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if(!this.state.initialized) {
      const rawContent = nextProps.input && nextProps.input.value ? nextProps.input.value.raw : null
      if(rawContent) {
        const val = Value.fromJSON(JSON.parse(rawContent));
        this.setState({
            value: val,
            initialized: true,
        })
      }
    }
  }

  onBlur = (event, editor, next) => {
    setTimeout(() => this.props.input.onBlur && this.props.input.onBlur({
      raw: JSON.stringify(this.state.value.toJSON()),
      js: this.getJSContent()
    }), 0)
    next()
  }

  onFocus = (event, editor, next) => {
    setTimeout(() => this.props.input.onFocus && this.props.input.onFocus(), 0)
    next()
  }

  getJSContent() {
    const _plain = Plain.serialize(this.state.value)
    return _plain
  }

  insertMention = tag => {
    const value = this.state.value
    const inputValue = getInput(value)
    const editor = this.editor.current

    // Delete the captured value, including the `@` symbol
    if(inputValue)
      editor.deleteBackward(inputValue.length + 1)

    const selectedRange = editor.value.selection
    
    editor
      .insertText(' ')
      .insertInlineAtRange(selectedRange, {
        data: {
          id: tag._id,
          name: tag.name,
        },
        nodes: [
          {
            object: 'text',
            leaves: [
              {
                text: `@${tag.name}`,
              },
            ],
          },
        ],
        type: TAG_MENTION_NODE_TYPE,
      })
      .focus()
  }

  onChange = change => {
    const value = change.value
    const inputValue = getInput(change.value)
    const { maxLength, onChange } = this.props

    if (inputValue !== this.lastInputValue) {
      this.lastInputValue = inputValue

      this.search(inputValue)

      const { selection } = change.value

      let annotations = change.value.annotations.filter(
        annotation => annotation.type !== CONTEXT_ANNOTATION_TYPE
      )

      if (inputValue) {
        const key = getMentionKey()

        annotations = annotations.set(key, {
          anchor: {
            key: selection.start.key,
            offset: selection.start.offset - inputValue.length,
          },
          focus: {
            key: selection.start.key,
            offset: selection.start.offset,
          },
          type: CONTEXT_ANNOTATION_TYPE,
          key: getMentionKey(),
        })
      }

      this.setState({ value: change.value }, () => {
        // We need to set annotations after the value flushes into the editor.
        this.editor.current.setAnnotations(annotations)
      })
      return
    }

    this.setState({ value: change.value })
  }

  search(searchQuery) {
    const { tags } = this.props 
    
    // We don't want to show the wrong users for the current search query, so
    // wipe them out.
    this.setState({
      tags: [],
    })

    if (!searchQuery) return

    // In order to make this seem like an API call, add a set timeout for some
    // async.
    setTimeout(() => {
      // WARNING: In a production environment you should escape the search query.
      const regex = new RegExp(`${searchQuery}`, 'gi')

      // If you want to get fancy here, you can add some emphasis to the part
      // of the string that matches.
      const result = _filter(tags, tag => {
        // if(tag.type == 'loan' || tag.type == 'client' || tag.type == 'formula')
        //   return regex.test(tag.name)
        // return false
        return regex.test(tag.name) || regex.test(tag.description)
      })
      this.setState({
        // Only return the first 5 results
        tags: result.slice(0, 20),
      })
    }, 50)
  }

  onKeyDown = (event, editor, next) => {
    const { value } = editor
    const { startBlock } = value

    if (event.key === 'Enter' && startBlock.type === 'code') {
      editor.insertText('/n')
      return
    }

    if (event.key === 'Tab') {
      event.preventDefault()
      event.stopPropagation()
      editor.insertText('  ')
      return
    }

    next()
  }

  decorateNode = (node, editor, next) => {
    const others = next() || []
    const language = 'js'
    const texts = Array.from(node.texts())
    const string = texts.map(([n]) => n.text).join('\n')
    const grammar = Prism.languages[language]
    const tokens = Prism.tokenize(string, grammar)
    const decorations = []
    let startEntry = texts.shift()
    let endEntry = startEntry
    let startOffset = 0
    let endOffset = 0
    let start = 0

    for (const token of tokens) {
      startEntry = endEntry
      startOffset = endOffset

      const [startText, startPath] = startEntry
      const content = getContent(token)
      const newlines = content.split('\n').length - 1
      const length = content.length - newlines
      const end = start + length

      let available = startText.text.length - startOffset
      let remaining = length

      endOffset = startOffset + remaining

      while (available < remaining && texts.length > 0) {
        endEntry = texts.shift()
        const [endText] = endEntry
        remaining = length - available
        available = endText.text.length
        endOffset = remaining
      }

      const [endText, endPath] = endEntry

      if (typeof token !== 'string') {
        const dec = {
          type: token.type,
          anchor: {
            key: startText.key,
            path: startPath,
            offset: startOffset,
          },
          focus: {
            key: endText.key,
            path: endPath,
            offset: endOffset,
          },
        }

        decorations.push(dec)
      }

      start = end
    }

    return [...others, ...decorations]
  }

  renderDecoration = (props, editor, next) => {
    const { children, decoration, attributes } = props

    switch (decoration.type) {  
      case 'comment':
        return (
          <span className='comment' {...attributes}>
            {children}
          </span>
        )
      case 'keyword':
        return (
          <span className='keyword' {...attributes} style={{ fontWeight: 'bold' }}>
            {children}
          </span>
        )
      case 'tag':
        return (
          <span {...attributes} className='tag' style={{ fontWeight: 'bold' }}>
            {children}
          </span>
        )
      case 'punctuation':
        return (
          <span {...attributes} style={{ opacity: '0.75' }}>
            {children}
          </span>
        )
      case 'string':
        return(
          <span {...attributes} className='string'>
            {children}
          </span>
        )
      case 'function':
          return(
            <span {...attributes} className='function'>
              {children}
            </span>
          )

      default:
        return next()
    }
  }

  renderBlock = (props, editor, next) => {
    switch (props.node.type) {
      case 'code':
        return <CodeBlock {...props} />
      case 'code_line':
        return <CodeBlockLine {...props} />
      default:
        return next()
    }
  }

  renderAnnotation(props, editor, next) {
    if (props.annotation.type === CONTEXT_ANNOTATION_TYPE) {
      return (
        // Adding the className here is important so that the `Suggestions`
        // component can find an anchor.
        <span {...props.attributes} className="mention-context">
          {props.children}
        </span>
      )
    }

    return next()
  }

  renderInline(props, editor, next) {
    const { attributes, node } = props

    if (node.type === TAG_MENTION_NODE_TYPE) {
      // This is where you could turn the mention into a link to the user's
      // profile or something.
      return <b className='tag' {...attributes}>{props.node.text}</b>
    }

    return next()
  }


  render() {
    const { disallowTitle, meta, placeholder } = this.props
    const { value } = this.state
    const { editor, onBlur, onChange, onFocus, onKeyDown } = this
    
    return (
      <FormulaWrapper
        onClick={() => {
          if (editor) {
            editor.current.focus()
          }
        }}
      >
        <FormulaToolbar
          visible={_get(meta, 'active', false)}
          editor={editor.current}
          {...{ disallowTitle, value }}
        />
        <Editor
          ref={this.editor}
          {...{
            onBlur,
            onChange,
            onFocus,
            onKeyDown,
            plugins,
            placeholder,
            value,
          }}
          renderInline={this.renderInline}
          renderBlock={this.renderBlock}
          renderAnnotation={this.renderAnnotation}
          decorateNode={this.decorateNode}
          renderDecoration={this.renderDecoration}
          schema={schema}
        />
        <TagSuggestions
          anchor=".mention-context"
          tags={this.state.tags}
          onSelect={this.insertMention}
        />
      </FormulaWrapper>
    )
  }
}

export default Formula
