import { compose, withState, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm, submit } from 'redux-form'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import { EditTemplateForm } from './consts'
import * as AddTemplateActions from './actions'
import { selectTemplate, selectTemplateId, selectInitialValues, selectIsFetching } from './selectors'

const mapState = createStructuredSelector({
  template: selectTemplate,
  templateId: selectTemplateId,
  initialValues: selectInitialValues,
  isFetching: selectIsFetching,
})

const mapDispatch = dispatch => ({
  ...bindActionCreators(AddTemplateActions, dispatch),
  submitForm: form => dispatch(submit(form)),
})

export default compose(
    connect(mapState, mapDispatch),
    withState('isUpdating', 'setUpdating', false),
    withState('redirectUrl', 'setRedirect', ''),
    reduxForm({
      form: EditTemplateForm,
      enableReinitialize: true,
      onSubmit: (values, dispatch, { templateId, setRedirect, isUpdating, setUpdating, updateTemplate }) => {
        
        setUpdating(true)
        if(!isUpdating) {
          updateTemplate(templateId, values, (template) => {
            setUpdating(false)
          })
        }
      },
    }),
    lifecycle({
      componentDidMount() {
        const { match: { params: { id }}, getTemplate } = this.props
        getTemplate(id)
      },
    })
)