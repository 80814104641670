import React from 'react'
import { compose, withProps } from 'recompose'

import ListRepaymentsContainer from 'containers/Loan/ListRepayments'

import { SecondaryButton } from 'components/Buttons'
import { Panel } from 'components/Forms'
import { Message } from 'components/Messages'

import { withLoanStatus } from 'components/HOC'

import LumpSumPopup from './views/LumpSumPopup'
import RepaymentPopup from './views/RepaymentPopup'
import NetAdvancePopup from './views/NetAdvancePopup'
import LoanRepaymentTerms from './views/LoanRepaymentTerms.js'
import LoanExtensionsRepaymentTerms from './views/LoanExtensionsRepaymentTerms'

const Repayments = ({
  terms,
  termId,
  loanId,
  extensions,
  toggleRepaymentPopup,
  repaymentPopupVisible,
  setRepaymentInitialValues,
  lumpSumPopupVisible,
  toggleLumpSumPopup,
  netAdvancePopupVisible,
  toggleNetAdvancePopup,
  ...props
}) => {
  return(
    <Panel title='Loan Repayments' button={
        <div>
          <SecondaryButton style={{marginRight: '5px'}} onClick={toggleNetAdvancePopup}>NET ADVANCE</SecondaryButton>
          <SecondaryButton onClick={toggleLumpSumPopup}>LUMP SUM PAYMENT</SecondaryButton>
        </div>
      }>
      <LoanRepaymentTerms {...{ terms, toggleRepaymentPopup, setRepaymentInitialValues }} />
      <LoanExtensionsRepaymentTerms {...{ extensions, toggleRepaymentPopup, setRepaymentInitialValues }} />
      {repaymentPopupVisible && 
        <RepaymentPopup {...{ title: "Loan Repayment", termId, loanId, handleToggle: toggleRepaymentPopup }} />
      }
      {lumpSumPopupVisible &&
        <LumpSumPopup {...{ title: "Lump Sum Payment", loanId, handleToggle: toggleLumpSumPopup }} />
      }
      {netAdvancePopupVisible &&
        <NetAdvancePopup {...{ title: "Net Advance", loanId, handleToggle: toggleNetAdvancePopup }} />
      }
    </Panel>
  )
}

export default compose(
  ListRepaymentsContainer,
  withProps(({ match: { params: { id }}}) => ({
    loanId: id,
    statusText: "In order to view this tab, loan must be active.",
  })),    
  withLoanStatus(["Active", "Closed"]),
)(Repayments)