import { compose, withProps } from 'recompose'

import { Field } from 'components/FormFields'

import { validateRequired } from 'utils/validators'
const validators = [validateRequired]

export default compose(
    withProps(() => ({
        name: 'telephone',
        label: "Telephone",
        validators,
    }))
)(Field)