import React from 'react'
import { connect } from 'react-redux'
import { compose, withProps, withState } from 'recompose'
import { reduxForm, submit } from 'redux-form'

import { Message } from "components/Messages";

import UsersField from 'components/FormFields/UsersField'
import UpdateGlobalLevelsContainer from 'containers/Checks/UpdateGlobalLevels'

import Spinner from 'components/globals/Spinner'

import { PrimaryButton } from 'components/Buttons'

const GlobalChecklist = ({
  handleSubmit,
  updated,
  isFetching,
  ...props
}) => {

  return(
    <div>
      {updated && 
        <Message success text="Checklist updated successfully." />
      }
      <div className='row'>
        <div className='col'>
          <UsersField name='firstLevelChecks' label='First Level Checks' />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <UsersField name='secondLevelChecks' label='Second Level Checks' />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <UsersField name='thirdLevelChecks' label='Third Level Checks' />
        </div>
      </div>
      
      <div className='actions text-right' style={{marginTop: 20}}>
        <PrimaryButton disabled={isFetching} onClick={handleSubmit}>
          UPDATE
          {isFetching && <Spinner />}
        </PrimaryButton>
      </div>
    </div> 
  )
}

const mapDispatch = dispatch => ({ 
  handleSubmit: () => dispatch(submit("GLOBAL_CHECKLIST")),
})

export default compose(
  // connect(null, mapDispatch),
  // withState('isFetching', 'setFetching', false),
  // reduxForm({
  //   form: "GLOBAL_CHECKLIST",
  //   enableReinitialize: true,
  //   onSubmit: (formData, { setFetching }) => {
  //     
  //     setFetching(true)

  //   }
  // })
  UpdateGlobalLevelsContainer,
)(GlobalChecklist)