import { createSelector } from 'reselect'
import _get from 'lodash/fp/get'

export const selectUsers = createSelector(
    state => state.global,
    _get("users"),
)

export const selectUser = createSelector(
    state => state.global,
    _get("user"),
)