import React from 'react';

import { FormStep } from 'components/Forms'

import { ClientTypeField } from 'components/FormFields/Client'

const Borrower = () => (
    <FormStep title="Choose Client Type">
        <ClientTypeField />
    </FormStep>
)

export default Borrower