import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const SuggestionListItem = styled.li`
  align-items: center;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
  display: flex;
  height: 32px;
  padding: 4px 8px;
  cursor: pointer;

  &:hover {
    background: #eee;
  }
  &:last-of-type {
    border-bottom: 1px solid #ddd;
  }
`

const SuggestionListWrapper = styled.ul`
  background: #fff;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
`

const SuggestionList = React.forwardRef((props, ref) => (
  <SuggestionListWrapper {...props} ref={ref} />
))

const Suggestion = props => (
  <SuggestionListItem {...props} />
)

const DEFAULT_POSITION = {
  top: -10000,
  left: -10000,
}

/**
 * Suggestions is a PureComponent because we need to prevent updates when x/ y
 * Are just going to be the same value. Otherwise we will update forever.
 */

class TagSuggestions extends React.PureComponent {
  menuRef = React.createRef()

  state = DEFAULT_POSITION

  componentDidMount = () => {
    this.updateMenu()
  }

  componentDidUpdate = () => {
    this.updateMenu()
  }

  render() {
    const root = window.document.getElementById('root')

    return ReactDOM.createPortal(
      <SuggestionList
        ref={this.menuRef}
        style={{
          top: this.state.top,
          left: this.state.left,
        }}
      >
        {this.props.tags.map(tag => {
          return (
            <Suggestion key={tag._id} onClick={() => this.props.onSelect(tag)}>
              {tag.name}
            </Suggestion>
          )
        })}
      </SuggestionList>,
      root
    )
  }

  updateMenu() {
    const anchor = window.document.querySelector(this.props.anchor)

    if (!anchor) {
      return this.setState(DEFAULT_POSITION)
    }

    const anchorRect = anchor.getBoundingClientRect()

    this.setState({
      top: anchorRect.bottom + window.pageYOffset,
      left: anchorRect.left + window.pageXOffset,
    })
  }
}

export default TagSuggestions