import _get from 'lodash/fp/get'
import { createSelector } from 'reselect'


export const selectLoan = createSelector(
    state => state.global,
    _get("loanDetails"),
)

export const selectClient = createSelector(
    selectLoan,
    _get("client"),
)

export const selectCompanyName = createSelector(
    selectClient,
    _get("companyName"),
)

export const selectStatement = createSelector(
    selectLoan,
    state => state.global,
    (loan, global) => _get(`${loan._id}_statement`, global)
)
