import API from 'api'

const getDynamicTagsWithValuesSubmit = () => ({ type: "FETCH/REQUEST" })
const getDynamicTagsWithValuesSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getDynamicTagsWithValuesError = err => ({ type: "FETCH/ERROR", error: err})

export const getDynamicTagsWithValues = loanId => dispatch => {
    dispatch(getDynamicTagsWithValuesSubmit())

    API
        .get(`/api/tags/loan?id=${loanId}`)
        .then(res => res.json())
        .then(json => {
            dispatch(getDynamicTagsWithValuesSuccess(json)) 
        })
        .catch(err => dispatch(getDynamicTagsWithValuesError(err)))
}