import React from 'react'
import { withProps } from 'recompose'
import styled from 'styled-components'

import { COLORS } from 'consts'

import { hasBlock, hasMark, iconsMap, onClickBlock } from './utils'

const ButtonWrapper = styled.span`
  cursor: pointer;
  color: ${props => (props.active ? COLORS.darkGray : COLORS.lightGray2)};
  margin-right: 5px;
  user-select: none;
  border-radius: 4px;
  padding: 6px;
  background: #323444;

  i {
    font-size: 17px;
    vertical-align: text-bottom;
    font-family: 'Material Icons' !important;
  }
`

const FormulaButton = ({ active, onClick, type }) => (
  <ButtonWrapper {...{ active, onClick }}>
    <i className="material-icons">{iconsMap[type]}</i>
  </ButtonWrapper>
)

export default withProps(({ block, editor, type, value }) => {
  let isActive = block ? hasBlock(value, type) : hasMark(value, type)

  if (block && !isActive && ['numbered-list', 'bulleted-list'].includes(type)) {
    const { document, blocks } = value

    if (blocks.size > 0) {
      const parent = document.getParent(blocks.first().key)

      if (parent) {
        isActive = hasBlock(value, 'list-item') && parent.type === type
      }
    }
  }

  return {
    active: isActive,
    onClick: ev => {
      ev.preventDefault()
      return (
        editor &&
        (block ? onClickBlock(type, editor, value) : editor.toggleMark(type))
      )
    },
  }
})(FormulaButton)
