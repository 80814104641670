import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { createSelector, createStructuredSelector } from 'reselect'

import _get from 'lodash/fp/get'

import { Panel } from 'components/Forms'
import { ClientType } from 'reducers/NewClientState'

import CorporateOverview from './CorporateOverview'
import PersonalOverview from './PersonalOverview'


const ClientOverview = ({
    client,
    ...props
}) => {
    
    const { clientType, personalDetails } = client ? client : { clientType : '' }
    
    return(
        <Panel title='Your Details' expandable initiallyExpanded>
            {clientType === ClientType.Corporate && <CorporateOverview {...client} />}
            {clientType === ClientType.Personal && <PersonalOverview {...personalDetails} />}
        </Panel>
    )
}

export default ClientOverview