import React from 'react'

import Page from 'components/Page'

import LoanProfessionals from './LoanProfessionals'

const LoanDetails = props => {
    return(
        <Page title=''>
            <LoanProfessionals {...props} />
        </Page>
    )
}

export default LoanDetails