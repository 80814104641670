import { compose, withProps, withState, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import * as ViewLoanActions from './actions'
import { selectLoan, selectIsFetching } from './selectors'

const mapState = createStructuredSelector({
  loan: selectLoan,
})

const mapDispatch = dispatch => ({
  ...bindActionCreators(ViewLoanActions, dispatch),
})

export default compose(
    connect(mapState, mapDispatch),
    withState('isFetching', 'setFetching', false),
    withState('redeemPopupVisible', 'setRedeemPopupVisible', false),
    withProps(({ match, redeemPopupVisible, setRedeemPopupVisible }) => ({
      id: match.params.id,
      toggleRedeemPopup: () => {
        setRedeemPopupVisible(!redeemPopupVisible)
      }
    })),
    lifecycle({
      componentDidMount() {
        const { getLoan, id, setFetching } = this.props
        
        setFetching(true)
        getLoan(id, () => {
          setFetching(false)
        })
      },
    }),
)