import { createSelector } from 'reselect'
import _get from 'lodash/fp/get'

export const selectLoan = createSelector(
    state => state.global,
    _get("loanDetails")
)

export const selectLoanExtensions = createSelector(
  selectLoan,
  _get("extensions")
)

export const selectStartDate = createSelector(
  selectLoan,
  selectLoanExtensions,
  (loan, extensions) => {
    const _extensions = [...extensions].sort((a, b) => a.startDate - b.startDate)
    if(_extensions && _extensions.length > 0) {
      const _last = _extensions.pop()
      const _terms = _last.terms
      return _terms[_terms.length - 1].periodEnd
    } else {
      const _terms = loan.terms
      return _terms[_terms.length - 1].periodEnd
    }
  }
)

export const selectInitialExtensionValues = createSelector(
    selectLoan,
    selectStartDate,
    (loan, startDate) => {
      let grossAmount = loan.currentGrossAmount
      
      return {
        grossAmount,
        startDate,
        periodEnd: startDate,
      }
    }
)

export const selectLoanId = createSelector(
  selectLoan,
  _get("_id")
)