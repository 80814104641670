import { compose, withProps } from 'recompose'

import { withFormValuesOnChange } from 'components/HOC'
import { PriceField } from 'components/FormFields'
import { AddRepaymentForm } from 'containers/Loan/AddRepayment/consts'

export default compose(
    withProps(() => ({
        name: 'totalExtensionCost',
        label: "Total Extension Cost",
        prependNumber: "£",
        readOnly: true,
    })),
    withFormValuesOnChange(
        AddRepaymentForm, 
        [
            'totalFeeAmount', 
            'totalInterestAmount', 
            'totalExitAmount', 
            'solicitorFee'
        ],
        ({ 
            totalFeeAmount, 
            totalInterestAmount, 
            totalExitAmount, 
            solicitorFee 
        }) => {
            return totalFeeAmount + totalInterestAmount + totalExitAmount + parseFloat(solicitorFee)
        }
    ),
)(PriceField)