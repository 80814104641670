import React from 'react'

import TextField from '../TextField'

class DatepickerInput extends React.PureComponent {
  render() {
    const {
      datepickerVisible,
      onChange,
      onClick,
      onInputClick,
      value,
      ...props
    } = this.props
    return (
      <TextField
        {...props}
        onClick={onInputClick}
        input={{ value, onChange, onFocus: onInputClick }}
        meta={{ ...props.meta, active: datepickerVisible }}
      />
    )
  }
}

export default DatepickerInput
