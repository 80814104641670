import { createSelector } from 'reselect'
import _get from 'lodash/fp/get'

export const selectObjectId = (_, props) => _get('objectId', props)

export const selectDocuments = createSelector(
    selectObjectId,
    state => state.global,
    (objectId, globalState) => _get(`documents_${objectId}`, globalState)
)

export const selectUploadVisible = createSelector(
    state => state.global,
    _get("uploadVisible")
)