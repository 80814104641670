import API from 'api'

export const nextStep = () => ({
    type: "NEXT_CLIENT_STEP",
})

export const prevStep = () => ({
    type: "PREV_CLIENT_STEP",
})

const createClientSubmit = () => ({ type: "FETCH/REQUEST" })
const createClientSuccess = data => ({ type: "FETCH/SUCCESS", data })
const createClientError = err => ({ type: "FETCH/ERROR", error: err})

export const createClient = (formValues, callback) => dispatch => {
    dispatch(createClientSubmit())

    API
        .post('/api/clients/create', { body: JSON.stringify(formValues) })
        .then(res => res.json())
        .then(json => { 
            dispatch(createClientSuccess(json.client)) 
            if(callback) callback(json.client)
        })
        .catch(err => dispatch(createClientError(err)))

}