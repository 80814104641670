import React from 'react'
import { compose } from 'recompose'

import DocumentsContainer from "containers/Documents";

import Spinner from 'components/globals/Spinner'
import DocumentListItem from "components/ListItems/DocumentListItem";
import DocumentUploader from "components/DocumentsUploader";
import { SecondaryButton } from "components/Buttons";
import { Message } from "components/Messages";
import { Panel } from "components/Forms";

import DocumentsListingWrapper from './DocumentsListingWrapper'
import DocumentsOuterWrapper from './DocumentsOuterWrapper'

const DocumentFiles =  ({
  objectId,
  documents,
  toggleUpload,
  uploadVisible,
  downloadFile,
  handleRemove,
  checks,
  ...props
}) => {
  return(
    <DocumentsOuterWrapper>
      <Panel 
        style={{ margin: 0 }}
          button={
              <div>
                  <SecondaryButton onClick={() => toggleUpload(true)}>UPLOAD</SecondaryButton>
              </div>
          }
          title='Uploaded Documents'>
          {uploadVisible && <DocumentUploader {...{ objectId, toggleUploadOverride: toggleUpload }} />}
          <DocumentsListingWrapper>
              {documents && documents.map(doc => 
                  <DocumentListItem key={doc.id} {...{ ...doc, downloadFile, handleRemove }} />    
              )}
          </DocumentsListingWrapper>
          {!documents && <Spinner />}
          {documents && documents.length == 0 && 
              <Message text='No documents found' />
          }
      </Panel>
    </DocumentsOuterWrapper>
  )
}
export default compose(
  DocumentsContainer
)(DocumentFiles)