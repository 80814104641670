import React from 'react'

import Row from 'components/Row'
import { Panel } from 'components/Forms'

import { SecondaryButton } from 'components/Buttons'

import * as DirectorFields from './DirectorFields'

const RenderDirector = ({
    director,
    index,
    removeField,
    ...props
}) => {
    return(
        <Panel title={`Director #${index + 1}`} 
                button={index != 0 && <SecondaryButton onClick={() => removeField(index)}>Remove</SecondaryButton>}>
            <Row>  
                <DirectorFields.DirectorNameField {...{ director, index }} />
                <DirectorFields.DirectorShareHoldingField ml={10} {...{ director, index }} />
            </Row>
        </Panel>
    )
}

export default RenderDirector