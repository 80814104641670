import API from 'api'
import _findIndex from 'lodash/fp/findIndex'
import _set from 'lodash/fp/set'
import _get from 'lodash/fp/get'

const confirmCheckLevelSubmit = () => ({ type: "FETCH/REQUEST" })
const confirmCheckLevelSuccess = values => data => ({ 
    type: "FETCH/SUCCESS", 
    data,
    customReducer: (state) => {
    
        const { result } = data
        const { loanId, checkId, level, checked } = values
        // find loan check index in details
        const index = _findIndex({ loanId, checkId }, state.loanDetails.checks)
        if(index >= 0) {
            let _state = _set(`loanDetails.checks.${index}`, result, state)
            return _state
        } else {
            const checks = _get(`loanDetails.checks`, state)
            checks.push(result)
            let _state = _set(`loanDetails.checks`, checks, state)
            return _state
        }
        return state
    }
})
const confirmCheckLevelError = err => ({ type: "FETCH/ERROR", error: err})

export const confirmCheckLevel = (values, callback) => dispatch => {
    dispatch(confirmCheckLevelSubmit())

    API
        .post(`/api/checks/loan/toggle`, { body: JSON.stringify(values)})
        .then(res => res.json())
        .then(json => { 
            
            dispatch(confirmCheckLevelSuccess(values)(json)) 
            if(callback) callback()
        })
        .catch(err => dispatch(confirmCheckLevelError(err)))
}