import { compose, withProps } from 'recompose'

import { Field } from 'components/FormFields'
import { SwitchField } from 'components/Fields';

export default compose(
  withProps(() => ({
    component: SwitchField,
  })),
)(Field)
