import API from 'api'

const updateTemplateSubmit = () => ({ type: "FETCH/REQUEST" })
const updateTemplateSuccess = data => ({ type: "FETCH/SUCCESS", data })
const updateTemplateError = err => ({ type: "FETCH/ERROR", error: err})

export const updateTemplate = (id, formValues, callback) => dispatch => {
    dispatch(updateTemplateSubmit())

    API
        .post(`/api/templates/update?id=${id}`, { body: JSON.stringify(formValues) })
        .then(res => res.json())
        .then(json => { 
            dispatch(updateTemplateSuccess(json.template)) 
            if(callback) callback(json.template)
        })
        .catch(err => dispatch(updateTemplateError(err)))
}

const getTemplateSubmit = () => ({ type: "FETCH/REQUEST" })
const getTemplateSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getTemplateError = err => ({ type: "FETCH/ERROR", error: err})

export const getTemplate = (id) => dispatch => {
    dispatch(getTemplateSubmit())

    API
        .get(`/api/templates/single?id=${id}`)
        .then(res => res.json())
        .then(json => { 
            dispatch(getTemplateSuccess(json))
        })
        .catch(err => dispatch(getTemplateError(err)))

}