import API from 'api'

const getRedemptionStatementSubmit = () => ({ type: "FETCH/REQUEST" })
const getRedemptionStatementSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getRedemptionStatementError = err => ({ type: "FETCH/ERROR", error: err})

export const getRedemptionStatement = (loanId, values, callback) => dispatch => {
    dispatch(getRedemptionStatementSubmit())

    API
        .post(`/api/loans/statement?id=${loanId}`, { body: JSON.stringify(values)})
        .then(res => res.json())
        .then(json => { 
            dispatch(getRedemptionStatementSuccess(json)) 
            if(callback) callback(json)
        })
        .catch(err => dispatch(getRedemptionStatementError(err)))

}