import API from 'api';

let TemplateActions = {

  Update: (data) => {
    return (dispatch) => {

      dispatch({
        type: "UPDATE_TEMPLATE_BEGIN",
        data: data,
      });

      API.post("/api/templates/update", { body: JSON.stringify(data) })
        .then( res => { return res.json(); })
        .then( json => {
          if( json.success ) {
            dispatch({
              type: "UPDATE_TEMPLATE_END",
              template: json.template,
            });
          }
        })
    }
  },

  Create: (data) => {
    return (dispatch) => {

      dispatch({
        type: "CREATE_TEMPLATE_BEGIN",
        data: data,
      });

      API.post("/api/templates/create", { body: JSON.stringify(data) })
        .then( res => { return res.json(); })
        .then( json => {
          if( json.success ) {
            dispatch({
              type: "CREATE_TEMPLATE_END",
              template: json.template,
            });
          }
        })
    }
  },

  LoadAll: () => {
    return (dispatch) => {

      dispatch({
        type: "LOAD_TEMPLATES_BEGIN",
      });

      API.get("/api/templates/")
        .then( res => { return res.json(); })
        .then( json => {
          
          if( json.success ) {
            dispatch({
              type: "LOAD_TEMPLATES_END",
              templates: json.templates,
            });
          }
        })
    }
  },
  
  LoadEditTemplate: (id) => {
    return (dispatch) => {
      dispatch({
        type: "EDIT_TEMPLATE_BEGIN",
      });

      API.get("/api/templates/single?id=" + id)
        .then( res => { return res.json() })
        .then( json => {
          if( json.success ) {
            
            dispatch({
              type: "EDIT_TEMPLATE_END",
              template: json.template,
            })
          }
        })
        .catch(err => {
          
        })
    }
  },

  Remove: (id) => {
    var data = {
      id: id,
    }
    return dispatch => {
      API.post("/api/templates/remove", { body: JSON.stringify(data) })
        .then(res => { return res.json() })
        .then(json => {
          

          if( json.success ) {
            // reload templates globally
            dispatch(TemplateActions.LoadAll());
          }
        })
    }
  }
}

export default TemplateActions;
