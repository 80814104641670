import { compose, withProps, withState } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm, submit } from 'redux-form'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import { FORM } from './consts'
import * as LumpSumActions from './actions'
import { selectLoanId, selectInitialValues } from './selectors'

const mapState = createStructuredSelector({
    initialValues: selectInitialValues,
    loanId: selectLoanId,
})

const mapDispatch = dispatch => ({
    ...bindActionCreators(LumpSumActions, dispatch),
    submitForm: form => dispatch(submit(form)),
})

export default compose(
    connect(mapState, mapDispatch),
    withState('isUpdating', 'setUpdating', false),
    reduxForm({
        form: FORM, 
        onSubmit: (values, dispatch, { setUpdating, updateLoanProfessionals, loanId }) => {
            setUpdating(true)
            updateLoanProfessionals(loanId, values, () => {
                setUpdating(false)
            })
        },
        enableReinitialize: true,
    }),
    withProps(({ submitForm }) => ({
        handleSubmit: () => {
            submitForm(FORM)
        }
    }))
)