import React from 'react'
import { compose } from 'recompose'

import Page from 'components/Page'
import Row from 'components/Row'
import { PrimaryButton } from 'components/Buttons' 
import LoadingPanel from 'components/LoadingPanel'
import { Message } from 'components/Messages'

import EditProfessionalContainer from 'containers/Professionals/EditProfessional'

import * as ProfessionalFields from 'components/FormFields/Professionals'


const EditProfessional = ({
    submitForm,
    isFetching,
    isUpdating,
    formValues,
    ...props
}) => {
    const buttonText = isUpdating ? "PLEASE WAIT..." : "UPDATE"
    
    if(isFetching)
        return <LoadingPanel text='Loading professional details.' />

    return(
        <Page title="Edit Professional">
            <Row>
                <ProfessionalFields.NameField />
            </Row>
            <Row>
                <ProfessionalFields.ContactField />
            </Row>
            <Row>
                <ProfessionalFields.TypeField />
            </Row>
            <Row>
                <ProfessionalFields.EmailField />
            </Row>
            <Row>
                <ProfessionalFields.TelephoneField />
            </Row>
            <Row>
                <ProfessionalFields.AddressField />
            </Row>
            <Row>
                <ProfessionalFields.PostcodeField />
            </Row>
            <Row>
                <ProfessionalFields.CityField />
            </Row>
            <Row style={{ justifyContent: "flex-end" }}>
                <PrimaryButton onClick={submitForm}>{buttonText}</PrimaryButton>
            </Row>
        </Page>
    )
}

export default compose(
    EditProfessionalContainer,
)(EditProfessional)