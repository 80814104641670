import React from 'react'

import Row from 'components/Row'
import { Panel } from 'components/Forms'
import LoanField from 'components/Loans/LoanField'

const CorporateDetails = () => {
    return(
        <Panel title='Corporate Details' expandable={true} initiallyExpanded>
            <Row>
                <LoanField path='client.companyName' label='Company Name' />
            </Row>
            <Row>
                <LoanField path='client.registeredAddress' label='Registered Address' />
                <LoanField path='client.registeredPostcode' label='Registered Postcode' />
            </Row>
            <Row>
                <LoanField path='client.registrationDate' label='Registration Date' />
                <LoanField path='client.registrationNumber' label='Registration Number' />
            </Row>
        </Panel>
    )
}

export default CorporateDetails