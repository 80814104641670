import React from 'react'

import ApplicantsWrap from './ApplicantsWrap'
import RenderApplicant from './RenderApplicant'

const RenderApplicants = ({
    fields,
    pushField,
    ...props
}) => (
    <ApplicantsWrap>
        {fields && fields.length > 0 &&
            <div className='applicants-list'>
                {fields.map((applicant, index) => 
                    <RenderApplicant {...{ applicant, index, ...props }} key={index} />    
                )}
            </div>
        }
    </ApplicantsWrap>
)

export default RenderApplicants