import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom'

import logo from '../../images/logo.svg';

class LandingHeader extends Component {
  render() {
    return (
      <header>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-sm-4'>
              <Link to="/">
                <img className='logo' src={logo} />
              </Link>
            </div>
            <div className='col-sm-4 text-center'>
              
            </div>
            <div className='col-sm-4 text-right'>
              <h2 className='header-title'>LOAN <strong>MANAGEMENT TOOL</strong></h2>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default LandingHeader;
