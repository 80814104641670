import React from 'react';
import {connect} from 'react-redux';
import {TweenMax, TimelineMax, Power4} from 'gsap';

class NewClientProgress extends React.Component {
  render() {
    var step = this.props.step;
    
    return(
      <div className='new-client-form-progress'>
        <div className='new-client-form-progress-items'>
          <NewClientStepLink step={0} active={step >= 0} title="01" description="Corporate Borrower" />
          <NewClientStepLink step={1} active={step >= 1} title="02" description="Application Details" />
          <NewClientStepLink step={2} active={step >= 2} title="03" description="Additional Properties" />
          <NewClientStepLink step={3} active={step >= 3} title="04" description="Additional Security" />
          <NewClientStepLink step={4} active={step >= 4} title="05" description="Applicants" />
          <NewClientStepLink step={5} active={step >= 5} title="06" description="Additional Information" />
          {/* <NewClientStepLink step={6} active={step >= 5} title="06" description="Declaration" /> */}
        </div>
      </div>
    )
  }
}

class NewClientStepLink extends React.Component {

  onMouseLeave(e) {
    var tl = new TimelineMax();

    TweenMax.killTweensOf(this.refs.wrap);
    TweenMax.killTweensOf(this.refs.description);

    // show description
    // reduce padding, set maximum width to 0px //
    tl.to(this.refs.description, 0.4, {
        maxHeight: '0px',
        ease: Power4.easeOut,
        y: 0,
        opacity: 0,
        scale: 0,
    });
    tl.set(this.refs.wrap, { zIndex: 0 }, "-=0.2");
  }

  onMouseEnter(e) {
    var tl = new TimelineMax();

    TweenMax.killTweensOf(this.refs.wrap);
    TweenMax.killTweensOf(this.refs.description);
    TweenMax.set(".new-client-step-link", { zIndex: 0 });

    tl.set(this.refs.wrap, { zIndex: 2 });
    tl.to(this.refs.description, 0.4, {
        maxHeight: '150px',
        ease: Power4.easeOut,
        opacity: 1,
        y: -45,
        scale: 1,
    });
  }

  render() {
    return(
      <div ref='wrap' onMouseLeave={this.onMouseLeave.bind(this)}
           onMouseEnter={this.onMouseEnter.bind(this)}
           className='new-client-step-link'>
        <a className={this.props.active ? "active" : ""}>
          <span ref='title' className='new-client-step-link-title'>
            {this.props.title}
          </span>
          <span ref='description' className='new-client-step-link-description'>
            {this.props.description}
          </span>
        </a>
      </div>
    )
  }
}

const mapState = state => {
  return {
    step: state.loan.step,
    total_steps: state.loan.total_steps,
  }
}

export default connect(mapState)(NewClientProgress);
