import API from 'api'

const getGlobalLevelsSubmit = () => ({ type: "FETCH/REQUEST" })
const getGlobalLevelsSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getGlobalLevelsError = err => ({ type: "FETCH/ERROR", error: err})

export const getGlobalLevels = (formValues, callback) => dispatch => {
    dispatch(getGlobalLevelsSubmit())

    API
        .get('/api/checks/globals')
        .then(res => res.json())
        .then(json => { 
            dispatch(getGlobalLevelsSuccess(json)) 
            if(callback) callback(json)
        })
        .catch(err => dispatch(getGlobalLevelsError(err)))

}

const updateGlobalLevelsSubmit = () => ({ type: "FETCH/REQUEST" })
const updateGlobalLevelsSuccess = data => ({ type: "FETCH/SUCCESS", data })
const updateGlobalLevelsError = err => ({ type: "FETCH/ERROR", error: err})

export const updateGlobalLevels = (formValues, callback) => dispatch => {
    dispatch(updateGlobalLevelsSubmit())

    API
        .post('/api/checks/update-globals', { body: JSON.stringify(formValues) })
        .then(res => res.json())
        .then(json => { 
            dispatch(updateGlobalLevelsSuccess(json)) 
            if(callback) callback(json)
        })
        .catch(err => dispatch(updateGlobalLevelsError(err)))

}