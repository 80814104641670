import React from 'react'
import { compose } from 'recompose'

import Row from 'components/Row'
import Page from 'components/Page'

import { PrimaryButton } from 'components/Buttons' 

import * as WalletEntryFields from 'components/FormFields/Wallet'

import AddWalletEntryContainer from 'containers/Wallet/AddEntry'

const AddWalletEntry = ({
    submitForm,
}) => {
    return(
        <Page title="Add Wallet Entry">
            <Row>
                <WalletEntryFields.WalletTransactionAmountField />
            </Row>
            <Row>
                <WalletEntryFields.WalletTransactionTypeField />
            </Row>
            <Row>
                <WalletEntryFields.WalletTransactionNotesField />
            </Row>
            <Row style={{ justifyContent: "flex-end" }}>
                <PrimaryButton onClick={submitForm}>CREATE</PrimaryButton>
            </Row>
        </Page>
    )
}

export default compose(
    AddWalletEntryContainer,
)(AddWalletEntry)