import { compose, lifecycle, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

import ListProfessionalsContainer from 'containers/Professionals/ListProfessionals'

const validators = [validateRequired]

export default compose(
  ListProfessionalsContainer,
  withProps(({ professionals, ...props }) => ({
      placeholder: 'Select Professional',
      name: 'valuer',
      wrapperConfig: { width: '100%' },
      component: DropdownField,
      label: "Valuer",
      isClearable: true,
      options: professionals && professionals.filter(p => p.profType == 'Valuer').map(p => {
        return {
          value: p._id,
          label: p.name,
        }
      }),
      full: true,
      validators,
  })),
)(Field)
