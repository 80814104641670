const LoanData = ({ loan, children, ...rest }) => {


    const data = {
        clientName: loan.client.companyName,
        ltv: loan.ltv,
        status: loan.status,
        amount: loan.grossAmount,
        repaid: 0,
        redirectUrl: `/dashboard/loans/details/${loan._id}`,
    }
    return children(data)
}

export default LoanData