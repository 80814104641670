import React from 'react'
import { compose, withProps } from 'recompose'
import moment from 'moment'
import Switch from "react-switch";

import { DEFAULT_DATE_FORMAT } from 'consts'

import { Helpers } from 'helpers'

const LoanTermDataTermListItem = ({
    periodStart,
    periodEnd,
    amount,
    compounded,
    onChange,
    input,
    ...props
}) => {
    
    return(
        <div className='row' style={{ fontSize: '14px', alignItems: 'center', marginBottom: '5px', color: "#666" }}>
            <div className='col-sm-3'>{moment(periodStart).format(DEFAULT_DATE_FORMAT)}</div>
            <div className='col-sm-3'>{moment(periodEnd).format(DEFAULT_DATE_FORMAT)}</div>
            <div className='col-sm-3'>
                {!compounded ? `£${Helpers.priceFormatter(amount)}` : `-`}
            </div>
            <div className='col-sm-3'>
                <Switch
                    className='switch'
                    onColor="#5fcc5f"
                    onChange={onChange}
                    checked={compounded}
                />
            </div>
        </div>
    )
}

export default compose(
    withProps(({ input, periodStart }) => ({
        onChange: (value) => {
            const currentValue = {...input.value}
            const index = currentValue.terms.findIndex(term => { return term.periodStart == periodStart })

            if( index >= 0 ) {
                currentValue.terms[index].compounded = value
                let totalCompounded = currentValue.terms.filter(item => item.compounded).length
                currentValue.totalCompounded = totalCompounded
                input.onChange(currentValue)
            }
        }
    }))
)(LoanTermDataTermListItem)