import API from 'api';

let WalletActions = {

  LoadWalletHistory: () => {
    return dispatch => {
      dispatch({
        type: "LOAD_WALLET_BEGIN",
      });
      
      API.get("/api/wallet/")
        .then(res => { return res.json() })
        .then(json => {

          if( json.success ) {
            dispatch({
              type: "LOAD_WALLET_END",
              entries: json.entries,
            })
          }
        })
    }
  },

  Create: (data) => {
    return (dispatch) => {

      dispatch({
        type: "CREATE_WALLET_ENTRY_BEGIN",
        data: data,
      });

      API.post("/api/wallet/create", { body: JSON.stringify(data) })
        .then( res => { return res.json(); })
        .then( json => {
          if( json.success ) {
            dispatch({
              type: "CREATE_WALLET_ENTRY_END",
              entry: json.entry,
            });

            dispatch(WalletActions.LoadBalance());
          }
        })
    }
  },

  LoadBalance: () => {
    return (dispatch) => {
      dispatch({
        type: "LOAD_WALLET_BALANCE_START",
      });

      API.get("/api/wallet/balance")
        .then(res => { return res.json() })
        .then(json => {
          if( json.success ) {
            dispatch({
              type: "LOAD_WALLET_BALANCE_END",
              result: json.result,
            })
          }
        })
    }
  },
};

export default WalletActions;