import API from 'api'

const createTemplateSubmit = () => ({ type: "FETCH/REQUEST" })
const createTemplateSuccess = data => ({ type: "FETCH/SUCCESS", data })
const createTemplateError = err => ({ type: "FETCH/ERROR", error: err})

export const createTemplate = (formValues, callback) => dispatch => {
    dispatch(createTemplateSubmit())

    API
        .post('/api/templates/create', { body: JSON.stringify(formValues) })
        .then(res => res.json())
        .then(json => { 
            dispatch(createTemplateSuccess(json.template)) 
            if(callback) callback(json.template)
        })
        .catch(err => dispatch(createTemplateError(err)))

}