import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Page from "components/Page"
import Tabs from 'components/Tabs'
import TabInner from 'components/Tabs/TabInner'

import GlobalTags from './GlobalTags'
import GlobalChecklist from './GlobalChecklist'

export default () => {
  return(
    <Page title="Global Settings">
      <Tabs
        tabs={[
          {
            to: '/dashboard/settings/globals/tags',
            title: 'Global Tags',
          },
          {
            to: '/dashboard/settings/globals',
            title: 'Checklist',
            exact: true,
          },
        ]}
      />
      <TabInner>
        <Switch>
          <Route exact path='/dashboard/settings/globals/tags' component={GlobalTags} />
          <Route exact path='/dashboard/settings/globals' component={GlobalChecklist} />
        </Switch>
      </TabInner>
    </Page>
  )
}