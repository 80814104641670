import React from 'react'
import moment from 'moment'

import { DEFAULT_DATE_FORMAT } from 'consts'

import {SecondaryButton } from 'components/Buttons'

import { priceFormatter } from 'helpers/Helpers'

import GenericListItem from '../GenericListItem'

const DueTermListItem = ({
    _id,
    loanId,
    clientName,
    periodEnd,
    amount,
}) => {
    const dueDate = moment(periodEnd).format(DEFAULT_DATE_FORMAT)

    return(
        <GenericListItem className=''>
            <div className='col-sm-4'>
                <strong>{clientName}</strong>
            </div>
            <div className='col-sm-3'>{dueDate}</div>
            <div className='col-sm-2'>£{priceFormatter(amount.toFixed(2))}</div>
            <div className='col-sm-3 text-right'>
                <SecondaryButton to={`/dashboard/loans/details/${loanId}/repayments`}>VIEW</SecondaryButton>
            </div>
        </GenericListItem>
    )
}

export default DueTermListItem