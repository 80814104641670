import React from 'react'
import Row from 'components/Row'

import ClientField from 'components/Clients/ClientField'

const PersonalOverview = ({
  title,
  firstName,
  middleName,
  lastName,
  dateOfBirth,
  martialStatus,
  dependants,
  nationality,
  livedUK,
  timeAtCurrentAddress,
  postcode,
  bedrooms,
  occupancyType,
  propertyType,
  ukResident,
  permanentRight
}) => {

  return(
    <React.Fragment>
      <Row alignTop>
          <ClientField label='Title' forceValue={title} />
          <ClientField label='First Name' forceValue={firstName} />
          <ClientField label='Middle Name' forceValue={middleName} />
      </Row>
      <Row alignTop>
          <ClientField label='Last Name' forceValue={lastName} />
          <ClientField label='Date of Birth' type='date' forceValue={dateOfBirth} />
      </Row>
      <Row alignTop>
          <ClientField label='Martial Status' forceValue={martialStatus} />
          <ClientField label='Dependants' forceValue={dependants} />
          <ClientField label='Nationality' forceValue={nationality} />
      </Row>
      <Row alignTop>
          <ClientField label='Is UK Resident?' forceValue={(ukResident && ukResident.checked) ? "Yes" : "No"} />
          <ClientField label='Permanent right to reside in UK?' forceValue={(permanentRight && permanentRight.checked) ? "Yes" : "No"} />
      </Row>
      <Row alignTop>
          <ClientField label='How long lived in UK?' forceValue={livedUK} />
          <ClientField label='Time at current address' forceValue={timeAtCurrentAddress} />
      </Row>
      <Row alignTop>
          <ClientField label='Postcode' forceValue={postcode} />
          <ClientField label='Number of bedrooms' forceValue={bedrooms} />
      </Row>
      <Row alignTop>
          <ClientField label='Occupancy Type' forceValue={occupancyType} />
          <ClientField label='Property Type' forceValue={propertyType} />
      </Row>
    </React.Fragment>
  )
}

export default PersonalOverview