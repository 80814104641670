import styled from 'styled-components'

import { COLORS } from 'consts'

export default styled.div`
    padding: 0px;
    margin: 10px 0;
    margin-bottom: 20px;

    ul {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;

        li {   
            display: block;
            list-style: none;
            padding: 0;
            margin: 0;
            margin-right: 10px;

            a {
                font-size: 12px;
                padding: 3px 10px;
                border: 1px solid #ddd;
                border-radius: 5px;
                text-transform: uppercase;

                &.active {
                    color: #fff;
                    background: ${COLORS.blue};
                    border-color: ${COLORS.blue};
                }
            }
        }
    }
`