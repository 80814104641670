import React from 'react'
import { compose } from 'recompose'

import Popup from 'components/globals/Popup'
import { PrimaryButton } from 'components/Buttons'

import AddLumpSumPaymentContainer from 'containers/Loan/AddLumpSum'

import LumpSumPaymentSpecification from './LumpSumPaymentSpecification'

const LumpSumPopup = ({
  handleSubmit,
  ...props
}) => {
  return(
    <Popup button={<PrimaryButton onClick={handleSubmit}>SAVE</PrimaryButton>} {...props}>
      <LumpSumPaymentSpecification />
    </Popup>
  )
}

export default compose(
    AddLumpSumPaymentContainer,  
)(LumpSumPopup)