import React from 'react'
import { compose, withProps } from 'recompose'

import ExtensionListItem from 'components/ListItems/ExtensionListItem'
import { SecondaryButton } from 'components/Buttons'
import { Panel } from 'components/Forms'
import { Message } from 'components/Messages'

import { withLoanStatus } from 'components/HOC'

import ExtensionsContainer from 'containers/Extensions/ListExtensions'

import ExtensionPopup from './views/ExtensionPopup' 
import ExtensionListItemsHeaders from './views/ExtensionListItemsHeaders'

const Extensions = ({
    extensions,
    popupVisible,
    toggleExtensionPopup,
}) => {
    return(
        <Panel 
            button={<SecondaryButton onClick={toggleExtensionPopup}>CREATE EXTENSION</SecondaryButton>}
            title='Loan Extensions'>
            {!extensions || extensions.length == 0 && 
                <Message text="No extensions found." />
            }
            {extensions && extensions.length > 0 &&
                <React.Fragment>
                    <ExtensionListItemsHeaders />
                    {extensions.map(extension => (
                        <ExtensionListItem 
                            {...{
                                ...extension,
                                key: extension._id,
                            }} 
                        />
                    ))}
                </React.Fragment>
            }
            {popupVisible && 
                <ExtensionPopup {...{ title: "Extend Loan", handleToggle: toggleExtensionPopup }} />
            }
        </Panel>
    )
}
export default compose(
    ExtensionsContainer,
    withProps(({ match: { params: { id }}}) => ({
        loanId: id,
        statusText: "In order to view this tab, loan must be active.",
    })),    
    withLoanStatus(["Active", "Closed"]),
)(Extensions)