import React from 'react'

import Row from 'components/Row'

import RedemptionAmountEntryWrapper from './RedemptionAmountEntryWrapper'

const RedemptionAmountEntry = ({
    day,
    date,
    interest,
    amount,
}) => {
    return(
        <RedemptionAmountEntryWrapper>
            <Row>
                <div className='col-sm-1'>{day}</div>
                <div className='col-sm-2'></div>
                <div className='col-sm-3'>{date}</div>
                <div className='col-sm-3 text-center'>{interest}</div>
                <div className='col-sm-3 text-right'>{amount}</div>
            </Row>
        </RedemptionAmountEntryWrapper>
    )
}

export default RedemptionAmountEntry