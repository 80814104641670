import styled from 'styled-components'

export default styled.div`
    width: 100%;
    position: relative;

    .signature-box {
        position: relative;
        margin: 10px 0;
        background: #fff;
        margin-top: 50px;
        border-radius: 10px;
        overflow: hidden;
    }

    .signature-clear {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .signature-value {
        position: absolute;
        left: 0px;
        top: 0px;
        max-width: 100%;
        z-index: 1;
        pointer-events: none;

        img { 
            max-width: 100%;
        }
    }
`