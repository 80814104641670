import React from 'react'
import { compose } from 'recompose'

import { Switch, Route, useParams } from 'react-router-dom'

import DocumentListItem from 'components/ListItems/DocumentListItem'
import SignatureRequestListItem from 'components/ListItems/SignatureRequestListItem'
import { SecondaryButton } from 'components/Buttons'
import { Message } from 'components/Messages'
import { Panel } from 'components/Forms'

import SignaturePopup from 'components/SignaturePopup'
import DocumentsListing from './views/DocumentsListing'

import DocumentsContainer from 'containers/Documents'
import ListSignatureRequestsContainer from 'containers/Loan/ListSignatureRequests'

import Tabs from 'components/Tabs'
import TabInner from 'components/Tabs/TabInner'
import SignatureRequestsListing from './views/SignatureRequestsListing'

const LoanDocuments = ({
    loanId,
    objectId,
    documents,
    toggleUpload,
    toggleSignature,
    uploadVisible,
    signatureVisible,
    downloadFile,
    handleRemove,
    requests,
    ...props
}) => {
    const { id } = useParams()
    let index = 0
    return(
        <>
            <Tabs
                tabs={[
                    {
                        to: `/dashboard/loans/details/${id}/documents`,
                        title: 'Documents',
                        exact: true,
                    },
                    {
                        to: `/dashboard/loans/details/${id}/documents/signatures`,
                        title: 'Signature Requests',
                        exact: true,
                    },
                ]}
            />
            <TabInner>
                <Switch>
                    <Route exact path='/dashboard/loans/details/:loanId/documents' component={DocumentsListing} />
                    <Route exact path='/dashboard/loans/details/:loanId/documents/signatures' component={SignatureRequestsListing} />
                </Switch>
            </TabInner>
        </>
    )
}

export default compose(

)(LoanDocuments)