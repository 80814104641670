import React from 'react'
import { Editor } from 'slate-react'
import SoftBreak from 'slate-soft-break'
import CollapseOnEscape from 'slate-collapse-on-escape'
import stringSimilarity from 'string-similarity'
import { isKeyHotkey } from 'is-hotkey'
import Plain from 'slate-plain-serializer'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'

import { getFormValue, getSlateContentLength, getSlateValue } from 'utils/slate'

import initialValue from './value.json'

import SlateBlock from './SlateBlock'
import SlateMark from './SlateMark'
import SlateToolbar from './SlateToolbar'
import SlateWrapper from './SlateWrapper'

const plugins = [SoftBreak({ shift: true }), CollapseOnEscape()]

const isBoldHotkey = isKeyHotkey('mod+b')
const isItalicHotkey = isKeyHotkey('mod+i')
const isUnderlinedHotkey = isKeyHotkey('mod+u')

class Slate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: getSlateValue(props.value || initialValue),
    }

    this.editor = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.value, this.props.value)) {
      const slateValue = getSlateValue(this.props.value)
      if (
        stringSimilarity.compareTwoStrings(
          Plain.serialize(slateValue),
          Plain.serialize(this.state.value),
        ) < 0.5
      ) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ value: slateValue })
      }
    }
  }

  onBlur = (event, editor, next) => {
    setTimeout(() => this.props.onBlur(getFormValue(this.state.value)), 0)
    next()
  }

  onFocus = (event, editor, next) => {
    setTimeout(() => this.props.onFocus(), 0)
    next()
  }

  onChange = ({ value }) => {
    const { maxLength, onChange } = this.props
    if (!maxLength || getSlateContentLength(value, false) <= maxLength) {
      if (Plain.serialize(value) !== Plain.serialize(this.state.value)) {
        onChange(getFormValue(value))
      }

      this.setState({ value })
    }
  }

  onKeyDown = (ev, editor, next) => {
    let mark

    if (isBoldHotkey(ev)) {
      mark = 'bold'
    } else if (isItalicHotkey(ev)) {
      mark = 'italic'
    } else if (isUnderlinedHotkey(ev)) {
      mark = 'underlined'
    } else {
      return next()
    }

    ev.preventDefault()
    return editor.toggleMark(mark)
  }

  render() {
    const { disallowTitle, fontSize, meta, placeholder } = this.props
    const { value } = this.state
    const { editor, onBlur, onChange, onFocus, onKeyDown } = this

    return (
      <SlateWrapper
        onClick={() => {
          if (editor) {
            editor.current.focus()
          }
        }}
      >
        <SlateToolbar
          visible={_get(meta, 'active', false)}
          editor={editor.current}
          {...{ disallowTitle, value }}
        />
        <Editor
          ref={this.editor}
          renderBlock={SlateBlock(fontSize)}
          renderMark={SlateMark}
          {...{
            onBlur,
            onChange,
            onFocus,
            onKeyDown,
            plugins,
            placeholder,
            value,
          }}
        />
      </SlateWrapper>
    )
  }
}

export default Slate
