import React from 'react'

import Row from 'components/Row'
import Panel from 'components/Forms/Panel'

import { ExtensionSpecificationFields } from 'components/FormFields/Extension'

const ExtensionSpecification = () => (
  <Panel title='Specification Fields'>
    <Row>
      <ExtensionSpecificationFields.ExtensionGrossAmountField mr={10} />
      <ExtensionSpecificationFields.ExtensionLateInterestField mr={10} />
      <ExtensionSpecificationFields.ExtensionStartDateField />
    </Row>
    <Row>
      <ExtensionSpecificationFields.ExtensionInterestRateField mr={10} />
      <ExtensionSpecificationFields.ExtensionArrangementFeeField />
    </Row>
    <Row>
      <ExtensionSpecificationFields.ExtensionTermField />
    </Row>
  </Panel>
)

export default ExtensionSpecification