import API from 'api'

const updateLoanProfessionalsSubmit = () => ({ type: "FETCH/REQUEST" })
const updateLoanProfessionalsSuccess = data => ({ type: "FETCH/SUCCESS", data })
const updateLoanProfessionalsError = err => ({ type: "FETCH/ERROR", error: err})

export const updateLoanProfessionals = (loanId, formValues, callback) => dispatch => {
    dispatch(updateLoanProfessionalsSubmit())

    API
        .post(`/api/loans/professionals?id=${loanId}`, { body: JSON.stringify(formValues) })
        .then(res => res.json())
        .then(json => { 
            
            dispatch(updateLoanProfessionalsSuccess({ loanDetails: json.loan })) 
            if(callback) callback(json.loan)
        })
        .catch(err => dispatch(updateLoanProfessionalsError(err)))

}