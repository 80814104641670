import React from 'react'

import Page from 'components/Page'

import ClientGeneralInfo from './views/ClientGeneralInfo'
import ApplicantsDetails from './views/ApplicantsDetails'

const ClientInfo = ({
    ...props
}) => {
    return(
        <Page title=''>
            <ClientGeneralInfo {...props}/>
            <ApplicantsDetails />
        </Page>
    )
}

export default ClientInfo