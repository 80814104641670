import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux' 
import { createStructuredSelector } from 'reselect'
import { compose, withProps, lifecycle } from 'recompose'

import { DEFAULT_DATE_FORMAT } from 'consts'

import { withFormValues, withFormValuesOnChange } from 'components/HOC'

import { selectClient, selectLoan, selectCompanyName, selectStatement } from './selectors'
import * as actions from './actions'
import { FORM_NAME } from './consts'
import moment from 'moment'

const mapState = createStructuredSelector({
    loan: selectLoan,
    client: selectClient,
    companyName: selectCompanyName,
    statement: selectStatement,
    initialValues: state => ({
        redemptionInterestRate: 3.0,
        redemptionDate: new Date(),
    }),
})

const mapDispatch = dispatch => ({
    ...bindActionCreators(actions, dispatch),
})

export default compose(
    connect(mapState, mapDispatch),
    reduxForm({
        form: FORM_NAME,
        onChange: (values, dispatch, { loanId, getRedemptionStatement }) => {
            getRedemptionStatement(loanId, {
                statementDate: values.redemptionDate,
                interestRate: values.redemptionInterestRate,
            })
        },
        onSubmit:( values, dispatch, { loanId, redeemLoan, statement, handleToggle, onRedeem }) => {
            if(statement) {
                const data = {
                    interestRate: values.redemptionInterestRate,
                    date: values.redemptionDate,
                    amount: statement.balance.amountNumeric,
                }
                redeemLoan(loanId, data, () => {
                    handleToggle()
                    if( onRedeem )
                        onRedeem()
                    
                })
            }
        }
    }),
    withFormValues(FORM_NAME),
    withProps(({ formValues: { interestRate, statementDate } }) => ({
        statementDateFormatted: moment(statementDate).format(DEFAULT_DATE_FORMAT),
    })),
    lifecycle({
        componentDidUpdate() {
            const { getRedemptionStatement, loanId, formValues } = this.props
            getRedemptionStatement(loanId, {
                statementDate: formValues.redemptionDate,
                interestRate: formValues.redemptionInterestRate,
            })
        }
    })
)