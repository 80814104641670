import React from 'react'
import { compose, withProps, withState } from 'recompose'
import _every from 'lodash/fp/every'
import _some from 'lodash/fp/some'
import _filter from 'lodash/fp/filter'

import CheckListItem from 'components/ListItems/LoanCheckListItem'
import { PrimaryButton } from 'components/Buttons'
import { Panel } from 'components/Forms'

import ListChecksContainer from 'containers/Checks/ListChecks'
import GetGlobalLevelsContainer from 'containers/Checks/GetGlobalLevels'
import GetCurrentUserContainer from 'containers/User/GetCurrentUser'
import LoanChecklistContainer from 'containers/Loan/Checks'

import RejectPopup from './RejectPopup'
import ApprovePopup from './ApprovePopup'
import SendNextPopup from './SendNextPopup'
import ChecklistNoteListItem from './NoteListItem'


const LoanChecklist = ({
    loanId,
    checks,
    getLoanChecks,
    user,
    canSendToNext,
    globalLevels,
    isFirstCheckUser,
    isSecondCheckUser,
    isThirdCheckUser,
    canSendFirstCheck,
    canSendSecondCheck,
    canSendThirdCheck,
    loanCheckRequest,
    firstLevelSubmitted,
    secondLevelSubmitted,
    thirdLevelSubmitted,
    handleSubmit,
    firstCheckRequests,
    secondCheckRequests,
    thirdCheckRequests,
    isAwaiting,
    isApproved,
    sendNextPopupVisible,
    rejectPopupVisible,
    approvePopupVisible,
    toggleApprovePopup,
    toggleRejectPopup,
    toggleSendNextPopup,
    ...props
}) => {

    const {
        firstLevelChecks,
        secondLevelChecks,
        thirdLevelChecks
    } = globalLevels || {}

    const {
        currentLevel,
        notes,
    } = loanCheckRequest || {}

    
    
    return(
        <Panel title='Loan Checklist'>
            <div className='page-content listing loanChecklist'>
                <div className='thead'>
                    <div className='row'>
                        <div className='col' style={{ minWidth: 250 }}>Check</div>
                        <div className='col'>
                            <h4>Level 1</h4>
                            <strong>{ firstLevelChecks && `${firstLevelChecks.first_name} ${firstLevelChecks.last_name}`}</strong>
                        </div>
                        <div className='col'>
                            <h4>Level 2</h4>
                            <strong>{secondLevelChecks && `${secondLevelChecks.first_name} ${secondLevelChecks.last_name}`}</strong>
                        </div>
                        <div className='col'>
                            <h4>Level 3</h4>
                            <strong>{thirdLevelChecks && `${thirdLevelChecks.first_name} ${thirdLevelChecks.last_name}`}</strong>
                        </div>
                    </div>
                </div>
            </div>
            {checks && checks.map(check => 
                <CheckListItem 
                    {...
                        {
                            ...check, 
                            key: check._id, 
                            loanId, 
                            checkId: check._id,
                            onCheck: () => { getLoanChecks(loanId) },
                            user,
                            firstLevelSubmitted,
                            secondLevelSubmitted,
                            thirdLevelSubmitted,
                            ...globalLevels,
                        }
                    } 
                />

            )}
            {notes && 
                <div className='notes'>
                    <h3 style={{ marginTop: 25, marginBottom: 25 }}>Notes:</h3>
                    {notes && notes.map(note => 
                        <ChecklistNoteListItem 
                            {...{
                                ...note
                            }}
                        />
                    )}
                </div>
            }
            {/* <div className='loanChecklistNotes'>
                <div className='row'>
                    <div className='col' style={{ minWidth: 250 }}></div>
                    <div className='col'>
                        <TextAreaField 
                            disabled={!user || !firstLevelChecks || firstLevelSubmitted || user.id != firstLevelChecks.id}
                            name='firstCheckNotes' 
                            label='First Level Notes'
                        /> 
                        {firstCheckRequests && firstCheckRequests.length > 0 && 
                            <div>
                                <h4 style={{ marginBottom: 5 }}>Notes:</h4>
                                {firstCheckRequests.map(req => (
                                    <Content style={{ margin: 0, marginBottom: 5 }}>{req.notes}</Content>
                                ))}
                            </div>
                        }
                    </div>
                    <div className='col'>
                        <TextAreaField 
                            disabled={!user || !secondLevelChecks || secondLevelSubmitted || user.id != secondLevelChecks.id}
                            name='secondCheckNotes' 
                            label='Second Level Notes'
                        /> 
                        {secondCheckRequests && secondCheckRequests.length > 0 && 
                            <div>
                                <h4 style={{ marginBottom: 5 }}>Notes:</h4>
                                {secondCheckRequests.map(req => (
                                    <Content style={{ margin: 0, marginBottom: 5 }}>{req.notes}</Content>
                                ))}
                            </div>
                        }
                    </div>
                    <div className='col'>
                        <TextAreaField 
                            disabled={!user || !thirdLevelChecks || thirdLevelSubmitted || user.id != thirdLevelChecks.id}
                            name='thirdCheckNotes' 
                            label='Third Level Notes'
                        /> 
                    </div>
                </div>
            </div> */}
            <div className='actions text-right' style={{ marginTop: 20, marginBottom: 20 }}>
                {(!currentLevel || (isFirstCheckUser && !isAwaiting && currentLevel == 1)) && 
                    <PrimaryButton onClick={toggleSendNextPopup}>
                        SEND TO NEXT LEVEL
                    </PrimaryButton>
                }
                {isSecondCheckUser && isAwaiting && currentLevel == 2 && 
                    <>
                        <PrimaryButton red onClick={toggleRejectPopup} style={{ marginRight: 10 }}>
                            SEND BACK TO LEVEL 1
                        </PrimaryButton>
                        <PrimaryButton onClick={toggleSendNextPopup}>
                            SEND TO NEXT LEVEL
                        </PrimaryButton>
                    </>
                }
                {isThirdCheckUser && !isApproved && currentLevel == 3 && 
                    <> 
                        <PrimaryButton red onClick={toggleRejectPopup} style={{ marginRight: 10 }}>SEND BACK TO LEVEL 1</PrimaryButton>
                        <PrimaryButton onClick={toggleApprovePopup}>APPROVE</PrimaryButton>
                    </>
                }
            </div>
            {rejectPopupVisible &&
                <RejectPopup {...{ title: "SEND BACK TO LEVEL 1", loanId, handleToggle: toggleRejectPopup }} />
            }   
            {approvePopupVisible &&
                <ApprovePopup {...{ title: "APPROVE CHECKLIST", loanId, handleToggle: toggleApprovePopup }} />
            }   
            {sendNextPopupVisible &&
                <SendNextPopup {...{ title: "SEND TO NEXT LEVEL", loanId, handleToggle: toggleSendNextPopup }} />
            }   
        </Panel>
    )
}

export default compose(
    GetGlobalLevelsContainer,
    GetCurrentUserContainer,
    ListChecksContainer,
    LoanChecklistContainer,
    withState('rejectPopupVisible', 'setRejectPopupVisible', false),
    withState('approvePopupVisible', 'setApprovePopupVisible', false),
    withState('sendNextPopupVisible', 'setSendNextPopupVisible', false),
    withProps(({ 
        match: { params: { id } },
        user,
        globalLevels,
        loanChecks,
        loanCheckRequest,
        rejectPopupVisible,
        approvePopupVisible,
        sendNextPopupVisible,
        setRejectPopupVisible,
        setApprovePopupVisible,
        setSendNextPopupVisible,

    }) => ({
        loanId: id,
        isAwaiting: loanCheckRequest && loanCheckRequest.status == 'Pending',
        isApproved: loanCheckRequest && loanCheckRequest.status == 'Approved',
        isFirstCheckUser: user && globalLevels && globalLevels.firstLevelChecks.id == user.id,
        isSecondCheckUser: user && globalLevels && globalLevels.secondLevelChecks.id == user.id,
        isThirdCheckUser: user && globalLevels && globalLevels.thirdLevelChecks.id == user.id,
        firstLevelSubmitted: loanCheckRequest && loanCheckRequest.currentLevel > 1,
        secondLevelSubmitted: loanCheckRequest && loanCheckRequest.currentLevel > 2,
        thirdLevelSubmitted: loanCheckRequest && loanCheckRequest.status == 'Approved',
        canSendFirstCheck: _every({ firstLevel: { checked: true }}, loanChecks),
        canSendSecondCheck: _every({ secondLevel: { checked: true }}, loanChecks),
        canSendThirdCheck: _every({ thirdLevel: { checked: true }}, loanChecks),
        checkRequest: loanCheckRequest,
        toggleRejectPopup: () => {
            setRejectPopupVisible(!rejectPopupVisible)
        },
        toggleApprovePopup: () => {
            setApprovePopupVisible(!approvePopupVisible)
        },
        toggleSendNextPopup: () => {
            setSendNextPopupVisible(!sendNextPopupVisible)
        },
    })),
)(LoanChecklist)