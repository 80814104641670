import React from "react";
import { compose } from "recompose";

import Row from "components/Row";
import { PrimaryButton } from 'components/Buttons' 
import { Panel } from "components/Forms";
import {
  LoanQuantitySurveyorField,
  LoanSolicitorField,
  LoanValuerField,
} from "components/FormFields/Professionals";

import LoanProfessionalsContainer from "containers/Loan/LoanProfessionals";

const LoanProfessionals = ({
  handleSubmit,
  isUpdating,
  ...props
}) => {
  const buttonText = isUpdating ? "PLEASE WAIT..." : "UPDATE"

  
  return (
    <Panel title="Approved Professionals" expandable initiallyExpanded>
      <Row>
        <LoanQuantitySurveyorField />
      </Row>
      <Row>
        <LoanSolicitorField />
      </Row>
      <Row>
        <LoanValuerField />
      </Row>
      <Row style={{ justifyContent: "flex-end" }}>
        <PrimaryButton onClick={handleSubmit}>{buttonText}</PrimaryButton>
      </Row>
    </Panel>
  );
};

export default compose(LoanProfessionalsContainer)(LoanProfessionals);
