import React from 'react'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'
import { Flex } from '@rebass/grid'

import { COLORS } from 'consts'

const Wrapper = styled(Flex)`
  ${props => (props.fullWidth ? 'width: 100%' : `width: ${props.width}px;`)};
  min-height: ${props => props.height}px;
  height: ${props => props.height}px;
  align-items: center;
  flex-shrink: 0;

  > * {
    width: 100%;
  }
`

const SkeletonBase = ({
  children,
  fullWidth,
  height,
  width,
  onClick,
  wrapperHeight,
  ...rest
}) => (
  <Wrapper height={wrapperHeight || height} {...{ fullWidth, width }} {...rest}>
    <ContentLoader
      speed={2}
      primaryColor={COLORS.whiteGray2}
      secondaryColor={COLORS.whiteGray}
      preserveAspectRatio="none"
      width={width}
      height={height}
    >
      {children}
    </ContentLoader>
  </Wrapper>
)

export default SkeletonBase
