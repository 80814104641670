import React from 'react'
import { compose } from 'recompose'

import RedemptionStatementContainer from 'containers/Loan/RedemptionStatement'

import RedemptionStatementAccountBalance from './RedemptionStatementAccountBalance'
import RedemptionStatementWrapper from './RedemptionStatementWrapper'
import RedemptionStatementForm from './RedemptionStatementForm'
import RedemptionStatementInfo from './RedemptionStatementInfo'
import RedemptionClientInfo from './RedemptionClientInfo'

const RedemptionStatement = ({
    loan, 
    client,
    ...props
}) => {
    
    if(!client || !loan)
        return null

    return(
        <RedemptionStatementWrapper>
            <RedemptionStatementForm />
            <div id='redemption'>
                <RedemptionClientInfo {...{ client }}/>
                <RedemptionStatementInfo {...props} />
                <RedemptionStatementAccountBalance {...props} />
            </div>
            
        </RedemptionStatementWrapper>
    )
}

export default compose(
    RedemptionStatementContainer,
)(RedemptionStatement)
