import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm, submit } from 'redux-form'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import { AddLumpSumForm } from './consts'
import * as LumpSumActions from './actions'
import { selectLoanId } from './selectors'

const mapState = createStructuredSelector({
    initialValues: state => ({
        date: new Date(),
    }),
    loanId: selectLoanId,
})

const mapDispatch = dispatch => ({
    ...bindActionCreators(LumpSumActions, dispatch),
    submitForm: form => dispatch(submit(form)),
})

export default compose(
    connect(mapState, mapDispatch),
    reduxForm({
        form: AddLumpSumForm, 
        onSubmit: (values, dispatch, props) => {
            const { createLumpSum, loanId } = props
            createLumpSum({
                ...values,
                loanId,
            }, () => {
                window.location.reload()
            })
        }
    }),
    withProps(({ submitForm }) => ({
        handleSubmit: () => {
            submitForm(AddLumpSumForm)
        }
    }))
)