import React from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'

import { pathnameIncludes } from 'utils/router'

import DashboardSubmenuWrapper from './DashboardSubmenuWrapper'
import DashboardSubmenuFixed from './DashboardSubmenuFixed'

import DashboardSubmenuNav from './views/DashboardOverview'
import LoanDetailsSubmenu from './views/LoanDetailsSubmenu'
import SettingsSubmenu from './views/SettingsSubmenu'
import ClientDetailsSubmenu from './views/ClientDetailsSubmenu'

const paths = [
  '/dashboard/pipeline',
  '/dashboard/due-payments',
  '/dashboard/recent-transactions',
  '/dashboard/loans/details/:id',
  '/dashboard/loans/details/:id/:submodule',
  '/dashboard/loans/details/:id/:submodule/:subsubmodule',
  '/dashboard/settings',
  '/dashboard/settings/:submodule',
  '/dashboard/settings/:submodule/:any',
  '/dashboard/settings/:submodule/:any/:any',
  '/dashboard/clients/details/:id',
  '/dashboard/clients/details/:id/:submodule',
]


const DashboardSubmenu = ({
  user
}) => {
  const location = useLocation()
  if(!pathnameIncludes(location.pathname, paths))
    return null
  return(
    <DashboardSubmenuWrapper>
      <DashboardSubmenuFixed>
        <Switch>
          <Route path={['/dashboard/pipeline', '/dashboard/due-payments', '/dashboard/recent-transactions']} component={DashboardSubmenuNav} />
          <Route path='/dashboard/loans/details/:id' component={LoanDetailsSubmenu} />
          <Route path='/dashboard/clients/details/:id' component={ClientDetailsSubmenu} />
          <Route path='/dashboard/settings' component={SettingsSubmenu} />
        </Switch>
      </DashboardSubmenuFixed>
    </DashboardSubmenuWrapper>
  )
}


export default DashboardSubmenu