import React from 'react';
import {Spacer} from '../../components/globals/UI'
import RecentPayments from '../../components/payments/RecentPayments';
import DuePayments from '../../components/payments/DuePayments';
import Pipeline from '../../components/payments/Pipeline';

class PaymentsInfo extends React.Component {
  render() {
    return(
      <div className='sub-page'>
        <DuePayments />
        <Spacer />
        <RecentPayments />
        <Spacer />
        <Pipeline />
      </div>
    )
  }
}

export default PaymentsInfo;
