import API from 'api'

const rejectSubmit = () => ({ type: "FETCH/REQUEST" })
const rejectSuccess = data => ({ type: "FETCH/SUCCESS", data, key: 'loanDetails' })
const rejectError = err => ({ type: "FETCH/ERROR", error: err})

export const reject = (data, callback) => dispatch => {
    dispatch(rejectSubmit())

    API
        .post('/api/loans/checklist/reject', { body: JSON.stringify(data) })
        .then(res => res.json())
        .then(json => { 
            dispatch(rejectSuccess(json.data))
            if(callback) callback()
        })
        .catch(err => dispatch(rejectError(err)))

}