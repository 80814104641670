import React from 'react';

import { FormStep } from 'components/Forms'

import { PropertiesArrayField } from 'components/FormFields/Loan'

const AdditionalProperties = () => (
    <FormStep title="Additional Properties">
        <PropertiesArrayField />
    </FormStep>
)

export default AdditionalProperties