import API from 'api'

const getDueTermsRequest = () => ({ type: "FETCH/REQUEST" })
const getDueTermsSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getDueTermsError = err => ({ type: "FETCH/ERROR", error: err})

export const getDueTerms = (id) => dispatch => {
    dispatch(getDueTermsRequest())

    API
        .get(`/api/loans/payments/due`)
        .then(res => res.json())
        .then(json => { dispatch(getDueTermsSuccess(json)) })
        .catch(err => dispatch(getDueTermsError(err)))
}