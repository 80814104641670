import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom'

import PasswordRecoveryMod from '../../components/auth/PasswordRecoveryMod';

import logo from '../../images/logo.svg';

class PasswordRecoveryPage extends Component {
  render() {
    return (
      <div id='login-page' className='page'>
        <div className='inner'>
          <PasswordRecoveryMod />
        </div>
      </div>
    );
  }
}

export default PasswordRecoveryPage;
