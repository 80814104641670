import React from 'react';

import Row from 'components/Row'
import { FormStep } from 'components/Forms'

import {
    PersonalDetailsFields,
    ClientEmailField,
    ClientBorrowerEmailField,
    ClientBorrowerTelephoneField,
  } from "components/FormFields/Client";

const PersonalDetails = () => (
    <FormStep subtitle="Personal Details">
        <Row>
            <PersonalDetailsFields.ApplicantTitleField mr={10}/>
            <PersonalDetailsFields.ApplicantFirstNameField />
        </Row>
        <Row>  
            <PersonalDetailsFields.ApplicantMiddleNameField mr={10} />
            <PersonalDetailsFields.ApplicantLastNameField />
        </Row>
        <Row>
            <PersonalDetailsFields.ApplicantMaidenNameField mr={10} />
            <ClientEmailField mr={10} />
            <PersonalDetailsFields.ApplicantDateOfBirthField />
        </Row>
        <Row>
            <PersonalDetailsFields.ApplicantNumberOfDependantsField mr={10} />
            <PersonalDetailsFields.ApplicantMartialStatusField />
        </Row>
        <Row>
            <PersonalDetailsFields.ApplicantNationalityField mr={10} />
            <PersonalDetailsFields.ApplicantUKResidentField />
        </Row>
        <Row>
            <PersonalDetailsFields.ApplicantUKLivedField />
            <PersonalDetailsFields.ApplicantPermanentRightField ml={10} />
        </Row>
        <Row>
            <PersonalDetailsFields.ApplicantCurrentAddressField />
            <PersonalDetailsFields.ApplicantNumberOfBedroomsField ml={10} />
        </Row>
        <Row>
            <PersonalDetailsFields.ApplicantTimeAtCurrentAddressField />
            <PersonalDetailsFields.ApplicantPostcodeField ml={10} />
        </Row>
        <Row>
            <PersonalDetailsFields.ApplicantOccupancyTypeField />
            <PersonalDetailsFields.ApplicantPropertyTypeField ml={10} />
        </Row>
        <Row>
            <ClientBorrowerEmailField />
            <ClientBorrowerTelephoneField ml={10} />
        </Row>
    </FormStep>
)

export default PersonalDetails