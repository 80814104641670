import { change, registerField } from 'redux-form'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

import ClientsContainer from 'containers/Client/ListClients'
import { AddLoanForm } from 'containers/Loan/AddLoan/consts'

import { ClientType } from 'reducers/NewClientState'

const validators = [validateRequired]

const mapDispatch = dispatch => ({
    registerApplicantsDetails: () => dispatch(registerField(AddLoanForm, 'applicantsDetails', "FieldArray")),
    updateApplicantsDetails: value => dispatch(change(AddLoanForm, 'applicantsDetails', value)),
})

export default compose(
    ClientsContainer,
    connect(null, mapDispatch),
    withProps(({ clientsData, input, updateApplicantsDetails, registerApplicantsDetails, ...props  }) => ({
        wrapperConfig: { width: '100%' },
        placeholder: 'Select Client',
        component: DropdownField,
        label: "Loan Borrower",
        name: 'loanBorrower',
        isClearable: true,
        options: clientsData.map(client => ({
            value: client._id,
            label: client.clientType === ClientType.Corporate ? client.companyName : `${client.personalDetails.firstName} ${client.personalDetails.lastName}`,
        })),
        onChangeValue: value => {
            const client = clientsData.find(client => client._id === value)
            const type = client.clientType
            const applicants = client.applicants
            const applicantsDetails = []
            applicants.forEach( applicant => {
                applicantsDetails.push({
                    applicant: applicant,
                })
            })

            if(type === ClientType.Personal) {
                applicantsDetails.push({
                    applicant: client.personalDetails
                })
            }
            registerApplicantsDetails()
            updateApplicantsDetails(applicantsDetails)
        },
        full: true,
        validators,
    })),
)(Field)
