import { createSelector } from 'reselect'
import { getFormValues, getFormSyncErrors } from 'redux-form/immutable'
import _reduce from 'lodash/reduce'

const clearObject = obj =>
  Object.keys(obj).reduce((acc, cur) => {
    if (!obj[cur]) {
      return acc
    }

    return {
      ...acc,
      [cur]: obj[cur],
    }
  }, {})

export const formValuesSelector = (formName, fallback = {}) =>
  createSelector(getFormValues(formName), formValues =>
    clearObject(formValues ? formValues : fallback),
  )

export const formSyncErrorsSelector = (formName, fallback = {}) =>
  createSelector(getFormSyncErrors(formName), errors => errors || fallback)

export const trimValues = values =>
  _reduce(
    values,
    (acc, curr, key) => ({
      ...acc,
      [key]: curr && curr.trim ? curr.trim() : curr,
    }),
    {},
  )

export const toBase64 = ({ file, callback }) =>
  new Promise((resolve, reject) => {
    if (typeof file === 'string') {
      if (callback) {
        callback(file)
      }
      resolve(file)
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (callback) {
        callback(reader.result)
      }
      resolve(reader.result)
    }
    reader.onerror = error => reject(error)
  })
