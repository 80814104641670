import { connect } from 'react-redux'
import { compose, lifecycle, withState } from 'recompose'
import withProps from 'recompose/withProps'
import { bindActionCreators } from 'redux'

import * as actions from './actions'

const mapDispatch = dispatch => ({ 
    ...bindActionCreators(actions, dispatch),
})

export default compose(
    connect(null, mapDispatch),
    withState('isSendingReminder', 'setSending', false),
    withProps(({
      sendReviewReminder,
      setSending,
      id,
    }) => ({
      handleSendReminder: () => {
        setSending(true)
        sendReviewReminder(id, () => {
          setSending(false)
        })
      }
    })),
 )