import moment from 'moment'
import { compose, withProps } from 'recompose'

import { PriceField } from 'components/FormFields'
import { withFormValuesOnChange } from 'components/HOC'
import { withFormValues } from 'components/HOC'

import { validateRequired } from 'utils/validators'

import { ExtensionFormName } from 'containers/Extensions/AddExtension/consts'

const validators = [validateRequired]

export default compose(
    withProps(() => ({
        name: 'lateInterest',
        label: "Late Interest",
        validators,
        readOnly: true,
    })),
    withFormValuesOnChange(
        ExtensionFormName, 
        [
            'grossAmount',
            'startDate', 
            'interestRate',
            'periodEnd',
        ],
        ({ grossAmount, startDate, interestRate, periodEnd, ...rest }, props) => {
            const numMonths = Math.abs(moment(startDate).diff(moment(periodEnd), 'month'))
            const iterateDate = moment(periodEnd).add(numMonths, 'month')
            const numDays = moment(startDate).diff(iterateDate, 'days')
            let totalInterestAmount = grossAmount * 0.01 * interestRate * numMonths
            let totalDailyInterstAmount = (grossAmount * (0.01 * interestRate)) * (numDays/31)
            return totalInterestAmount + totalDailyInterstAmount
        }
    ),
)(PriceField)