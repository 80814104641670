import API from 'api'

export const nextStep = () => ({
    type: "NEXT_LOAN_STEP",
})

export const prevStep = () => ({
    type: "PREV_LOAN_STEP",
})

const createLoanSubmit = () => ({ type: "FETCH/REQUEST" })
const createLoanSuccess = data => ({ type: "FETCH/SUCCESS", data })
const createLoanError = err => ({ type: "FETCH/ERROR", error: err})

export const createLoan = (formValues, callback) => dispatch => {
    dispatch(createLoanSubmit())

    
    API
        .post('/api/loans/create', { body: JSON.stringify(formValues) })
        .then(res => res.json())
        .then(json => { 
            dispatch(createLoanSuccess(json.loan)) 
            if(callback) callback(json.loan)
        })
        .catch(err => dispatch(createLoanError(err)))

}