import styled from 'styled-components'

import mediaImage from 'images/upload.svg'

export default styled.div`
    width: 100px;
    height: 100px;
    background-position: center;
    background: url(${mediaImage}) no-repeat;
    background-size: contain;
    opacity: 0.5;
`