import API from 'api'

const getProfessionalSubmit = () => ({ type: "FETCH/REQUEST" })
const getProfessionalSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getProfessionalError = err => ({ type: "FETCH/ERROR", error: err})

export const getProfessional = id => dispatch => {
    dispatch(getProfessionalSubmit())

    API
        .get(`/api/system/professionals/single?id=${id}`)
        .then(res => res.json())
        .then(json => { 
            dispatch(getProfessionalSuccess({ professional: json.data }))
        })
        .catch(err => dispatch(getProfessionalError(err)))
}


const updateProfessionalSubmit = () => ({ type: "FETCH/REQUEST" })
const updateProfessionalSuccess = data => ({ type: "FETCH/SUCCESS", data })
const updateProfessionalError = err => ({ type: "FETCH/ERROR", error: err})

export const updateProfessional = (id, values, callback) => dispatch => {
    dispatch(updateProfessionalSubmit())

    API
        .post(`/api/system/professionals/update?id=${id}`, { body: JSON.stringify(values)})
        .then(res => res.json())
        .then(json => { 
            dispatch(updateProfessionalSuccess({ professional: json.data }))
            if(callback) callback()
        })
        .catch(err => dispatch(updateProfessionalError(err)))

}