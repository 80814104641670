import React from 'react'

import { PrimaryButton } from 'components/Buttons'

const NewClientActionsBar = ({
  onPrevStep,
  onNextStep,
  hasNext,
  hasPrev,
  nextButtonText,
  ...props
}) => (
    <div className='new-client-actions'>
        <PrimaryButton 
          onClick={onPrevStep} 
          disabled={!hasPrev}>
            Prev
        </PrimaryButton>
        <PrimaryButton 
          onClick={onNextStep} 
          disabled={!hasNext}>
            {nextButtonText}
        </PrimaryButton>
    </div>
)

export default NewClientActionsBar