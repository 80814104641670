import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import moment from 'moment';

import GlobalActions from '../../actions/GlobalActions';

const numberWithCommas = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

class DuePayments extends React.Component {

  componentDidMount() {
    this.props.LoadDuePayments();
  }

  renderPayments() {
    var key = [];
    var display = [];

    if( this.props.due_payments.length == 0 ) {
      return <p>No results found.</p>
    }

    this.props.due_payments.forEach(payment => {
      display.push(
        <PaymentListItem payment={payment} key={key++} />
      )
    })

    return display;
  }


  render() {
    var total = 0;
    this.props.due_payments.forEach(payment => {
      total += parseFloat(payment.amount)
    });

    return(
      <div className='due-payments'>
        <div className='info'>
          <h2>
            <span className='flag-icon'></span>
            Due Payments
          </h2>
          <h2 className='total-sum'>£{numberWithCommas(total.toFixed(2))}</h2>
        </div>

        <div className='listing'>
          <div className='thead'>
            <div className='row'>
              <div className='col-sm-4'></div>
              <div className='col-sm-2'>Installment</div>
              <div className='col-sm-2'>Due Date</div>
              <div className='col-sm-3'>Amount Due</div>
            </div>
          </div>
          <div className='items'>
            {this.renderPayments()}
          </div>
        </div>
      </div>
    )
  }
}

class PaymentListItem extends React.Component {
  render() {
    let payment = this.props.payment;
    let loanLink = "/dashboard/loans/details/" + payment.loan_id + "/repayments";
    let dueDate = moment(payment.period_start).format("DD/MM/YYYY");

    return(
      <div className='payment-list-item'>
        <div className='row'>
          <div className='col-sm-4'>
            {payment.client_name}
          </div>
          <div className='col-sm-2'>
            {payment.number + 1}/{payment.total}
          </div>
          <div className='col-sm-2'>
            <strong>{dueDate}</strong>
          </div>
          <div className='col-sm-2'>
            £{numberWithCommas(payment.amount.toFixed(2))}
          </div>
          <div className='col-sm-2 text-right'>
            <Link to={loanLink}>
              <span className='flag-icon'></span>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state) => {
  return {
    due_payments: []
  }
}

const mapDispatch = dispatch => {
  return {
    LoadDuePayments: () => {
      dispatch(GlobalActions.LoadDuePayments());
    },
  }
}

export default connect(mapState, mapDispatch)(DuePayments);