import { compose, withProps } from 'recompose'

import { withFormValuesOnChange } from 'components/HOC'
import { PriceField } from 'components/FormFields'
import { AddRepaymentForm } from 'containers/Loan/AddRepayment/consts'

export default compose(
    withProps(() => ({
        name: 'totalFeeAmount',
        label: "Total Fee Amount",
        prependNumber: "£",
        readOnly: true,
    })),
    withFormValuesOnChange(
        AddRepaymentForm, 
        ['grossAmount', 'arrangementFee'],
        ({ grossAmount, arrangementFee }) => {
            return parseFloat(grossAmount) * 0.01 * parseFloat(arrangementFee)
        }
    ),
)(PriceField)