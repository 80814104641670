import React from 'react'

export default ({
  client
}) => {
  const {
    entityName,
    postalAddress,
    postalPostcode,
    country,
  } = client
  

  return(
    <div className='client-info' style={{display: 'none'}}>
      <p><strong>{entityName}</strong></p>
      <p>{postalAddress}</p>
      <p>{postalPostcode} {country}</p>
    </div>
  )
}