import API from 'api'

const addProfessionalSubmit = () => ({ type: "FETCH/REQUEST" })
const addProfessionalSuccess = data => ({ 
    type: "FETCH/SUCCESS", 
    data,
    customReducer: (state) => {
        state.professionals.push(data.data)
        return state
    }
})
const addProfessionalError = err => ({ type: "FETCH/ERROR", error: err})

export const addProfessional = (values, callback) => dispatch => {
    dispatch(addProfessionalSubmit())

    API
        .post('/api/system/professionals/create', { body: JSON.stringify(values)})
        .then(res => res.json())
        .then(json => { 
            
            dispatch(addProfessionalSuccess(json))
            if(callback) callback()
        })
        .catch(err => dispatch(addProfessionalError(err)))

}