import { compose, withProps } from 'recompose'

import { withFormValuesOnChange } from 'components/HOC'
import { PriceField } from 'components/FormFields'
import { AddRepaymentForm } from 'containers/Loan/AddRepayment/consts'

export default compose(
    withProps(() => ({
        name: 'monthlyPaymentAmount',
        label: "Monthly Payment Amount",
        prependNumber: "£",
        readOnly: true,
    })),
    withFormValuesOnChange(
        AddRepaymentForm, 
        ['totalInterestAmount', 'term'],
        ({ totalInterestAmount, term }) => {
            let _term = parseInt(term.total)
            let _totalInterestAmount = parseFloat(totalInterestAmount)
            if( _term > 0 ) 
                return parseFloat(_totalInterestAmount/_term).toFixed(2)
            return 0
        }
    ),
)(PriceField)