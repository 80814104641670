import { compose, withProps, withState, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import * as actions from './actions'
import { selectPipeline } from './selectors'

const mapState = createStructuredSelector({
    pipeline: selectPipeline,
})

const mapDispatch = dispatch => ({
    ...bindActionCreators(actions, dispatch),
})

export default compose(
    connect(mapState, mapDispatch),
    lifecycle({
        componentDidMount() {
            const { getPipeline } = this.props
            getPipeline()
        }
    })
)