import React from 'react'
import { FieldArray } from 'redux-form'

import { FieldArrayHelper } from 'components/Fields'

import RenderProperties from './RenderProperties';

const PropertiesArrayField = ({
    fields,
    ...props
}) => {
    return(
        <FieldArray
            name="additionalProperties"
            component={FieldArrayHelper}
            renderFields={RenderProperties}
            {...props}
        />
    )
}

export default PropertiesArrayField