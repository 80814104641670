import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars';

import { TextButton } from 'components/Buttons'

import TooltipWrapper from './TooltipWrapper'

const ToggableTooltip = ({
    visible,
    display,
    title,
    children,
    scrollbar,
    handleClose,
    ...props
}) => {
    return(
        <TooltipWrapper {...{ visible, display, ...props }}>
            <div className='tooltip-content'>
                <a className='tooltip-close' onClick={handleClose}></a>
                <h3>{title}</h3>
                <Scrollbars
                    {...{
                        autoHeight: true,
                        autoHeightMin: 200,
                        autoHeightMax: 400,
                        ...scrollbar
                    }}
                    renderView={props => (
                        <div {...props} style={{ ...props.style, paddingBottom: 20, overflowX: 'hidden' }} />
                    )}>
                    {children}
                </Scrollbars>
            </div>
        </TooltipWrapper>
    )
}

export default ToggableTooltip