import { compose, withProps } from 'recompose'

import { Field } from 'components/FormFields'
import Formula from 'components/Fields/FormulaField'

import { validateRequired } from 'utils/validators'
const validators = [validateRequired]

export default compose(
    withProps(() => ({
        name: 'formula',
        label: "Formula",
        validators,
        component: Formula,
    }))
)(Field)