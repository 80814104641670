import API from 'api'

const getRecentWalletEntriesSubmit = () => ({ type: "FETCH/REQUEST" })
const getRecentWalletEntriesSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getRecentWalletEntriesError = err => ({ type: "FETCH/ERROR", error: err})

export const getRecentWalletEntries = () => dispatch => {
    dispatch(getRecentWalletEntriesSubmit())

    API
        .get('/api/wallet/recent')
        .then(res => res.json())
        .then(json => { 
            dispatch(getRecentWalletEntriesSuccess(json)) 
        })
        .catch(err => dispatch(getRecentWalletEntriesError(err)))
}