import React from 'react'
import { compose } from 'recompose'

import Popup from 'components/globals/Popup'
import { PrimaryButton } from 'components/Buttons'

import RedeemLoanContainer from 'containers/Loan/RedeemLoan'
import RedemptionForm from './RedemptionForm'
import RedemptionBalance from './RedemptionBalance'

const RedeemPopup = ({
  handleSubmit,
  statement,
  ...props
}) => {
    
    return(
        <Popup {...{
            button: 
                <PrimaryButton onClick={handleSubmit}>
                    REDEEM
                </PrimaryButton>,
            ...props
        }}>
            <RedemptionForm />
            {statement && 
                <RedemptionBalance {...statement.balance} />
            }
        </Popup>
    )
}

export default compose(
    RedeemLoanContainer,  
)(RedeemPopup)