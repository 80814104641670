import API from 'api'

const removeProfessionalRequest = () => ({ type: "FETCH/REQUEST" })
const removeProfessionalSuccess = data => ({ type: "FETCH/SUCCESS", data })
const removeProfessionalError = err => ({ type: "FETCH/ERROR", error: err})

export const removeProfessional = (id, callback) => dispatch => {
    dispatch(removeProfessionalRequest())

    API
        .post(`/api/system/professionals/delete?id=${id}`)
        .then(res => res.json())
        .then(json => { 
            dispatch(removeProfessionalSuccess(json))
            if(callback)
                callback()
        })
        .catch(err => {  dispatch(removeProfessionalError(err)) })
}