import styled from 'styled-components'

export default styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0;

    p {
        max-width: 75%;
        margin: 20px auto;
        text-align: center;
        color: #666;
        font-style: italic;
    }
`