import { createSelector } from 'reselect'
import { formValuesSelector } from 'utils/reduxFormUtils'

import _get from 'lodash/fp/get'

import { AddRepaymentForm } from './consts'

export const selectLoan = createSelector(
    state => state.global,
    _get("loanDetails")
)

export const selectLoanId = createSelector(
    selectLoan,
    _get("_id")
)

export const selectFormValues = formValuesSelector(AddRepaymentForm)

export const selectInitialValues = createSelector(
    selectLoan,
    (loan, props) => {
        
        
        return {
            grossAmount: loan.currentGrossAmount,
        }
    }
)