import API from 'api'

const sendReviewReminderSubmit = () => ({ type: "FETCH/REQUEST" })
const sendReviewReminderSuccess = data => ({ type: "FETCH/SUCCESS", data })
const sendReviewReminderError = err => ({ type: "FETCH/ERROR", error: err})

export const sendReviewReminder = (loanId, callback) => dispatch => {
    dispatch(sendReviewReminderSubmit())

    API
        .get(`/api/loans/reviews/send?id=${loanId}`)
        .then(res => res.json())
        .then(json => { 
            dispatch(sendReviewReminderSuccess(json))
            if(callback) callback()
        })
        .catch(err => dispatch(sendReviewReminderError(err)))

}