import styled from 'styled-components'

export default styled.div`
    background: #eee;
    padding: 15px;
    border-radius: 10px;
    color: #666;
    margin-bottom: 10px;

    p {
        margin: 0px;
        font-size: 14px;
        margin-top: 10px;
    }

    h2 {
        margin: 0px;
    }

    h3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0px;

        strong {
            font-size: 20px;
        }
    }
    
`