import { compose, withProps, withState } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm, submit, change } from 'redux-form'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import { AddRepaymentForm } from './consts'
import * as AddRepaymentActions from './actions'
import { selectFormValues, selectLoanId, selectInitialValues } from './selectors'

const mapState = createStructuredSelector({
  formValues: selectFormValues,
  loanId: selectLoanId,
  // initialValues: selectInitialValues,
})

const mapDispatch = dispatch => ({
  ...bindActionCreators(AddRepaymentActions, dispatch),
  submitForm: form => dispatch(submit(form)),
})

export default compose(
  connect(mapState, mapDispatch),
  withProps(({ submitForm, updateValue, setTermId, setAmount }) => ({
    handleSubmit: () => {
      submitForm(AddRepaymentForm)
    },
  })),
  reduxForm({
    form: AddRepaymentForm,
    enableReinitialize: true,
    onSubmit: (values, dispatch, { createRepayment, loanId, termId, formValues }) => {
      createRepayment(loanId, termId, formValues, () => {
        window.location.reload()
      })
    },
  }), 
  
)