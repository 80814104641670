import API from 'api'

const getLoanRequest = () => ({ type: "FETCH/REQUEST" })
const getLoanSuccess = data => ({ type: "FETCH/SUCCESS", key: 'loanDetails', data })
const getLoanError = err => ({ type: "FETCH/ERROR", error: err})

export const getLoan = (id, callback) => dispatch => {
    dispatch(getLoanRequest())

    API
        .get(`/api/loans/details?id=${id}`)
        .then(res => res.json())
        .then(json => { 
            dispatch(getLoanSuccess(json.loan)) 
            if( callback ) callback()
        })
        .catch(err => dispatch(getLoanError(err)))
}