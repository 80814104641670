import styled from 'styled-components'

import { PrimaryButton } from 'components/Buttons'

export default styled.div`    
    width: 100%;
    margin-bottom: 10px;
    color: #2d2d2d;
    background: #F4F4F4;
    border-radius: 5px;
    padding: 10px;

    .row {
        align-items: center;

        .col {
            flex: 1;

            h3 {
                font-style: normal;
                font-weight: 400;
                margin: 0;
                margin-bottom: 5px;
            }

            p {
                margin: 0;
                font-size: 14px;
                font-weight: 300;
            }
        }
    }

    .inner {
        background: #f9f9f9;
        border-radius: 7px;
        padding: 10px;
        box-shadow: 0 1px 1px grey;

        h3 {
            color: #2d2d2d;
            font-size: 14px;
            margin-top: 10px;
        }

        p {
            font-size: 12px;
        }

        .actions {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            margin-top: 20px;
            margin: 20px 0px 0 0px;
        }

        .spinner {
            width: 15px;
            height: 18px;
            margin-left: 5px;

            &:after {
                border-color: transparent transparent #f6bd26 #f6bd26;
                width: 15px;
                height: 15px;
            }
        }

        ${PrimaryButton} {
            font-size: 12px;
            height: 24px;
            display: flex;

            div > span {
                display: flex;
                align-items: center;
                width: 100%;

                svg {
                    margin-top: 2px;
                }
            }

            &:first-child {
                margin-right: 5px;
            }

            div {
                text-align: center;
                justify-content: center;
            }
        }
    }
`