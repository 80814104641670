import _omit from 'lodash/omit'

const flexProps = [
    'm',
    'p',
    'mx',
    'my',
    'px',
    'py',
    'mr',
    'ml',
    'mt',
    'mb',
    'pr',
    'pl',
    'pt',
    'pb',
]

export const omitFlexProps = props => _omit(props, flexProps)