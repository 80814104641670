import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import { COLORS } from 'consts'

import ToolbarButton from '../Toolbar/ToolbarButton'
import Icon from '../Toolbar/Icon'

const Menu = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 7px 6px;
    position: absolute;
    z-index: 1000000;
    top: -10000px;
    left: -10000px;
    color: #fff;
    margin-top: -20x;
    opacity: 0;
    background-color: ${COLORS.darkGray};
    border-radius: 4px;
    transition: opacity 0.75s;
    box-shadow: 0 1px 3px gray;

    a {
        color: #fff;
        text-transform: uppercase;
        font-size: 12px;
    }

    ${props => props.visible && `
        opacity: 1
    `}
`

const renderMarkButton = (text, onClick) => {
    return (
      <ToolbarButton onMouseDown={onClick}>
        {text}
      </ToolbarButton>
    )
}

const TableMenu = React.forwardRef(({ editor }, ref) => {
    const root = window.document.getElementById('root')
    return ReactDOM.createPortal(
      <Menu ref={ref}>
        {renderMarkButton('Add Row', () => { editor.insertRow() })}
        {renderMarkButton('Remove Row', () => { editor.removeRow() })}
        {renderMarkButton('Add Column', () => { editor.insertColumn() })}
        {renderMarkButton('Remove Column', () => { editor.removeColumn() })}
      </Menu>,
      root
    )
})

export default TableMenu