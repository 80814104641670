import React from 'react'
import { compose } from 'recompose'

import Page from 'components/Page'
import { Message } from 'components/Messages'
import TagListItem from 'components/ListItems/TagListItem'
import LoadingPanel from 'components/LoadingPanel'
import { SecondaryButton } from 'components/Buttons'

import ListTagsContainer from 'containers/Tags/ListTags'

const ListTags = ({
    tags,
    getTags,
    isFetching,
}) => {
    if( isFetching )
        return <LoadingPanel text="Loading tags." />
    return(
        <Page 
            title="Template Tags"
            buttons={<SecondaryButton to='/dashboard/settings/tags/create' darkGray>CREATE</SecondaryButton>}>
            {tags && tags.length > 0 &&
                <div className='listing'>
                    <div className='thead'>
                        <div className='row'>
                            <div className='col-sm-4'>Tag Name</div>
                            <div className='col-sm-4'>Description</div>
                            <div className='col-sm-2'>Type</div>
                        </div>
                    </div>
                </div>
            }
            {tags.map(tag => 
                <TagListItem key={tag._id} {...{...tag, onComplete: () => { getTags() }}} />    
            )}
            {tags && tags.length == 0 && 
                <Message text="No tags found." />
            }
        </Page>
    )
}

export default compose(
    ListTagsContainer,
)(ListTags)