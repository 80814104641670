import styled from 'styled-components'

export default styled.div`

  background: #eee;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;

  .author {
    color: #27608a;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }

  .date {
    font-style: italic;
    margin-left: 10px;
    font-size: 12px;
    color: #666;
  }

  .status {
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 10px;
    border-radius: 10px;
    color: #666;
    
    &.Info {
      background: #ecce85;
    }

    &.Rejected {
      background: #e67e7e;
    }
  }

  .message {
    font-size: 14px;
    color: #666;
  }
`