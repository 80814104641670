import { createSelector } from 'reselect'
import _get from 'lodash/fp/get'

export const selectArrangementFee = reducerPath => createSelector(
  state => state,
  _get(`${reducerPath}.arrangementFee`)
)

export const selectGrossLoanAmount = reducerPath => createSelector(
  state => state,
  _get(`${reducerPath}.grossAmount`)
)

export const selectLoan = createSelector(
  state => state.global,
  _get("loanDetails")
)

export const selectCurrentGrossAmount = createSelector(
  selectLoan,
  _get("currentGrossAmount")
)

export const selectTerms = createSelector(
  selectLoan,
  _get("terms")
)

export const selectExtensions = createSelector(
  selectLoan,
  _get("extensions")
)

export const selectActiveInterestRate = createSelector(
  selectLoan,
  selectExtensions,
  (loan, extensions) => {
    let interestRate = 0
    if( extensions && extensions.length > 0) {
      const lastExtension = extensions[extensions.length - 1]
      interestRate = lastExtension.interestRate
    } else {
      interestRate = loan.interestRate
    }
    return interestRate
  }
)

export const selectTotalFeeAmount = reducerPath => createSelector(
  selectArrangementFee(reducerPath),
  selectGrossLoanAmount(reducerPath),
  (arrangementFee, grossAmount) => { 
    const total = parseFloat(grossAmount) * parseFloat(arrangementFee)/100;
    return total;
  }
)