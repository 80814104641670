import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import { SwitchField } from 'components/FormFields'

export default compose(
    withProps(({ applicant, ...props }) => ({
        name: `${applicant}.defaults`,
        justLabel: true,
        wrapperConfig: { width: '100%' },
        label: "Have you ever had defaults registered against?",
    })),
)(SwitchField)
