import API from 'api'

export const nextStep = () => ({
    type: "NEXT_CLIENT_STEP",
})

export const prevStep = () => ({
    type: "PREV_CLIENT_STEP",
})

const updateClientSubmit = () => ({ type: "FETCH/REQUEST" })
const updateClientSuccess = data => ({ type: "FETCH/SUCCESS", data })
const updateClientError = err => ({ type: "FETCH/ERROR", error: err})

export const updateClient = (id, formValues, callback) => dispatch => {
    dispatch(updateClientSubmit())

    API
        .post(`/api/clients/update?id=${id}`, { body: JSON.stringify(formValues) })
        .then(res => res.json())
        .then(json => { 
            dispatch(updateClientSuccess(json.client)) 
            if(callback) callback(json.client)
        })
        .catch(err => dispatch(updateClientError(err)))
}


const getClientSubmit = () => ({ type: "FETCH/REQUEST" })
const getClientSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getClientError = err => ({ type: "FETCH/ERROR", error: err})

export const getClient = id => dispatch => {
    dispatch(getClientSubmit())

    API
        .get(`/api/clients/single?id=${id}`)
        .then(res => res.json())
        .then(json => { 
            dispatch(getClientSuccess(json)) 
        })
        .catch(err => dispatch(getClientError(err)))

}