import { compose, withProps } from 'recompose'

import { SwitchField } from 'components/FormFields'

export default compose(
    withProps(() => ({
        name: 'updateInterest',
        wrapperConfig: { width: '100%' },
        label: "Update monthly payment on remaining terms",
        justLabel: true,
    })),
)(SwitchField)
