import React from 'react'
import { FieldArray } from 'redux-form'

import { FieldArrayHelper } from 'components/Fields'

import RenderApplicants from './RenderApplicants';

const ApplicantsArrayField = ({
    fields,
    ...props
}) => {
    return(
        <FieldArray
            name="applicantsDetails"
            component={FieldArrayHelper}
            renderFields={RenderApplicants}
            {...props}
        />
    )
}

export default ApplicantsArrayField