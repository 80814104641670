import { createSelector } from 'reselect'
import _get from 'lodash/fp/get'

export const selectRecentWalletEntries = createSelector(
    state => state.global,
    _get("recentEntries")
)

export const selectIsFetching = createSelector(
    state => state.global,
    _get("fetching")
)