export const LoanSteps = {
  ClientSelect: 0,
  LoanDetails: 1,
  AdditionalProperties: 2,
  AdditionalSecurity: 3,
  ApplicantsDetails: 4,
  AdditionalInfo: 5,
  Confirmation: 6,
}

export const defaultState = {
  totalSteps: Object.keys(LoanSteps).length,
  step: 0,
}

export const LoanState = (state = defaultState, action) => {
  switch(action.type) {

    case "NEXT_LOAN_STEP":
      if( state.step < state.totalSteps ) {
        return Object.assign({}, state, {
          step: state.step + 1,
        });
      }
      return state;

    case "PREV_LOAN_STEP":
      if( state.step > 0 ) {
        return Object.assign({}, state, {
          step: state.step - 1,
        });
      }
      return state;

    default:
      return state;
  }
}