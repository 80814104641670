import React from 'react'

import { PrimaryButton } from 'components/Buttons'
import { Text } from 'components/Texts'

import ApplicantsWrap from './ApplicantsWrap'
import RenderApplicant from './RenderApplicant'

const RenderProperties = ({
    fields,
    pushField,
    ...props
}) => (
    <ApplicantsWrap>
        <div className='properties-actions'>
            <Text>Add applicants that apply for the loan.</Text>
            <PrimaryButton onClick={pushField}>
                Add Applicant
            </PrimaryButton>
        </div>
        {fields && fields.length > 0 &&
            <div className='properties-list'>
                {fields.map((applicant, index) => 
                    <RenderApplicant {...{ applicant, index, ...props }} key={index} />    
                )}
            </div>
        }
    </ApplicantsWrap>
)

export default RenderProperties