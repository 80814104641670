import React from 'react'
import { PropTypes } from 'prop-types'

import { getSlateContentLength } from 'utils/slate'

import Slate from 'components/Slate'

import InputWrapper from './InputWrapper'

const SlateField = ({
  autoFocus,
  disabled,
  disallowTitle,
  fontSize = '16px',
  input,
  maxLength,
  meta,
  noWrapper,
  placeholder,
  readOnly,
  type,
  ...props
}) => {
  const value = input && input.value && input.value.toJS()

  const inputComponent = (
    <Slate
      {...{
        ...input,
        value,
        autoFocus,
        disabled,
        disallowTitle,
        fontSize,
        maxLength,
        meta,
        placeholder,
        readOnly: readOnly || disabled,
      }}
    />
  )
  return noWrapper ? (
    inputComponent
  ) : (
    <InputWrapper
      hasValue={getSlateContentLength(value) !== 0}
      {...{
        ...props,
        disabled,
        fontSize,
        meta,
        placeholder,
      }}
    >
      {inputComponent}
    </InputWrapper>
  )
}

SlateField.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  fontSize: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  noWrapper: PropTypes.bool,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
}

export default SlateField
