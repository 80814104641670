import React from 'react';

import { FormStep } from 'components/Forms'

import MainProperty from './views/MainProperty'
import LoanCalculations from './views/LoanCalculations'
import LoanSpecification from './views/LoanSpecification'
import PersonalGuarantee from './views/PersonalGuarantee'

const LoanDetails = () => (
  <FormStep title="New Loan Details">
    <PersonalGuarantee />
    <MainProperty />
    <LoanSpecification />
    <LoanCalculations />
  </FormStep>       
)

export default LoanDetails