import React from 'react'
import moment from 'moment'
import { PropTypes } from 'prop-types'
import { compose, withProps, withStateHandlers } from 'recompose'
import DatePicker from 'react-datepicker'

import { DEFAULT_DATEPICKER_FORMAT } from 'consts'

import { withActionOnOutsideClick } from 'components/HOC'

import DatepickerFieldInput from './DatepickerFieldInput'
import DatepickerFieldWrapper from './DatepickerFieldWrapper'

import 'react-datepicker/dist/react-datepicker.css'

const DatepickerField = ({
  datepickerVisible,
  input,
  showDatepicker,
  onChange,
  ...props
}) => {
  let value = input.value ? moment(input.value).toDate() : null;

  return(
    <DatepickerFieldWrapper props={props.full}>
      <DatePicker
        open={datepickerVisible}
        dateFormat={DEFAULT_DATEPICKER_FORMAT}
        customInput={
          <DatepickerFieldInput
            {...{ ...props, input, datepickerVisible }}
            onInputClick={showDatepicker}
            append={
              <i className="icon-5-calender" style={{ cursor: 'pointer' }} />
            }
          />
        }
        selected={value}
        {...{ onChange }}
      />
    </DatepickerFieldWrapper>
  )
}

DatepickerField.propTypes = {
  datepickerVisible: PropTypes.bool,
  input: PropTypes.object,
  showDatepicker: PropTypes.func,
  onChange: PropTypes.func,
}

export default compose(
  withStateHandlers(
    {
      datepickerVisible: false,
    },
    {
      showDatepicker: () => () => ({
        datepickerVisible: true,
      }),
      hideDatepicker: () => () => {
        return {
          datepickerVisible: false,
        }
      },
    },
  ),
  withProps(({ hideDatepicker, input }) => ({
    onChange: value => {
      input.onChange(value)
      hideDatepicker()
    },
  })),
  withActionOnOutsideClick('datepickerVisible', 'hideDatepicker'),
)(DatepickerField)
