import { connect } from 'react-redux'
import { reduxForm, submit } from 'redux-form'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import { withFormValues } from 'components/HOC'

import { redirectToPath } from 'utils/router'

import * as actions from './actions'
import { FORM_NAME } from './consts'

const mapDispatch = dispatch => ({ 
    ...bindActionCreators(actions, dispatch),
    submitForm: () => dispatch(submit(FORM_NAME)),
})

export default compose(
    connect(null, mapDispatch),
    withRouter,
    reduxForm({
        form: FORM_NAME,
        onSubmit: (values, dispatch, { addProfessional, history, reset }) => {
            addProfessional(values, () => {
                reset()
                history.push('/dashboard/settings/professionals')
            })
        }
    }),
    withFormValues(FORM_NAME),
)