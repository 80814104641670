import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import UserPicture from '../user/UserPicture';
import UserAccountActions from '../user/UserAccountActions';

class CultchaUserHeader extends React.Component {

  render() {
    return(
      <div className='cultcha-user-header'>
        <UserAccountActions />
      </div>
    );
  }
}

const mapState = (state) => {
  return {
    user: state.user.user,
  }
}

export default connect(mapState)(CultchaUserHeader);
