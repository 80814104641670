import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

const validators = [validateRequired]

const options = [
    { value: 'Single', label: 'Single' },
    { value: 'Married', label: 'Married' },
    { value: 'Divorced', label: 'Divorced' },
    { value: 'Widowed', label: 'Widowed' },
]

export default compose(
    withProps(({ applicant, ...props }) => ({
        placeholder: 'Select Martial Status',
        name: `personalDetails.martialStatus`,
        wrapperConfig: { width: '100%' },
        component: DropdownField,
        label: "Martial Status",
        isClearable: true,
        options: options,
        full: true,
        validators,
    })),
)(Field)
