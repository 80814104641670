import styled from 'styled-components'

export default styled.label`
  top: 17px;
  left: 15px;
  font-size: ${props => props.fontSize || '14px'};
  line-height: 21px;
  position: absolute;
  pointer-events: none;

  ${props =>
    props.moveLabel &&
    `
    transform: translateY(-9px);
    font-size: 12px;
    line-height: 15px;
  `};

  ${props =>
    props.large &&
    `
    top: 28px;
    font-size: 24px;
    line-height: 26px;
  `};

  ${props =>
    props.large &&
    props.moveLabel &&
    `
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 10px;
    transform: translateY(-15px);
  `};
`
