import React from 'react';

import Row from 'components/Row'
import { FormStep } from 'components/Forms'

import { ApplicationDetailsFields } from 'components/FormFields/Client'

const ApplicationDetails = () => (
    <FormStep title="Application Details">
        <Row>
            <ApplicationDetailsFields.ApplicantsArrayField />
        </Row>
    </FormStep>
)

export default ApplicationDetails