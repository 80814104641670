import React from 'react';

import { FormStep } from 'components/Forms'

import { LoanBorrowerField } from 'components/FormFields/Loan'

const Borrower = () => (
    <FormStep title="Choose Existing Client">
        <LoanBorrowerField />
    </FormStep>
)

export default Borrower