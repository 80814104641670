import React from 'react'
import { compose } from 'recompose'
import { Link } from 'react-router-dom'

import Page from 'components/Page'
import { Message } from 'components/Messages'
import WalletEntryListItem from 'components/ListItems/WalletEntryListItem'
import LoadingPanel from 'components/LoadingPanel'
import { SecondaryButton } from 'components/Buttons'

import ListWalletEntriesContainer from 'containers/Wallet/ListEntries'

const ListEntries = ({
    entries,
    getWalletEntries,
    isFetching,
}) => {
    if( isFetching )
        return <LoadingPanel text="Loading wallet history." />

    return(
        <Page 
            title="Wallet Entries"
            buttons={<SecondaryButton to='/dashboard/wallet/create' darkGray>ADD</SecondaryButton>}>
            {entries && entries.length > 0 &&
                <div className='listing'>
                    <div className='thead'>
                        <div className='row'>
                            <div className='col-sm-4'>User</div>
                            <div className='col-sm-3'>Type</div>
                            <div className='col-sm-2'>Amount</div>
                        </div>
                    </div>
                </div>
            }
            {entries && entries.map(entry => 
                <WalletEntryListItem key={entry._id} {...{...entry, onComplete: () => { getWalletEntries() }}} />    
            )}
            {entries && entries.length == 0 && 
                <Message text="No entries found." />
            }
        </Page>
    )
}

export default compose(
    ListWalletEntriesContainer,
)(ListEntries)