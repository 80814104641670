import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Overview from './views/Overview'
import Documents from './views/Documents'
import Directors from './views/Directors'
import Applicants from './views/Applicants'

const ClientTabs = props => {

    return(
        <div className='loan-sub-page'>
            <Switch>
                <Route exact path='/dashboard/clients/details/:id/' {...props} component={Overview} />
                <Route exact path='/dashboard/clients/details/:id/directors' component={Directors} />
                <Route exact path='/dashboard/clients/details/:id/applicants' component={Applicants} />
                <Route exact path='/dashboard/clients/details/:id/documents' render={ routeProps => {
                    return <Documents {...{ routeProps, objectId: routeProps.match.params.id }} />
                }}/>
                
            </Switch>
        </div>
    )
}

export default ClientTabs