import React from 'react'
import styled from 'styled-components'

import { COLORS } from 'consts'

const GenericListItemWrapper = styled.div`
    font-size: 12px;
    align-items: center;

    h4 {
        margin: 0;
        font-size: 14px!important;
        text-transform: none!important;
        font-style: normal!important;
        font-weight: 400!important;
    }

    p {
        text-transform: none!important;
        font-size: 14px!important;
    }

    .row > div {
        display: flex;
        align-items: center;
    }

    strong {
        color: #666;
    }

    .details {
        display: block;
        width: 100%;
        font-size: 12px;
        font-style: italic;
        margin-top: 5px;
    }

    &.duePayment {
        background: ${COLORS.duePayment}!important;
        color: #fff;

        strong {
            color: #fff;
        }
    }
`

const GenericListItem = ({ children, className, onClick, ...props }) => {
    return(
        <GenericListItemWrapper className={`obj-list-item ${className}`}
            onClick={onClick}
            {...props}>
            <div className='row'>
                {children}
            </div>
        </GenericListItemWrapper>
    )
}

export default GenericListItem