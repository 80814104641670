import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import { SwitchField } from 'components/FormFields'

const validators = [validateRequired]

export default compose(
    withProps(() => ({
        wrapperConfig: { width: '100%' },
        showDetails: (checked) => { return false },
        justLabel: true,
        label: "Is New Build?",
        name: 'mpNewBuild',
    })),
)(SwitchField)
