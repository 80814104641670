import React from 'react'
import { PropTypes } from 'prop-types'
import { compose, withPropsOnChange } from 'recompose'
import styled from 'styled-components'
import Flex from 'components/Flex'

import { COLORS, FONTS } from 'consts'

import { getColor } from 'utils/color'

import { withSkeleton } from 'components/HOC'
import SkeletonBase from 'components/SkeletonBase'

// prettier-ignore
const DefaultText = styled(Flex)`
  font-family: ${FONTS.primary};
  color: ${COLORS.dark};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  word-break: break-word;
  display: ${props => (props.block ? 'block' : 'inline-flex')};
  ${props => props.full && `width: 100%`};
  ${props => props.interactive && `
    cursor: pointer;
  `};
  ${props => props.alignRight && `
    text-align: right;
    justify-content: flex-end;
  `};
  ${props => props.alignLeft && `
    text-align: left;
    justify-content: flex-start;
  `};

  ${props => props.alignCenter && `
    text-align: center;
    align-self: center;
    justify-content: center;
  `};

  ${props => props.normal && `
    font-weight: normal;
  `};

  ${props => props.block && `
    display: block;
  `};

  ${props => props.ellipsis && `
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    max-width: 100%;
  `};

  ${props => props.inline && 'width: auto'};

  ${props => props.strike && 'text-decoration: line-through'};
  ${props => props.bold && 'font-weight: bold'};
  ${props => props.light && 'font-weight: lighter'};

  color: ${props => getColor(props, COLORS.darkGray)};

  ${props => props.transparent && `opacity: 0.6`};
  ${props => props.toRight && `text-align: right`};
  ${props => props.center && `text-align: center;`};

  ${props => `font-size: ${props.fontSize}`};
  ${props => `line-height: ${props.lineHeight}`};

  ${props => props.inherit && `
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin-top: 0;
    margin-bottom: 0;
  `};

  a {
    color: inherit;
  }
`

const Skeleton = ({ fontSize, lineHeight, skeletonWidth, width, ...props }) => {
  const wrapperHeight = parseInt(lineHeight, 10)
  const contentHeight = parseInt(fontSize, 10)
  const actualWidth = parseInt(skeletonWidth || width, 10) || 100

  return (
    <SkeletonBase
      height={contentHeight}
      width={actualWidth}
      wrapperHeight={wrapperHeight}
      {...props}
    >
      <rect
        x="0"
        y="0"
        rx="6"
        ry="6"
        width={actualWidth}
        height={contentHeight}
      />
    </SkeletonBase>
  )
}

Skeleton.propTypes = {
  fontSize: PropTypes.string,
  skeletonWidth: PropTypes.string,
  lineHeight: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default compose(
  withPropsOnChange(['fontSize'], ({ fontSize, lineHeight }) => ({
    lineHeight:
      lineHeight ||
      `${parseInt(fontSize, 10) + parseInt(parseInt(fontSize, 10) / 3, 10)}px`,
  })),
  withSkeleton(Skeleton),
)(DefaultText)
