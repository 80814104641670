let _defState = {
  user: null,
  connected: false,
};

var dotProp = require('dot-prop-immutable');

const defaultState = (state = _defState, action) => {
  switch(action.type) {

    case "INIT_USER":
      var user = action.user;

      if( user != null )
        user.can = (pName) => {
          if( user.hasOwnProperty("permissions") ) {
            if( user.permissions.indexOf(pName) >= 0 )
              return true;
            return false;
          }
          return false;
        };

      return Object.assign({}, state, {
        user: user,
      });

    case "CONNECT_IO":
      //window._socket.emit("user_join", { "user_id" : action.user._id });
      
      return Object.assign({}, state, {
        connected: true,
      })



    default:
      return state;
  }
}

export default defaultState;
