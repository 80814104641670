import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

import ListUsersContainer from 'containers/User/ListUsers'

const validators = [validateRequired]

export default compose(
  ListUsersContainer,
  withProps(({
    users,
  }) => ({
    name: 'managedBy',
    wrapperConfig: { width: '100%' },
    component: DropdownField,
    placeholder: 'Select Loan Manager',
    label: "Managed By",
    isClearable: true,
    full: true,
    options: users && users.map(user => ({
      label: `${user.first_name} ${user.last_name}`,
      value: user.id
    })),
    validators,
  })),
)(Field)
