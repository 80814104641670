import Cookies from "js-cookie"
import { compose, withProps, withState, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import * as actions from './actions'

const ACCESS_CODE_LENGTH = 6

const mapState = createStructuredSelector({
  
})

const mapDispatch = dispatch => ({
  ...bindActionCreators(actions, dispatch),
})

export default compose(
    connect(mapState, mapDispatch),
    withState('code', 'setCode', []),
    withProps(({ id, verifyCode, code, setCode }) => ({
      setCodePart: index => e => {
        const next = index + 2 <= ACCESS_CODE_LENGTH ? `c${index + 2}` : null
        
        if (next != null) {
          const el = document.getElementById(next)
          if (el)
            el.focus()
        }

        const codePart = e.target.value
        const nCode = code
        nCode[index] = codePart
        
        setCode(nCode)
      },
      verifyAccess: () => {
        const codeStr = code.join('')
        if(codeStr.length != ACCESS_CODE_LENGTH) {
          return
        }

        verifyCode(id, codeStr)
      },
    })),
    lifecycle({
      componentDidMount() {
        const { setCode } = this.props
        const code = new Array(ACCESS_CODE_LENGTH)
        
        setCode(code)
      }
    })
)