import { compose, withProps } from 'recompose'

import { withFormValuesOnChange } from 'components/HOC'
import { PriceField } from 'components/FormFields'
import { AddLoanForm } from 'containers/Loan/AddLoan/consts'

export default compose(
    withProps(() => ({
        name: 'totalLoanCost',
        label: "Total Loan Cost",
        prependNumber: "£",
        readOnly: true,
    })),
    withFormValuesOnChange(
        AddLoanForm, 
        [
            'totalFeeAmount', 
            'totalInterestAmount', 
            'totalExitAmount', 
            'solicitorFee',
            'brokerFee',
        ],
        ({ 
            totalFeeAmount, 
            totalInterestAmount, 
            totalExitAmount, 
            solicitorFee,
            brokerFee,
        }) => {
            return totalFeeAmount + totalInterestAmount + totalExitAmount + parseFloat(solicitorFee) + parseFloat(brokerFee)
        }
    ),
)(PriceField)