import { connect } from 'react-redux'
import { reduxForm, submit } from 'redux-form'
import { compose, withState } from 'recompose'
import { createStructuredSelector }  from 'reselect'
import { bindActionCreators } from 'redux'

import { withFormValues } from 'components/HOC'

import * as actions from './actions'
import { FORM_NAME } from './consts'
import { selectInitialValues } from './selectors'

const mapState = createStructuredSelector({
    initialValues: selectInitialValues,
})

const mapDispatch = dispatch => ({ 
    ...bindActionCreators(actions, dispatch),
    submitForm: () => dispatch(submit(FORM_NAME)),
})

export default compose(
    connect(mapState, mapDispatch),
    withState('isUpdating', 'setUpdating', false),
    reduxForm({
        form: FORM_NAME,
        onSubmit: (values, dispatch, { id, updateTags, setUpdating }) => {
            setUpdating(true)
            updateTags(id, values, () => {
                setTimeout(() => setUpdating(false), 1000)
            })
        },
        enableReinitialize: true,
    }),
    withFormValues(FORM_NAME),
)