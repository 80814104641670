import React from 'react'
import Cookies from "js-cookie";

import DashboardTopSectionWrapper from './DashboardTopSectionWrapper'

export default () => {
  const handleClick = () => {
    Cookies.remove('connect.sid');

    window.location.href = window.location.origin;
  }
  return (
    <DashboardTopSectionWrapper>
      <div className='flex'>
        <div className='row'>
          <div className='col'>
            <strong>Today:</strong> 17 Sep 2018 | 12:34
          </div>
          <div className='col'>
            <strong>Last Login:</strong> 17 Sep 2018 | 12:34
          </div>
          <div className='col text-right'>
            <a onClick={handleClick}>
              Logout
            </a>
          </div>
        </div>
        <div className='row money-overview'>
          <div className='col'>
            Available Funds:
            <strong>£34,000,000.00</strong>
          </div>
          <div className='col'>
            Money Out:
            <strong>£12,234,234.09</strong>
          </div>
        </div>
      </div>
    </DashboardTopSectionWrapper>
  )
}