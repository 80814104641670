import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

const validators = [validateRequired]

const ternureOptions = [
    { value: 'Freehold', label: 'Freehold' },
    { value: 'Leasehold', label: 'Leasehold' },
]

export default compose(
    withProps(({ property, ...props }) => ({
        name: `${property}.ternure`,
        wrapperConfig: { width: '100%' },
        component: DropdownField,
        placeholder: 'Select Ternure',
        label: "Ternure",
        isClearable: true,
        full: true,
        options: ternureOptions,
        validators,
    })),
)(Field)
