import React from 'react'

import RenderDeclaration from './RenderDeclaration'
import DeclarationsWrap from './DeclarationsWrap';

const RenderDeclarations = ({
    fields,
    pushField,
    ...props
}) => (
    <DeclarationsWrap>
        {fields && fields.length > 0 &&
            <div className='declarations-list'>
                {fields.map((declaration, index) => 
                    <RenderDeclaration {...{ declaration, index, ...props }} key={index} />    
                )}
            </div>
        }
    </DeclarationsWrap>
)

export default RenderDeclarations