import React from 'react'

import Page from 'components/Page'

import LoanOverview from './views/LoanOverview'
import MainPropertyOverview from './views/MainPropertyOverview'

const LoanDetails = props => {
    return(
        <Page title=''>
            <LoanOverview {...props} />
            <MainPropertyOverview {...props} />
        </Page>
    )
}

export default LoanDetails