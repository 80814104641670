import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import SubmenuInnerWrapper from './SubmenuInnerWrapper'


const ClientDetailsSubmenu = ({

}) => {
  const { id } = useParams()

  return(
    <SubmenuInnerWrapper>
      <ul>
        <li>
          <NavLink exact to={`/dashboard/clients/details/${id}`}>Overview</NavLink>
        </li>
        <li>
          <NavLink to={`/dashboard/clients/details/${id}/directors`}>Directors</NavLink>
        </li>
        <li>
          <NavLink to={`/dashboard/clients/details/${id}/applicants`}>Applicants</NavLink>
        </li>
        <li>
          <NavLink to={`/dashboard/clients/details/${id}/documents`}>Documents</NavLink>
        </li>
      </ul>
    </SubmenuInnerWrapper>
  )
}

const mapState = createStructuredSelector({
  loan: state => state.global.loanDetails
})

export default ClientDetailsSubmenu