import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { compose, lifecycle } from 'recompose'

import * as actions from './actions'
import { selectTemplates, selectIsFetching } from './selectors'

const mapState = createStructuredSelector({
    templates: selectTemplates,
    isFetching: selectIsFetching,
})

const mapDispatch = dispatch => ({
    ...bindActionCreators(actions, dispatch)
})

export default compose(
    connect(mapState, mapDispatch),
    lifecycle({
        componentDidMount() {
            const { getTemplates } = this.props
            getTemplates()
        }
    })
)