import styled from 'styled-components'

import { COLORS } from 'consts'

export default styled.div`
    color: #515151;
    font-size: 12px;
    text-align: left;
    word-break: break-word;
    max-width: 90px;
    display: flex;
    align-items: center;

    span {
        margin-right: 3px;
    }

    ${props => props.checked && `
        color: ${COLORS.green};
        max-width: none;
    `}
`