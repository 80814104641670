import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

const validators = [validateRequired]

const options = [
    { value: 'Tenant', label: 'Tenant' },
    { value: 'Owner', label: 'Owner' },
]

export default compose(
    withProps(({ applicant, ...props }) => ({
        placeholder: 'Select Occupancy Type',
        name: `${applicant}.occupancyType`,
        wrapperConfig: { width: '100%' },
        component: DropdownField,
        label: "Occupancy Type",
        isClearable: true,
        options: options,
        full: true,
        validators,
    })),
)(Field)
