import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

const validators = [validateRequired]

const options = [
    { value: 'Flat', label: 'Flat' },
    { value: 'House', label: 'House' },
]

export default compose(
    withProps(({ applicant, ...props }) => ({
        placeholder: 'Select Property Type',
        name: `personalDetails.propertyType`,
        wrapperConfig: { width: '100%' },
        component: DropdownField,
        label: "Property Type",
        isClearable: true,
        options: options,
        full: true,
        validators,
    })),
)(Field)
