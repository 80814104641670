import React from 'react';
import {connect} from 'react-redux';
import {Scrollbars} from 'react-custom-scrollbars';

import TagsActions from 'actions/TagsActions';
import TagListItem from './TagListItem';
import Spinner from 'components/globals/Spinner';

class TagsPopup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            phrase: "",
        };
        this.timeout = null;
    }

    handleClose() {
        this.props.editor.hideTagsPopup();
    }

    componentDidMount() {
        this.props.LoadTags();
    }

    handleInsert(tag) {
        let self = this;
        
        this.props.editor.insertTag(tag);
        
        setTimeout( function() {
            self.handleClose()
        }, 50);
    }

    handleSearch(e) {
        var value = e.target.value; 
        
        this.setState({
            phrase: value,
        }, () => {
            clearTimeout(this.timeout);

            this.timeout = setTimeout( () => {
                this.props.Search(this.state.phrase);
            });
        })
    }

    renderClientFields() {
        var key = 0;
        var display = [];

        this.props.client_tags.forEach(tag => {
            display.push(
                <TagListItem popup={this} tag={tag} key={key++} />
            )
        });

        return display;
    }

    renderLoanFields() {
        var key = 0;
        var display = [];

        this.props.loan_tags.forEach(tag => {
            display.push(
                <TagListItem popup={this} tag={tag} key={key++} />
            )
        });

        return display;
    }

    renderGeneralFields() {
        var key = 0;
        var display = [];

        this.props.general_tags.forEach(tag => {
            display.push(
                <TagListItem popup={this} tag={tag} key={key++} />
            )
        });

        return display;
    }

    renderTags() {
        var key = 0;
        var display = [];

        this.props.tags.forEach(tag => {
            display.push(
                <TagListItem popup={this} tag={tag} key={key++} />
            )
        });

        return display;
    }

    hasClientFields() {
        return this.props.client_tags.length > 0;
    }

    hasLoanFields() {
        return this.props.loan_tags.length > 0;
    }

    hasUserFields() {
        return this.props.user_tags.length > 0;
    }

    hasGeneralFields() {
        return this.props.general_tags.length > 0;
    }

    hasGeneratedFields() {
        return this.props.generated.length > 0;
    }

    render() {
        return(
            <div className='tags-popup-outer'>
                <div className='tags-popup-overlay' onClick={this.handleClose.bind(this)}></div>
                <div className='tags-popup-content'>
                    <a className='close-popup' onClick={this.handleClose.bind(this)}></a>
                    <h2>Tags</h2>
                    <div className='search-tags'>
                        <input onChange={this.handleSearch.bind(this)} type='text' />
                        <a className='btn btn-blue btn-search'></a>
                        {this.props.fetching && <Spinner /> }
                    </div>
                    <div className='search-results'>
                        <Scrollbars>
                            <div className='search-results-inner'>
                                <div>
                                    <h3>Tags</h3>
                                    <div className='client-fields'>
                                        {this.renderTags()}
                                    </div>
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                    <div className='tags-actions'>
                        <a className='btn btn-default' onClick={this.handleClose.bind(this)}>Cancel</a>
                        <a className='btn btn-blue'>Insert</a>
                    </div>
                </div>
            </div>
        )
    }
}

const mapState = state => {
    return {
        tags: state.global.tags,
        fetching: state.global.fetching,
    }
}

const mapDispatch = dispatch => {
    return {
        LoadTags: (search) => {
            dispatch(TagsActions.Search(search));
        },
        Search: (search) => {
            dispatch(TagsActions.Search(search)); // stupid
        },
    }    
}

export default connect(mapState, mapDispatch)(TagsPopup);