import { createSelector } from 'reselect'
import { formValuesSelector } from 'utils/reduxFormUtils'

import _get from 'lodash/fp/get'

import { AddClientForm } from './consts'

export const selectNewClient = createSelector(
    state => state,
    _get("client")
)

export const selectStep = createSelector(
    state => state.client,
    _get("step")
)

export const selectFormValues = formValuesSelector(AddClientForm)
