import queryString from 'query-string'
import { matchPath } from 'react-router-dom'

import history from 'utils/history'

export const cropParam = to => to.replace(/\/:[^/].*$/g, '')

export const pathnameIncludes = (pathname, paths) =>
  paths.some(path => matchPath(pathname, { path, exact: true }))

export const pathnameExact = (pathname, path) =>
  matchPath(pathname, {
    path,
    exact: true,
  })

export const replaceParams = (to, params) =>
  Object.keys(params).reduce((acc, key) => {
    // eslint-disable-next-line no-useless-escape
    const regex = new RegExp(`:${key}\?`, 'g')
    return acc.replace(regex, params[key])
  }, to)

export const getPath = (to, params, query, keepQuery = false) => {
  let queryObject = query
  let resultQuery = ''

  if (typeof document !== 'undefined' && keepQuery) {
    queryObject = {
      ...queryString.parseUrl(document.location.href).query,
      ...queryObject,
    }
  }

  resultQuery = queryString.stringify(queryObject)

  return (
    cropParam(params ? replaceParams(to, params) : to) +
    (resultQuery && `?${resultQuery}`)
  ).replace('??', '?')
}

export const getQueryParams = () => {
  if (typeof document !== 'undefined') {
    return queryString.parseUrl(document.location.href).query
  }

  return null
}

export const redirectToPath = (to, params, query, keepQuery) => {
  const newPath = getPath(to, params, query, keepQuery)

  if (
    typeof document === 'undefined' ||
    newPath !== `${document.location.pathname}?`
  ) {
    history.push(newPath)
  }
}

export const parseSearch = search => queryString.parse(search)
