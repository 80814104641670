import { createSelector } from 'reselect'
import _get from 'lodash/fp/get'

export const selectInputTags = createSelector(
    state => state.global,
    _get("inputTags")
)

export const selectInitialValues = createSelector(
    selectInputTags,
    inputTags => {
        const map = {}
        if( inputTags )
            inputTags.forEach(tag => {
                map[tag._id] = tag.value
            })
        return map
    }
)