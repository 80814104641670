import styled, { css } from 'styled-components'

import { COLORS } from 'consts'

import { getColor } from 'utils/color'

import ButtonBase from './ButtonBase'

const activeStyles = props => `
  color: ${COLORS.white};
  background: ${getColor(props)};

  ${!props.toggleButton &&
    `
    pointer-events: none;

    :hover {
      box-shadow: none;
      cursor: default;
    }
  `};
`

export const PrimaryButtonStyle = css`
  height: ${props => props.height || '40px'};
  padding: 0 20px;
  font-size: ${props => props.fontSize || '16px'};
  text-transform: uppercase;
  border-radius: 6px;
  text-decoration: none;
  background: none;

  i,
  img {
    margin-right: 6px;
    margin-left: -8px;
    font-size: ${props => props.iconFontSize || '17px'};
  }

  i::before {
    font-size: 1em;
  }

  ${props => `
    color: ${props.inverted ? COLORS.white : getColor(props)};
    ${props.inverted && `background: ${getColor(props)}`};
    border: 1px solid ${getColor(props)};

    :hover {
      color: ${COLORS.white};
      background: ${getColor(props)};
    }
  `};

  ${props =>
    props.large &&
    `
    font-size: 18px;
    height: 32px;
  `};

  ${props =>
    props.disabled &&
    !props.inverted &&
    `
    border-color: ${COLORS.lightGray} !important;
    color: ${COLORS.lightGray} !important;
  `};

  ${props =>
    props.disabled &&
    props.inverted &&
    `
    background: ${COLORS.lightGray} !important;
    border-color: ${COLORS.lightGray} !important;
  `};
`

export default styled(ButtonBase)`
  ${PrimaryButtonStyle};
`
