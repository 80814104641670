import { compose, withProps, withState } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm, submit } from 'redux-form'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import { LoanSteps } from 'reducers/NewLoanState'

import { AddLoanForm } from './consts'
import * as NewLoanActions from './actions'
import { selectStep, selectFormValues, selectPersonalGuarantee } from './selectors'

const mapState = createStructuredSelector({
  step: selectStep,
  formValues: selectFormValues,
  initialValues: state => ({
    startDate: new Date(),
  }),
  isPersonalGuarantee: selectPersonalGuarantee,
})

const mapDispatch = dispatch => ({
  ...bindActionCreators(NewLoanActions, dispatch),
  submitForm: form => dispatch(submit(form)),
})

export default compose(
    connect(mapState, mapDispatch),
    withState('isCreating', 'setCreating', false),  
    withState('redirectUrl', 'setRedirect', ''),
    withProps(({ nextStep, prevStep, step, ...props }) => ({
      isCreateStep: step == Object.keys(LoanSteps).length - 2,
      hasNext: step < Object.keys(LoanSteps).length - 1,
      hasPrev: step > 0,
      nextButtonText: step < Object.keys(LoanSteps).length - 2 ? "Next" : "Create",
      onNextStep: () => {
        if( step < Object.keys(LoanSteps).length - 1)
          props.submitForm(AddLoanForm)
      },
      onPrevStep: () => {
        if( step > 0 )
          prevStep()
      },
    })),
    reduxForm({
      form: AddLoanForm,
      onSubmit: (values, dispatch, { setRedirect, nextStep, isCreateStep, isCreating, setCreating, createLoan, formValues }) => {
        if(nextStep) 
          nextStep()
        
        if(isCreateStep && !isCreating) {
          setCreating(true)
          
          createLoan(formValues, (loan) => {
            setRedirect(`/dashboard/loans/details/${loan._id}`)
            setCreating(false)
          })
        }
      },
    }), 
)