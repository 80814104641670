import React from 'react'
import { connect } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import SubmenuInnerWrapper from './SubmenuInnerWrapper'

const SettingsSubmenu = ({

}) => {
  
  return(
    <SubmenuInnerWrapper>
      <ul>
        <li>
          <NavLink to={`/dashboard/settings/checklist`}>Checklist</NavLink>
        </li>
        <li>
          <NavLink to={`/dashboard/settings/globals`}>Globals</NavLink>
        </li>
        <li>
          <NavLink exact to={`/dashboard/settings/professionals`}>Professionals</NavLink>
        </li>
        <li>
          <NavLink exact to={`/dashboard/settings/tags`}>Tags</NavLink>
        </li>
        <li>
          <NavLink exact to={`/dashboard/settings/templates`}>Templates</NavLink>
        </li>
        <li>
          <NavLink exact to={`/dashboard/settings`}>Users</NavLink>
        </li>
      </ul>
    </SubmenuInnerWrapper>
  )
}


export default  SettingsSubmenu