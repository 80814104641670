import React from 'react'
import moment from 'moment'

import { Flex } from '@rebass/grid'
import { compose, withState, withProps } from 'recompose'

import Popup from 'components/globals/Popup'
import DocumentUploader from 'components/DocumentsUploader'
import DocumentListItem from 'components/ListItems/DocumentListItem'
import { SecondaryButton, PrimaryButton } from 'components/Buttons'
import { Message } from 'components/Messages'

import DocumentsContainer from 'containers/Documents'
import CheckLevelContainer from 'containers/Checks/CheckLevel'

import LoanCheckLevelWrapper from './LoanCheckLevelWrapper'
import LoanCheckLevelMessage from './LoanCheckLevelMessage'
import DocumentsList from './DocumentsList'
import DocumentsListToggle from './DocumentsListToggle'
import CheckedDate from './CheckedDate'

import SwitchField from 'components/FormFields/SwitchField'

const LoanCheckLevel = ({
    level,
    loanId,
    checkId,
    levelData,
    levelUser,
    user,
    objectId,
    documents,
    isChecked,
    isUserLevel,
    enableUpload,
    uploadVisible,
    toggleUpload,
    toggleFileList,
    fileListVisible,
    downloadFile,
    handleRemove,
    confirmPopupVisible,
    toggleConfirmPopup,
    handleConfirm,
    toggleCheck,
    disabled,
    ...props
}) => {
    if(!user || !levelUser)
        return null

    return(
        <LoanCheckLevelWrapper>                
            <Flex alignItems="center">
                <CheckedDate>{isChecked ? moment(levelData.checkDate).format('DD/MM/YYYY') : 'N/A'}</CheckedDate>
                <SwitchField 
                    justLabel={true}
                    name={`${loanId}_${checkId}_${level}`}
                    onChange={(state) => {
                        toggleCheck(level, state.checked)
                    }}
                    checked={isChecked || false}
                    currentValue={{ checked: isChecked}}
                    disabled={!isUserLevel || disabled}
                />
            </Flex>
        </LoanCheckLevelWrapper>
    )
}

export default compose(
    withState('fileListVisible', 'setFileListVisible', false),
    withState('confirmPopupVisible', 'setConfirmPopupVisible', false),
    withProps(({ 
        checkId, 
        loanId, 
        user,
        level,
        levelData,
        levelUser,
        fileListVisible, 
        setFileListVisible, 
        confirmPopupVisible, 
        setConfirmPopupVisible 
    }) => ({
        objectId: `${checkId}_${loanId}`,
        isUserLevel: levelUser && user && levelUser._id == user._id,
        isChecked: levelData && levelData.checked,
        toggleFileList: () => {
            setFileListVisible(!fileListVisible)
        },
        toggleConfirmPopup: () => {
            setConfirmPopupVisible(!confirmPopupVisible)
        },
    })),
    DocumentsContainer,
    CheckLevelContainer,
)(LoanCheckLevel)