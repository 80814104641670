import styled from 'styled-components'

const Flex = styled.div`
    display: flex;
    width: 100%;
    align-items: center;

    ${props => props.width && `
        width: ${props.width};
    `};
`

export default Flex