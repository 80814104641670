import React from 'react'

import Row from 'components/Row'
import { Panel } from 'components/Forms'
import ClientField from 'components/Clients/ClientField'

const DirectorListItem = ({
    index,
    name,
    shares
}) => {

    return(
        <Panel title={`Director #${index}`} expandable initiallyExpanded>
            <Row alignTop>
                <ClientField label="Director Name" type='text' forceValue={name} />
                <ClientField label="Director Shares" type='number' forceValue={shares} append="%" />
            </Row>
        </Panel>
    )
}

export default DirectorListItem