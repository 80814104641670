import React from 'react'

import MessageWrap from './MessageWrap'

const Message = ({
    text,
    children,
    ...props
}) => (
    <MessageWrap {...props}>
        {text}
        {children}
    </MessageWrap>
)

export default Message