import { compose, withProps } from 'recompose'

import { SwitchField } from 'components/FormFields'

export default compose(
    withProps(({ applicant, ...props }) => ({
        name: `${applicant}.iva`,
        justLabel: true,
        wrapperConfig: { width: '100%' },
        label: "Have you entered into any IVA or debt management plan?",
    })),
)(SwitchField)
