import API from 'api';

let TagsActions = {

  Create: (data, callback) => {
    return (dispatch) => {

      dispatch({
        type: "CREATE_TAG_BEGIN",
        data: data,
        callback: callback,
      });

      API.post("/api/tags/create", { body: JSON.stringify(data) })
        .then( res => { return res.json(); })
        .then( json => {
          if( json.success ) {
            dispatch({
              type: "CREATE_TAG_END",
              tag: json.tag,
            });
          }
        })
    }
  },

  Search: (phrase = '') => {
    return dispatch => {
      dispatch({
        type: "SEARCH_TAGS_BEGIN",
      });
      
      API.get("/api/tags/search?q=" + phrase)
        .then(res => { return res.json() })
        .then(json => {

          if( json.success ) {
            dispatch({
              type: "SEARCH_TAGS_END",
              tags: json.tags,
            })
          }
        })
    }
  },
};

export default TagsActions;