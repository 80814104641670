import styled from 'styled-components'

export default styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    color: #666;
`