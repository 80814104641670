import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { compose, withProps } from 'recompose'
import { reduxForm, submit } from 'redux-form'
import { createStructuredSelector } from 'reselect'

import { ExtensionFormName } from './consts'
import { selectInitialExtensionValues, selectLoanId } from './selectors'
import * as actions from './actions'

const mapDispatch = dispatch => ({
  ...bindActionCreators(actions, dispatch),
})

export default compose(
  connect(null, mapDispatch),
)