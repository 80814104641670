import React from 'react'
import { compose } from 'recompose'

import Popup from 'components/globals/Popup'
import { PrimaryButton } from 'components/Buttons'

import AddExtensionContainer from 'containers/Extensions/AddExtension'

import ExtensionSpecification from './ExtensionSpecification'
import ExtensionCalculated from './ExtensionCalculated'

const ExtensionPopup = ({
  handleSubmit,
  ...props
}) => {
  return(
    <Popup 
      button={
        <PrimaryButton onClick={handleSubmit}>
            Save
        </PrimaryButton>
      }
      {...props}>
      <ExtensionSpecification />
      <ExtensionCalculated />
    </Popup>
  )
}

export default compose(
  AddExtensionContainer,  
)(ExtensionPopup)