import React from 'react'
import { compose, withProps } from 'recompose'

import { Panel } from 'components/Forms'
import withLoanFieldValue from 'components/HOC/withLoanFieldValue';

import ApplicantDetails from './ApplicantDetails'

const ApplicantsDetails = ({
    value,
}) => {
    if(!value)
        return null 

    let index = 0
    return(
        <Panel title='Applicants Details' expandable initiallyExpanded>
            {value.map(item => <ApplicantDetails key={index++} {...{ index: index, ...item }} />)}
        </Panel>
    )
}

export default compose(
    withProps({
        path: 'applicantsDetails',
    }),
    withLoanFieldValue,
)(ApplicantsDetails)