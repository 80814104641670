import React, { PureComponent } from 'react'
import { PropTypes } from 'prop-types'
import { compose, branch, getContext, renderComponent } from 'recompose'

import { omitFlexProps } from 'utils'

const withSkeleton = (Skeleton, useProps = false) => WrappedComponent => {
  class WithSkeleton extends PureComponent {
    state = { width: 0 }

    constructor(props) {
      super(props)

      this.component = React.createRef()
    }

    componentDidMount() {
      this.getSkeletonWidth()
    }

    componentDidUpdate() {
      this.getSkeletonWidth()
    }

    getSkeletonWidth = () => {
      const {
        isFetching,
        calculateSkeletonWidth,
        useParentForSkeletonWidth,
      } = this.props
      const { current } = this.component
      let widthElement = current

      if (widthElement && useParentForSkeletonWidth) {
        widthElement = widthElement.parentNode
      }

      if (isFetching && calculateSkeletonWidth && widthElement) {
        const width = useParentForSkeletonWidth
          ? widthElement.offsetWidth
          : widthElement.offsetWidth + 10
        this.setWidth(width)
      }
    }

    setWidth = width => {
      if (!this.state.width) {
        this.setState({ width })
      }
    }

    render() {
      const { isFetching, calculateSkeletonWidth, ...rest } = this.props

      if (isFetching && calculateSkeletonWidth && Skeleton) {
        return (
          <React.Fragment>
            <div
              ref={this.component}
              style={{
                display: 'inline',
                visibility: 'hidden',
                position: 'absolute',
              }}
            >
              <WrappedComponent {...omitFlexProps(rest)} />
            </div>
            <Skeleton {...rest} width={this.state.width} />
          </React.Fragment>
        )
      }

      if (isFetching && Skeleton) {
        return <Skeleton {...rest} />
      }

      return <WrappedComponent {...rest} />
    }
  }

  if (process.env.NODE_ENV !== 'production') {
    WithSkeleton.displayName = `WithSkeleton(${WrappedComponent.displayName ||
      WrappedComponent.name ||
      'Component'})`
  }

  if (useProps) {
    return branch(props => props.noSkeleton, renderComponent(WrappedComponent))(
      WithSkeleton,
    )
  }

  return compose(
    branch(props => props.noSkeleton, renderComponent(WrappedComponent)),
    getContext({ isFetching: PropTypes.bool }),
  )(WithSkeleton)
}

export default withSkeleton
