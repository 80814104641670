import React from "react";
import { compose } from "recompose";

import GlobalTagsContainer from "containers/Tags/GlobalTags";

import Page from "components/Page";
import { Message } from "components/Messages";
import { PrimaryButton } from "components/Buttons";
import TagInputField from "components/FormFields/TagInputField";
import TagSignatureField from "components/FormFields/TagSignatureField";

import GlobalTagsWrapper from "./GlobalTagsWrapper";

const GlobalTagsSettings = ({
  globalTags,
  isUpdating,
  handleSubmit,
  submitSucceeded,
  ...props
}) => {
  const updateText = isUpdating ? "PLEASE WAIT..." : "UPDATE";

  return (
    <div>
      {submitSucceeded && !isUpdating && (
        <Message success text="Global tags updated successfully." />
      )}
      <GlobalTagsWrapper>
        {globalTags &&
          globalTags.map((tag) => {
            if (tag.type != "signature")
              return (
                <TagInputField
                  {...{
                    type: tag.type,
                    name: tag._id,
                    label: tag.name,
                    key: tag._id,
                    defaultValue: tag.value,
                  }}
                />
              );
            else
              return (
                <TagSignatureField
                  {...{
                    type: tag.type,
                    name: tag._id,
                    label: tag.name,
                    key: tag._id,
                    defaultValue: tag.value,
                  }}
                />
              );
          })}
        {globalTags && globalTags.length == 0 && (
          <Message text="No tags found." />
        )}
      </GlobalTagsWrapper>
      {globalTags && globalTags.length > 0 && (
        <div style={{ textAlign: "right", marginBottom: 10 }}>
          <PrimaryButton disabled={isUpdating} onClick={handleSubmit}>
            {updateText}
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};

export default compose(GlobalTagsContainer)(GlobalTagsSettings);
