import { createSelector } from 'reselect'
import _get from 'lodash/fp/get'

export const selectLoan = createSelector(
    state => state.global,
    _get("loanDetails")
)

export const selectLoanTerms = createSelector(
    selectLoan,
    _get("terms")
)

export const selectLoanExtensions = createSelector(
  selectLoan,
  _get("extensions")
)