import API from 'api'

const getWalletEntrySubmit = () => ({ type: "FETCH/REQUEST" })
const getWalletEntrySuccess = data => ({ type: "FETCH/SUCCESS", data })
const getWalletEntryError = err => ({ type: "FETCH/ERROR", error: err})

export const getWalletEntry = id => dispatch => {
    dispatch(getWalletEntrySubmit())

    API
        .get(`/api/wallet/single?id=${id}`)
        .then(res => res.json())
        .then(json => { 
            dispatch(getWalletEntrySuccess(json))
        })
        .catch(err => dispatch(getWalletEntryError(err)))
}


const updateWalletEntrySubmit = () => ({ type: "FETCH/REQUEST" })
const updateWalletEntrySuccess = data => ({ type: "FETCH/SUCCESS", data })
const updateWalletEntryError = err => ({ type: "FETCH/ERROR", error: err})

export const updateWalletEntry = (id, values, callback) => dispatch => {
    dispatch(updateWalletEntrySubmit())

    API
        .post(`/api/wallet/update?id=${id}`, { body: JSON.stringify(values)})
        .then(res => res.json())
        .then(json => { 
            dispatch(updateWalletEntrySuccess(json))
            if(callback) callback()
        })
        .catch(err => dispatch(updateWalletEntryError(err)))

}