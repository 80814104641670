import API from 'api'

const verifyCodeRequest = () => ({ type: "FETCH/REQUEST" })
const verifyCodeSuccess = data => ({ type: "FETCH/SUCCESS", data })
const verifyCodeError = err => ({ type: "FETCH/ERROR", error: err})

export const verifyCode = (id, code) => dispatch => {
    dispatch(verifyCodeRequest())

    API
        .get(`/api/clients/loan/verify?id=${id}&code=${code}`)
        .then(res => res.json())
        .then(json => { dispatch(verifyCodeSuccess(json)) })
        .catch(err => dispatch(verifyCodeError(err)))
}