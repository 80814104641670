import React from 'react';

export default class TagListItem extends React.Component {
    handleClick(e) {
        this.props.popup.handleInsert(this.props.tag);
    }

    render() {
        let tag = this.props.tag;
        
        return(
            <div className="tag-list-item" onClick={this.handleClick.bind(this)}>
                <div className='inner'>
                    <h4>{tag.name}</h4>
                    {tag.description.length > 0 && 
                        <p>{tag.description.length == 0 ? "-" : tag.description}</p>
                    }
                    <span className='type'>{tag.type}</span>
                </div>
            </div>
        )
    }
}