import API from 'api'

const createNetAdvanceSubmit = () => ({ type: "FETCH/REQUEST" })
const createNetAdvanceSuccess = data => ({ type: "FETCH/SUCCESS", data })
const createNetAdvanceError = err => ({ type: "FETCH/ERROR", error: err})

export const createNetAdvance = (formValues, callback) => dispatch => {
    dispatch(createNetAdvanceSubmit())

    API
        .post('/api/loans/netadvance', { body: JSON.stringify(formValues) })
        .then(res => res.json())
        .then(json => { 
            dispatch(createNetAdvanceSuccess(json.loan)) 
            if(callback) callback(json.loan)
        })
        .catch(err => dispatch(createNetAdvanceError(err)))

}