import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect';
import { compose, lifecycle, withProps } from 'recompose'

import * as actions from './actions'
import { selectLoanChecks } from './selectors'

const mapState = createStructuredSelector({
    checks: selectLoanChecks,
})

const mapDispatch = dispatch => ({
    ...bindActionCreators(actions, dispatch)
})

export default compose(
    connect(mapState, mapDispatch),
    // withProps(({ match: params }) => ({
    //     loanId: id,
    // })),
    lifecycle({
        componentDidMount() {
            const { getLoanChecks, loanId } = this.props
            getLoanChecks(loanId)
        }
    }),
)
