import API from 'api'

const removeExtensionSubmit = () => ({ type: "FETCH/REQUEST" })
const removeExtensionSuccess = data => ({ type: "FETCH/SUCCESS", data })
const removeExtensionError = err => ({ type: "FETCH/ERROR", error: err})

export const removeExtension = (extensionId, loanId, callback) => dispatch => {
    dispatch(removeExtensionSubmit())

    API
        .post('/api/loans/remove-extension', { body: JSON.stringify({extensionId, loanId}) })
        .then(res => res.json())
        .then(json => { 
            dispatch(removeExtensionSuccess()) 
            if(callback) callback()
        })
        .catch(err => dispatch(removeExtensionError(err)))

}