import React from 'react'

import Row from 'components/Row'
import { Panel } from 'components/Forms'
import LoanField from 'components/Loans/LoanField'

const MainPropertyOverview = ({

}) => {

    return(
        <Panel title='Main Property Overview' expandable>
            <Row>
                <LoanField path='mpUse' label='Property Use' />
                <LoanField path='mpCondition' label='Property Condition' />
                <LoanField path='mpType' label='Property Type' />
            </Row>
            <Row>
                <LoanField path='mpAddress' label='Address' />
                <LoanField path='mpPostcode' label='Postcode' />
            </Row>
            <Row>
                <LoanField type='number' path='mpPrice' prepend="£" label='Price' />
                <LoanField type='boolean' path='mpValuation.checked' label='Valuation' />
            </Row>
            <Row>
                <LoanField path='mpTernure' label='Ternure' />
                <LoanField path='mpTotalBedrooms' label='Total Bedrooms' />
            </Row>
            <Row>
                <LoanField type='boolean' path='mpExAuthority.checked' label='Is Ex Authority?' />
                <LoanField path='mpOccupancy' label='Occupancy' />
            </Row>
            <Row>
                <LoanField type='boolean' path='mpUnexpiredTermLease.checked' label='Unexpired Term Lease?' />
                <LoanField type='boolean' path='mpNewBuild.checked' label='Is New Build?' />
            </Row>
        </Panel>
    )
}

export default MainPropertyOverview