import React from 'react'
import { compose } from 'recompose'
import { Link } from 'react-router-dom'

import Page from 'components/Page'
import { Message } from 'components/Messages'
import TemplateListingItem from 'components/ListItems/TemplateListingItem'
import LoadingPanel from 'components/LoadingPanel'
import { SecondaryButton } from 'components/Buttons'

import ListTemplatesContainer from 'containers/Templates/ListTemplates'

const ListTemplates = ({
    templates,
    getTemplates,
    isFetching,
}) => {
    if( isFetching )
        return <LoadingPanel text="Loading templates." />

    return(
        <Page 
            title="Templates"
            buttons={<SecondaryButton to='/dashboard/settings/templates/create' darkGray>CREATE</SecondaryButton>}>
            {templates && templates.length > 0 &&
                <div className='listing'>
                    <div className='thead'>
                        <div className='row'>
                            <div className='col-sm-4'>Template Name</div>
                            <div className='col-sm-5'>Description</div>
                        </div>
                    </div>
                </div>
            }
            {templates && templates.map(entry => 
                <TemplateListingItem key={entry._id} {...{...entry, onComplete: () => { getTemplates() } }} />    
            )}
            {templates && templates.length == 0 && 
                <Message text="No templates found." />
            }
        </Page>
    )
}

export default compose(
    ListTemplatesContainer,
)(ListTemplates)