import { createSelector } from 'reselect'
import _get from 'lodash/fp/get'

export const selectGlobalTags = createSelector(
    state => state.global,
    _get("globalTags")
)

export const selectInitialValues = createSelector(
    selectGlobalTags,
    globalTags => {
        
        const map = {}
        if( globalTags )
            globalTags.forEach(tag => {
                map[tag._id] = tag.value
            })
        return map
    }
)