import React from 'react'
import Dropzone from 'react-dropzone'
import { compose, withProps } from 'recompose'

import DocumentUploadZoneWrapper from './DocumentUploadZoneWrapper'
import DocumentUploadMessage from './DocumentUploadMessage'

const DocumentUploadZone = ({
    onDrop,
    ...props
}) => {
    return(
        <DocumentUploadZoneWrapper {...props}>
            <Dropzone onDrop={onDrop}>
                {({getRootProps, getInputProps, isDragActive}) => {
                    return (
                        <div
                            {...getRootProps()}
                            className={"dropzone" + (isDragActive ? " dropzone-active" : "")}>
                            <input {...getInputProps()} />
                            <DocumentUploadMessage />
                        </div>
                    )
                }}
            </Dropzone>
        </DocumentUploadZoneWrapper>
    )
}

export default compose(
    withProps(({ handleUpload }) => ({
        onDrop: (acceptedFiles, rejectedFiles) => {
            handleUpload(acceptedFiles)
        },
    })),
)(DocumentUploadZone)