import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect';
import { compose, lifecycle } from 'recompose'

import * as actions from './actions'
import { selectUsers, selectUser } from './selectors'

const mapState = createStructuredSelector({
    users: selectUsers,
    user: selectUser,
})

const mapDispatch = dispatch => ({
    ...bindActionCreators(actions, dispatch)
})

export default compose(
    connect(mapState, mapDispatch),
    lifecycle({
        componentDidMount() {
            const { getUsers, getUser } = this.props
            getUsers()
            getUser()
        }
    }),
)
