import { compose, withProps } from 'recompose'

import { DatepickerField } from 'components/Fields'
import { Field } from 'components/FormFields'

import { validateRequired } from 'utils/validators'

const validators = [validateRequired]

export default compose(
  withProps(({ declaration }) => ({
        name: `${declaration}.date`,
        label: "Date",
        wrapperConfig: { minWidth: "200px" },
        component: DatepickerField,
        full: true,
        validators,
  })),
)(Field)