import API from 'api';

export const getClients = () => dispatch => {
    dispatch({ type: "LOAD_CLIENTS_BEGIN" })
    API.get("/api/clients/")
        .then(res => res.json())
        .then( json => {
          if(json.success) {
            dispatch({
              type: "LOAD_CLIENTS_END",
              clients: json.clients,
            })
          }
        })
        .catch(ex => {
            console.error(ex)
            dispatch({ type: "LOAD_CLIENTS_ERROR" })
        })
}