import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import { SecondaryButton } from 'components/Buttons'
import Spinner from 'components/globals/Spinner'
import GenericListItem from 'components/ListItems/GenericListItem'

import GlobalActions from 'actions/GlobalActions'

class Users extends React.Component {

  componentDidMount() {
    this.props.LoadAllUsers();
  }

  renderUsers() {
    var key = 0;
    var display = [];

    if( this.props.fetching ) {
      return(
        <div className='fetching-results text-center'>
          <Spinner />
        </div>
      )
    }

    if( this.props.users.length == 0 ) {
      return(
        <div className='no-results'>
          <p>No results found.</p>
        </div>
      )
    }

    this.props.users.forEach( user => {
      display.push(<UserListItem key={key++} user={user} />)
    });

    return display;
  }

  render() {
    return(
      <div className='page'>
        <div className='page-heading info'>
          <h2>
            System Users
            <SecondaryButton to='/dashboard/new-user' darkGray>CREATE</SecondaryButton>
          </h2>
        </div>
        <div className='listing'>
          <div className='thead'>
            <div className='row'>
              <div className='col-sm-3'>User</div>
              <div className='col-sm-4'>Email</div>
              <div className='col-sm-3'>Type</div>
            </div>
          </div>
          <div className='items'>
            {this.renderUsers()}
          </div>
        </div>
      </div>
    )
  }
}

class UserListItem extends React.Component {
  render() {
    var user = this.props.user;

    return(
      <GenericListItem>
        <div className='col-sm-3'>
          <strong>{user.first_name + " " + user.last_name}</strong>
        </div>
        <div className='col-sm-4'>{user.email}</div>
        <div className='col-sm-3'>{user.type.name}</div>
        <div className='col-sm-2 text-right'>
          {/* <SecondaryButton style={{marginRight: 5}}>EDIT</SecondaryButton> */}
          <SecondaryButton justText darkGray>REMOVE</SecondaryButton>
        </div>
      </GenericListItem>
    );
  }
}

const mapState = state => {
  return {
    users: state.global.users,
    fetching: state.global.fetching,  
  }
}

const mapDispatch = dispatch => {
  return {
    LoadAllUsers: () => {
      dispatch(GlobalActions.LoadAllUsers());
    }
  }
}

export default connect(mapState, mapDispatch)(Users);
