import API from 'api';
import WalletActions from './WalletActions';

let LoanActions = {

  Create: (data) => {
    return (dispatch) => {

      dispatch({
        type: "CREATE_LOAN_BEGIN",
        data: data,
      });

      API.post("/api/loans/create", { body: JSON.stringify(data) })
        .then( res => { return res.json(); })
        .then( json => {
          if( json.success ) {
            dispatch({
              type: "CREATE_LOAN_END",
              loan: json.loan,
            });
          }
        })
    }
  },

  LoadAll: (callback) => {
    return (dispatch) => {

      dispatch({
        type: "LOAD_LOANS_BEGIN",
      });

      API.get("/api/loans/")
        .then( res => { return res.json(); })
        .then( json => {
          if( json.success ) {
            dispatch({
              type: "LOAD_LOANS_END",
              loans: json.loans,
            });
          }
          if(callback) 
            callback()
        })
    }
  },

  LoadLoanDetails: (id) => {
    return (dispatch) => {
      dispatch({
        type: "LOAD_LOAN_DETAILS_BEGIN",
      })

      API.get("/api/loans/details?id=" + id)
        .then(res => { return res.json() })
        .then(json => {
          if( json.success ) {
            dispatch({
              type: "LOAD_LOAN_DETAILS_END",
              loan: json.loan,
            })
          }
        })
    }
  },

  GenerateRedemptionStatement: (id) => {
    return (dispatch) => {
      dispatch({
        type: "LOAN_R_STATEMENT_BEGIN",
      })

      API.get("/api/loans/rstatement?id=" + id)
        .then( res => { return res.blob() })
        .then( blob => {
        
          // It is necessary to create a new blob object with mime-type explicitly set
          // otherwise only Chrome works like it should
          var newBlob = new Blob([blob], {type: "application/pdf"})
        
          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          } 
        
          // For other browsers: 
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);
          var link = document.createElement('a');
          link.href = data;
          link.download="redemption_statement.pdf";
          link.click();
          setTimeout(function(){
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100); 
        })
    }
  },

  UpdateLoanTemplates: (data) => {
    return (dispatch) => {
      dispatch({
        type: "UPDATE_LOAN_TEMPLATES_BEGIN",
      })

      API.post("/api/loans/loan-templates", { body: JSON.stringify(data)})
        .then( res => { return res.json() })
        .then( json => {
          if( json.success ) {
            dispatch({
              type: "UPDATE_LOAN_TEMPLATES_END",
            })
          }
        })
    }
  },
  ActivateLoan: (id) => {

    return dispatch => {
      dispatch({
        type: "ACTIVATE_LOAN_BEGIN",
      })

      var data = {
        id: id,
      }
      
      API.post("/api/loans/activate", { body: JSON.stringify(data)})
        .then(res => {return res.json()})
        .then(json => {
          
          if( json.success ) {
            dispatch({
              type: "ACTIVATE_LOAN_END",
              loan: json.loan,
            })
          }
        })
    }
  },

  MakeRepayment: (installment, payload) => {
    return dispatch => {
      dispatch({
        type: "MAKE_REPAYMENT_BEGIN",
      })

      var data = {
        installment: installment,
        amount: payload.amount,
        date: payload.date,
      }

      API.post("/api/loans/repayment", { body: JSON.stringify(data)})
        .then( res => { return res.json() }) 
        .then( json => {

          if( json.success ) {
            if( installment.extension_installment ) 
            {
              // load
              LoanActions.LoadLoanDetails(json.loan_id);
              dispatch({
                type: "MAKE_REPAYMENT_END",
                installment: json.installment,
              });
            }  //LoanActions.LoadLoanDetails(json.loan_id);
            else 
              dispatch({
                  type: "MAKE_REPAYMENT_END",
                  installment: json.installment,
              });
              
            setTimeout(function() {
              dispatch(WalletActions.LoadBalance());
            }, 50);
          }
        })
    }
  },

  ConfirmExtension: (data) => {
    return dispatch => {
      dispatch({
        type: "CONFIRM_EXTENSION_BEGIN",
      })

      API.post("/api/loans/extend", { body: JSON.stringify(data)})
        .then( res => { return res.json() }) 
        .then( json => {
          
          if( json.success ) {
            dispatch({
                type: "CONFIRM_EXTENSION_END",
                extension: json.extension,
            });
          }
        })
    }
  },

  ConfirmNetAdvance: (id, amount, date) => {
    return dispatch => {
      dispatch({
        type: "NET_ADVANCE_BEGIN",
      })

      var data = {
        id: id,
        date: date,
        amount: amount,
      }

      API.post("/api/loans/net_advance", { body: JSON.stringify(data)})
        .then( res => { return res.json() }) 
        .then( json => {

          if( json.success ) {

            

            setTimeout(function() {
              dispatch(WalletActions.LoadBalance());
              dispatch({
                type: "NET_ADVANCE_END",
                result: json.result,
              })
            }, 50);
          }
        })
    }
  },

  ConfirmLumpSumRepayment: (id, amount, date) => {
    return dispatch => {
      dispatch({
        type: "MAKE_REPAYMENT_BEGIN",
      })

      var data = {
        id: id,
        date: date,
        amount: amount,
      }

      API.post("/api/loans/lump_sum_repayment", { body: JSON.stringify(data)})
        .then( res => { return res.json() }) 
        .then( json => {

          if( json.success ) {

            

            setTimeout(function() {
              dispatch(WalletActions.LoadBalance());
            }, 50);
          }
        })
    }
  },

  CreateLoanAction: (data, callback) => {
    return dispatch => {
      dispatch({
        type: "CREATE_LOAN_CHECK_BEGIN",
      })

      API.post("/api/loans/check/create", { body: JSON.stringify(data)})
        .then( res => { return res.json() }) 
        .then( json => {

          if( json.success ) {
            dispatch({
                type: "CREATE_LOAN_CHECK_END",
                check: json.result,
            });

            if( callback ) {
              callback(json.result);
            }
          }
        })
    }
  },

  HandleRedemption: (data) => {
    return dispatch => {

      dispatch({
        type: "REDEMPTION_BEGIN",
      })

      API.post("/api/loans/redemption", { body: JSON.stringify(data)})
        .then( res => { return res.json() }) 
        .then( json => {

          if( json.success ) {
            dispatch({
                type: "REDEMPTION_END",
            });
          }
        })
    }
  }
}

export default LoanActions;