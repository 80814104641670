import React from 'react';
import Select from 'react-select'
import Switch from "react-switch";
import NumericInput from 'react-numeric-input';
import Scrollbars from 'react-custom-scrollbars'

class HorizontalSeparator extends React.Component {
  render() {
    var text = this.props.text ? this.props.text : "";
    return(
      <div className='separator'><span>{text}</span></div>
    );
  }
}

class Dropdown extends React.Component {
  
  constructor(props) {
    super(props);

    var value = this.props.value ? this.props.value : "";
    var otherValue = "";
    var showOther = false;
    var option = null;

    if( value.length > 0 ) {
      option = this.props.options.find(obj => { return obj.value == value });

      if( this.props.withOther && option == null ) {
        option = {
          label: "Other",
          value: "",
        }
        otherValue = value;
        showOther = true;
      }
    }

    this.state = {
      showOtherField: showOther,
      otherValue: otherValue,
      value: value,
      option: option,
    }
  }

  dispatchReduxChange(value) {
    var ev = {
      target: {
        value: value,
      },
    }
    
    this.setState({
      value: value,
    })
    
    if( this.props.onChange ) 
      this.props.onChange(ev);
  }

  onChange(option) {
    var value = ""; 
    if( this.props.withOther ) {
      if( option.label.toLowerCase() == "other" ) {
        this.setState({
          showOtherField: true,
          option: option,
        })
      } else {
        this.setState({
          showOtherField: false,
          option: option,
        })
        this.dispatchReduxChange(option.value);
      }
    } else {
      this.setState({
        showOtherField: false,
        option: option,
      })
      this.dispatchReduxChange(option.value);
    }
  }

  handleOtherFieldChange(e) {

    this.setState({
      otherValue: e.target.value,
    }, () => {
      this.dispatchReduxChange(this.state.otherValue);
    });
  }

  render() {
    var options = this.props.options ? this.props.options : [];
    var option = this.props.ext_control ? options.find(opt => { return opt.value == this.props.value }) : this.state.option;

    if( this.props.withOther ) {
      if( !options.find(opt => { return opt.label.toLowerCase() == "other" }) ) {
        options.push({
          value: "",
          label: "Other",
        });
      }
    }

    return(
      <div className='dropdown-wrap'>
        <Select onChange={this.onChange.bind(this)} ref="dropdown" className='dropdown' options={options} value={option} defaultValue={this.props.defaultValue ? this.props.defaultValue : ""} />

        {this.state.showOtherField &&
          <input type='text' className='' ref="other" onChange={this.handleOtherFieldChange.bind(this)} value={this.state.otherValue} />
        }
      </div>
    )
  }
}

class Collapsable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: this.props.collapsed ? this.props.collapsed : false,
    }
  }

  triggerCollapse() {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  render() {
    var addClass = this.state.collapsed ? "collapsed" : "open";
    var className = this.props.className ? this.props.className : "";
    return(
      <div className={"collapsable " + addClass + " " + className}>
        <div className='collapsable-head' onClick={this.triggerCollapse.bind(this)}>
          {this.props.title ? this.props.title : ""}
          <a className='collapsable-trigger'></a>
        </div>
        {!this.state.collapsed &&
          <div className='collapsable-body'>
            {this.props.children}
          </div>
        }
      </div>
    )
  }
}

class Button extends React.Component {
  render() {
    var classNames = this.props.className ? this.props.className : "";
    var bordered = this.props.bordered ? " bordered" : "";
    var color = this.props.color ? " " + this.props.color : "";
    var hasIcon = this.props.icon && this.props.icon != null ? true : false;
    var icon = this.props.icon;

    var props = Object.assign({}, this.props);

    if( props.bordered )
      delete props.bordered

    classNames += bordered + color;

    return(
      <a {...props} className={ 'btn ' + classNames }>{icon && <span className='btn-icon'><img src={icon} /></span>}{this.props.children}</a>
    )
  }
}

class Input extends React.Component {
  render() {
    var type = this.props.type ? this.props.type : "text";
    return(
      <input {...this.props} className='input-component' type={type} />
    )
  }
}

class ImageField extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <div className='image-field'>
        <div className='image-preview'>
          {this.props.label &&
            <div className='image-field-label'>
              {this.props.label}
            </div>
          }
          <div className='image-actions'>
            <Button className='btn-blue'>Edit</Button>
            <Button className='btn-default'>Remove</Button>
          </div>
        </div>
      </div>
    );
  }
}

class Spacer extends React.Component {
  render() {
    return(
      <div className='spacer'></div>
    )
  }
}

class Popup extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: this.props.visible ? this.props.visible : false,
    };
  }

  close() {
    this.setState({
      visible: false,
    })
  }

  open() {
    this.setState({
      visible: true,
    })
  }

  render() {
    var className = 'popup-outer' + (this.props.className ? " " + this.props.className : "");

    if( !this.state.visible )
      return null;

    return(
      <div className={className}>
        <div className='popup-overlay' onClick={this.close.bind(this)}></div>
        <div className='popup-content'>
          <a className='popup-close-btn' onClick={this.close.bind(this)}></a>
          {this.props.title &&
          <div className='popup-title'>
            <h2>{this.props.title}</h2>
          </div>
          }
          <div className='popup-content-inner'>
              {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

class BooleanSwitch extends React.Component {
  constructor(props) {
    super(props);

    var details = this.props.detailsValue ? this.props.detailsValue : ""; 
    var checked = this.props.defaultValue ? this.props.defaultValue : false;

    this.state = { 
      checked: checked,
      true_details: details,
      false_details: details,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  
  handleChange(checked) {
    this.setState({ checked: checked });
    
    if( this.props.onChange ) {
      this.props.onChange(checked);
    }
  }

  isChecked() {
    return this.state.checked;
  }

  getTrueDetails() {
    return this.state.true_details;
  }

  getFalseDetails() {
    return this.state.false_details;
  }

  showDetailsIfTrue() {
    if( this.props.showDetailsIfTrue ) 
      return true;
    return false;
  }

  showDetailsIfFalse() {
    if( this.props.showDetailsIfFalse )
      return true;
    return false;
  }

  renderDetails() {
    if( this.showDetailsIfFalse() && this.state.checked == false ) {
      return (
        <div className='details'>
          <input type='text' onChange={ e => { 
            this.setState({ 
              false_details: e.target.value 
            }, () => { 
              if( this.props.onDetailsChange) 
                this.props.onDetailsChange(this.state.false_details) 
            })
          }} value={this.state.false_details} />
        </div>
      )
    }

    if( this.showDetailsIfTrue() && this.state.checked == true ) {
      return (
        <div className='details'>
          <input placeholder={this.props.detailsPlaceholder ? this.props.detailsPlaceholder : ""} type='text' onChange={ e => { 
            this.setState({ 
              true_details: e.target.value 
            }, () => { 
              if( this.props.onDetailsChange) 
                this.props.onDetailsChange(this.state.true_details) 
            })
          }} value={this.state.true_details} />
        </div>
      )
    }
  }

  render() {
    var checked = this.props.ext_control ? this.props.checked : this.state.checked;
    return(
      <div className='boolean-switch'>
        <Switch
          onColor="#5fcc5f"
          onChange={this.handleChange}
          checked={checked}
        />
        {this.renderDetails()}
      </div>
    )
  }
}

export {
  HorizontalSeparator,
  Spacer,
  Button,
  Input,
  Popup,
  ImageField,
  Dropdown,
  BooleanSwitch,
  Collapsable,
}
