import React from 'react'
import { compose, withState, lifecycle } from 'recompose'

const Panel = ({
    title,
    button,
    expandable,
    expanded,
    initiallyExpanded,
    setExpanded,
    children,
}) => {
    let showContent = true
    
    if( expandable && !expanded )
        showContent = false

    return(
        <div className='main-property-box'>
            <h3 onClick={() => {setExpanded(!expanded)}}>
                {title}
                {button && button}
                {expandable && 
                    <a className={`mp-box-expand ${expanded ? 'expanded' : ''}`}>{expanded ? "-" : "+"}</a>
                }
            </h3>
            {showContent && 
                <div className='main-property-box-inner'>
                    {children}
                </div>
            }
        </div>
    )
}

export default compose(
    withState("expanded", "setExpanded", false),
    lifecycle({
        componentDidMount() {
            const { setExpanded, initiallyExpanded } = this.props
            if( initiallyExpanded )
                setExpanded(true)
        },
    }),
)(Panel)