import React from 'react'
import { Flex } from '@rebass/grid'

import { COLORS } from 'consts'

import { Content } from 'components/Texts'

import TabsWrapper from './TabsWrapper'
import Tab from './Tab'

// export interface TabProps {
//   to: string,
//   activeText?: string
//   isActive?: Function
//   title?: string
//   icon?: IconName
//   iconProps?: Omit<IconProps, 'name'>
// }

// export interface Props {
//   button?: React.ReactNode
//   color?: COLORS
//   full?: boolean
//   small?: boolean
//   tabs: TabProps[]
//   vertical?: boolean
// }

const Tabs = React.memo(
  ({ button, color = COLORS.blue, full, small, tabs, vertical }) => {

    return (
      <TabsWrapper
        {...{ button, full, small, vertical }}>
        <Flex flexDirection={vertical ? 'column' : 'row'} style={{ boxSizing: "content-box"}}>
          {tabs.map(tab => (
            <Tab
              key={tab.to}
              activeClassName="active"
              activeText={tab.activeText || tab.title}
              noSkeleton
              {...{ small, vertical, ...tab }}>
              <Content inherit>{tab.title}</Content>
            </Tab>
          ))}
        </Flex>
        {button}
      </TabsWrapper>
    )
  },
)

export default Tabs
