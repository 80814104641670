import React from 'react'
import { compose, withProps } from 'recompose'

import { withClientFieldValue } from 'components/HOC'

import DirectorsListWrapper from './DirectorsListWrapper'
import DirectorListItem from './DirectorListItem'

const DirectorsList = ({
    value,
}) => {
    let key = 0
    return(
        <DirectorsListWrapper>
            {value && value.map(director => <DirectorListItem {...{ ...director, key: key++, index: key }} />)}
        </DirectorsListWrapper>
    )
}

export default compose(
    withProps({
        path: 'directors',
    }),
    withClientFieldValue,
)(DirectorsList)