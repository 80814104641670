import React from 'react'
import { compose, withProps } from 'recompose'

import { withClientFieldValue } from 'components/HOC'

import ApplicantsListWrapper from './ApplicantsListWrapper'
import ApplicantListItem from './ApplicantListItem'

const ApplicantsList = ({
    value,
}) => {
    let key = 0
    return(
        <ApplicantsListWrapper>
            {value && value.map(applicant => <ApplicantListItem {...{ ...applicant, key: key++, index: key }} />)}
        </ApplicantsListWrapper>
    )
}

export default compose(
    withProps({
        path: 'applicants',
    }),
    withClientFieldValue,
)(ApplicantsList)