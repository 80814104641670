import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import * as actions from './actions'
import { selectProfessionals, selectIsFetching } from './selectors'

const mapState = createStructuredSelector({
    professionals: selectProfessionals,
    isFetching: selectIsFetching,
})

const mapDispatch = dispatch => bindActionCreators(actions, dispatch)

export default compose(
    connect(mapState, mapDispatch),
    lifecycle({
        componentDidMount() {
            const { getProfessionals, professionals } = this.props
            
            if(professionals && professionals.length == 0)
                getProfessionals()
        }
    })
)