import React from "react";
import { compose, withProps } from "recompose";
import { Link, useLocation, useParams } from 'react-router-dom'

import SignatureRequestListItem from 'components/ListItems/SignatureRequestListItem'
import { SecondaryButton } from 'components/Buttons'
import { Message } from 'components/Messages'
import { Panel } from 'components/Forms'

import SignaturePopup from 'components/SignaturePopup'

import DocumentsContainer from 'containers/Documents'
import ListSignatureRequestsContainer from 'containers/Loan/ListSignatureRequests'


const RequestSignaturesListing = ({
  objectId,
  documents,
  toggleUpload,
  toggleSignature,
  uploadVisible,
  signatureVisible,
  downloadFile,
  handleRemove,
  requests,
  ...props
}) => {
    
    return (
      <Panel 
        button={
            <div>
                <SecondaryButton onClick={() => toggleSignature(true)}>REQUEST SIGNATURE</SecondaryButton>
            </div>
        }
        title='Signature Requests'>
        {requests && requests.map(r => {
            return(
                <SignatureRequestListItem {...{ ...r, key: r._id }} />
            )
        })}
        {signatureVisible && <SignaturePopup { ...{ objectId, togglePopup: toggleSignature }} />}
        {requests && requests.length == 0 && 
            <Message text='No documents found' />
        }
      </Panel>
    );    
};

export default compose(
  withProps(({ match: { params: { loanId }}}) => ({
      objectId: loanId,
      loanId,
  })),
  DocumentsContainer,
  ListSignatureRequestsContainer,
)(RequestSignaturesListing);
