import styled, { css } from 'styled-components'
import { COLORS } from 'consts'

const TextFieldInputStyles = css`
  border: none;
  background: none;
  color: ${COLORS.darkGray};
  font-size: 16px;
  line-height: 16px;
  outline: none;
  width: 100%;
  font-weight: 300;
  padding-left: 0px;
  border-radius: 6px!important;

  ${props => props.withLabel && `padding-top: 14px`};

  ::placeholder {
    font-size: 14px;
    color: ${COLORS.lightBlack};
  }

  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    
    ::placeholder {
      color: ${COLORS.lightGray};
    }
  `};

  ${props =>
    props.large &&
    `
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    ${props.withLabel && `padding-top: 20px`};

    ::placeholder {
      font-size: 24px;
    }
  `};

  ${props =>
    props.nonFocusable &&
    `
    pointer-events: none;
  `};

  ${props =>
    props.hidden &&
    `
    visibility: hidden;
  `};

  ${props =>
    props.hideValue &&
    `
    color: transparent;
  `};
`

export default styled.input`
  ${TextFieldInputStyles};
`
