export const API_URL = `${process.env.API_URL ||
  'http://iosephbackend-env.eba-x23dpdkj.eu-west-2.elasticbeanstalk.com'}/api`;

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY'
export const DEFAULT_DATEPICKER_FORMAT = 'dd/MM/yyyy'
export const DEFAULT_DATE_NOYEAR_FORMAT = 'DD/MM'
export const DEFAULT_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm'

export const ERROR_CODES = []

export const COLORS = {
  black: '#000000',
  lightBlack: '#252525',
  white: '#FFFFFF',
  whiteGray: '#F8F8F8',
  whiteGray2: '#F2F2F2',
  lightestWhiteGray: '#fbfbfb',
  gray: '#ccc',
  darkGray: '#515151',
  darkestGray: '#313131',
  lightGray: '#949494',
  lightGray2: '#B4B4B4',
  lightGrayWithOpacity: '#9494944a',
  lightGrayWithOpacity2: '#f4f4f4',
  lightestGray: '#DCDCDC',
  lightestGray2: '#EDF2F5',
  darkBlue: '#497E93',
  blue: '#4692D7',
  highlightBlue: '#EAEEF7',
  highlightGray: '#E6E1E1',
  infoBlue: '#8CBED2',
  lightBlue: '#0079FD',
  orange: '#FA9264',
  green: '#7ac17c',
  green2: '#9EC19F',
  lightGreen: '#DFFF87',
  yellow: '#f6bd26',
  darkRed: '#ED1C24',
  darkRed2: '#A22B2B',
  red: '#D74646',
  widgetBg: '#EDEDED',
  lightRed: '#F57D7D',
  primary: '#4692D7',
  inputBg: '#fff',
  
  selectBorder: '#B5B5B5',
  violet: '#8383C4',

  duePayment: "#d74546",
  lightRed: "#8c2d2e",
  
  editorKeyword: "#dc58aa",
  editorComment: "#5f658c",
  editorString: "#d6e08b",
  editorFunction: "#4ff18a",
}

export const BOX_SHADOWS = {
  box: '0 1px 4px 0 rgba(0,0,0,0.11)',
  button: '0 0 7px 0 rgba(0,0,0,0.34)',
  textButton: '0 0 4px 0 rgba(0,0,0,0.3)',
  modal: '0 2px 4px 0 rgba(0,0,0,0.12)',
  borderedIcon: 'inset 0 1px 3px 0 rgba(0,0,0,0.25)',
}

export const MAX_WIDTH = 1200

export const FONTS = {
  primary: 'Lato, sans-serif',
  icons: 'cultcha-icons',
}

export const DIMENSIONS = {
  moduleMenuHeight: 40
}

export const ZINDEXES = {
  header: 4,
}

export const TRANSITIONS = {
  input: 'transform 150ms ease-out',
}
