import styled from 'styled-components'

export default styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 10px -5px 0 -5px;

    .sc-dnqmqq {
        margin: 0 5px 10px 0px;
        min-width: calc(50% - 10px);
        width: auto;
    }
`