import React from 'react'
import { compose, branch, withProps } from 'recompose'

import { SecondaryButton } from 'components/Buttons'
import { Panel } from 'components/Forms'

import { withLoanStatus } from 'components/HOC'
import API from 'api'

import RedemptionStatement from './views/RedemptionStatement'

const Redemption = ({
    handleDownload,
    ...props
}) => {
    return(
        <Panel title='Loan Redemption'
            button={
                <div>
                    <SecondaryButton onClick={handleDownload} style={{ marginRight: '5px' }}>DOWNLOAD STATEMENT</SecondaryButton>
                </div>
            }>
            <RedemptionStatement {...props} />
        </Panel>
    )
}

export default compose(
    withProps(({ match: { params: { id }}}) => ({
        loanId: id,
        statusText: "In order to view this tab, loan must be active.",
        handleDownload: () => {
            let html = document.getElementById("redemption").outerHTML
            API.post(`/api/loans/download-statement?id=${id}`, { body: JSON.stringify({ html })})
                    .then( res => { return res.json() })
                    .then( json => {        
                        if(!json.buffer)
                            return null
                        const buffer = json.buffer.data
                        const newBlob = new Blob([new Uint8Array(buffer, 0, buffer.length)])

                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        } 
                        const data = window.URL.createObjectURL(newBlob)
                        let link = document.createElement('a')
                        link.href = data
                        link.download = `statement-${id}.pdf`
                        link.click()

                        setTimeout(function(){
                            window.URL.revokeObjectURL(data);
                        }, 100)
                    })
        }
    })),    
    withLoanStatus(["Active", "Closed"]),
)(Redemption)