import React from 'react'
import { compose } from 'recompose'

import { SwitchField } from 'components/Fields'

import TermListItemContainer from './containers'

const TermListItem = ({
    index,
    date,
    compounded,
    amount,
    updateTerm,
}) => (
    <div className='term-info'>
        <div className='row'>
            <div className='col-sm-2'>No.{index}</div>
            <div className='col-sm-3'>{date}</div>
            <div className='col-sm-4'>
                <span>{compounded ? `-` : `&pound;${amount}`}</span>
            </div>
            <div className='col-sm-3'>
                <SwitchField 
                    checked={compounded}
                    onChange={e => { updateTerm(index, { compounded: e }) }}
                    showDetails={() => {return false}}
                />
            </div>
        </div>
    </div>
)

export default compose(
    TermListItemContainer,
)(TermListItem)