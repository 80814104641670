import React from 'react';

import { FormStep } from 'components/Forms'

import { ApplicantsArrayField } from 'components/FormFields/Loan'

const AdditionalProperties = () => (
    <FormStep title="Applicants Details">
        <ApplicantsArrayField />
    </FormStep>
)

export default AdditionalProperties