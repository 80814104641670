import { compose, withProps } from 'recompose'

import { SwitchField } from 'components/FormFields'

export default compose(
    withProps(({ property, ...props }) => ({
        showDetails: (checked) => { return checked || false },
        wrapperConfig: { width: '100%' },
        name: `${property}.liabilities`,
        label: "Any Outstanding Liabilities?",
        numericInput: false,
    })),
)(SwitchField)

