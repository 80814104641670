import React from 'react'

import Row from 'components/Row'
import { Panel } from 'components/Forms'
import { SecondaryButton } from 'components/Buttons'

import * as ApplicantFields from './ApplicantFields'

const RenderApplicant = ({
    applicant,
    index,
    removeField,
    ...props
}) => {
    return(
        <Panel 
            button={<SecondaryButton onClick={() => removeField(index)}>Remove</SecondaryButton>}
            title={`Applicant #${index + 1}`}>
            <Row>
                <ApplicantFields.ApplicantTitleField {...{ applicant, index }} mr={10}/>
                <ApplicantFields.ApplicantFirstNameField {...{ applicant, index }} />
            </Row>
            <Row>  
                <ApplicantFields.ApplicantMiddleNameField mr={10} {...{ applicant, index }} />
                <ApplicantFields.ApplicantLastNameField {...{ applicant, index }} />
            </Row>
            <Row>
                <ApplicantFields.ApplicantMaidenNameField {...{ applicant, index }} mr={10} />
                <ApplicantFields.ApplicantDateOfBirthField {... { applicant, index }} />
            </Row>
            <Row>
                <ApplicantFields.ApplicantNumberOfDependantsField mr={10} {...{ applicant, index }} />
                <ApplicantFields.ApplicantMartialStatusField {...{ applicant, index }} />
            </Row>
            <Row>
                <ApplicantFields.ApplicantNationalityField mr={10} {...{ applicant, index }} />
                <ApplicantFields.ApplicantUKResidentField {...{ applicant, index }} />
            </Row>
            <Row>
                <ApplicantFields.ApplicantUKLivedField {...{ applicant, index }} />
                <ApplicantFields.ApplicantPermanentRightField ml={10} {...{ applicant, index }} />
            </Row>
            <Row>
                <ApplicantFields.ApplicantCurrentAddressField {...{ applicant, index }} />
                <ApplicantFields.ApplicantNumberOfBedroomsField ml={10} {...{ applicant, index }} />
            </Row>
            <Row>
                <ApplicantFields.ApplicantTimeAtCurrentAddressField {...{ applicant, index }} />
                <ApplicantFields.ApplicantPostcodeField ml={10} {...{ applicant, index }} />
            </Row>
            <Row>
                <ApplicantFields.ApplicantOccupancyTypeField {...{ applicant, index }} />
                <ApplicantFields.ApplicantPropertyTypeField ml={10} {...{ applicant, index }} />
            </Row>
        </Panel>
    )
}

export default RenderApplicant