import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { COLORS, DIMENSIONS } from 'consts'

import { PrimaryButton } from 'components/Buttons'


const TabsWrapper = styled(Flex)`
  justify-content: space-between;
  height: ${DIMENSIONS.moduleMenuHeight}px;
  width: 100%;

  ${props =>
    !props.small &&
    `
    align-items: center;
  `};

  ${props =>
    props.full &&
    `
    flex-grow: 1;
  `};

  > ${PrimaryButton} {
    margin-top: 4px;
  }
  border-bottom: 1px solid ${COLORS.lightGrayWithOpacity};

  ${props =>
    props.small &&
    `
    min-height: initial;
    flex-direction: column;

    .Tabs {
      
      &__lineWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        background: red;
        transform: translateY(3px);
        margin-top: -3px;
        ${!!props.button &&
          `
          transform: translateY(17px);
          margin-top: -17px;
        `};
      }
    }
  `};

  ${props =>
    props.vertical &&
    `
    flex-grow: 1;
    flex-shrink: 0;
    align-items: flex-start;
    border-right: 1px solid ${COLORS.lightGray2};
    margin-right: 24px;
  `};
`

export default TabsWrapper
