import React from 'react' 
import { compose } from 'recompose' 
import moment from 'moment'

import { withLoanFieldValue } from 'components/HOC'
import { priceFormatter } from 'helpers/Helpers'

import { DEFAULT_DATE_FORMAT } from 'consts'

import LoanFieldWrap from './LoanFieldWrap'

const LoanField = ({
    label,
    value,
    forceValue,
    append,
    prepend,
    type,
    renderField,
    ...props
}) => {
    let mValue = typeof forceValue != 'undefined' ? forceValue : value
    if(type && typeof mValue != 'undefined') {
        if(type == 'number')   
            mValue = priceFormatter(parseFloat(mValue).toFixed(2))
        else if(type == 'date')
            mValue = moment(mValue).format(DEFAULT_DATE_FORMAT)
        else if(type == 'boolean') 
            mValue = !!mValue ? "Yes" : "No"
    }
    return(
        <LoanFieldWrap {...props}>
            <label>{label}</label>
            <div>
                {prepend && <span className='preppend'>{prepend}</span>}
                {!renderField && mValue}
                {renderField && renderField(mValue)}
                {append && <span className='append'>{append}</span>}
            </div>
        </LoanFieldWrap>
    )
}

export default compose(
    withLoanFieldValue,   
)(LoanField)