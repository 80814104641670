import React from 'react'

import Page from 'components/Page'

import ClientOverview from './views/ClientOverview'

const Overview = props => {
    return(
        <Page title=''>
            <ClientOverview {...props} />
        </Page>
    )
}
export default Overview