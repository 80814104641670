import styled from 'styled-components'

export default styled.div`
  background: #fff;
  box-shadow: 0px 0px 3px 0px #828282;
  margin-bottom: 20px;
  border-radius: 0 0px 10px 10px;
  overflow: hidden;
  font-weight: 300;
  color: #484848;

  strong {
    font-weight: 400;
  }

  a {
    font-weight: 400;
  }

  .row {
    height: 55px;
    align-items: center;
  }

  .money-overview {
    background: #F4F4F4;

    strong {
      margin-left: 5px;
      font-size: 20px;
      font-weight: 400;
    }
  }

  .flex {
    padding: 0 15px;
  }
`