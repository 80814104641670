import React from 'react'

const RepaymentsListItemsHeaders = ({}) => (
  <div className='row table-headers'>
    <div className='col-sm-3'>Period Start</div>
    <div className='col-sm-3'>Period End</div>
    <div className='col-sm-3'>Amount</div>
    <div className='col-sm-3'>Actions</div>
  </div>
)

export default RepaymentsListItemsHeaders