import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";

import { CloseButton } from "components/Buttons";
import { H3 } from "components/Texts";
import Row from "components/Row";
import { PrimaryButton } from 'components/Buttons' 

import DocumentsContainer from "containers/Documents";

import * as SignatureFields from 'components/FormFields/Signatures'

import Wrapper from "./Wrapper";
import Overlay from "./Overlay";
import Content from "./Content";

const SignaturePopup = ({
  objectId,
  togglePopup,
  handleUpload,
  handleComplete,
  submitForm,
  isCreating,
  ...props
}) => {
  
  const buttonText = isCreating ? 'PLEASE WAIT...' : "CREATE"
  return (
    <Wrapper>
      <Overlay onClick={togglePopup} />
      <Content>
        <H3>Request Professional Signature</H3>
        <CloseButton onClick={togglePopup}></CloseButton>
        <Row>
          <SignatureFields.TemplateField />
        </Row>
        <Row>
          <SignatureFields.ProfessionalsField />
        </Row>
        <Row style={{ justifyContent: "flex-end" }}>
            <PrimaryButton disabled={isCreating} onClick={submitForm}>{buttonText}</PrimaryButton>
        </Row>
      </Content>
    </Wrapper>
  );
};

SignaturePopup.propTypes = {
  objectId: PropTypes.string.isRequired,
};

export default compose(DocumentsContainer)(SignaturePopup);
