import React from 'react'
import { Flex } from '@rebass/grid'
import { compose, withProps, withState } from 'recompose'
import _get from 'lodash/fp/get'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFolderOpen, faFolder } from '@fortawesome/free-solid-svg-icons'

import LoanCheckLevelDataContainer from 'containers/Loan/Checks'
import DocumentsContainer from 'containers/Documents'

import Row from 'components/Row'
import { Message } from 'components/Messages'
import GenericListItem from 'components/ListItems/GenericListItem'
import { SecondaryButton } from 'components/Buttons'

import DocumentUploader from 'components/DocumentsUploader'
import DocumentsList from 'components/DocumentsUploader/DocumentsList'
import DocumentListItem from 'components/ListItems/DocumentListItem'

import LoanCheckListItemWrapper from './LoanCheckListItemWrapper'
import LoanCheckLevel from './views/LoanCheckLevel'
import LoanCheckDocumentsLink from './LoanCheckDocumentsLink'

library.add(faFolderOpen, faFolder)

const LoanCheckListItem = ({
    _id,
    name,
    description,
    loanId,
    documents,
    levels,
    onCheck,   
    levelData,
    firstLevelChecks,
    secondLevelChecks,
    thirdLevelChecks,
    fileListVisible,
    toggleFileList,
    downloadFile,
    handleRemove,
    isChecked,
    uploadVisible,
    toggleUpload,
    objectId,
    user,
    firstLevelSubmitted,
    secondLevelSubmitted,
    thirdLevelSubmitted,
    ...props
}) => {

    return(
        <LoanCheckListItemWrapper>
            {uploadVisible && <DocumentUploader {...{ objectId: objectId, toggleUploadOverride: toggleUpload }} />}

            <GenericListItem className="loan-check-list-item" style={{paddingTop: 8, paddingBottom: 8}}>
                <div className='col' style={{ minWidth: 250, alignSelf: "center" }}>
                    <Flex flexDirection='column' style={{ width: "100%", marginTop: 8, marginBottom: 8 }}>
                        <Flex alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
                            <div>
                                <h4>{name}</h4>
                                {description && <p>{description}</p>}
                            </div>
                            <LoanCheckDocumentsLink onClick={toggleFileList}>
                                <FontAwesomeIcon icon={['fal', !fileListVisible ? 'folder' : 'folder-open']} />
                                <span>{documents ? documents.length : '-'}</span>
                            </LoanCheckDocumentsLink>
                        </Flex>
                        {fileListVisible && 
                            <>
                                <DocumentsList style={{ marginTop: 15, marginBottom: 15 }}>
                                    {documents.length == 0 && 
                                        <Message text="No files found." />
                                    }
                                    {documents && documents.map(document => 
                                        <DocumentListItem 
                                        {...{
                                            key: document._id, 
                                            downloadFile, 
                                            handleRemove,
                                            removeDisabled: isChecked || thirdLevelSubmitted ,
                                            ...document, 
                                        }} />
                                    )}
                                </DocumentsList>
                                <Flex justifyContent="flex-end" width="100%">
                                    <SecondaryButton onClick={toggleUpload}>UPLOAD</SecondaryButton>
                                </Flex>
                            </>
                        }
                    </Flex>
                </div>
                <div className='col'>
                    <LoanCheckLevel 
                        {...{
                            level: 'firstLevel',
                            levelData: _get('firstLevel', levelData),
                            levelUser: firstLevelChecks,
                            user,
                            checkId: _id,
                            loanId,
                            disabled: firstLevelSubmitted,
                        }}
                    />
                </div>
                <div className='col'>
                    <LoanCheckLevel 
                        {...{
                            level: 'secondLevel',
                            levelData: _get('secondLevel', levelData),
                            levelUser: secondLevelChecks,
                            user,
                            checkId: _id,
                            loanId,
                            disabled: secondLevelSubmitted,
                        }}
                    />
                </div>
                <div className='col'>
                    <LoanCheckLevel 
                        {...{
                            level: 'thirdLevel',
                            levelData: _get('thirdLevel', levelData),
                            levelUser: thirdLevelChecks,
                            user,
                            checkId: _id,
                            loanId,
                            disabled: thirdLevelSubmitted,
                        }}
                    />
                </div>

            </GenericListItem>
        </LoanCheckListItemWrapper>
    )
}

export default compose(
    withState('fileListVisible', 'setFileListVisible', false),
    withProps(({ 
        fileListVisible, 
        setFileListVisible,
        loanId,
        checkId,
    }) => ({
        toggleFileList: () => {
            setFileListVisible(!fileListVisible)
        },
        objectId: `${loanId}_${checkId}`,
    })),
    LoanCheckLevelDataContainer,
    DocumentsContainer,
)(LoanCheckListItem)