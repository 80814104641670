import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { NavLink, useParams } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import { SecondaryButton } from 'components/Buttons'


import ViewLoanContainer from 'containers/Loan/ViewLoan'
import ActivateLoanContainer from 'containers/Loan/ActivateLoan'

import SubmenuInnerWrapper from './SubmenuInnerWrapper'

import RedeemPopup from 'pages/loans/ViewLoan/views/RedeemPopup'
import lifecycle from 'recompose/lifecycle'

const LoanDetailsSubmenu = ({
  loan, 
  getLoan,
  isFetching,
  activateLoan,
  toggleRedeemPopup,
  redeemPopupVisible,
}) => {
  const { id } = useParams()
  let clientName = ''
  if(loan && loan.client)
    clientName = loan.client.clientType == 'Personal' ? loan.client.entityName : loan.client.companyName

  return(
    <SubmenuInnerWrapper>
      <h4>Loan details to:</h4>
      <h2>{clientName}</h2>
      <ul>
        <li>
          <NavLink exact to={`/dashboard/loans/details/${id}`}>Overview</NavLink>
        </li>
        <li>
          <NavLink to={`/dashboard/loans/details/${id}/client`}>Client Details</NavLink>
        </li>
        <li>
          <NavLink to={`/dashboard/loans/details/${id}/professionals`}>Approved Professionals</NavLink>
        </li>
        <li>
          <NavLink to={`/dashboard/loans/details/${id}/extensions`}>Extensions</NavLink>
        </li>
        <li>
          <NavLink to={`/dashboard/loans/details/${id}/repayments`}>Repayments</NavLink>
        </li>
        <li>
          <NavLink to={`/dashboard/loans/details/${id}/redemption`}>Redemption</NavLink>
        </li>
        <li>
          <NavLink to={`/dashboard/loans/details/${id}/templates`}>Templates</NavLink>
        </li>
        <li>
          <NavLink to={`/dashboard/loans/details/${id}/documents`}>Documents</NavLink>
        </li>
        <li>
          <NavLink to={`/dashboard/loans/details/${id}/checklist`}>Loan Checks</NavLink>
        </li>
        <li>
            <a>
              {loan && 
                <SecondaryButton 
                    style={{marginRight: 5, width: '100%'}}
                    disabled={!loan.isActive}
                    onClick={toggleRedeemPopup}>
                    REDEEM
                </SecondaryButton>
              }
            </a>
        </li>
        <li>
          <a>
            {loan && 
              <SecondaryButton 
                style={{ width: '100%' }}
                disabled={loan && loan.status !== "Ready" && (!loan.isActive || !loan.isClosed)}
                onClick={() => activateLoan(id, () => getLoan(id))}>
                ACTIVATE
              </SecondaryButton>
            }
          </a>
        </li>
      </ul>
      {redeemPopupVisible && 
        <RedeemPopup {...{ 
          handleToggle: toggleRedeemPopup, 
          onRedeem: () => getLoan(id),
          title: "Redeem Loan", 
          loanId: id,
        }}/>
      }
    </SubmenuInnerWrapper>
  )
}

const mapState = createStructuredSelector({
  loan: state => state.global.loanDetails
})

export default compose(
  connect(mapState),
  ViewLoanContainer,
  ActivateLoanContainer, 
)(LoanDetailsSubmenu)