import API from 'api'

const getLoanForReviewRequest = () => ({ type: "FETCH/REQUEST" })
const getLoanForReviewSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getLoanForReviewError = err => ({ type: "FETCH/ERROR", error: err})

export const getLoanForReview = (id, accessCode) => dispatch => {
    dispatch(getLoanForReviewRequest())

    API
        .get(`/api/clients/loan?id=${id}&accessCode=${accessCode}`)
        .then(res => res.json())
        .then(json => { dispatch(getLoanForReviewSuccess(json)) })
        .catch(err => dispatch(getLoanForReviewError(err)))
}

const getLoanAccessRequest = () => ({ type: "FETCH/REQUEST" })
const getLoanAccessSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getLoanAccessError = err => ({ type: "FETCH/ERROR", error: err})

export const getLoanAccess = (id) => dispatch => {
    dispatch(getLoanAccessRequest())

    API
        .get(`/api/clients/loan/access?id=${id}`)
        .then(res => res.json())
        .then(json => {dispatch(getLoanAccessSuccess(json)) })
        .catch(err => dispatch(getLoanAccessError(err)))
}


const reviewLoanSubmit = () => ({ type: "FETCH/REQUEST" })
const reviewLoanSuccess = data => ({ type: "FETCH/SUCCESS", data })
const reviewLoanError = err => ({ type: "FETCH/ERROR", error: err})

export const confirmLoanReview = (id, formValues, callback) => dispatch => {
    dispatch(reviewLoanSubmit())

    API
        .post(`/api/clients/loan/review-confirm?id=${id}`, { body: JSON.stringify(formValues) })
        .then(res => res.json())
        .then(json => { 
            
            dispatch(reviewLoanSuccess(json)) 
            if(callback) callback(json.loan)
        })
        .catch(err => dispatch(reviewLoanError(err)))

}