import API from 'api'
import { compose, lifecycle, withProps, withHandlers, withState } from 'recompose'

export default compose(
    withState('progress', 'setProgress', 0),
    withHandlers({
        handleTransferStart: ({ setProgress }) => evt => {
            setProgress(0)
        },
        handleProgressUpdate: ({ setProgress }) => evt => {
            if( evt.lengthComputable ) {
                let progress = 100 * evt.loaded / evt.total
                setProgress(progress)
            }
        },
        handleTransferComplete: ({ setProgress }) => evt => {
            setProgress(100)
        },
    }),
    withProps(({
        handleTransferStart,
        handleProgressUpdate,
        handleTransferComplete,
        handleComplete,
    }) => ({
        upload: (objectId, file) => {
            
            const formData = new FormData()
            formData.append("file", file)
            formData.append("objectId", objectId)
            
            const xhr = new XMLHttpRequest()
            xhr.withCredentials = true
            xhr.upload.addEventListener("loadstart", handleTransferStart) 
            xhr.upload.addEventListener("progress", handleProgressUpdate, false)
            xhr.upload.addEventListener("load", handleTransferComplete, false)
            xhr.onreadystatechange = () => {
                if(xhr.readyState === 4) {
                    if(handleComplete) {
                        setTimeout(() => {
                            handleComplete(file)
                        }, 500)
                    }
                }
            }
            xhr.open("POST", API.getEndpointURL("/api/docs/upload"), true)
            xhr.send(formData)
        }
    })),
    lifecycle({
        componentDidMount() {
            const { file, objectId, upload } = this.props
            upload(objectId, file)
        }
    }),
)