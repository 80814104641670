import styled from 'styled-components'

import {COLORS} from 'consts'

export default styled.div`
  background: ${COLORS.lightGrayWithOpacity2};

  width: 100%;
  padding: 10px;

  .main-property-box {
    margin: 0px;
  }
`