import React from 'react'

import Row from 'components/Row'
import { Panel } from 'components/Forms'

import * as ApplicantFields from './ApplicantFields'

const RenderApplicant = ({
    applicant,
    index,
    ...props
}) => {
    return(
        <Panel title={`Applicant #${index + 1}`}>
            <Row>  
                <ApplicantFields.ApplicantCourtJudgementField mr={10} {...{ applicant, index }} />
                <ApplicantFields.ApplicantIVAField {...{ applicant, index }} />
            </Row>
            <Row>
                <ApplicantFields.ApplicantEverBankruptField {...{ applicant, index }} mr={10} />
                <ApplicantFields.ApplicantArrearsField {... { applicant, index }} />
            </Row>
            <Row>
                <ApplicantFields.ApplicantDefaultsFields mr={10} {...{ applicant, index }} />
                <ApplicantFields.ApplicantPaydayLoansField {...{ applicant, index }} />
            </Row>
        </Panel>
    )
}

export default RenderApplicant