import React from 'react';
import { FormStep } from 'components/Forms'

import { ClientType } from 'reducers/NewClientState'

import CorporateDetails from '../NewClientCorporate'
import PersonalDetails from '../NewClientPersonal'

const Borrower = ({
    formValues,
    ...props
}) => {
    const { clientType } = formValues

    return(
        <FormStep title="Borrower Details">
            {clientType === ClientType.Personal && <PersonalDetails />}
            {clientType === ClientType.Corporate && <CorporateDetails />}        
        </FormStep>
    )
}

export default Borrower