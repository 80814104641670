import React from 'react'

import Row from 'components/Row'
import { Panel } from 'components/Forms'
import { PersonalGuaranteeFields } from 'components/FormFields/Loan'

const MainProperty = () => (
    <Panel title="Personal Guarantee">
        <Row>
            <PersonalGuaranteeFields.PersonalGuaranteeField mr={10} />
            <PersonalGuaranteeFields.PersonalGuaranteeAmountField />
        </Row>
    </Panel>
)

export default MainProperty