import React from 'react'

import { PrimaryButton } from 'components/Buttons'
import { Text } from 'components/Texts'

import PropertiesWrap from './PropertiesWrap'
import RenderProperty from './RenderProperty'

const RenderProperties = ({
    fields,
    pushField,
    ...props
}) => (
    <PropertiesWrap>
        <div className='properties-actions'>
            <Text>Add additional properties.</Text>
            <PrimaryButton onClick={pushField}>
                Add Property
            </PrimaryButton>
        </div>
        {fields && fields.length > 0 &&
            <div className='properties-list'>
                {fields.map((property, index) => 
                    <RenderProperty {...{ property, index, ...props }} key={index} />    
                )}
            </div>
        }
    </PropertiesWrap>
)

export default RenderProperties