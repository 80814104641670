import { compose, withProps, lifecycle } from 'recompose'
import { Map } from 'immutable'
import { PropTypes } from 'prop-types'

const FieldArrayHelper = ({ hide, renderFields, ...props }) =>
  renderFields(props)

FieldArrayHelper.propTypes = {
  children: PropTypes.func,
  fields: PropTypes.object,
  minFields: PropTypes.number,
}

export default compose(
  withProps(({ fields, fieldInitialValues }) => ({
    pushField: () => fields.push(new Map(fieldInitialValues)),
    removeField: (index) => fields.remove(index),
  })),
  lifecycle({
    componentDidMount() {
      const { minFields, fieldInitialValues, fields } = this.props

      if(minFields && minFields > 0) {
        for(let i= 0; i< minFields; i++) {
          fields.push(new Map(fieldInitialValues))
        }
      }
    }
  })
)(FieldArrayHelper)
