import React from 'react'
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import Row from 'components/Row'
import { Panel } from 'components/Forms'
import { MainPropertyFields } from 'components/FormFields/Loan'

import { selectPersonalGuarantee } from 'containers/Loan/AddLoan/selectors'

const mapState = createStructuredSelector({
    isPersonalGuarantee: selectPersonalGuarantee
})

const MainProperty = ({
    isPersonalGuarantee,
}) => {
    if(isPersonalGuarantee)
        return null

    return(
        <Panel title="Main Property Details">
            <Row>
                <MainPropertyFields.MainPropertyUseField />
            </Row>
            <Row>
                <MainPropertyFields.MainPropertyAddressField />
            </Row>
            <Row>  
                <MainPropertyFields.MainPropertyPostcodeField mr={10} />
                <MainPropertyFields.MainPropertyConditionField />
            </Row>
            <Row>
                <MainPropertyFields.MainPropertyTypeField />
            </Row>
            <Row>
                <MainPropertyFields.MainPropertyPriceField mr={10} />
                <MainPropertyFields.MainPropertyValuationField />
            </Row>
            <Row>
                <MainPropertyFields.MainPropertyTernureField mr={10} />
                <MainPropertyFields.MainPropertyTotalBedroomsField />
            </Row>
            <Row>
                <MainPropertyFields.MainPropertyExAuthorityField />
                <MainPropertyFields.MainPropertyOccupancyField ml={10} />
            </Row>
            <Row>
                <MainPropertyFields.MainPropertyUnexpiredTermLeaseField mr={10} />
                <MainPropertyFields.MainPropertyIsNewBuildField />
            </Row>
            <Row>
                <MainPropertyFields.MainPropertyEquitableChargeField />
            </Row>
        </Panel>
    )
}

export default compose(
    connect(mapState),
)(MainProperty)