import API from 'api'

const removeCheckRequest = () => ({ type: "FETCH/REQUEST" })
const removeCheckSuccess = data => ({ type: "FETCH/SUCCESS", data })
const removeCheckError = err => ({ type: "FETCH/ERROR", error: err})

export const removeCheck = (id, callback) => dispatch => {
    dispatch(removeCheckRequest())

    API
        .get(`/api/checks/delete?id=${id}`)
        .then(res => res.json())
        .then(json => { 
            dispatch(removeCheckSuccess(json))
            if(callback)
                callback()
        })
        .catch(err => {  dispatch(removeCheckError(err)) })
}