import React from 'react'
import { compose, lifecycle, withProps, withState } from 'recompose'
import _uniqueId from 'lodash/uniqueId'
import SignaturePad from 'signature_pad'

import { API_URL } from 'api'

import InputWrapper from 'components/Fields/InputWrapper'
import { SecondaryButton } from 'components/Buttons'
import SignatureFieldWrapper from './SignatureFieldWrapper'

const SignatureField = ({
    input,
    canvasId,
    wrapperId,
    label,
    large,
    hasValue,
    disabled,
    placeholder,
    clearSignature,
    onSignatureChange,
    ...props
}) => {
    
    return(
    <InputWrapper
        {...{ ...props, label, large, disabled, placeholder }}>
        <SignatureFieldWrapper>
            <div className='signature-box' id={wrapperId}>
                {hasValue && !input.value.url &&
                    <div className='signature-value'>
                        <img src={`${API_URL}/docs/signatures/${input.value}`} />
                    </div>
                }       
                {hasValue &&
                    <SecondaryButton 
                        className='signature-clear'
                        onClick={clearSignature}>
                        CLEAR
                    </SecondaryButton>
                }
                <canvas 
                    id={canvasId}
                    onTouchEnd={onSignatureChange}
                    onMouseUp={onSignatureChange}
                    className='signature-canvas' 
                />
            </div>
        </SignatureFieldWrapper>
    </InputWrapper>
    )
}

export default compose(
    withState('pad', 'setPad', null),
    withProps(({ pad, input }) => ({
        canvasId: _uniqueId("canvas_"),
        wrapperId: _uniqueId("canvasWrapper_"),
        hasValue: !!((input && input.value)),
        onSignatureChange: e => {
            if( input ) {
                input.onChange({
                    url: pad.toDataURL(),
                    data: pad.toData(),
                })
            }
        },
        clearSignature: () => {
            pad.clear()

            if( input ) 
                input.onChange(null)
        },
    })),
    lifecycle({
        componentDidMount() {
            const { 
                canvasId, 
                wrapperId, 
                setPad, 
                input 
            } = this.props

            const canvas = document.getElementById(canvasId)
            const canvasWrapper = document.getElementById(wrapperId)
            const pad = new SignaturePad(canvas)

            canvas.width = canvasWrapper.offsetWidth;
            canvas.height = canvasWrapper.offsetHeight;
            pad.width = canvasWrapper.offsetWidth
            pad.height = canvasWrapper.offsetHeight

            if( input && input.value && input.value.data ) 
                pad.fromData(input.value.data)

            setPad(pad)
        },
    })
)(SignatureField)