import styled from 'styled-components'

export default styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 5px;
    background: #fff;
    font-size: 14px;
    color: #666;
    margin: 10px 0;
    font-style: italic;
    text-transform: none;

    ${props => props.success && `
        background: #ccead0;
        border: 1px solid #b8dabc;
    `} 
`