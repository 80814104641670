import React from 'react'
import { Data } from 'slate'
import Select from 'react-select'
import { compose, withProps, withHandlers } from 'recompose'

import { DEFAULT_NODE } from '../consts'

import ToolbarWrapper from './ToolbarWrapper'
import ToolbarButton from './ToolbarButton'
import Icon from './Icon'

const renderBlockButton = (type, icon, hasBlock, onClick, value) => {
    let isActive = hasBlock(type)

    if (['numbered-list', 'bulleted-list'].includes(type)) {
      const { document, blocks } = value
      
      if (blocks.size > 0) {
        const parent = document.getParent(blocks.first().key)
        isActive = hasBlock('list-item') && parent && parent.type === type
      }
    }

    if(['alignLeft', 'alignCenter', 'alignRight'].includes(type)) {
        const { document, blocks } = value
      
      if (blocks.size > 0) {
        const parent = document.getParent(blocks.first().key)
        isActive = parent && parent.type === type
      }
    }


    return (
      <ToolbarButton
        active={isActive}
        onMouseDown={e => onClick(e, type)}>
        <Icon>{icon}</Icon>
      </ToolbarButton>
    )
}

const renderMarkButton = (type, icon, isActive, onClick) => {
    return (
      <ToolbarButton
        active={isActive}
        onMouseDown={e => onClick(e, type)}>
        <Icon>{icon}</Icon>
      </ToolbarButton>
    )
}

const renderAttributeButton = (type, value, icon, isActive, onClick) => {
    return (
      <ToolbarButton
        active={isActive}
        onMouseDown={e => onClick(e, type, value)}>
        <Icon>{icon}</Icon>
      </ToolbarButton>
    )
}

const Toolbar = ({
    value,
    editor,
    hasMark,
    hasBlock,
    hasAttribute,
    onClickMark,
    onClickBlock,
    onClickAttribute,
    onClickTableButton,
    showTagsPopup,
}) => {
    return(
        <ToolbarWrapper>
            {renderMarkButton('bold', 'format_bold', hasMark('bold'), onClickMark)}
            {renderMarkButton('italic', 'format_italic', hasMark('italic'), onClickMark)}
            {renderMarkButton('underline', 'format_underlined', hasMark('underline'), onClickMark)}
            {renderBlockButton('alignLeft', 'format_align_left', hasBlock, onClickBlock, value)}
            {renderBlockButton('alignCenter', 'format_align_center', hasBlock, onClickBlock, value)}
            {renderBlockButton('alignRight', 'format_align_right', hasBlock, onClickBlock, value)}
            {renderBlockButton('heading1', 'looks_one', hasBlock, onClickBlock, value)}
            {renderBlockButton('heading2', 'looks_two', hasBlock, onClickBlock, value)}
            {renderBlockButton('heading3', 'looks_3', hasBlock, onClickBlock, value)}
            {renderBlockButton('numbered-list', 'format_list_numbered', hasBlock, onClickBlock, value)}
            {renderBlockButton('bulleted-list', 'format_list_bulleted', hasBlock, onClickBlock, value)}
            {renderBlockButton('table', 'table_chart', hasBlock, onClickTableButton, value)}
            {(hasBlock("numbered-list") || hasBlock("bulleted-list") || hasBlock("list-item")) && 
                renderBlockButton('format-clear', 'format_clear', hasBlock, onClickBlock, value)
            }
            <ToolbarButton
                onMouseDown={e => showTagsPopup()}>
                <Icon>developer_board</Icon>
            </ToolbarButton>
        </ToolbarWrapper>
    )
}

export default compose(
    withProps(({ editor, value }) => ({
        hasMark: type => {
            return value.activeMarks.some(mark => mark.type === type)
        },
        hasBlock: type => {
            if(['alignLeft', 'alignCenter', 'alignRight'].includes(type)) {
                const { document, blocks } = value
              
                if (blocks.size > 0) {
                    const parent = document.getParent(blocks.first().key)
                    return parent && parent.type === type   
                }
            }
            if(type === 'table') {
                return editor && editor.isSelectionInTable()
            }
            return value.blocks.some(node => { return node.type === type })
        },
        hasAttribute: (name, val) => {
            return value.blocks.some(node => {
                //
                return node.data[name] && node.data[name] == val
            })
        }
    })),
    withHandlers({
        onClickMark: ({ editor }) => (e, type) => {
            e.preventDefault()
            editor.toggleMark(type)
        },
        onClickBlock: ({ editor, value, hasBlock }) => (e, type) => {
            e.preventDefault()

            const { value } = editor
            const { document, focusBlock } = value
        
            // Handle everything but list buttons.
            if(type === 'alignLeft' || type === 'alignCenter' || type === 'alignRight') {
                const isAligned = hasBlock("alignRight") || hasBlock("alignCenter") || hasBlock("alignLeft")
                if( isAligned ) {
                    editor
                        .unwrapBlock('alignRight')
                        .unwrapBlock('alignLeft')
                        .unwrapBlock('alignCenter')
                } else {
                    editor
                        .unwrapBlock('alignLeft')
                        .unwrapBlock('alignRight')
                        .unwrapBlock('alignCenter')
                        .wrapBlock(type)
                }
                    
            } else if(type == "format-clear") {
                editor
                    .setBlocks(DEFAULT_NODE)
                    .unwrapBlock('bulleted-list')
                    .unwrapBlock('numbered-list')
            } else if (type !== 'bulleted-list' && type !== 'numbered-list') {
                const isActive = hasBlock(type)
                const isList = hasBlock('list-item')
                
                editor
                    .setBlocks(isActive ? DEFAULT_NODE : type)
            } else {
                // Handle the extra wrapping required for list buttons.
                const isList = hasBlock('list-item')
                const isType = value.blocks.some(block => {
                    return !!document.getClosest(block.key, parent => parent.type === type)
                })
        
                if (isList && isType) {
                    editor
                        .setBlocks('list-item')
                        .wrapBlock(type)
                } else if (isList) {
                    editor
                        .wrapBlock(type)
                } else {
                    editor.setBlocks('list-item').wrapBlock(type)
                }
            }
        },
        onClickTableButton: ({ editor }) => (e) => {
            const { value } = editor
            const { document, focusBlock } = value
            const inTable = editor.isSelectionInTable()

            if(!inTable) {
                editor.insertTable(2, 2)
                editor.focus()
            } else {
                editor.removeTable()
            }
        }
    })
)(Toolbar)