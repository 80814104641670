import API from 'api'

const getProfessionalsSubmit = () => ({ type: "FETCH/REQUEST" })
const getProfessionalsSuccess = data => ({ type: "FETCH/SUCCESS", data })
const getProfessionalsError = err => ({ type: "FETCH/ERROR", error: err})

export const getProfessionals = () => dispatch => {
    dispatch(getProfessionalsSubmit())

    API
        .get('/api/system/professionals')
        .then(res => res.json())
        .then(json => { 
            dispatch(getProfessionalsSuccess({ professionals: json.data })) 
        })
        .catch(err => dispatch(getProfessionalsError(err)))
}