import React from 'react'
import { compose } from 'recompose'

import Popup from 'components/globals/Popup'
import Spinner from 'components/globals/Spinner'
import { PrimaryButton } from 'components/Buttons'
import { TextAreaField } from 'components/FormFields'

import RejectContainer from 'containers/Loan/Checks/Reject'


const RepaymentPopup = ({
  loanId,
  handleSubmit,
  isUpdating,
  ...props
}) => {
  return(
    <Popup 
      button={
        <PrimaryButton disabled={isUpdating} onClick={handleSubmit}>
          SAVE
          {isUpdating && <Spinner />}
        </PrimaryButton>
      }
      {...props}>
      <TextAreaField 
          name='message' 
          label='Message'
      /> 
    </Popup>
  )
}

export default compose(
  RejectContainer,  
)(RepaymentPopup)