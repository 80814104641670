import React from 'react';

import { FormStep } from 'components/Forms'
import { Text } from 'components/Texts'

import { LoanAdditionalInfoFields } from 'components/FormFields/Loan'

const AdditionalInfo = () => (
    <FormStep title="Additional Info">
        <Text>Please provide a brief summary of your clients requirements, overview of the deal, listing any ‘soft-facts’, case merits, client experience</Text>
        <LoanAdditionalInfoFields.AdditionalInfoClientRequirementsField />
    </FormStep>
)

export default AdditionalInfo