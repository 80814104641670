import { compose, withProps } from 'recompose'

import Field from 'components/FormFields/Field'

import { validateRequired } from 'utils/validators'

const validators = [validateRequired]

export default compose(
    withProps(props => ({
        name: 'country',
        label: "Country of Incorporation",
        validators,
    }))
)(Field)