import { compose, withProps } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

const validators = [validateRequired]

const options = [
    { value: 'Mr', label: 'Mr' },
    { value: 'Mrs', label: 'Mrs' },
    { value: 'Ms', label: 'Ms' },
]

export default compose(
    withProps(({ applicant, ...props }) => ({
        placeholder: 'Select Title',
        name: `personalDetails.title`,
        wrapperConfig: { width: '100%' },
        component: DropdownField,
        label: "Title",
        isClearable: true,
        options: options,
        full: true,
        validators,
    })),
)(Field)
