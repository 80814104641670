import { compose, withProps, lifecycle } from 'recompose'

import { validateRequired } from 'utils/validators'

import Field from 'components/FormFields/Field'
import { DropdownField } from 'components/Fields'

const validators = [validateRequired]

const options = [
    { value: 'loan', label: 'Loan Field' },
    { value: 'client', label: 'Client Field' },
    { value: 'input', label: 'Input' },
    { value: 'textarea', label: 'Textarea' },
    { value: 'formula', label: 'Formula' },
    { value: 'signature', label: 'Signature' },
    { value: 'ds_signature', label: 'DocuSign Signature' },
]

export default compose(
    withProps(props => ({
        name: `type`,
        placeholder: 'Select Type',
        wrapperConfig: { width: '100%' },
        options: options,
        component: DropdownField,
        label: "Type",
        isClearable: true,
        full: true,
        validators,
    })),
)(Field)
